import React from "react";
import $ from "jquery";
import echarts from "echarts";
import dayjs from "dayjs";

//首页
class GsmHomepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            yh: 0,
            sb: 0,
            zx: 0,
            lx: 0,
            bj: 0,
            ty: 0,
            dtsj: [],
            tx: [],
            zsdq: [],
            sbdq: [],
            jzdq: [],
        }
        this.op();
    }

    op = () => {
        //pie test start

        const hem = this;
        window.ajax({
            cache: true,
            type: "POST",
            url: "/homepage",
            error: function () {
            },
            fail: function () {
            },
            success: function (data) {
                const json_data = JSON.parse(data);

                if (hem.props.GsmFlag) {
                    window.gsm_app.setState({
                        notification: json_data["tx"],
                    })
                }
                hem.setState({
                    yh: json_data.yh,
                    sb: json_data.sb,
                    zx: json_data.zx,
                    lx: json_data.lx,
                    bj: json_data.bj,
                    ty: json_data.ty,
                    dtsj: json_data.dt,
                    tx: window.gsm_app.state.notification,
                    zsdq: json_data.zsdqsbdqjzdq.filter(function (item) {
                        return item["ii"] == "zsdq"
                    }),
                    sbdq: json_data.zsdqsbdqjzdq.filter(function (item) {
                        return item["ii"] == "sbdq"
                    }),
                    jzdq: json_data.zsdqsbdqjzdq.filter(function (item) {
                        return item["ii"] == "jzdq"
                    }),
                })
                let dtsj = json_data["dt"]
                const cityMap = {
                    "安徽": "340000",
                    "澳门": '820000',
                    "北京": '110000',
                    "重庆": '500000',
                    "福建": '350000',
                    "甘肃": '620000',
                    "广东": '440000',
                    "广西": "450000",
                    "贵州": '520000',
                    "海南": '460000',
                    "河北": '130000',
                    "黑龙江": '230000',
                    "河南": '410000',
                    "湖北": '420000',
                    "湖南": '430000',
                    "江苏": '320000',
                    "江西": '360000',
                    "吉林": '220000',
                    "辽宁": '210000',
                    "内蒙古": '150000',
                    "宁夏": '640000',
                    "青海": '630000',
                    "山东": '370000',
                    "上海": '310000',
                    "山西": '140000',
                    "四川": '510000',
                    "台湾": '710000',
                    "天津": '120000',
                    "新疆": '650000',
                    "陕西": '610000',
                    "西藏": '540000',
                    "云南": '530000',
                    "浙江": "330000",
                    "香港": "810000",
                    "北京市": "110100",
                    "天津市": "120100",
                    "上海市": "310100",
                    "重庆市": "500100",
                    "崇明县": "310200",            // ??
                    "湖北省直辖县市": "429000",       // ??
                    "铜仁市": "522200",            // ??
                    "毕节市": "522400",            // ??

                    "石家庄市": "130100",
                    "唐山市": "130200",
                    "秦皇岛市": "130300",
                    "邯郸市": "130400",
                    "邢台市": "130500",
                    "保定市": "130600",
                    "张家口市": "130700",
                    "承德市": "130800",
                    "沧州市": "130900",
                    "廊坊市": "131000",
                    "衡水市": "131100",
                    "太原市": "140100",
                    "大同市": "140200",
                    "阳泉市": "140300",
                    "长治市": "140400",
                    "晋城市": "140500",
                    "朔州市": "140600",
                    "晋中市": "140700",
                    "运城市": "140800",
                    "忻州市": "140900",
                    "临汾市": "141000",
                    "吕梁市": "141100",
                    "呼和浩特市": "150100",
                    "包头市": "150200",
                    "乌海市": "150300",
                    "赤峰市": "150400",
                    "通辽市": "150500",
                    "鄂尔多斯市": "150600",
                    "呼伦贝尔市": "150700",
                    "巴彦淖尔市": "150800",
                    "乌兰察布市": "150900",
                    "兴安盟": "152200",
                    "锡林郭勒盟": "152500",
                    "阿拉善盟": "152900",
                    "沈阳市": "210100",
                    "大连市": "210200",
                    "鞍山市": "210300",
                    "抚顺市": "210400",
                    "本溪市": "210500",
                    "丹东市": "210600",
                    "锦州市": "210700",
                    "营口市": "210800",
                    "阜新市": "210900",
                    "辽阳市": "211000",
                    "盘锦市": "211100",
                    "铁岭市": "211200",
                    "朝阳市": "211300",
                    "葫芦岛市": "211400",
                    "长春市": "220100",
                    "吉林市": "220200",
                    "四平市": "220300",
                    "辽源市": "220400",
                    "通化市": "220500",
                    "白山市": "220600",
                    "松原市": "220700",
                    "白城市": "220800",
                    "延边朝鲜族自治州": "222400",
                    "哈尔滨市": "230100",
                    "齐齐哈尔市": "230200",
                    "鸡西市": "230300",
                    "鹤岗市": "230400",
                    "双鸭山市": "230500",
                    "大庆市": "230600",
                    "伊春市": "230700",
                    "佳木斯市": "230800",
                    "七台河市": "230900",
                    "牡丹江市": "231000",
                    "黑河市": "231100",
                    "绥化市": "231200",
                    "大兴安岭地区": "232700",
                    "南京市": "320100",
                    "无锡市": "320200",
                    "徐州市": "320300",
                    "常州市": "320400",
                    "苏州市": "320500",
                    "南通市": "320600",
                    "连云港市": "320700",
                    "淮安市": "320800",
                    "盐城市": "320900",
                    "扬州市": "321000",
                    "镇江市": "321100",
                    "泰州市": "321200",
                    "宿迁市": "321300",
                    "杭州市": "330100",
                    "宁波市": "330200",
                    "温州市": "330300",
                    "嘉兴市": "330400",
                    "湖州市": "330500",
                    "绍兴市": "330600",
                    "金华市": "330700",
                    "衢州市": "330800",
                    "舟山市": "330900",
                    "台州市": "331000",
                    "丽水市": "331100",
                    "合肥市": "340100",
                    "芜湖市": "340200",
                    "蚌埠市": "340300",
                    "淮南市": "340400",
                    "马鞍山市": "340500",
                    "淮北市": "340600",
                    "铜陵市": "340700",
                    "安庆市": "340800",
                    "黄山市": "341000",
                    "滁州市": "341100",
                    "阜阳市": "341200",
                    "宿州市": "341300",
                    "六安市": "341500",
                    "亳州市": "341600",
                    "池州市": "341700",
                    "宣城市": "341800",
                    "福州市": "350100",
                    "厦门市": "350200",
                    "莆田市": "350300",
                    "三明市": "350400",
                    "泉州市": "350500",
                    "漳州市": "350600",
                    "南平市": "350700",
                    "龙岩市": "350800",
                    "宁德市": "350900",
                    "南昌市": "360100",
                    "景德镇市": "360200",
                    "萍乡市": "360300",
                    "九江市": "360400",
                    "新余市": "360500",
                    "鹰潭市": "360600",
                    "赣州市": "360700",
                    "吉安市": "360800",
                    "宜春市": "360900",
                    "抚州市": "361000",
                    "上饶市": "361100",
                    "济南市": "370100",
                    "青岛市": "370200",
                    "淄博市": "370300",
                    "枣庄市": "370400",
                    "东营市": "370500",
                    "烟台市": "370600",
                    "潍坊市": "370700",
                    "济宁市": "370800",
                    "泰安市": "370900",
                    "威海市": "371000",
                    "日照市": "371100",
                    "莱芜市": "371200",
                    "临沂市": "371300",
                    "德州市": "371400",
                    "聊城市": "371500",
                    "滨州市": "371600",
                    "菏泽市": "371700",
                    "郑州市": "410100",
                    "开封市": "410200",
                    "洛阳市": "410300",
                    "平顶山市": "410400",
                    "安阳市": "410500",
                    "鹤壁市": "410600",
                    "新乡市": "410700",
                    "焦作市": "410800",
                    "濮阳市": "410900",
                    "许昌市": "411000",
                    "漯河市": "411100",
                    "三门峡市": "411200",
                    "南阳市": "411300",
                    "商丘市": "411400",
                    "信阳市": "411500",
                    "周口市": "411600",
                    "驻马店市": "411700",
                    "省直辖县级行政区划": "469000",
                    "武汉市": "420100",
                    "黄石市": "420200",
                    "十堰市": "420300",
                    "宜昌市": "420500",
                    "襄阳市": "420600",
                    "鄂州市": "420700",
                    "荆门市": "420800",
                    "孝感市": "420900",
                    "荆州市": "421000",
                    "黄冈市": "421100",
                    "咸宁市": "421200",
                    "随州市": "421300",
                    "恩施土家族苗族自治州": "422800",
                    "长沙市": "430100",
                    "株洲市": "430200",
                    "湘潭市": "430300",
                    "衡阳市": "430400",
                    "邵阳市": "430500",
                    "岳阳市": "430600",
                    "常德市": "430700",
                    "张家界市": "430800",
                    "益阳市": "430900",
                    "郴州市": "431000",
                    "永州市": "431100",
                    "怀化市": "431200",
                    "娄底市": "431300",
                    "湘西土家族苗族自治州": "433100",
                    "广州市": "440100",
                    "韶关市": "440200",
                    "深圳市": "440300",
                    "珠海市": "440400",
                    "汕头市": "440500",
                    "佛山市": "440600",
                    "江门市": "440700",
                    "湛江市": "440800",
                    "茂名市": "440900",
                    "肇庆市": "441200",
                    "惠州市": "441300",
                    "梅州市": "441400",
                    "汕尾市": "441500",
                    "河源市": "441600",
                    "阳江市": "441700",
                    "清远市": "441800",
                    "东莞市": "441900",
                    "中山市": "442000",
                    "潮州市": "445100",
                    "揭阳市": "445200",
                    "云浮市": "445300",
                    "南宁市": "450100",
                    "柳州市": "450200",
                    "桂林市": "450300",
                    "梧州市": "450400",
                    "北海市": "450500",
                    "防城港市": "450600",
                    "钦州市": "450700",
                    "贵港市": "450800",
                    "玉林市": "450900",
                    "百色市": "451000",
                    "贺州市": "451100",
                    "河池市": "451200",
                    "来宾市": "451300",
                    "崇左市": "451400",
                    "海口市": "460100",
                    "三亚市": "460200",
                    "三沙市": "460300",
                    "成都市": "510100",
                    "自贡市": "510300",
                    "攀枝花市": "510400",
                    "泸州市": "510500",
                    "德阳市": "510600",
                    "绵阳市": "510700",
                    "广元市": "510800",
                    "遂宁市": "510900",
                    "内江市": "511000",
                    "乐山市": "511100",
                    "南充市": "511300",
                    "眉山市": "511400",
                    "宜宾市": "511500",
                    "广安市": "511600",
                    "达州市": "511700",
                    "雅安市": "511800",
                    "巴中市": "511900",
                    "资阳市": "512000",
                    "阿坝藏族羌族自治州": "513200",
                    "甘孜藏族自治州": "513300",
                    "凉山彝族自治州": "513400",
                    "贵阳市": "520100",
                    "六盘水市": "520200",
                    "遵义市": "520300",
                    "安顺市": "520400",
                    "黔西南布依族苗族自治州": "522300",
                    "黔东南苗族侗族自治州": "522600",
                    "黔南布依族苗族自治州": "522700",
                    "昆明市": "530100",
                    "曲靖市": "530300",
                    "玉溪市": "530400",
                    "保山市": "530500",
                    "昭通市": "530600",
                    "丽江市": "530700",
                    "普洱市": "530800",
                    "临沧市": "530900",
                    "楚雄彝族自治州": "532300",
                    "红河哈尼族彝族自治州": "532500",
                    "文山壮族苗族自治州": "532600",
                    "西双版纳傣族自治州": "532800",
                    "大理白族自治州": "532900",
                    "德宏傣族景颇族自治州": "533100",
                    "怒江傈僳族自治州": "533300",
                    "迪庆藏族自治州": "533400",
                    "拉萨市": "540100",
                    "昌都地区": "542100",
                    "山南地区": "542200",
                    "日喀则地区": "542300",
                    "那曲地区": "542400",
                    "阿里地区": "542500",
                    "林芝地区": "542600",
                    "西安市": "610100",
                    "铜川市": "610200",
                    "宝鸡市": "610300",
                    "咸阳市": "610400",
                    "渭南市": "610500",
                    "延安市": "610600",
                    "汉中市": "610700",
                    "榆林市": "610800",
                    "安康市": "610900",
                    "商洛市": "611000",
                    "兰州市": "620100",
                    "嘉峪关市": "620200",
                    "金昌市": "620300",
                    "白银市": "620400",
                    "天水市": "620500",
                    "武威市": "620600",
                    "张掖市": "620700",
                    "平凉市": "620800",
                    "酒泉市": "620900",
                    "庆阳市": "621000",
                    "定西市": "621100",
                    "陇南市": "621200",
                    "临夏回族自治州": "622900",
                    "甘南藏族自治州": "623000",
                    "西宁市": "630100",
                    "海东地区": "632100",
                    "海北藏族自治州": "632200",
                    "黄南藏族自治州": "632300",
                    "海南藏族自治州": "632500",
                    "果洛藏族自治州": "632600",
                    "玉树藏族自治州": "632700",
                    "海西蒙古族藏族自治州": "632800",
                    "银川市": "640100",
                    "石嘴山市": "640200",
                    "吴忠市": "640300",
                    "固原市": "640400",
                    "中卫市": "640500",
                    "乌鲁木齐市": "650100",
                    "克拉玛依市": "650200",
                    "吐鲁番地区": "652100",
                    "哈密地区": "652200",
                    "昌吉回族自治州": "652300",
                    "博尔塔拉蒙古自治州": "652700",
                    "巴音郭楞蒙古自治州": "652800",
                    "阿克苏地区": "652900",
                    "克孜勒苏柯尔克孜自治州": "653000",
                    "喀什地区": "653100",
                    "和田地区": "653200",
                    "伊犁哈萨克自治州": "654000",
                    "塔城地区": "654200",
                    "阿勒泰地区": "654300",
                    "自治区直辖县级行政区划": "659000",
                    "台湾省": "710000",
                    "香港特别行政区": "810100",
                    "澳门特别行政区": "820000"
                };

                let geoMapJson = [];

                document.getElementById("world-map-markers").style.width = document.getElementById("world-map-markers").style.width + "px"
                var chart1 = echarts.init(document.getElementById("world-map-markers"));

                var geoCoordMap = [];
                for (var i = 0; i < dtsj.length; i++) {
                    geoCoordMap.push({
                        name: dtsj[i].name,
                        sheng: dtsj[i].sheng,
                        shi: dtsj[i].shi,
                        qu: dtsj[i].qu,
                        value: [
                            parseFloat(dtsj[i]["longitude"]),
                            parseFloat(dtsj[i]["latitude"]),
                        ],
                        sbsl: (dtsj[i]["sbsl"] == "NULL" ? 0 : parseInt(dtsj[i]["sbsl"])),
                        zxsl: (dtsj[i]["zxsl"] == "NULL" ? 0 : parseInt(dtsj[i]["zxsl"])),
                        lxsl: (dtsj[i]["lxsl"] == "NULL" ? 0 : parseInt(dtsj[i]["lxsl"])),
                        dqsl: (dtsj[i]["dqsl"] == "NULL" ? 0 : parseInt(dtsj[i]["dqsl"])),
                    });
                }

                echarts.extendsMap = function (id, opt) {
                    // 实例
                    var chart = this.init(document.getElementById(id));

                    var curGeoJson = {};

                    var defaultOpt = {
                        mapName: 'china', // 地图展示
                        goDown: false, // 是否下钻
                        bgColor: '#404a59', // 画布背景色
                        activeArea: [], // 区域高亮,同echarts配置项
                        data: [],
                        // 下钻回调(点击的地图名、实例对象option、实例对象)
                        callback: function (name, option, instance) {
                        }
                    };
                    if (opt) opt = this.util.extend(defaultOpt, opt);

                    // 层级索引
                    var name = [opt.mapName];
                    var idx = 0;
                    var pos = {
                        leftPlus: 80,
                        leftCur: 20,
                        left: 10,
                        top: 10
                    };

                    var line = [
                        [0, 0],
                        [8, 11],
                        [0, 22]
                    ];
                    // style
                    var style = {};

                    var handleEvents = {
                        /**
                         * i 实例对象
                         * o option
                         * n 地图名
                         **/
                        resetOption: function (i, o, n) {
                            var breadcrumb = this.createBreadcrumb(n);
                            var j = name.indexOf(n);
                            var l = o.graphic.length;
                            if (j < 0) {
                                o.graphic.push(breadcrumb);
                                o.graphic[0].children[0].shape.x2 = 180;
                                o.graphic[0].children[1].shape.x2 = 180;
                                if (o.graphic.length > 2) {
                                    var cityData = [];
                                    var cityJson;
                                    for (var x = 0; x < opt.data.length; x++) {
                                        if (n === opt.data[x].city) {
                                            $([opt.data[x]]).each(function (index, data) {
                                                cityJson = {
                                                    city: data.city,
                                                    name: data.name,
                                                    value: data.value,
                                                    crew: data.crew,
                                                    company: data.company,
                                                    position: data.position,
                                                    region: data.region
                                                };
                                                cityData.push(cityJson)
                                            })
                                        }
                                    }

                                    if (cityData != null) {
                                        o.series[0].data = handleEvents.initSeriesData(cityData);
                                    } else {
                                        o.series[0].data = [];
                                    }


                                }
                                name.push(n);
                                idx++;
                            } else {
                                o.graphic.splice(j + 2, l);
                                if (o.graphic.length <= 2) {
                                    o.graphic[0].children[0].shape.x2 = 60;
                                    o.graphic[0].children[1].shape.x2 = 60;
                                    o.series[0].data = handleEvents.initSeriesData(opt.data);
                                }
                                ;
                                name.splice(j + 1, l);
                                idx = j;
                                pos.leftCur -= pos.leftPlus * (l - j - 1);
                            }
                            ;

                            o.geo.map = n;
                            o.geo.zoom = 1.2;
                            i.clear();
                            i.setOption(o);
                            // this.zoomAnimation();
                            opt.callback(n, o, i);
                        },

                        /**
                         * name 地图名
                         **/
                        createBreadcrumb: function (name) {
                            var breadcrumb = {
                                type: 'group',
                                id: name,
                                left: pos.leftCur + pos.leftPlus,
                                top: pos.top + 6,
                                children: [{
                                    type: 'polyline',
                                    left: -90,
                                    top: -5,
                                    shape: {
                                        points: line
                                    },
                                    style: {
                                        stroke: '#FFF',
                                        key: name,
                                        fill: "#389BB7",
                                        // lineWidth: 2,
                                    },
                                    onclick: function () {
                                        var name = this.style.key;
                                        handleEvents.resetOption(chart, option, name);
                                    }
                                }, {
                                    type: 'text',
                                    left: -68,
                                    top: 0,
                                    style: {
                                        text: name,
                                        textAlign: 'center',
                                        fill: "#389BB7",
                                    },
                                    onclick: function () {
                                        var name = this.style.text;
                                        geoMapJson = [];
                                        if (lsheng == name) {
                                            lshi = "";
                                            lsheng = name;
                                            // console.log("市")
                                            mapa = mapSort(geoCoordMap.filter(value => value.sheng == lsheng), "shi")
                                        } else {
                                            lshi = name;
                                            // console.log("区");
                                            mapa = mapSort(geoCoordMap.filter(value => value.sheng == lsheng).filter(value => value.shi == lshi), "qu")
                                        }
                                        for (var i = 0; i < echarts.getMap(name).geoJson.features.length; i++) {
                                            const cvalue = mapa.filter(value => value.name === echarts.getMap(name).geoJson.features[i].properties.name);
                                            geoMapJson.push({
                                                name: echarts.getMap(name).geoJson.features[i].properties.name,
                                                value: cvalue.length == 0 ? 0 : (cvalue[0].value > 10 ? 5 : (parseFloat(cvalue[0].value) / 2)),
                                            })
                                        }
                                        option.series[1].data = geoMapJson;
                                        option.yAxis.data = mapa.map(value => value.name);
                                        option.series[2].data = mapa;
                                        handleEvents.resetOption(chart, option, name);
                                    }
                                }]
                            }

                            pos.leftCur += pos.leftPlus;

                            return breadcrumb;
                        },

                        // 设置effectscatter
                        initSeriesData: function (data) {
                            return geoCoordMap;
                        },
                        zoomAnimation: function () {
                            var count = null;
                            var zoom = function (per) {
                                if (!count) count = per;
                                count = count + per;
                                // console.log(per,count);
                                chart.setOption({
                                    geo: {
                                        zoom: count
                                    }
                                });
                                if (count < 1) window.requestAnimationFrame(function () {
                                    zoom(0.2);
                                });
                            };
                            window.requestAnimationFrame(function () {
                                zoom(0.2);
                            });
                        }
                    };

                    var option = {
                        backgroundColor: opt.bgColor,
                        tooltip: {
                            padding: 0,
                            enterable: true,
                            transitionDuration: 1,
                            textStyle: {
                                color: '#fff',
                                decoration: 'none',
                            },
                            formatter: function (params) {

                                if (params.data == undefined) {
                                    return "";
                                }
                                if (params.data.sbsl == undefined) {
                                    return "";
                                } else {
                                    var tipHtml = '';
                                    var tipHtml = '<div style="width:300px;background:rgba(0, 0, 0, 0.4);">' +
                                        '    <div style="height:50px;width:100%;border-radius:5px;border-bottom:1px solid #4e4e4e">' +
                                        '        <span style="line-height:50px;margin-left:18px">' + params.name + '</span>' +
                                        // '        <span style="float:right;line-height:50px;margin-right:18px;color:#5396E3;cursor:pointer" onclick="mapTooltipClick(this);">点击查看详情</span>' +
                                        '    </div>' +
                                        '    <div style="padding-bottom:20px;width:100%;">' +
                                        '        <div style="padding-left:18px;padding-top:22px">' +
                                        '            <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:rgba(92,169,235,1)"></span> ' +
                                        '            <span>设备数量</span>' +
                                        '            <span style="float:right;margin-right:18px">' + params.data.sbsl + '</span>' +
                                        '        </div>' +
                                        '        <div style="padding-left:18px;padding-top:14px">' +
                                        '            <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#2ac776"></span> ' +
                                        '            <span>在线数量</span>' +
                                        '            <span style="float:right;margin-right:18px">' + params.data.zxsl + '</span>' +
                                        '        </div>' +
                                        '        <div style="padding-left:18px;padding-top:14px">' +
                                        '            <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#f15050"></span> ' +
                                        '            <span>离线数量</span>' +
                                        '            <span style="float:right;margin-right:18px">' + params.data.lxsl + '</span>' +
                                        '        </div>' +
                                        '        <div style="padding-left:18px;padding-top:14px">' +
                                        '            <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ffbc34"></span> ' +
                                        '            <span>到期数量</span>' +
                                        '            <span style="float:right;margin-right:18px">' + params.data.dqsl + '</span>' +
                                        '        </div>' +
                                        '    </div>' +
                                        '</div>';
                                    return tipHtml;
                                }
                            }
                        },
                        graphic: [{
                            type: 'group',
                            left: pos.left,
                            top: pos.top - 4,
                            children: [{
                                type: 'line',
                                left: 0,
                                top: -20,
                                shape: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 60,
                                    y2: 0
                                },
                                style: {
                                    stroke: style.lineColor,
                                }
                            }, {
                                type: 'line',
                                left: 0,
                                top: 20,
                                shape: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 60,
                                    y2: 0
                                },
                                style: {
                                    stroke: style.lineColor,
                                }
                            }]
                        },
                            {
                                id: name[idx],
                                type: 'group',
                                left: pos.left + 2,
                                top: pos.top,
                                children: [{
                                    type: 'polyline',
                                    left: 90,
                                    top: -12,
                                    shape: {
                                        points: line
                                    },
                                    style: {
                                        stroke: 'transparent',
                                        key: name[0]
                                    },
                                    onclick: function () {
                                        var name = this.style.key
                                        handleEvents.resetOption(chart, option, name);
                                    }
                                }, {
                                    type: 'text',
                                    left: 0,
                                    top: 0,
                                    style: {
                                        text: '中国',
                                        textAlign: 'center',
                                        fill: "#389BB7",
                                    },
                                    onclick: function () {
                                        geoMapJson = [];
                                        lsheng = "";
                                        lshi = "";
                                        mapa = mapSort(geoCoordMap, "sheng")
                                        for (var i = 0; i < echarts.getMap('中国').geoJson.features.length; i++) {
                                            const cvalue = mapa.filter(value => value.name == echarts.getMap('中国').geoJson.features[i].properties.name);
                                            geoMapJson.push({
                                                name: echarts.getMap('中国').geoJson.features[i].properties.name,
                                                value: cvalue.length == 0 ? 0 : (cvalue[0].value > 10 ? 5 : (parseFloat(cvalue[0].value) / 2)),
                                            })
                                        }
                                        option.series[1].data = geoMapJson;
                                        option.yAxis.data = mapa.map(value => value.name);
                                        option.series[2].data = mapa;
                                        handleEvents.resetOption(chart, option, '中国');
                                    }
                                }]
                            }],

                        visualMap: {
                            type: 'piecewise',
                            show: false,
                            min: 0,
                            max: 5,
                            left: 'left',
                            top: 'bottom',
                            text: ['', ''], // 文本，默认为数值文本
                            // calculable: true,
                            seriesIndex: [1],
                            textStyle: {
                                color: '#f00'
                            },
                            inRange: {
                                // color: ['#1488CC', '#2B32B2'] // 浅蓝
                                // color: ['#0e275c', 'rgba(51, 69, 89, .0)'] //
                                color: ["#011234","#004169", '#07598B', '#0E71AD', '#1589CF', "#1CA2F1"] //

                            }
                        },
                        geo: {
                            map: opt.mapName,
                            roam: true,
                            zoom: 1.2,
                            left: 150,
                            // center: [104.074683,30.624611],
                            label: {
                                normal: {
                                    show: true,
                                    color: "#c5ccff"
                                },
                                emphasis: {
                                    color: "#c5ccff"
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: 'rgba(51, 69, 89, .5)', //地图背景色
                                    borderColor: 'rgba(234, 234, 234, .5)', //省市边界线00fcff 516a89 rgba(234, 234, 234, .5)
                                    borderWidth: 1,
                                    shadowColor: 'rgba(51, 69, 89, .5)',
                                    shadowBlur: 0
                                    // areaColor: '#031525',
                                },
                                emphasis: {
                                    color: 'rgba(204, 185, 21, 1)' //悬浮背景
                                    // areaColor: '#2B91B7',
                                }
                            },
                        },

                        grid: {
                            height:300,
                            right: 10,
                            bottom: 30,
                            width: '20%'
                        },
                        xAxis: {
                            show:false,
                            type: 'value',
                            scale: true,
                            position: 'top',
                            splitNumber: 1,
                            boundaryGap: false,
                            splitLine: {
                                show: false
                            },
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                margin: 2,
                                textStyle: {
                                    color: '#aaa'
                                }
                            }
                        },
                        yAxis: {
                            show:true,
                            type: 'category',
                            nameGap: 10,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#ddd'
                                }
                            },
                            axisTick: {
                                show: false,
                                lineStyle: {
                                    color: '#ddd'
                                }
                            },
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: '#999'
                                }
                            },
                            data: mapa.map(value => value.name)
                        },
                        series: [
                            {
                                name: '点',
                                type: 'effectScatter',
                                coordinateSystem: 'geo',
                                data: geoCoordMap,
                                symbolSize: 10,
                                showEffectOn: 'render',
                                rippleEffect: {
                                    brushType: 'stroke'
                                },
                                hoverAnimation: true,
                                label: {
                                    normal: {
                                        formatter: '{b}',
                                        position: 'right',
                                        show: false
                                    }
                                },
                                itemStyle: {
                                    // f4e925\d8d03e
                                    // f15050\ffbc34\2ac776
                                    // ff6939\ffbc34\59f382
                                    normal: {
                                        color: function (params) {
                                            if (params["data"]["lxsl"] > 0) {
                                                return '#f4e925'
                                            } else if (params["data"]["dqsl"] > 0) {
                                                return "#f4e925"
                                            } else {
                                                return "#f4e925"
                                            }
                                        },
                                        shadowBlur: 10,
                                        shadowColor: function (params) {
                                            if (params["data"]["lxsl"] > 0) {
                                                return '#f4e925'
                                            } else if (params["data"]["dqsl"] > 0) {
                                                return "#f4e925"
                                            } else {
                                                return "#f4e925"
                                            }
                                        },
                                    }
                                },
                                zlevel: 1
                            },

                            {
                                type: 'map',
                                map: opt.mapName,
                                geoIndex: 0,
                                aspectScale: 0.75, //长宽比
                                showLegendSymbol: false, // 存在legend时显示
                                label: {
                                    normal: {
                                        show: true
                                    },
                                    emphasis: {
                                        show: false,
                                        textStyle: {
                                            color: '#fff'
                                        }
                                    }
                                },
                                roam: true,
                                itemStyle: {
                                    normal: {
                                        areaColor: '#031525',
                                        borderColor: '#3B5077',
                                    },
                                    emphasis: {
                                        areaColor: '#2B91B7'
                                    }
                                },
                                animation: false,
                                data: geoMapJson
                            },{
                                name: 'barSer',
                                type: 'bar',
                                roam: false,
                                visualMap: false,
                                zlevel: 2,
                                barMaxWidth: 15,
                                barMinHeight:5,
                                itemStyle: {
                                    normal: {
                                        color: '#40a9ed'
                                    },
                                    emphasis: {
                                        color: "#3596c0"
                                    }
                                },
                                label: {
                                    normal: {
                                        show: false,
                                        position: 'right',
                                        offset: [0, 10]
                                    },
                                    emphasis: {
                                        show: true,
                                        position: 'right',
                                        offset: [10, 0]
                                    }
                                },
                                data: mapa
                            }
                        ]
                    };
                    chart.setOption(option);
                    // 添加事件
                    chart.on('click', function (params) {
                        var _self = this;
                        if (opt.goDown && params.name !== name[idx]) {
                            if (cityMap[params.name]) {
                                window.ajax({
                                    cache: true,
                                    type: "GET",
                                    url: "/json/" + cityMap[params.name] + ".json",
                                    error: function () {
                                    },
                                    fail: function () {
                                    },
                                    success: function (response) {
                                        var response_json = JSON.parse(response);
                                        geoMapJson = [];
                                        if (lsheng == "") {
                                            lsheng = params.name;
                                            // console.log("市")
                                            mapa = mapSort(geoCoordMap.filter(value => value.sheng == lsheng), "shi")
                                        } else {
                                            lshi = params.name;
                                            // console.log("区");
                                            mapa = mapSort(geoCoordMap.filter(value => value.sheng == lsheng).filter(value => value.shi == lshi), "qu")
                                        }
                                        for (var i = 0; i < response_json.features.length; i++) {
                                            const cvalue = mapa.filter(value => value.name == response_json.features[i].properties.name);

                                            geoMapJson.push({
                                                name: response_json.features[i].properties.name,
                                                value: cvalue.length == 0 ? 0 : (cvalue[0].value > 10 ? 5 : (parseFloat(cvalue[0].value) / 2)),
                                            })
                                        }
                                        curGeoJson = response;
                                        echarts.registerMap(params.name, response);
                                        option.series[1].data = geoMapJson;
                                        option.yAxis.data = mapa.map(value => value.name);
                                        option.series[2].data = mapa;
                                        handleEvents.resetOption(_self, option, params.name);

                                    }
                                });
                            }
                        }
                    });

                    return chart;
                };


                /*获取地图数据*/
                window.ajax({
                        cache: true,
                        type: "GET",
                        url: "/json/100000.json",
                        error: function () {
                        },
                        fail: function () {
                        },
                        success: function (response) {
                            var response_json = JSON.parse(response);
                            geoMapJson = [];
                            // console.log("省")
                            mapa = mapSort(geoCoordMap, "sheng")
                            for (var i = 0; i < response_json.features.length; i++) {
                                const cvalue = mapa.filter(value => value.name == response_json.features[i].properties.name);
                                geoMapJson.push({
                                    name: response_json.features[i].properties.name,
                                    value: cvalue.length == 0 ? 0 : (cvalue[0].value > 10 ? 5 : (parseFloat(cvalue[0].value) / 2)),
                                })
                            }
                            // mapa.filter(value => value.name === response_json.features[i].properties.name).length == 0 ? 0 : mapa.filter(value => value.name === response_json.features[i].properties.name)[0].value
                            // console.log(geoCoordMap.filter(value => (value.sheng == params.name || value.shi == params.name)))

                            echarts.registerMap('中国', response);
                            var chart1 = echarts.extendsMap('world-map-markers', {
                                bgColor: 'transparent', // 画布背景色
                                mapName: '中国', // 地图名
                                goDown: true, // 是否下钻
                                // 下钻回调
                                callback: function (name, option, instance) {
                                    //console.log(name, option, instance);
                                },
                            });

                            setTimeout(function () {
                                chart1.resize();
                            }, 1)
                        }
                    }
                );


                var dom = document.getElementById("citybar");
                var myChart6 = echarts.init(dom);
                var option3 = null;
                option3 = {
                    grid: {
                        left: 60,
                        bottom: 20,
                        top: 10
                    },
                    xAxis: {
                        type: 'value',
                        show: false,
                        scale: true,
                        position: 'top',
                        boundaryGap: false,
                        splitLine: {show: false},
                        axisLine: {show: false},
                        axisTick: {show: false},
                        axisLabel: {margin: 2, textStyle: {color: '#aaa'}},
                    },
                    yAxis: {
                        type: 'category',
                        axisLine: {show: false, lineStyle: {color: '#ddd'}},
                        axisTick: {show: false, lineStyle: {color: '#ddd'}},
                        axisLabel: {interval: 0, textStyle: {color: '#ddd', fontSize: 14}},
                        data: ['库   房', '冷   库', '保温箱', '冷藏车', '冰   箱'],

                    },
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            xAxisIndex: 0,
                            yAxisIndex: 0,
                            barWidth: '30%',
                            itemStyle: {
                                normal: {
                                    color: '#fff',

                                }
                            },
                            label: {
                                normal: {
                                    show: true,
                                    position: "right",
                                    textStyle: {
                                        color: "#fff",
                                        fontSize: '24'
                                    }
                                }
                            },
                            data: [
                                {value: json_data.bt.kf, name: '库房'},
                                {value: json_data.bt.lk, name: '冷库'},
                                {value: json_data.bt.bw, name: '保温箱'},
                                {value: json_data.bt.lc, name: '冷藏车'},
                                {value: json_data.bt.bx, name: '冰箱'}
                            ],

                        },
                    ]
                };
                if (option3 && typeof option3 === "object") {
                    myChart6.setOption(option3, true);
                }

                window.onresize = function () {
                    chart1.resize();
                };

                //marquee
                $('.marquee').kxbdSuperMarquee({
                    isMarquee: true,
                    isEqual: false,
                    scrollDelay: 55,
                    controlBtn: {up: '#goUM', down: '#goDM'},
                    direction: 'up'
                });


            }
        })


        //pie test end


    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
    }

    render() {
        window.gsm_page = this;
        return (
            <div className={"container-fluid indexbg" + (this.state.full ? " card-fullscreen" : "")}>
                <div id="ibg"></div>
                <div className="row" style={{position: "relative"}}>
                    <div className="col-lg-3" style={{position: "absolute", top: "90px", left: "20px", zIndex: "11"}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="boxall">
                                    {/*<div className="alltitle"></div>*/}
                                    <div className="sycm m-t-20">
                                        <ul className="clearfix">
                                            <li>
                                                <h2>{this.state["yh"]}</h2><span>用户数量</span></li>
                                            <li>
                                                <h2>{this.state["sb"]}</h2><span>设备数量</span></li>
                                            <li>
                                                <h2>{this.state["zx"]}</h2><span>在线数量</span></li>
                                        </ul>
                                        <div style={{height: "1px", background: "rgba(229, 229, 229, 0.09)"}}></div>
                                        <ul className="clearfix m-t-20">
                                            <li>
                                                <h2>{this.state["lx"]}</h2><span>离线数量</span></li>
                                            <li>
                                                <h2>{this.state["bj"]}</h2><span>报警数量</span></li>
                                            <li>
                                                <h2>{this.state["ty"]}</h2><span>停用数量</span></li>
                                        </ul>
                                    </div>
                                    <div className="boxfoot"></div>
                                </div>
                            </div>
                            {/*<div className="col-lg-12">
                            <div className="boxall" style={{background:"transparent"}}>
                                <div className="alltitle">图表</div>
                                <div className="wrap" id="citybar" style={{height: "450px"}}>

                                </div>
                                <div className="boxfoot"></div>
                            </div>
                        </div>*/}
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="">
                            {/*<div className="alltitle"></div>*/}
                            <div className="wrap" id="world-map-markers" style={{height: "calc(100vh - 108px)"}}>
                            </div>
                            {/*<div className="boxfoot"></div>*/}
                        </div>
                        <div className="boxall"
                             style={{position: "absolute", left: "30px", top: "61%", bottom: "85px", width: "31.5%"}}>
                            <div id="citybar" className="wrap" style={{height: "250px"}}></div>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="boxall" style={{height: "10rem"}}>
                                    <div className="alltitle">验证到期【{this.state["zsdq"].length}】</div>
                                    <div className="wrap marquee">
                                        <ul style={{paddingLeft: "0px"}}>
                                            {this.state["zsdq"].map((value, index) => {
                                                return <li
                                                    key={"zsdq-" + index}>
                                                    <p>【{value["name"]}】{value["end_at"] == "NULL" ? "已" : dayjs(value["end_at"]).format("YYYY-MM-DD")}到期，请更新证书</p>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className="boxfoot"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="boxall" style={{height: "10rem"}}>
                                    <div className="alltitle">设备到期【{this.state["sbdq"].length}】</div>
                                    <div className="wrap marquee">
                                        <ul style={{paddingLeft: "0px"}}>
                                            {this.state["sbdq"].map((value, index) => {
                                                return <li
                                                    key={"sbdq-" + index}>
                                                    <p>【{value["name"]}】{value["end_at"] == "NULL" ? "已" : dayjs(value["end_at"]).format("YYYY-MM-DD")}到期，请续费</p>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className="boxfoot"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="boxall" style={{height: "10rem"}}>
                                    <div className="alltitle">校准到期【{this.state["jzdq"].length}】</div>
                                    <div className="wrap marquee">
                                        <ul style={{paddingLeft: "0px"}}>
                                            {this.state["jzdq"].map((value, index) => {
                                                return <li
                                                    key={"jzdq-" + index}>
                                                    <p>【{value["name"]}】{value["end_at"] == "NULL" ? "已" : dayjs(value["end_at"]).format("YYYY-MM-DD")}到期，请更新证书报告</p>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className="boxfoot"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="boxall" style={{height: "calc(100vh - 30rem - 108px)"}}>
                                    <div className="alltitle">报警通知【{this.state.tx.length}】</div>
                                    <div className="wrap marquee" id="pie" style={{height: "450px"}}>
                                        <ul style={{paddingLeft: "0px"}}>
                                            {this.state.tx.map((value, index) => {
                                                return <li
                                                    key={"bjtz-" + index}>
                                                    <p>【{dayjs(value["end_at"]).format("YYYY-MM-DD HH:mm:ss") + "　" + value["itype"]}】【{value["machine_id"]}】{value["message"]}</p>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                    <div className="boxfoot"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

let mapa = [];
let lsheng = "";
let lshi = "";

function mapSort(mapc, key) {
    const res_array = [];
    mapc.map((value, index) => {
        let pd = false;
        res_array.map((value2, index2) => {
            if (value2.name == value[key]) {
                res_array[index2].value = res_array[index2].value + 1;
                pd = true;
            }
        });
        if (!pd) {
            res_array.push({
                name: value[key],
                value: 1
            })
        }
    });
    res_array.sort(function (o1, o2) {
        if (isNaN(o1.value) || o1.value == null) return -1;
        if (isNaN(o2.value) || o2.value == null) return 1;
        return o1.value - o2.value;
    });
    return res_array
}

export default GsmHomepage;
