import React from 'react';
import logo from './logo.svg';
import Login from './login';
import Main from './main';

function App() {
    const [status, setStatus] = React.useState("login");
    return (
        <div className="App">
            {
                (() => {
                    switch (status) {
                        case "login":
                            return <Login LLFunc={setStatus}/>;
                        case "main":
                            return <Main LLFunc={setStatus}/>;
                    }
                })()
            }
        </div>
    );
}

export default App;
