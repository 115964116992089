window.gsm_history = [];

window.gsm_history_save = (url, clear) => {
    clearTimeout(window.time_refresh)
    let eval_flag = true;

    if (url == window.gsm_history[window.gsm_history.length - 1]) {
        eval_flag = false;
    }
    if (clear) {
        window.gsm_history = [];
    }
    window.gsm_history.push(url);
    if (eval_flag) {
        eval(url);
    }
}

window.gsm_history_back = () => {
    if (window.gsm_history.length > 1) {
        window.gsm_history.pop();
        eval(window.gsm_history[window.gsm_history.length - 1]);
    }
}