import React from "react";
import $ from "jquery";
import {
    GsmShare,
    GsmShareMachine,
    GsmShareFile,
    GsmMyShareMachine,
    GsmMyShareFile,
    GsmMyShareMachineDetail
} from "./share"
import echarts from "echarts"
import dayjs from "dayjs"

// 我的共享设备管理
window.gsm_to_my_share_machine = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMyShareMachine/>
    })
    window.operate_data = {};
    setTimeout(function () {

        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        let datatableelement = $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            pageLength: 15,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="far fa-file-excel"></i> EXCEL',
                exportOptions: {columns: ':visible'}
            },
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}],
            ajax: {
                type: "POST",
                url: "/my/share/machine",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {
                    "data": "machine_id",
                    render: (data, type, full, mate) => {
                        if (window.user_permission.indexOf("[MachineDetail]") != -1) {
                            return "<span style='cursor: pointer' onclick=\"window.gsm_history_save('window.gsm_to_my_share_machine_detail(" + full["id"] + ")',false)\">" + data + "</span>"
                        } else {
                            return data
                        }
                    }
                },
                {"data": "name"},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "在线":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            case "离线":
                                return "<span class=\"btn btn-xs btn-secondary font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                {
                    "data": "repair",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "正常":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">正常</span>";
                                break;
                            case "报修":
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">报修</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                {"data": "classification_name"},
                {"data": "customer_name"},
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return full["minbaojingwendu"].split(".")[0] + " ~ " + full["maxbaojingwendu"].split(".")[0]
                    }
                },
                {
                    "data": "wendu",
                    render: function (data, type, full, meta) {
                        if (data == "NULL") {
                            return ""
                        } else {
                            if (full["maxbaojingwendu"] <= data) {
                                return "<div class='text-danger'>" + parseFloat(data).toFixed(2).slice(0, -1) + "<div>" // "[" + parseFloat(full["baojingshidu"]).toFixed(2).slice(0,-1) + ]
                            } else {
                                return parseFloat(data).toFixed(2).slice(0, -1) //+ "(" + parseFloat(full["baojingwendu"]).toFixed(2).slice(0,-1) + ")"
                            }
                        }
                    }
                },
                {
                    "data": "shidu",
                    render: function (data, type, full, meta) {
                        if (data == "NULL") {
                            return ""
                        } else {
                            if (full["maxbaojingshidu"] <= data) {
                                return "<div class='text-danger'>" + parseFloat(data).toFixed(2).slice(0, -1) + "<div>" //"[" + parseFloat(full["baojingshidu"]).toFixed(2).slice(0,-1) +]
                            } else {
                                return parseFloat(data).toFixed(2).slice(0, -1) //+ "(" + parseFloat(full["baojingshidu"]).toFixed(2).slice(0,-1) + ")"
                            }
                        }
                    }
                },
                {
                    "data": "update_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        window.operate_data["t" + full["id"]] = full;
                        return "<div class=\"btn-group\"><button type=\"button\" class=\"btn btn-info btn-xs dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"><i class=\"ti-settings\"></i></button>" +
                            "<div class=\"dropdown-menu animated fadeIn\">" +
                            "<a href=\"javascript:void(0)\" class=\"dropdown-item text-success p-r-10\"onclick=\"window.gsm_history_save('window.gsm_to_my_share_machine_detail(" + full["id"] + ")',false)\"><i class=\"fas fa-eye\"></i> 查看</a> " +
                            "</div></div>";
                        //return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"/machine/detail?i=" + data + "&mi=" + full["machine_id"] + "\" class=\"text-success p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"查看\"><i class=\"fas fa-eye\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"报修\"><i class=\"fas fa-wrench\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"http://test.ddrenli.top/pdf.pdf\" download='http://test.ddrenli.top/pdf.pdf' target='_blank' class=\"text-primary p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"校准报告\"><i class=\"fas fa-file-pdf\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"编辑\"><i class=\"fas fa-pencil-alt\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-warning p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"续费\"><i class=\"fas fa-yen-sign\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"删除\"><i class=\"fas fa-trash\"></i></a>";
                    }
                }
            ]
        });

        $('#dt-start, #dt-end').change(function () {
            datatableelement.draw();
        });


        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
        $('#date-range').datepicker({
            toggleActive: true
        });
    }, 1)
}

//我的共享资料管理
window.gsm_to_my_share_file = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMyShareFile/>
    })
    setTimeout(function () {


        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            pageLength: 15,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="far fa-file-excel"></i> EXCEL',
                    exportOptions: {columns: ':visible'}
                },
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/my/share/file",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "share_name"},
                {"data": "name"},
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file" + full.path + "','" + full.name + "')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"下载\"><i class=\"fas fa-download\"></i></a>";
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}

// 我的共享设备--详细页
window.gsm_to_my_share_machine_detail_refresh = (t1, t2) => {
    $('#ts-error').on('click', function () {
        window.toastr.error('标题', '内容', {
            "showMethod": "slideDown",
            "hideMethod": "slideUp",
            timeOut: 2000,
            positionClass: 'toast-top-center'
        });
    });
    $('#zero_config').DataTable({
        destroy: true,
        columnDefs: [
            {orderable: false, targets: 0},
            {orderable: false, targets: -1}
        ],
        language: {
            "sProcessing": "处理中...",
            "sLengthMenu": "显示 _MENU_ 项结果",
            "sZeroRecords": "没有匹配结果",
            "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
            "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
            "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
            "sInfoPostFix": "",
            "sSearch": "搜索:",
            "sUrl": "",
            "sEmptyTable": "表中数据为空",
            "sLoadingRecords": "载入中...",
            "sInfoThousands": ",",
            "oPaginate": {
                "sFirst": "首页",
                "sPrevious": "上页",
                "sNext": "下页",
                "sLast": "末页"
            },
            "oAria": {
                "sSortAscending": ": 以升序排列此列",
                "sSortDescending": ": 以降序排列此列"
            }
        },
        searching: true,
        lengthChange: false,
        //serverSide: false,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
            {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
            {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
            // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
            {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
        ],
        ajax: {
            type: "POST",
            url: "/my/share/machine/detail?i=" + window.operate_data["machine_id"] + "&s=" + window.operate_data["share_machine_id"] + "&t1=" + t1 + "&t2=" + t2,
            dataSrc: ''
        },
        "columns": [
            {"data": "id"},
            {
                "data": "update_at",
                render: function (data, type, full, meta) {
                    if (data == "NULL")
                        return ""
                    else
                        return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                }
            },
            {
                "data": "id",
                render: function (data, type, full, meta) {
                    return parseFloat(full["longitude"]).toFixed(6) + "," + parseFloat(full["latitude"]).toFixed(6)
                }
            },
            {
                "data": "wendu",
                render: function (data, type, full, meta) {
                    if (data == "NULL") {
                        return ""
                    } else {
                        window.return_hts = "";
                        (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                            if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                            } else {
                                window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                            }
                        });
                        return window.return_hts
                    }
                }
            },
            {
                "data": "shidu",
                render: function (data, type, full, meta) {
                    if (data == "NULL") {
                        return ""
                    } else {
                        window.return_hts = "";
                        (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                            if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                            } else {
                                window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                            }
                        });
                        return window.return_hts
                    }
                }
            },
        ]
    });

    //导出
    // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
    $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
    $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');


    // table filter
    $('.text-inputs-searching tfoot th').each(function () {
        var title = $(this).text();
        $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
    });
    var tableSearching = $('.text-inputs-searching').DataTable();
    tableSearching.columns().every(function () {
        var that = this;
        $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this.value) {
                that
                    .search(this.value)
                    .draw();
            }
        });
    });
    var r = $('#zero_config tfoot tr');
    r.find('th').each(function () {
        $(this).css('padding', 1);
    });
    $('#zero_config thead').append(r);

    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime',
        type: 'datetime'
    });
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime2',
        type: 'datetime'
    });
    $('#date-range').datepicker({
        toggleActive: true
    });


    // specify chart configuration item and data
    if ((window.operate_data["ifield"].indexOf("[wendu]") != -1) || (window.operate_data["ifield"].indexOf("[shidu]") != -1)) {
        $(function () {
            $.ajax({
                type: "POST",
                url: "/my/share/machine/detail/list7day?i=" + window.operate_data["machine_id"] + "&s=" + window.operate_data["share_machine_id"] + "&t1=" + t1 + "&t2=" + t2,
                success: function (data) {
                    const new_data = data.reverse();
                    const chartArray = [];

                    const wenduzd = [];
                    const wenduzs = [];
                    const wendupj = [];
                    const wendujc = [];
                    const wendubzc = [];
                    const shiduzd = [];
                    const shiduzs = [];
                    const shidupj = [];
                    const shidujc = [];
                    const shidubzc = [];

                    if (window.operate_data["minbaojingwendu"] !== "" && window.operate_data["minbaojingwendu"] !== "NULL" && window.operate_data["minbaojingwendu"] !== null && window.operate_data["minbaojingwendu"] !== undefined) {
                        for (let kk = 0; kk < (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",").length; kk++) {
                            const wd_array = [];
                            const time_array = [];
                            flag:for (var i = 0; i < new_data.length; i++) {
                                wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                                time_array.push(new_data[i]["update_at"]);
                            }

                            if (wd_array.length > 0) {
                                wenduzd.push(wd_array.length > 0 ? Math.max.apply(null, wd_array).toFixed(1) : 0);
                                wenduzs.push(wd_array.length > 0 ? Math.min.apply(null, wd_array).toFixed(1) : 0);
                                wendupj.push(wd_array.length > 0 ? (wd_array.reduce(function (x, y) {
                                    return parseFloat(x) + parseFloat(y);
                                }) / wd_array.length).toFixed(1) : 0);
                                wendujc.push(wenduzd.length > 0 ? (parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1) : 0);
                                wendubzc.push(wd_array.length > 1 ? Math.sqrt(wd_array.map(function (x) {
                                    return parseFloat(x) - wendupj[wendupj.length - 1];
                                }).map(function (x) {
                                    return parseFloat(x) * parseFloat(x);
                                }).reduce(function (x, y) {
                                    return parseFloat(x) + parseFloat(y);
                                }) / (wd_array.length - 1)).toFixed(1) : 0);
                            }
                            const myChart = echarts.init(document.getElementById('basic-line-w' + kk));
                            const option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'line'
                                    }
                                },
                                legend: {
                                    data: ['温度', '湿度']
                                },

                                color: ['#f62d51'],
                                grid: {
                                    left: '1%',
                                    right: '2%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                xAxis: {
                                    type: 'category',
                                    data: time_array,
                                    scale: true,
                                    boundaryGap: false,
                                    axisLine: {onZero: false},
                                    splitLine: {show: false},
                                    splitNumber: 20,
                                    min: 'dataMin',
                                    max: 'dataMax'
                                },
                                yAxis: [
                                    {
                                        type: 'value',
                                        axisLabel: {
                                            formatter: '{value} ℃'
                                        }
                                    }
                                ],
                                dataZoom: [
                                    {
                                        type: 'inside',
                                        start: 0,
                                        end: 100
                                    },
                                    {
                                        show: true,
                                        type: 'slider',
                                        y: '90%',
                                        start: 0,
                                        end: 100
                                    }
                                ],
                                series: [
                                    {
                                        name: '温度' + (kk + 1),
                                        type: 'line',
                                        data: wd_array,
                                        markPoint: {
                                            data: [
                                                {type: 'max', name: 'Max'},
                                                {type: 'min', name: 'Min'}
                                            ]
                                        },
                                        markLine: {
                                            symbol: "none",               //去掉警戒线最后面的箭头
                                            label: {
                                                position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                            },
                                            data: [{
                                                silent: false,             //鼠标悬停事件  true没有，false有
                                                lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                    type: "dashed",
                                                    color: "#2962FF",
                                                },
                                                yAxis: (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                            }, {
                                                silent: false,             //鼠标悬停事件  true没有，false有
                                                lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                    type: "dashed",
                                                    color: "#2962FF",
                                                },
                                                yAxis: (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                            }]
                                        },
                                        lineStyle: {
                                            normal: {
                                                width: 3,
                                                shadowColor: 'rgba(0,0,0,0.1)',
                                                shadowBlur: 10,
                                                shadowOffsetY: 10
                                            }
                                        },
                                    }
                                ]
                            };

                            myChart.setOption(option);
                            chartArray.push(myChart);
                        }
                    }
                    if (window.operate_data["minbaojingshidu"] !== "" && window.operate_data["minbaojingshidu"] !== "NULL" && window.operate_data["minbaojingshidu"] !== null && window.operate_data["minbaojingshidu"] !== undefined) {
                        for (let kk = 0; kk < (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",").length; kk++) {
                            const sd_array = [];
                            const time_array = [];
                            flag:for (var i = 0; i < new_data.length; i++) {
                                sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                                time_array.push(new_data[i]["update_at"]);
                            }


                            if (sd_array.length > 0) {
                                shiduzd.push(sd_array.length > 0 ? Math.max.apply(null, sd_array).toFixed(1) : 0);
                                shiduzs.push(sd_array.length > 0 ? Math.min.apply(null, sd_array).toFixed(1) : 0);
                                shidupj.push(sd_array.length > 0 ? (sd_array.reduce(function (x, y) {
                                    return parseFloat(x) + parseFloat(y);
                                }) / sd_array.length).toFixed(1) : 0);
                                shidujc.push(shiduzd.length > 0 ? (parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1) : 0);
                                shidubzc.push(sd_array.length > 1 ? Math.sqrt(sd_array.map(function (x) {
                                    return parseFloat(x) - shidupj[shidupj.length - 1];
                                }).map(function (x) {
                                    return parseFloat(x) * parseFloat(x);
                                }).reduce(function (x, y) {
                                    return parseFloat(x) + parseFloat(y);
                                }) / (sd_array.length - 1)).toFixed(1) : 0);
                            }
                            const myChart = echarts.init(document.getElementById('basic-line-s' + kk));
                            const option = {
                                tooltip: {
                                    trigger: 'axis',
                                    axisPointer: {
                                        type: 'line'
                                    }
                                },
                                legend: {
                                    data: ['温度', '湿度']
                                },

                                color: ['#2962FF'],
                                grid: {
                                    left: '1%',
                                    right: '2%',
                                    bottom: '3%',
                                    containLabel: true
                                },
                                xAxis: {
                                    type: 'category',
                                    data: time_array,
                                    scale: true,
                                    boundaryGap: false,
                                    axisLine: {onZero: false},
                                    splitLine: {show: false},
                                    splitNumber: 20,
                                    min: 'dataMin',
                                    max: 'dataMax'
                                },
                                yAxis: [
                                    {
                                        type: 'value',
                                        axisLabel: {
                                            formatter: '{value} %'
                                        }
                                    }
                                ],
                                dataZoom: [
                                    {
                                        type: 'inside',
                                        start: 0,
                                        end: 100
                                    },
                                    {
                                        show: true,
                                        type: 'slider',
                                        y: '90%',
                                        start: 0,
                                        end: 100
                                    }
                                ],
                                series: [
                                    {
                                        name: '湿度' + (kk + 1),
                                        type: 'line',
                                        data: sd_array,
                                        markPoint: {
                                            data: [
                                                {type: 'max', name: 'Max'},
                                                {type: 'min', name: 'Min'}
                                            ]
                                        },
                                        markLine: {
                                            symbol: "none",               //去掉警戒线最后面的箭头
                                            label: {
                                                position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                            },
                                            data: [{
                                                silent: false,             //鼠标悬停事件  true没有，false有
                                                lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                    type: "dashed",
                                                    color: "#f62d51",
                                                },
                                                yAxis: (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[kk] // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                            }, {
                                                silent: false,             //鼠标悬停事件  true没有，false有
                                                lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                    type: "dashed",
                                                    color: "#f62d51",
                                                },
                                                yAxis: (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                            }]
                                        },
                                        lineStyle: {
                                            normal: {
                                                width: 3,
                                                shadowColor: 'rgba(0,0,0,0.1)',
                                                shadowBlur: 10,
                                                shadowOffsetY: 10
                                            }
                                        },
                                    }
                                ]
                            };

                            myChart.setOption(option);
                            chartArray.push(myChart);
                        }
                    }

                    window.onresize = function () {
                        for (var i = 0; i < chartArray.length; i++)
                            chartArray[i].resize();
                    }
                    window.gsm_page.setState({
                        wenduzd: wenduzd.join(","),
                        wenduzs: wenduzs.join(","),
                        wendupj: wendupj.join(","),
                        wendujc: wendujc.join(","),
                        wendubzc: wendubzc.join(","),
                        shiduzd: shiduzd.join(","),
                        shiduzs: shiduzs.join(","),
                        shidupj: shidupj.join(","),
                        shidujc: shidujc.join(","),
                        shidubzc: shidubzc.join(","),
                    })
                }
            });

        })
    }
}

window.gsm_to_my_share_machine_detail = (id) => {
    window.operate_data = window.operate_data["t" + id];
    // console.log(window.operate_data)
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMyShareMachineDetail GsmVal={window.operate_data["ifield"]}/>
    })

    setTimeout(function () {
        window.gsm_to_my_share_machine_detail_refresh('', '')
    }, 1)

}

//共享管理
window.gsm_to_share = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmShare/>
    })
    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            pageLength: 15,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            //dom: 'Bfrtip',
            buttons: [
                // {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/share",
                dataSrc: ''
            },
            "order": [[0, "desc"]],
            "columns": [
                {"data": "id"},
                {"data": "customer_name"},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "正常":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                {"data": "account"},
                {
                    "data": "binding",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "NULL":
                                return "";
                                break;
                            default:
                                return data
                        }
                    }
                },
                {"data": "describe"},
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return ((window.user_permission.indexOf("[ReviseShare]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\"\n" +
                            "                                                           data-target=\".adddevice\" class=\"text-success p-r-10\"\n" +
                            "                                                           data-toggle=\"tooltip\" title=\"\" data-original-title=\"编辑\" onclick='window.gsm_page.state.new = false;window.gsm_page.state.id = \"" + full["id"] + "\";" +
                            "                window.gsm_page.zh_input.value = \"" + full["account"] + "\";" +
                            "                window.gsm_page.mm_input.value = \"" + full["password"] + "\";" +
                            "                window.gsm_page.zt_input.value = \"" + full["status"] + "\";" +
                            "                window.gsm_page.bd_input.value = \"" + (full["binding"] == "NULL" ? "" : full["binding"]) + "\";" +
                            "'><i class=\"fas fa-edit\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[ShareMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"text-inverse p-r-10\"\n" +
                                "                                                                data-toggle=\"tooltip\" title=\"\"\n" +
                                "                                                                data-original-title=\"共享设备\" onclick='window.operate_data={i:" + data + ",c:" + full["customer"] + ",n:\"" + full["account"] + "\"};window.gsm_history_save(\"window.gsm_to_share_machine()\",false)'><i\n" +
                                "                                                                    class=\"fas fa-box\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[ShareFile]") != -1) ? "<a href=\"javascript:void(0)\" class=\"text-inverse p-r-10\"\n" +
                                "                                                                data-toggle=\"tooltip\" title=\"\"\n" +
                                "                                                                data-original-title=\"共享资料\" onclick='window.operate_data={i:" + data + ",c:" + full["customer"] + ",n:\"" + full["account"] + "\"};window.gsm_history_save(\"window.gsm_to_share_file()\",false)'><i\n" +
                                "                                                                    class=\"fas fa-file-alt\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[DeleteShare]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"取消共享\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-unlink\"></i></a>" : "");
                        ;
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}

window.sharemachine_checkifieldfunc = (e, id, name) => {
    if (window.user_permission.indexOf("[CheckShareMachine]") != -1) {
        var el = e;
        // el.checked = !el.checked;
        $.ajax({
            type: "POST",
            url: "/share/machine/check",
            data: {
                s: window.operate_data["i"],
                c: window.operate_data["c"],
                i: id,
                n: name,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('操作成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    //el.checked = !el.checked;
                    if (el.firstElementChild.className == "fas fa-times-circle text-secondary")
                        el.firstElementChild.className = "fas fa-check-circle text-success";
                    else
                        el.firstElementChild.className = "fas fa-times-circle text-secondary";
                }
            }
        });
    }
}

window.gsm_to_share_machine = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmShareMachine/>
    })
    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });

        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [
                // {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/share/machine?i=" + window.operate_data["i"] + "&c=" + window.operate_data["c"],
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "machine_id"},
                {"data": "name"},
                {
                    "data": "history_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        if (full.ifield == "NULL") {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[wendu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        } else if (full.ifield.indexOf("[wendu]") != -1) {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[wendu]')\"><i class='fas fa-check-circle text-success'></i></div>";
                        } else {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[wendu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        }
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        if (full.ifield == "NULL") {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[shidu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        } else if (full.ifield.indexOf("[shidu]") != -1) {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[shidu]')\"><i class='fas fa-check-circle text-success'></i></div>";
                        } else {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[shidu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        }
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        if (full.ifield == "NULL") {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[jingdu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        } else if (full.ifield.indexOf("[jingdu]") != -1) {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[jingdu]')\"><i class='fas fa-check-circle text-success'></i></div>";
                        } else {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[jingdu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        }
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        if (full.ifield == "NULL") {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[weidu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        } else if (full.ifield.indexOf("[weidu]") != -1) {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[weidu]')\"><i class='fas fa-check-circle text-success'></i></div>";
                        } else {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[weidu]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        }
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        if (full.ifield == "NULL") {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[lishi]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        } else if (full.ifield.indexOf("[lishi]") != -1) {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[lishi]')\"><i class='fas fa-check-circle text-success'></i></div>";
                        } else {
                            return "<div class='text-center f20' onclick=\"window.sharemachine_checkifieldfunc(this,'" + data + "','[lishi]')\"><i class='fas fa-times-circle text-secondary'></i></div>";
                        }
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}

window.sharefile_fileuploadfunc = () => {
    var xhr;
    var fileObj = document.getElementById("inputGroupFile01").files[0];
    var form = new FormData();
    form["enctype"] = "multipart/form-data";
    form["Access-Control-Allow-Origin"] = "*";
    form.append("file", fileObj, fileObj.name);

    xhr = new XMLHttpRequest();
    xhr.open("post", window.file_server + "/upload", true);
    xhr.onload = function (evt) {
        // console.log(evt.target.responseText)
        var data = JSON.parse(evt.target.responseText);
        $.ajax({
            type: "POST",
            url: "/share/file/add",
            data: {
                share: window.operate_data["i"],
                customer: window.operate_data["c"],
                name: data.name,
                path: data.path,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('添加成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    window.gsm_to_share_file()
                }
            }
        });
    };
    xhr.onerror = function (evt) {
    };
    xhr.send(form);
}

window.sharefile_deletefilefunc = (id) => {
    $.ajax({
        type: "POST",
        url: "/share/file/delete",
        data: {
            s: window.operate_data["i"],
            c: window.operate_data["c"],
            i: id,
        },
        dataType: "json",
        success: function (data) {
            if (data == "success") {
                window.toastr.success('删除成功', '消息', {
                    "showMethod": "slideDown",
                    "hideMethod": "slideUp",
                    timeOut: 2000,
                    positionClass: 'toast-top-center'
                });
                window.gsm_to_share_file()
            }
        }
    });
}

window.gsm_to_share_file = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmShareFile/>
    })
    setTimeout(function () {


        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [
                // {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/share/file?i=" + window.operate_data["i"] + "&c=" + window.operate_data["c"],
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file" + full.path + "','" + full.name + "')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"下载\"><i class=\"fas fa-download\"></i></a> " +
                            ((window.user_permission.indexOf("[DeleteShareFile]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:window.sharefile_deletefilefunc(" + data + ")\" class=\"text-danger\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"删除\"><i class=\"fas fa-trash\"></i></a>" : "");
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}
