import React from "react";
import $ from "jquery";
//验证报告
class GsmReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            upload: false,
            file: "",
            id: "",
            select: [],
            full: false,
            check: 0,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    {/*{this.props.MMSFlag === undefined &&*/}
                    {/*<div className="col-12 d-flex justify-content-between" style={{marginBottom: '-5px'}}>*/}
                    {/*    <div className="col-2_5">*/}
                    {/*        <button*/}
                    {/*            className={"btn btn-block btn-lg report_tab " + (this.state.check === 0 ? "btn-light" : "btn-secondary")}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.setState({check: 0});*/}
                    {/*                window.gsm_to_report_refresh("")*/}
                    {/*            }}>全部*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-2_5">*/}
                    {/*        <button*/}
                    {/*            className={"btn btn-block btn-lg report_tab " + (this.state.check === 1 ? "btn-light" : "btn-secondary")}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.setState({check: 1});*/}
                    {/*                window.gsm_to_report_refresh(1)*/}
                    {/*            }}>验证计划*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-2_5">*/}
                    {/*        <button*/}
                    {/*            className={"btn btn-block btn-lg report_tab " + (this.state.check === 2 ? "btn-light" : "btn-secondary")}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.setState({check: 2});*/}
                    {/*                window.gsm_to_report_refresh(2)*/}
                    {/*            }}>验证方案*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-2_5">*/}
                    {/*        <button*/}
                    {/*            className={"btn btn-block btn-lg report_tab " + (this.state.check === 3 ? "btn-light" : "btn-secondary")}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.setState({check: 3});*/}
                    {/*                window.gsm_to_report_refresh(3)*/}
                    {/*            }}>验证报告*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-2_5">*/}
                    {/*        <button*/}
                    {/*            className={"btn btn-block btn-lg report_tab " + (this.state.check === 4 ? "btn-light" : "btn-secondary")}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.setState({check: 4});*/}
                    {/*                window.gsm_to_report_refresh(4)*/}
                    {/*            }}>标准操作流程*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>}*/}
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                验证报告管理 {(window.user_permission.indexOf("[AddReport]") != -1) ? <a href="javascript:void(0)"
                                                                                            className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                                                                                            data-toggle="modal"
                                                                                            data-target=".adddevice"
                                                                                            onClick={() => {
                                                                                                this.state.new = true
                                                                                            }}><i
                                className="ti-plus m-r-5"/>新增验证报告</a> : null}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i className="mdi mdi-arrow-expand"/></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>

                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1" colSpan="1"
                                                            aria-sort="ascending" aria-label=""
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1" colSpan="1"
                                                            style={{width: "0px"}}>验证报告名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1" colSpan="1"
                                                            style={{width: "0px"}}>验证报告类型
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1" colSpan="1"
                                                            style={{width: "0px"}}>所属客户
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1" colSpan="1"
                                                            style={{width: "0px"}}>到期时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1" colSpan="1"
                                                            style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>验证报告名称</th>
                                                        <th>验证报告类型</th>
                                                        <th>所属客户</th>
                                                        <th>到期时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel"
                                 aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">验证报告名称:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.mc_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">类型:</label>
                                                    <select className="select2 form-control custom-select"
                                                            style={{width: "100%", height: "36px"}}
                                                            ref={input => this.lx_input = input}>
                                                        <optgroup label="">
                                                            {this.state.select}
                                                        </optgroup>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">验证报告</label>
                                                    <div className="">
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">上传</span>
                                                            </div>
                                                            <div className="custom-file">
                                                                <input type="file" className="custom-file-input"
                                                                       id="inputGroupFile01"
                                                                       ref={input => this.wj_input = input} onChange={
                                                                    () => {
                                                                        const el = this;
                                                                        this.state.upload = true;
                                                                        var xhr;
                                                                        var fileObj = document.getElementById("inputGroupFile01").files[0];
                                                                        var form = new FormData();
                                                                        form["enctype"] = "multipart/form-data";
                                                                        form["Access-Control-Allow-Origin"] = "*";
                                                                        form.append("file", fileObj, fileObj.name);

                                                                        xhr = new XMLHttpRequest();
                                                                        xhr.open("post", window.file_server + "/upload", true);
                                                                        xhr.onload = function (evt) {
                                                                            var data = JSON.parse(evt.target.responseText);
                                                                            el.setState(
                                                                                {
                                                                                    file: data.path
                                                                                }
                                                                            )
                                                                            el.state.upload = false;
                                                                        };
                                                                        xhr.onerror = function (evt) {
                                                                        };
                                                                        xhr.send(form);
                                                                    }
                                                                }/>
                                                                <label className="custom-file-label"
                                                                       htmlFor="inputGroupFile01" style={{
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis"
                                                                }}>{this.state.file}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">到期时间:</label>
                                                    <input type="date" className="form-control" id="recipient-name"
                                                           ref={input => this.dqsj_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">验证报告备注:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.bz_input = input}/>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light"
                                                    onClick={() => {
                                                        if (this.state.upload) {
                                                            window.toastr.success('文件上传中，请稍等', '消息', {
                                                                "showMethod": "slideDown",
                                                                "hideMethod": "slideUp",
                                                                timeOut: 2000,
                                                                positionClass: 'toast-top-center'
                                                            });
                                                        } else {
                                                            const props_flag = this.props.MMSFlag;
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: (this.state.new ? "/report/add" : "/report/revise"),
                                                                data: {
                                                                    name: this.mc_input.value,
                                                                    lx: this.lx_input.value,
                                                                    bz: this.bz_input.value,
                                                                    wj: this.state.file,
                                                                    dqsj: this.dqsj_input.value,
                                                                    i: this.state.id,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_report(props_flag);
                                                                }
                                                            })
                                                        }
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确定删除该厂商吗？</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const props_flag = this.props.MMSFlag;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report/delete",
                                                            data: {
                                                                i: this.state.id
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('删除成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                window.gsm_to_report(props_flag);
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

//设备分类
class GsmReportClassification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            parent: "",
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"cardx" + (this.state.full ? " card-fullscreen" : "")}>
                            {/*<div className="card-header">
                                验证报告分类管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>*/}
                            <div className="card-body">
                                <div className="myadmin-dd-empty " id="nestable">
                                    <ol className="dd-list">
                                        <li className="dd-item dd3-item" gsm-id="root">
                                            <div className="dd-handle dd3-handle d-none"></div>
                                            <div className="dd3-content d-none">
                                                验证报告分类管理
                                            </div>
                                            {/*<ol></ol>*/}
                                            <div className="row"></div>
                                        </li>
                                    </ol>
                                    <div className="row"></div>
                                </div>
                                <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel"
                                     aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">验证报告分类名称:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.mc_input = input}/>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-info waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: (this.state.new ? "/report/classification/add" : "/report/classification/revise"),
                                                                data: {
                                                                    name: this.mc_input.value,
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_reportclassification();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">确定删除该分类吗？</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: "/report/classification/delete",
                                                                data: {
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('删除成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_reportclassification();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//报警提醒
class GsmReport2List extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                验证管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>用户名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>收件人姓名
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>联系电话
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>详细地址
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>快递单号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>添加时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>查看
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>用户名称</th>
                                                        <th>状态</th>
                                                        <th>收件人姓名</th>
                                                        <th>联系电话</th>
                                                        <th>详细地址</th>
                                                        <th>快递单号</th>
                                                        <th>添加时间</th>
                                                        <th>查看</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设备分类
class GsmReportClassification2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            parent: "",
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"cardx" + (this.state.full ? " card-fullscreen" : "")}>
                            {/*<div className="card-header">
                                验证报告分类管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>*/}
                            <div className="card-body">
                                <div className="myadmin-dd-empty " id="nestable">
                                    <ol className="dd-list">
                                        <li className="dd-item dd3-item" gsm-id="root">
                                            <div className="dd-handle dd3-handle d-none"></div>
                                            <div className="dd3-content d-none">
                                                验证报告分类管理
                                            </div>
                                            {/*<ol></ol>*/}
                                            <div className="row"></div>
                                        </li>
                                    </ol>
                                    <div className="row"></div>
                                </div>
                                <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel"
                                     aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">验证报告分类名称:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.mc_input = input}/>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-info waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: (this.state.new ? "/report2/classification/add" : "/report2/classification/revise"),
                                                                data: {
                                                                    name: this.mc_input.value,
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_reportclassification2();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">确定删除该分类吗？</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: "/report2/classification/delete",
                                                                data: {
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('删除成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_reportclassification2();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//验证报告
class GsmReport2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            choose_status: 0,
            add_time: "",
            add_time2: "",
            customer: this.props.GsmCustomer,
            key: this.props.GsmKey,
            value: {},
            image: [],
            image2: [],
            file: [],
            classification: [],
            info: [],
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    {/*新增验证报告样式 1907011501  s*/}
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                验证报告管理{(this.state.status > 1 && this.state.status < 7 && (window.user_permission.indexOf("[PreReport2-" + this.state.status + "]") != -1)) &&
                            <a href="javascript:void(0)"
                               className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                               data-toggle="modal"
                               data-target=".predevice"><i
                                className="fas fa-angle-left m-r-5"/>上一步</a>}{(this.state.status < 7 && this.state.status !== 5 && (window.user_permission.indexOf("[NextReport2-" + this.state.status + "]") != -1)) &&
                            <a href="javascript:void(0)"
                               className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                               data-toggle="modal"
                               data-target=".nextdevice">下一步<i
                                className="fas fa-angle-right m-l-5"/></a>}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            {(this.state.status === 1 || this.state.status === 3 && (window.user_permission.indexOf("[AddReport2-1]") != -1 || window.user_permission.indexOf("[AddReport2-3]") != -1)) &&
                            <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel"
                                 aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">开始时间:</label>
                                                    <input type="text" className="form-control ptdatetime"
                                                           id="recipient-name"
                                                           ref={input => this.op_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">结束时间:</label>
                                                    <input type="text" className="form-control ptdatetime2"
                                                           id="recipient-name"
                                                           ref={input => this.ed_input = input}/>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/add",
                                                            data: {
                                                                op: this.op_input.value,
                                                                ed: this.ed_input.value,
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status === 1 || this.state.status === 3 && (window.user_permission.indexOf("[AddReport2-1]") != -1 || window.user_permission.indexOf("[AddReport2-3]") != -1)) &&
                            <div className="modal fade adddevice2" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel"
                                 aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">开始时间:</label>
                                                    <input type="text" className="form-control ptdatetime3"
                                                           id="recipient-name"
                                                           ref={input => this.op2_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">结束时间:</label>
                                                    <input type="text" className="form-control ptdatetime4"
                                                           id="recipient-name"
                                                           ref={input => this.ed2_input = input}/>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/add2",
                                                            data: {
                                                                op: this.op2_input.value,
                                                                ed: this.ed2_input.value,
                                                                i: this.state.add_time,
                                                                i2: this.state.add_time2,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status > 1 && this.state.status < 7 && (window.user_permission.indexOf("[PreReport2-" + this.state.status + "]") != -1)) &&
                            <div className="modal fade predevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                 style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确定提交置上一流程</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/pre",
                                                            data: {
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status < 7 && this.state.status !== 5 && (window.user_permission.indexOf("[NextReport2-" + this.state.status + "]") != -1)) &&
                            <div className="modal fade nextdevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                 style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确定提交至下一流程</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/next",
                                                            data: {
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status === 5 && (window.user_permission.indexOf("[NextReport2-" + this.state.status + "]") != -1)) &&
                            <div className="modal fade nextdevice2" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                 style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确定提交至下一流程</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/next2",
                                                            data: {
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                                mc: this.sjmc_input.value,
                                                                dh: this.sjdh_input.value,
                                                                dz: this.sjdz_input.value,
                                                                dh2: this.sjdh2_input.value,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status === 2 && (window.user_permission.indexOf("[DeleteFileReport2]") != -1)) &&
                            <div className="modal fade deletefdevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                 style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确认是否删除</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const  el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/file/delete",
                                                            data: {
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 删 除
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status === 2 && (window.user_permission.indexOf("[DeleteImageReport2]") != -1)) &&
                            <div className="modal fade deleteidevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                 style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确认是否删除</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/image/delete",
                                                            data: {
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 删 除
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {(this.state.status === 5 && (window.user_permission.indexOf("[DeleteImage2Report2]") != -1)) &&
                            <div className="modal fade deletei2device" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                 style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确认是否删除</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        const el = this;
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/report2/image2/delete",
                                                            data: {
                                                                i: this.state.add_time,
                                                                c: this.state.customer,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                if(el.state.customer === "") {
                                                                    window.gsm_to_report2();
                                                                } else {
                                                                    window.gsm_to_report22(el.state.key,el.state.customer)
                                                                }
                                                            }
                                                        })
                                                    }}> 删 除
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            <div className="card-body">
                                <div id="example-basic" className="wizard m-t-20">
                                    <div className="steps clearfix">
                                        {/* done已完成  current当前的  disabled未开始 */}
                                        <ul>
                                            <li className={this.state.choose_status === 1 ? "current" : (this.state.status >= 1 ? "first done" : "disabled")}>
                                                <a id="example-basic-t-0" href="javascript:void(0);" onClick={() => {
                                                    if (this.state.status >= 1) {
                                                        this.setState({
                                                            choose_status: 1
                                                        }, () => {
                                                            window.gsm_to_report2_init(Object.assign({},this.state.value,{status:1}), this.state.info)
                                                        })
                                                    }
                                                }}><span
                                                    className="number">1.</span> 验证计划</a>
                                            </li>
                                            <li className={this.state.choose_status === 2 ? "current" : (this.state.status >= 2 ? "first done" : "disabled")}>
                                                <a id="example-basic-t-1" href="javascript:void(0);" onClick={() => {
                                                    if (this.state.status >= 2) {
                                                        this.setState({
                                                            choose_status: 2
                                                        }, () => {
                                                            window.gsm_to_report2_init(Object.assign({},this.state.value,{status:2}), this.state.info)
                                                        })
                                                    }
                                                }}><span
                                                    className="number">2.</span> 验证方案</a>
                                            </li>
                                            <li className={this.state.choose_status === 3 ? "current" : (this.state.status >= 3 ? "first done" : "disabled")}>
                                                <a id="example-basic-t-2" href="javascript:void(0);" onClick={() => {
                                                    if (this.state.status >= 3) {
                                                        this.setState({
                                                            choose_status: 3
                                                        }, () => {
                                                            window.gsm_to_report2_init(Object.assign({},this.state.value,{status:3}), this.state.info)
                                                        })
                                                    }
                                                }}><span
                                                    className="number">3.</span> 验证实施</a>
                                            </li>
                                            <li className={this.state.choose_status === 4 ? "current" : (this.state.status >= 4 ? "first done" : "disabled")}>
                                                <a id="example-basic-t-3" href="javascript:void(0);" onClick={() => {
                                                    if (this.state.status >= 4) {
                                                        this.setState({
                                                            choose_status: 4
                                                        }, () => {
                                                            window.gsm_to_report2_init(Object.assign({},this.state.value,{status:4}), this.state.info)
                                                        })
                                                    }
                                                }}><span
                                                    className="number">4.</span> 验证报告</a>
                                            </li>
                                            <li className={this.state.choose_status === 5 ? "current" : (this.state.status >= 5 ? "first done" : "disabled")}>
                                                <a id="example-basic-t-4" href="javascript:void(0);" onClick={() => {
                                                    if (this.state.status >= 5) {
                                                        this.setState({
                                                            choose_status: 5
                                                        }, () => {
                                                            window.gsm_to_report2_init(Object.assign({},this.state.value,{status:5}), this.state.info)
                                                        })
                                                    }
                                                }}><span
                                                    className="number">5.</span> 验证培训</a>
                                            </li>
                                            <li className={this.state.choose_status === 6 ? "current" : (this.state.status >= 6 ? "first done" : "disabled")}>
                                                <a id="example-basic-t-5" href="javascript:void(0);" onClick={() => {
                                                    if (this.state.status >= 6) {
                                                        this.setState({
                                                            choose_status: 6
                                                        }, () => {
                                                            window.gsm_to_report2_init(Object.assign({},this.state.value,{status:6}), this.state.info)
                                                        })
                                                    }
                                                }}><span
                                                    className="number">6.</span> 验证完毕</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="content clearfix">
                                        <h3 className="title" onClick={() => {
                                            if (this.state.status >= 1) {
                                                this.setState({
                                                    choose_status: 1
                                                }, () => {
                                                    window.gsm_to_report2_init(Object.assign({},this.state.value,{status:1}), this.state.info)
                                                })
                                            }
                                        }}>验证计划</h3>
                                        {this.state.choose_status === 1 && <section
                                            className={"body " + (this.state.choose_status === 1 ? "current" : "")}>
                                            <div className="card">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-10 border-right p-r-0">
                                                            <div className="card-body b-l calender-sidebar">
                                                                <div className="calendar_yz"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div id="calendar-events" className="">
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-danger">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "xitong";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-danger m-r-10"/>系统验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "1").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-info">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangku";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-info m-r-10"/>冷藏库验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "2").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-primary">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangche";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-primary m-r-10"/>冷藏车验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "3").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-warning">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "baowenxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-warning m-r-10"/>保温箱验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "4").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-success">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "bingxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-success m-r-10"/>冰箱/冰柜验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "5").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>}
                                        <h3 className="title" onClick={() => {
                                            if (this.state.status >= 2) {
                                                this.setState({
                                                    choose_status: 2
                                                }, () => {
                                                    window.gsm_to_report2_init(Object.assign({},this.state.value,{status:2}), this.state.info)
                                                })
                                            }
                                        }}>验证方案</h3>
                                        {this.state.choose_status === 2 && <section
                                            className={"body " + (this.state.choose_status === 2 ? "current" : "")}>

                                            <div className="card">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-10 border-right p-r-0">
                                                            <div className="card-body b-l">

                                                                {/*新增图片展示样式s*/}
                                                                <div className="row el-element-overlay">
                                                                    {
                                                                            this.state.image.map((value, index) => {
                                                                                return <div key={index} className="col-3">
                                                                                            <div className="card">
                                                                                                <div className="el-card-item">
                                                                                                    <div className="el-card-avatar el-overlay-1"> <img src={"/file" + value.path} />
                                                                                                        <div className="el-overlay">
                                                                                                            <ul className="list-style-none el-info">
                                                                                                                <li className="el-item"><a className="btn default btn-outline image-popup-vertical-fit el-link" href={"/file" + value.path}><i className="sl-icon-eye"></i></a></li>
                                                                                                                <li className="el-item"><a className="btn default btn-outline el-link" href="javascript:void(0);" data-toggle="modal" data-target=".deleteidevice" onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                    }}><i className="sl-icon-trash"></i></a></li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                            })
                                                                    }
                                                                </div>
                                                                <hr/>
                                                                <div className="row el-element-overlay">
                                                                    {this.state.file.map((value, index) => {
                                                                        return <div key={index} className="col-3">
                                                                                            <div className="card">
                                                                                                <div className="el-card-item">
                                                                                                    {value.status === "修改中" && <span
                                                                                                    className="badge badge-dark m-r-5" style={{position:'absolute',zIndex:'1'}}>修改中</span>}
                                                                                                    {value.status === "未审核" && <span
                                                                                                    className="badge badge-danger m-r-5" style={{position:'absolute',zIndex:'1'}}>未审核</span>}
                                                                                                    {value.status === "已通过" && <span
                                                                                                    className="badge badge-success" style={{position:'absolute',zIndex:'1'}}>已通过</span>}
                                                                                                    <div className="el-card-avatar el-overlay-1">
                                                                                                        <i className={"fas fa-8x pt-4 "+(()=>{
                                                                                                            switch (value.name.split(".")[value.name.split(".").length-1]) {
                                                                                                                case "xlsx":
                                                                                                                    return "fa-file-excel";
                                                                                                                case "xls":
                                                                                                                    return "fa-file-excel";
                                                                                                                case "doc":
                                                                                                                    return "fa-file-word";
                                                                                                                case "docx":
                                                                                                                    return "fa-file-word";
                                                                                                                case "pdf":
                                                                                                                    return "fa-file-pdf";
                                                                                                                default:
                                                                                                                        return "fa-file-alt";
                                                                                                            }
                                                                                                        })()}/>
                                                                                                        <div className="el-overlay">
                                                                                                            <ul className="list-style-none el-info">

                                                                                                                <li className="el-item">
                                                                                                                    <a className="btn default btn-outline image-popup-vertical-fit el-link"
                                                                                                                       href="javascript:void(0);"
                                                                                                                       onClick={() => {
                                                                                                                            window.gsm_file_download("/file" + value.path, value.name);
                                                                                                                        }}>
                                                                                                                        <i className="sl-icon-cloud-download"/>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[ReviseFileReport2]") != -1)) &&
                                                                                                                    <li className="el-item">
                                                                                                                    <a className="btn default btn-outline el-link" href="javascript:void(0);"
                                                                                                                       onClick={(event) => {
                                                                                                                            this.state.add_time = value.id;
                                                                                                                            event.currentTarget.parentElement.nextElementSibling.firstElementChild.click();
                                                                                                                        }}>
                                                                                                                        <i className="sl-icon-note"/>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                                {(value.status !== "已通过" && this.state.status === 2) &&
                                                                                                                    <div
                                                                                                                        className="custom-file d-none">
                                                                                                                        <input type="file"
                                                                                                                               className="custom-file-input"
                                                                                                                               id="inputGroupFile03"
                                                                                                                               onChange={
                                                                                                                                   () => {
                                                                                                                                       window.report2_fileuploadfunc_re(value.id, this.state.value["id"], this.state.customer)
                                                                                                                                   }
                                                                                                                               }/>
                                                                                                                        <label
                                                                                                                            className="custom-file-label"
                                                                                                                            htmlFor="inputGroupFile01"></label>
                                                                                                                    </div>}
                                                                                                                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[DeleteFileReport2]") != -1)) &&
                                                                                                                <li className="el-item">
                                                                                                                    <a className="btn default btn-outline el-link" href="javascript:void(0);"
                                                                                                                       data-toggle="modal"
                                                                                                                       data-target=".deletefdevice"
                                                                                                                       onClick={() => {
                                                                                                                                this.state.add_time = value.id;
                                                                                                                                }}>
                                                                                                                        <i className="sl-icon-trash"/>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/*123s*/}
                                                                                                    <small className="h-link pt-1 row justify-content-center">
                                                                                                        {(this.state.status === 2 && (window.user_permission.indexOf("[EXReport2-2]") != -1)) &&
                                                                                                        <span
                                                                                                            className="badge badge-dark m-r-5"
                                                                                                            onClick={() => {
                                                                                                                const el = this;
                                                                                                                const i_i0 = value.id;
                                                                                                                const i_i = this.state.value["id"];
                                                                                                                const i_c = this.state.customer;
                                                                                                                $.ajax({
                                                                                                                    type: "POST",
                                                                                                                    url: "/report2/file/success",
                                                                                                                    data: {
                                                                                                                        i0: i_i0,
                                                                                                                        i: i_i,
                                                                                                                        c: i_c,
                                                                                                                    },
                                                                                                                    dataType: "json",
                                                                                                                    success: function (data) {
                                                                                                                        if (data == "success") {
                                                                                                                            window.toastr.success('审核成功', '消息', {
                                                                                                                                "showMethod": "slideDown",
                                                                                                                                "hideMethod": "slideUp",
                                                                                                                                timeOut: 2000,
                                                                                                                                positionClass: 'toast-top-center'
                                                                                                                            });
                                                                                                                            if(el.state.customer === "") {
                                                                                                                                window.gsm_to_report2();
                                                                                                                            } else {
                                                                                                                                window.gsm_to_report22(el.state.key,el.state.customer)
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}>审核通过</span>}
                                                                                                        {(this.state.status === 2 && (window.user_permission.indexOf("[EXReport2-2]") != -1)) &&
                                                                                                        <span
                                                                                                            className="badge badge-dark m-r-5"
                                                                                                            onClick={() => {
                                                                                                                const el = this;
                                                                                                                const i_i0 = value.id;
                                                                                                                const i_i = this.state.value["id"];
                                                                                                                const i_c = this.state.customer;
                                                                                                                $.ajax({
                                                                                                                    type: "POST",
                                                                                                                    url: "/report2/file/fail",
                                                                                                                    data: {
                                                                                                                        i0: i_i0,
                                                                                                                        i: i_i,
                                                                                                                        c: i_c,
                                                                                                                    },
                                                                                                                    dataType: "json",
                                                                                                                    success: function (data) {
                                                                                                                        if (data == "success") {
                                                                                                                            window.toastr.success('返回成功', '消息', {
                                                                                                                                "showMethod": "slideDown",
                                                                                                                                "hideMethod": "slideUp",
                                                                                                                                timeOut: 2000,
                                                                                                                                positionClass: 'toast-top-center'
                                                                                                                            });
                                                                                                                            if(el.state.customer === "") {
                                                                                                                                window.gsm_to_report2();
                                                                                                                            } else {
                                                                                                                                window.gsm_to_report22(el.state.key,el.state.customer)
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}>返回修改</span>}
                                                                                                    </small>
                                                                                                    {/*123e*/}
                                                                                                    <div className="el-card-content p-2">
                                                                                                        <h5 className="m-b-0">{value.name}</h5> <span className="text-muted">{value.create_at}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                        })
                                                                    }
                                                                </div>

                                                                {/*<div className="gallery-container">*/}
                                                                {/*    <div className="tz-gallery">*/}
                                                                {/*        <div className="row">*/}
                                                                {/*            {*/}
                                                                {/*            this.state.image.map((value, index) => {*/}
                                                                {/*                return <div key={index} className="col-3">*/}
                                                                {/*                            <a className="lightbox" href="javascript:void(0);">*/}
                                                                {/*                                <img*/}
                                                                {/*                                    data-toggle="modal"*/}
                                                                {/*                                    data-target=".deleteidevice"*/}
                                                                {/*                                    src={"/file" + value.path}*/}
                                                                {/*                                    onClick={() => {*/}
                                                                {/*                                        this.state.add_time = value.id;*/}
                                                                {/*                                    }}/>*/}
                                                                {/*                            </a>*/}
                                                                {/*                        </div>*/}
                                                                {/*                })*/}
                                                                {/*            }*/}
                                                                {/*        </div>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                                {/*新增图片展示样式e*/}

                                                                {/*<div id="galley" className="carousel slide"*/}
                                                                {/*     data-ride="carousel">*/}
                                                                {/*    <div className="carousel-inner" role="listbox">*/}
                                                                {/*        {*/}
                                                                {/*            this.state.image.map((value, index) => {*/}
                                                                {/*                return <div key={index}*/}
                                                                {/*                            className={"carousel-item " + (index === 0 ? "active" : "")}>*/}
                                                                {/*                    <img*/}
                                                                {/*                        data-toggle="modal"*/}
                                                                {/*                        data-target=".deleteidevice"*/}
                                                                {/*                        className="img-fluid"*/}
                                                                {/*                        src={"/file" + value.path}*/}
                                                                {/*                        onClick={() => {*/}
                                                                {/*                            this.state.add_time = value.id;*/}
                                                                {/*                        }}/>*/}
                                                                {/*                </div>*/}
                                                                {/*            })*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}
                                                                {/*    <a className="carousel-control-prev" href="#galley"*/}
                                                                {/*       role="button" data-slide="prev"> <span*/}
                                                                {/*        className="carousel-control-prev-icon"*/}
                                                                {/*        aria-hidden="true"></span> <span*/}
                                                                {/*        className="sr-only">Previous</span> </a>*/}
                                                                {/*    <a className="carousel-control-next" href="#galley"*/}
                                                                {/*       role="button" data-slide="next"> <span*/}
                                                                {/*        className="carousel-control-next-icon"*/}
                                                                {/*        aria-hidden="true"></span> <span*/}
                                                                {/*        className="sr-only">Next</span> </a>*/}
                                                                {/*</div>*/}

                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div id="calendar-events" className="">
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-danger">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "xitong";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-danger m-r-10"/>系统验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "1").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-info">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangku";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-info m-r-10"/>冷藏库验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "2").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-primary">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangche";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-primary m-r-10"/>冷藏车验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "3").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-warning">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "baowenxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-warning m-r-10"/>保温箱验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "4").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-success">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "bingxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-success m-r-10"/>冰箱/冰柜验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "5").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {(this.state.status === 2 && (window.user_permission.indexOf("[AddImageReport2]") != -1)) &&
                                                                        <div>
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="btn m-t-20 btn-info btn-block waves-effect waves-light"
                                                                                onClick={(event) => {
                                                                                    event.currentTarget.nextElementSibling.firstElementChild.click();
                                                                                }}>
                                                                                <i className="ti-plus"></i> 新增布点图
                                                                            </a>
                                                                            <div className="custom-file d-none">
                                                                                <input type="file"
                                                                                       className="custom-file-input"
                                                                                       id="inputGroupFile02"
                                                                                       accept="image/*" onChange={
                                                                                    () => {
                                                                                        window.report2_imageuploadfunc(this.state.value["id"], this.state.customer)
                                                                                    }
                                                                                }/>
                                                                                <label className="custom-file-label"
                                                                                       htmlFor="inputGroupFile02"></label>
                                                                            </div>
                                                                        </div>}
                                                                        {(this.state.status === 2 && (window.user_permission.indexOf("[AddFileReport2]") != -1)) &&
                                                                        <div>
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="btn m-t-20 btn-info btn-block waves-effect waves-light"
                                                                                onClick={(event) => {
                                                                                    event.currentTarget.nextElementSibling.firstElementChild.click();
                                                                                }}>
                                                                                <i className="ti-plus"></i> 上传文件
                                                                            </a>
                                                                            <div className="custom-file d-none">
                                                                                <input type="file"
                                                                                       className="custom-file-input"
                                                                                       id="inputGroupFile01" onChange={
                                                                                    () => {
                                                                                        window.report2_fileuploadfunc(this.state.value["id"], this.state.customer)
                                                                                    }
                                                                                }/>
                                                                                <label className="custom-file-label"
                                                                                       htmlFor="inputGroupFile01"></label>
                                                                            </div>
                                                                        </div>}
                                                                        {/*<div className="list-group" style={{*/}
                                                                        {/*    maxHeight: "650px",*/}
                                                                        {/*    overflowY: "scroll"*/}
                                                                        {/*}}>*/}
                                                                        {/*    {this.state.file.map((value, index) => {*/}
                                                                        {/*        return <a key={index}*/}
                                                                        {/*                  href="javascript:void(0)"*/}
                                                                        {/*                  className="list-group-item list-group-item-action flex-column align-items-start">*/}
                                                                        {/*            <div*/}
                                                                        {/*                className="d-flex w-100 justify-content-between">*/}
                                                                        {/*                <h5 className="mb-1">{value.name}</h5>*/}
                                                                        {/*            </div>*/}
                                                                        {/*            <small><span*/}
                                                                        {/*                className="m-r-5">{value.create_at}</span>*/}
                                                                        {/*                {value.status === "修改中" && <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5">修改中</span>}*/}
                                                                        {/*                {value.status === "未审核" && <span*/}
                                                                        {/*                    className="badge badge-danger m-r-5">未审核</span>}*/}
                                                                        {/*                {value.status === "已通过" && <span*/}
                                                                        {/*                    className="badge badge-success">已通过</span>}*/}
                                                                        {/*            </small>*/}
                                                                        {/*            <small*/}
                                                                        {/*                className="m-l-5 h-link float-right">*/}
                                                                        {/*                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[DeleteFileReport2]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-danger m-r-5"*/}
                                                                        {/*                    data-toggle="modal"*/}
                                                                        {/*                    data-target=".deletefdevice"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        this.state.add_time = value.id;*/}
                                                                        {/*                    }}>删除</span>}*/}
                                                                        {/*                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[ReviseFileReport2]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5"*/}
                                                                        {/*                    onClick={(event) => {*/}
                                                                        {/*                        this.state.add_time = value.id;*/}
                                                                        {/*                        event.currentTarget.nextElementSibling.firstElementChild.click();*/}
                                                                        {/*                    }}>修改</span>}*/}
                                                                        {/*                {(value.status !== "已通过" && this.state.status === 2) &&*/}
                                                                        {/*                <div*/}
                                                                        {/*                    className="custom-file d-none">*/}
                                                                        {/*                    <input type="file"*/}
                                                                        {/*                           className="custom-file-input"*/}
                                                                        {/*                           id="inputGroupFile03"*/}
                                                                        {/*                           onChange={*/}
                                                                        {/*                               () => {*/}
                                                                        {/*                                   window.report2_fileuploadfunc_re(value.id, this.state.value["id"], this.state.customer)*/}
                                                                        {/*                               }*/}
                                                                        {/*                           }/>*/}
                                                                        {/*                    <label*/}
                                                                        {/*                        className="custom-file-label"*/}
                                                                        {/*                        htmlFor="inputGroupFile01"></label>*/}
                                                                        {/*                </div>}*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-success"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        window.gsm_file_download("/file" + value.path, value.name);*/}
                                                                        {/*                    }}>下载</span>*/}
                                                                        {/*                {(this.state.status === 2 && (window.user_permission.indexOf("[EXReport2-2]") != -1)) &&*/}
                                                                        {/*            <span*/}
                                                                        {/*                className="badge badge-dark m-r-5"*/}
                                                                        {/*                onClick={() => {*/}
                                                                        {/*                    const el = this;*/}
                                                                        {/*                    const i_i0 = value.id;*/}
                                                                        {/*                    const i_i = this.state.value["id"];*/}
                                                                        {/*                    const i_c = this.state.customer;*/}
                                                                        {/*                    $.ajax({*/}
                                                                        {/*                        type: "POST",*/}
                                                                        {/*                        url: "/report2/file/success",*/}
                                                                        {/*                        data: {*/}
                                                                        {/*                            i0: i_i0,*/}
                                                                        {/*                            i: i_i,*/}
                                                                        {/*                            c: i_c,*/}
                                                                        {/*                        },*/}
                                                                        {/*                        dataType: "json",*/}
                                                                        {/*                        success: function (data) {*/}
                                                                        {/*                            if (data == "success") {*/}
                                                                        {/*                                window.toastr.success('审核成功', '消息', {*/}
                                                                        {/*                                    "showMethod": "slideDown",*/}
                                                                        {/*                                    "hideMethod": "slideUp",*/}
                                                                        {/*                                    timeOut: 2000,*/}
                                                                        {/*                                    positionClass: 'toast-top-center'*/}
                                                                        {/*                                });*/}
                                                                        {/*                                if(el.state.customer === "") {*/}
                                                                        {/*                                    window.gsm_to_report2();*/}
                                                                        {/*                                } else {*/}
                                                                        {/*                                    window.gsm_to_report22(el.state.key,el.state.customer)*/}
                                                                        {/*                                }*/}
                                                                        {/*                            }*/}
                                                                        {/*                        }*/}
                                                                        {/*                    });*/}
                                                                        {/*                }}>审核通过</span>}*/}
                                                                        {/*                {(this.state.status === 2 && (window.user_permission.indexOf("[EXReport2-2]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        const el = this;*/}
                                                                        {/*                        const i_i0 = value.id;*/}
                                                                        {/*                        const i_i = this.state.value["id"];*/}
                                                                        {/*                        const i_c = this.state.customer;*/}
                                                                        {/*                        $.ajax({*/}
                                                                        {/*                            type: "POST",*/}
                                                                        {/*                            url: "/report2/file/fail",*/}
                                                                        {/*                            data: {*/}
                                                                        {/*                                i0: i_i0,*/}
                                                                        {/*                                i: i_i,*/}
                                                                        {/*                                c: i_c,*/}
                                                                        {/*                            },*/}
                                                                        {/*                            dataType: "json",*/}
                                                                        {/*                            success: function (data) {*/}
                                                                        {/*                                if (data == "success") {*/}
                                                                        {/*                                    window.toastr.success('返回成功', '消息', {*/}
                                                                        {/*                                        "showMethod": "slideDown",*/}
                                                                        {/*                                        "hideMethod": "slideUp",*/}
                                                                        {/*                                        timeOut: 2000,*/}
                                                                        {/*                                        positionClass: 'toast-top-center'*/}
                                                                        {/*                                    });*/}
                                                                        {/*                                    if(el.state.customer === "") {*/}
                                                                        {/*                                        window.gsm_to_report2();*/}
                                                                        {/*                                    } else {*/}
                                                                        {/*                                        window.gsm_to_report22(el.state.key,el.state.customer)*/}
                                                                        {/*                                    }*/}
                                                                        {/*                                }*/}
                                                                        {/*                            }*/}
                                                                        {/*                        });*/}
                                                                        {/*                    }}>返回修改</span>}*/}
                                                                        {/*            </small>*/}
                                                                        {/*        </a>*/}
                                                                        {/*    })}*/}

                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>}
                                        <h3 className="title" onClick={() => {
                                            if (this.state.status >= 3) {
                                                this.setState({
                                                    choose_status: 3
                                                }, () => {
                                                    window.gsm_to_report2_init(Object.assign({},this.state.value,{status:3}), this.state.info)
                                                })
                                            }
                                        }}>验证实施</h3>
                                        {this.state.choose_status === 3 && <section
                                            className={"body " + (this.state.choose_status === 3 ? "current" : "")}>
                                            <div className="card">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-10 border-right p-r-0">
                                                            <div className="card-body b-l calender-sidebar">
                                                                <div className="calendar_yz"></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div id="calendar-events" className="">
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-danger">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "xitong";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-danger m-r-10"/>系统验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "1").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-info">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangku";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-info m-r-10"/>冷藏库验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "2").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-primary">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangche";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-primary m-r-10"/>冷藏车验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "3").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-warning">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "baowenxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-warning m-r-10"/>保温箱验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "4").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-success">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "bingxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-success m-r-10"/>冰箱/冰柜验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "5").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    data-toggle="modal"
                                                                                                    data-target=".adddevice2"
                                                                                                    onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                        this.state.add_time2 = value.parent;
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>}
                                        <h3 className="title" onClick={() => {
                                            if (this.state.status >= 4) {
                                                this.setState({
                                                    choose_status: 4
                                                }, () => {
                                                    window.gsm_to_report2_init(Object.assign({},this.state.value,{status:4}), this.state.info)
                                                })
                                            }
                                        }}>验证报告</h3>
                                        {this.state.choose_status === 4 && <section
                                            className={"body " + (this.state.choose_status === 4 ? "current" : "")}>

                                            <div className="card">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-10 border-right p-r-0">
                                                            <div className="card-body b-l">
                                                                {/*css calender-sidebar*/}
                                                                {/*<div className="calendar_yz"></div>*/}

                                                                <div className="row el-element-overlay">
                                                                    {this.state.file.map((value, index) => {
                                                                        return <div key={index} className="col-3">
                                                                                            <div className="card">
                                                                                                <div className="el-card-item">
                                                                                                    {value.status === "修改中" && <span
                                                                                                    className="badge badge-dark m-r-5" style={{position:'absolute',zIndex:'1'}}>修改中</span>}
                                                                                                    {value.status === "未审核" && <span
                                                                                                    className="badge badge-danger m-r-5" style={{position:'absolute',zIndex:'1'}}>未审核</span>}
                                                                                                    {value.status === "已通过" && <span
                                                                                                    className="badge badge-success" style={{position:'absolute',zIndex:'1'}}>已通过</span>}
                                                                                                    <div className="el-card-avatar el-overlay-1">
                                                                                                        <i className={"fas fa-8x pt-4 "+(()=>{
                                                                                                            switch (value.name.split(".")[value.name.split(".").length-1]) {
                                                                                                                case "xlsx":
                                                                                                                    return "fa-file-excel";
                                                                                                                case "xls":
                                                                                                                    return "fa-file-excel";
                                                                                                                case "doc":
                                                                                                                    return "fa-file-word";
                                                                                                                case "docx":
                                                                                                                    return "fa-file-word";
                                                                                                                case "pdf":
                                                                                                                    return "fa-file-pdf";
                                                                                                                default:
                                                                                                                        return "fa-file-alt";
                                                                                                            }
                                                                                                        })()}/>
                                                                                                        <div className="el-overlay">
                                                                                                            <ul className="list-style-none el-info">

                                                                                                                <li className="el-item">
                                                                                                                    <a className="btn default btn-outline image-popup-vertical-fit el-link"
                                                                                                                       href="javascript:void(0);"
                                                                                                                       onClick={() => {
                                                                                                                            window.gsm_file_download("/file" + value.path, value.name);
                                                                                                                        }}>
                                                                                                                        <i className="sl-icon-cloud-download"/>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[ReviseFileReport2-4   ]") != -1)) &&
                                                                                                                    <li className="el-item">
                                                                                                                    <a className="btn default btn-outline el-link" href="javascript:void(0);"
                                                                                                                       onClick={(event) => {
                                                                                                                            this.state.add_time = value.id;
                                                                                                                            event.currentTarget.parentElement.nextElementSibling.firstElementChild.click();
                                                                                                                        }}>
                                                                                                                        <i className="sl-icon-note"/>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[DeleteFileReport2-4]") != -1)) &&
                                                                                                                <li className="el-item">
                                                                                                                    <a className="btn default btn-outline el-link" href="javascript:void(0);"
                                                                                                                       data-toggle="modal"
                                                                                                                       data-target=".deletefdevice"
                                                                                                                       onClick={() => {
                                                                                                                                this.state.add_time = value.id;
                                                                                                                                }}>
                                                                                                                        <i className="sl-icon-trash"/>
                                                                                                                    </a>
                                                                                                                </li>}
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/*123s*/}
                                                                                                    <small className="h-link pt-1 row justify-content-center">
                                                                                                        {(this.state.status === 4 && (window.user_permission.indexOf("[EXReport2]") != -1)) &&
                                                                                                        <span
                                                                                                            className="badge badge-dark m-r-5"
                                                                                                            onClick={() => {
                                                                                                                const el = this;
                                                                                                                const i_i0 = value.id;
                                                                                                                const i_i = this.state.value["id"];
                                                                                                                const i_c = this.state.customer;
                                                                                                                $.ajax({
                                                                                                                    type: "POST",
                                                                                                                    url: "/report2/file/success",
                                                                                                                    data: {
                                                                                                                        i0: i_i0,
                                                                                                                        i: i_i,
                                                                                                                        c: i_c,
                                                                                                                    },
                                                                                                                    dataType: "json",
                                                                                                                    success: function (data) {
                                                                                                                        if (data == "success") {
                                                                                                                            window.toastr.success('审核成功', '消息', {
                                                                                                                                "showMethod": "slideDown",
                                                                                                                                "hideMethod": "slideUp",
                                                                                                                                timeOut: 2000,
                                                                                                                                positionClass: 'toast-top-center'
                                                                                                                            });
                                                                                                                            if(el.state.customer === "") {
                                                                                                                                window.gsm_to_report2();
                                                                                                                            } else {
                                                                                                                                window.gsm_to_report22(el.state.key,el.state.customer)
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}>审核通过</span>}
                                                                                                        {(this.state.status === 4 && (window.user_permission.indexOf("[EXReport2]") != -1)) &&
                                                                                                        <span
                                                                                                            className="badge badge-dark m-r-5"
                                                                                                            onClick={() => {
                                                                                                                const el = this;
                                                                                                                const i_i0 = value.id;
                                                                                                                const i_i = this.state.value["id"];
                                                                                                                const i_c = this.state.customer;
                                                                                                                $.ajax({
                                                                                                                    type: "POST",
                                                                                                                    url: "/report2/file/fail",
                                                                                                                    data: {
                                                                                                                        i0: i_i0,
                                                                                                                        i: i_i,
                                                                                                                        c: i_c,
                                                                                                                    },
                                                                                                                    dataType: "json",
                                                                                                                    success: function (data) {
                                                                                                                        if (data == "success") {
                                                                                                                            window.toastr.success('返回成功', '消息', {
                                                                                                                                "showMethod": "slideDown",
                                                                                                                                "hideMethod": "slideUp",
                                                                                                                                timeOut: 2000,
                                                                                                                                positionClass: 'toast-top-center'
                                                                                                                            });
                                                                                                                            if(el.state.customer === "") {
                                                                                                                                window.gsm_to_report2();
                                                                                                                            } else {
                                                                                                                                window.gsm_to_report22(el.state.key,el.state.customer)
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                });
                                                                                                            }}>返回修改</span>}
                                                                                                    </small>
                                                                                                    {/*123e*/}
                                                                                                    <div className="el-card-content p-2">
                                                                                                        <h5 className="m-b-0">{value.name}</h5> <span className="text-muted">{value.create_at}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                        })
                                                                    }
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="col-2">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div id="calendar-events" className="">
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-danger">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "xitong";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-danger m-r-10"/>系统验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "1").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-info">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangku";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-info m-r-10"/>冷藏库验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "2").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-primary">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangche";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-primary m-r-10"/>冷藏车验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "3").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-warning">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "baowenxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-warning m-r-10"/>保温箱验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "4").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-success">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "bingxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-success m-r-10"/>冰箱/冰柜验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "5").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {(this.state.status === 4 && (window.user_permission.indexOf("[AddFileReport2-4]") != -1)) &&
                                                                        <div>
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="btn m-t-20 btn-info btn-block waves-effect waves-light"
                                                                                onClick={(event) => {
                                                                                    event.currentTarget.nextElementSibling.firstElementChild.click();
                                                                                }}>
                                                                                <i className="ti-plus"></i> 上传文件
                                                                            </a>
                                                                            <div className="custom-file d-none">
                                                                                <input type="file"
                                                                                       className="custom-file-input"
                                                                                       id="inputGroupFile01" onChange={
                                                                                    () => {
                                                                                        window.report2_fileuploadfunc(this.state.value["id"], this.state.customer)
                                                                                    }
                                                                                }/>
                                                                                <label className="custom-file-label"
                                                                                       htmlFor="inputGroupFile01"></label>
                                                                            </div>
                                                                        </div>}

                                                                        {/*<div className="list-group m-t-10" style={{*/}
                                                                        {/*    maxHeight: "650px",*/}
                                                                        {/*    overflowY: "scroll"*/}
                                                                        {/*}}>*/}

                                                                        {/*    {this.state.file.map((value, index) => {*/}
                                                                        {/*        return <a key={index}*/}
                                                                        {/*                  href="javascript:void(0)"*/}
                                                                        {/*                  className="list-group-item list-group-item-action flex-column align-items-start">*/}
                                                                        {/*            <div*/}
                                                                        {/*                className="d-flex w-100 justify-content-between">*/}
                                                                        {/*                <h5 className="mb-1">{value.name}</h5>*/}
                                                                        {/*            </div>*/}
                                                                        {/*            <small><span*/}
                                                                        {/*                className="m-r-5">{value.create_at}</span>*/}
                                                                        {/*                {value.status === "修改中" && <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5">修改中</span>}*/}
                                                                        {/*                {value.status === "未审核" && <span*/}
                                                                        {/*                    className="badge badge-danger m-r-5">未审核</span>}*/}
                                                                        {/*                {value.status === "已通过" && <span*/}
                                                                        {/*                    className="badge badge-success">已通过</span>}*/}
                                                                        {/*            </small>*/}
                                                                        {/*            <small*/}
                                                                        {/*                className="m-l-5 h-link float-right">*/}
                                                                        {/*                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[DeleteFileReport2-4]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-danger m-r-5"*/}
                                                                        {/*                    data-toggle="modal"*/}
                                                                        {/*                    data-target=".deletefdevice"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        this.state.add_time = value.id;*/}
                                                                        {/*                    }}>删除</span>}*/}
                                                                        {/*                {(value.status !== "已通过" && this.state.status === 2 && (window.user_permission.indexOf("[ReviseFileReport2-4   ]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5"*/}
                                                                        {/*                    onClick={(event) => {*/}
                                                                        {/*                        this.state.add_time = value.id;*/}
                                                                        {/*                        event.currentTarget.nextElementSibling.firstElementChild.click();*/}
                                                                        {/*                    }}>修改</span>}*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-success m-r-5"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        window.gsm_file_download("/file" + value.path, value.name);*/}
                                                                        {/*                    }}>下载</span>*/}
                                                                        {/*                {(this.state.status === 4 && (window.user_permission.indexOf("[EXReport2]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        const el = this;*/}
                                                                        {/*                        const i_i0 = value.id;*/}
                                                                        {/*                        const i_i = this.state.value["id"];*/}
                                                                        {/*                        const i_c = this.state.customer;*/}
                                                                        {/*                        $.ajax({*/}
                                                                        {/*                            type: "POST",*/}
                                                                        {/*                            url: "/report2/file/success",*/}
                                                                        {/*                            data: {*/}
                                                                        {/*                                i0: i_i0,*/}
                                                                        {/*                                i: i_i,*/}
                                                                        {/*                                c: i_c,*/}
                                                                        {/*                            },*/}
                                                                        {/*                            dataType: "json",*/}
                                                                        {/*                            success: function (data) {*/}
                                                                        {/*                                if (data == "success") {*/}
                                                                        {/*                                    window.toastr.success('审核成功', '消息', {*/}
                                                                        {/*                                        "showMethod": "slideDown",*/}
                                                                        {/*                                        "hideMethod": "slideUp",*/}
                                                                        {/*                                        timeOut: 2000,*/}
                                                                        {/*                                        positionClass: 'toast-top-center'*/}
                                                                        {/*                                    });*/}
                                                                        {/*                                    if(el.state.customer === "") {*/}
                                                                        {/*                                        window.gsm_to_report2();*/}
                                                                        {/*                                    } else {*/}
                                                                        {/*                                        window.gsm_to_report22(el.state.key,el.state.customer)*/}
                                                                        {/*                                    }*/}
                                                                        {/*                                }*/}
                                                                        {/*                            }*/}
                                                                        {/*                        });*/}
                                                                        {/*                    }}>审核通过</span>}*/}
                                                                        {/*                {(this.state.status === 4 && (window.user_permission.indexOf("[EXReport2]") != -1)) &&*/}
                                                                        {/*                <span*/}
                                                                        {/*                    className="badge badge-dark m-r-5"*/}
                                                                        {/*                    onClick={() => {*/}
                                                                        {/*                        const el = this;*/}
                                                                        {/*                        const i_i0 = value.id;*/}
                                                                        {/*                        const i_i = this.state.value["id"];*/}
                                                                        {/*                        const i_c = this.state.customer;*/}
                                                                        {/*                        $.ajax({*/}
                                                                        {/*                            type: "POST",*/}
                                                                        {/*                            url: "/report2/file/fail",*/}
                                                                        {/*                            data: {*/}
                                                                        {/*                                i0: i_i0,*/}
                                                                        {/*                                i: i_i,*/}
                                                                        {/*                                c: i_c,*/}
                                                                        {/*                            },*/}
                                                                        {/*                            dataType: "json",*/}
                                                                        {/*                            success: function (data) {*/}
                                                                        {/*                                if (data == "success") {*/}
                                                                        {/*                                    window.toastr.success('返回成功', '消息', {*/}
                                                                        {/*                                        "showMethod": "slideDown",*/}
                                                                        {/*                                        "hideMethod": "slideUp",*/}
                                                                        {/*                                        timeOut: 2000,*/}
                                                                        {/*                                        positionClass: 'toast-top-center'*/}
                                                                        {/*                                    });*/}
                                                                        {/*                                    if(el.state.customer === "") {*/}
                                                                        {/*                                        window.gsm_to_report2();*/}
                                                                        {/*                                    } else {*/}
                                                                        {/*                                        window.gsm_to_report22(el.state.key,el.state.customer)*/}
                                                                        {/*                                    }*/}
                                                                        {/*                                }*/}
                                                                        {/*                            }*/}
                                                                        {/*                        });*/}
                                                                        {/*                    }}>返回修改</span>}*/}
                                                                        {/*            </small>*/}
                                                                        {/*        </a>*/}
                                                                        {/*    })}*/}

                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>}
                                        <h3 className="title" onClick={() => {
                                            if (this.state.status >= 5) {
                                                this.setState({
                                                    choose_status: 5
                                                }, () => {
                                                    window.gsm_to_report2_init(Object.assign({},this.state.value,{status:5}), this.state.info)
                                                 })
                                            }
                                        }}>验证培训</h3>
                                        {this.state.choose_status === 5 && <section
                                            className={"body " + (this.state.choose_status === 5 ? "current" : "")}>

                                            <div className="card">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-10 border-right p-r-0">
                                                            <div className="card-body b-l">
                                                                {/*新增图片展示样式s*/}
                                                                <div className="row el-element-overlay">
                                                                    {
                                                                            this.state.image2.map((value, index) => {
                                                                                return <div key={index} className="col-3">
                                                                                            <div className="card">
                                                                                                <div className="el-card-item">
                                                                                                    <div className="el-card-avatar el-overlay-1"> <img src={"/file" + value.path} />
                                                                                                        <div className="el-overlay">
                                                                                                            <ul className="list-style-none el-info">
                                                                                                                <li className="el-item"><a className="btn default btn-outline image-popup-vertical-fit el-link" href={"/file" + value.path}><i className="sl-icon-eye"></i></a></li>
                                                                                                                <li className="el-item"><a className="btn default btn-outline el-link" href="javascript:void(0);" data-toggle="modal" data-target=".deleteidevice" onClick={() => {
                                                                                                        this.state.add_time = value.id;
                                                                                                    }}><i className="sl-icon-trash"></i></a></li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                            })
                                                                    }
                                                                </div>
                                                                {/*<div className="gallery-container">*/}
                                                                {/*    <div className="tz-gallery">*/}
                                                                {/*        <div className="row">*/}
                                                                {/*            {*/}
                                                                {/*            this.state.image2.map((value, index) => {*/}
                                                                {/*                return <div key={index} className="col-3">*/}
                                                                {/*                            <a className="lightbox" href="javascript:void(0);">*/}
                                                                {/*                                <img*/}
                                                                {/*                                    data-toggle="modal"*/}
                                                                {/*                                    data-target=".deleteidevice"*/}
                                                                {/*                                    src={"/file" + value.path}*/}
                                                                {/*                                    onClick={() => {*/}
                                                                {/*                                        this.state.add_time = value.id;*/}
                                                                {/*                                    }}/>*/}
                                                                {/*                            </a>*/}
                                                                {/*                        </div>*/}
                                                                {/*                })*/}
                                                                {/*            }*/}
                                                                {/*        </div>*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                                {/*新增图片展示样式e*/}

                                                                {/*<div id="galley" className="carousel slide"*/}
                                                                {/*     data-ride="carousel">*/}
                                                                {/*    <div className="carousel-inner" role="listbox">*/}
                                                                {/*        {*/}
                                                                {/*            this.state.image2.map((value, index) => {*/}
                                                                {/*                return <div key={index}*/}
                                                                {/*                            className={"carousel-item " + (index === 0 ? "active" : "")}>*/}
                                                                {/*                    <img*/}
                                                                {/*                        data-toggle="modal"*/}
                                                                {/*                        data-target=".deletei2device"*/}
                                                                {/*                        className="img-fluid"*/}
                                                                {/*                        src={"/file" + value.path}*/}
                                                                {/*                        onClick={() => {*/}
                                                                {/*                            this.state.add_time = value.id;*/}
                                                                {/*                        }}/>*/}
                                                                {/*                </div>*/}
                                                                {/*            })*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}
                                                                {/*    <a className="carousel-control-prev" href="#galley"*/}
                                                                {/*       role="button" data-slide="prev"> <span*/}
                                                                {/*        className="carousel-control-prev-icon"*/}
                                                                {/*        aria-hidden="true"></span> <span*/}
                                                                {/*        className="sr-only">Previous</span> </a>*/}
                                                                {/*    <a className="carousel-control-next" href="#galley"*/}
                                                                {/*       role="button" data-slide="next"> <span*/}
                                                                {/*        className="carousel-control-next-icon"*/}
                                                                {/*        aria-hidden="true"></span> <span*/}
                                                                {/*        className="sr-only">Next</span> </a>*/}
                                                                {/*</div>*/}

                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div id="calendar-events" className="">
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-danger">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "xitong";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-danger m-r-10"/>系统验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "1").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-info">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangku";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-info m-r-10"/>冷藏库验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "2").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-primary">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangche";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-primary m-r-10"/>冷藏车验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "3").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-warning">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "baowenxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-warning m-r-10"/>保温箱验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "4").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-success">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "bingxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-success m-r-10"/>冰箱/冰柜验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "5").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {(this.state.status === 5 && (window.user_permission.indexOf("[AddImage2Report2]") != -1)) &&
                                                                        <div>
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="btn m-t-20 btn-info btn-block waves-effect waves-light"
                                                                                onClick={(event) => {
                                                                                    event.currentTarget.nextElementSibling.firstElementChild.click();
                                                                                }}>
                                                                                <i className="ti-plus"></i> 新增照片
                                                                            </a>
                                                                            <div className="custom-file d-none">
                                                                                <input type="file"
                                                                                       className="custom-file-input"
                                                                                       id="inputGroupFile03"
                                                                                       accept="image/*" onChange={
                                                                                    () => {
                                                                                        window.report2_image2uploadfunc(this.state.value["id"], this.state.customer)
                                                                                    }
                                                                                }/>
                                                                                <label className="custom-file-label"
                                                                                       htmlFor="inputGroupFile02"></label>
                                                                            </div>
                                                                        </div>}
                                                                        <form className="form m-t-10">
                                                                            <div className="form-group">
                                                                                <label>邮寄信息</label>
                                                                                <div className="input-group mb-3">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                    <span className="input-group-text"
                                                                                          id="basic-addon1"><i
                                                                                        className="ti-user"></i></span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="收件人姓名"
                                                                                           disabled={this.state.status !== 5}
                                                                                           defaultValue={this.state.value["mingcheng"] === "NULL" ? "" : this.state.value["mingcheng"]}
                                                                                           aria-label="Username"
                                                                                           aria-describedby="basic-addon1"
                                                                                           ref={input => this.sjmc_input = input}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <div className="input-group mb-3">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                    <span className="input-group-text"
                                                                                          id="basic-addon2"><i
                                                                                        className="ti-mobile"></i></span>
                                                                                    </div>
                                                                                    <input type="number"
                                                                                           className="form-control"
                                                                                           placeholder="联系电话"
                                                                                           disabled={this.state.status !== 5}
                                                                                           defaultValue={this.state.value["dianhua"] === "NULL" ? "" : this.state.value["dianhua"]}
                                                                                           aria-label="Username"
                                                                                           aria-describedby="basic-addon2"
                                                                                           ref={input => this.sjdh_input = input}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <div className="input-group mb-3">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                    <span className="input-group-text"
                                                                                          id="basic-addon3"><i
                                                                                        className="ti-location-pin"></i></span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="详细地址"
                                                                                           disabled={this.state.status !== 5}
                                                                                           defaultValue={this.state.value["dizhi"] === "NULL" ? "" : this.state.value["dizhi"]}
                                                                                           aria-label="Username"
                                                                                           aria-describedby="basic-addon3"
                                                                                           ref={input => this.sjdz_input = input}/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <div className="input-group mb-3">
                                                                                    <div
                                                                                        className="input-group-prepend">
                                                                                    <span className="input-group-text"
                                                                                          id="basic-addon4"><i
                                                                                        className="ti-file"></i></span>
                                                                                    </div>
                                                                                    <input type="text"
                                                                                           className="form-control"
                                                                                           placeholder="快递单号"
                                                                                           disabled={this.state.status !== 5}
                                                                                           defaultValue={this.state.value["danhao"] === "NULL" ? "" : this.state.value["danhao"]}
                                                                                           aria-label="Username"
                                                                                           aria-describedby="basic-addon4"
                                                                                           ref={input => this.sjdh2_input = input}/>
                                                                                </div>
                                                                            </div>
                                                                            {(this.state.status === 5 && (window.user_permission.indexOf("[NextReport2-5]") != -1))&&
                                                                            <button type="button"
                                                                                    data-toggle="modal"
                                                                                    data-target=".nextdevice2"
                                                                                    className="btn btn-info m-r-10">提 交
                                                                            </button>}
                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>}
                                        <h3 className="title" onClick={() => {
                                            if (this.state.status >= 6) {
                                                this.setState({
                                                    choose_status: 6
                                                }, () => {
                                                    window.gsm_to_report2_init(Object.assign({},this.state.value,{status:6}), this.state.info)
                                                })
                                            }
                                        }}>验证完毕</h3>
                                        {this.state.choose_status === 6 && <section
                                            className={"body " + (this.state.choose_status === 6 ? "current" : "")}>

                                            <div className="card">
                                                <div className="">
                                                    <div className="row">
                                                        <div className="col-10 border-right p-r-0">

                                                            {/*以前的日历*/}
                                                            {/*<div className="card-body b-l calender-sidebar">*/}
                                                            {/*    <div className="calendar_yz"></div>*/}
                                                            {/*</div>*/}

                                                            {/*2020-02-25 改成图片+文件显示模式*/}
                                                            <div className="card-body b-l">

                                                                {/*新增图片展示样式s*/}
                                                                <div className="row el-element-overlay">
                                                                    {
                                                                            this.state.image.map((value, index) => {
                                                                                return <div key={index} className="col-3">
                                                                                            <div className="card">
                                                                                                <div className="el-card-item">
                                                                                                    <div className="el-card-avatar el-overlay-1"> <img src={"/file" + value.path} />
                                                                                                        <div className="el-overlay">
                                                                                                            <ul className="list-style-none el-info">
                                                                                                                <li className="el-item"><a className="btn default btn-outline image-popup-vertical-fit el-link" href={"/file" + value.path}><i className="sl-icon-eye"></i></a></li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                            })
                                                                    }
                                                                </div>
                                                                <hr/>
                                                                <div className="row el-element-overlay">
                                                                    {this.state.file.map((value, index) => {
                                                                        return <div key={index} className="col-3">
                                                                                            <div className="card">
                                                                                                <div className="el-card-item">
                                                                                                    {value.status === "修改中" && <span
                                                                                                    className="badge badge-dark m-r-5" style={{position:'absolute',zIndex:'1'}}>修改中</span>}
                                                                                                    {value.status === "未审核" && <span
                                                                                                    className="badge badge-danger m-r-5" style={{position:'absolute',zIndex:'1'}}>未审核</span>}
                                                                                                    {value.status === "已通过" && <span
                                                                                                    className="badge badge-success" style={{position:'absolute',zIndex:'1'}}>已通过</span>}
                                                                                                    <div className="el-card-avatar el-overlay-1">
                                                                                                        <i className={"fas fa-8x pt-4 "+(()=>{
                                                                                                            switch (value.name.split(".")[value.name.split(".").length-1]) {
                                                                                                                case "xlsx":
                                                                                                                    return "fa-file-excel";
                                                                                                                case "xls":
                                                                                                                    return "fa-file-excel";
                                                                                                                case "doc":
                                                                                                                    return "fa-file-word";
                                                                                                                case "docx":
                                                                                                                    return "fa-file-word";
                                                                                                                case "pdf":
                                                                                                                    return "fa-file-pdf";
                                                                                                                default:
                                                                                                                        return "fa-file-alt";
                                                                                                            }
                                                                                                        })()}/>
                                                                                                        <div className="el-overlay">
                                                                                                            <ul className="list-style-none el-info">

                                                                                                                <li className="el-item">
                                                                                                                    <a className="btn default btn-outline image-popup-vertical-fit el-link"
                                                                                                                       href="javascript:void(0);"
                                                                                                                       onClick={() => {
                                                                                                                            window.gsm_file_download("/file" + value.path, value.name);
                                                                                                                        }}>
                                                                                                                        <i className="sl-icon-cloud-download"/>
                                                                                                                    </a>
                                                                                                                </li>

                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="el-card-content p-2">
                                                                                                        <h5 className="m-b-0">{value.name}</h5> <span className="text-muted">{value.create_at}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                        })
                                                                    }
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div id="calendar-events" className="">
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-danger">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "xitong";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-danger m-r-10"/>系统验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "1").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-info">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangku";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-info m-r-10"/>冷藏库验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "2").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-primary">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "lengcangche";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-primary m-r-10"/>冷藏车验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "3").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-warning">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "baowenxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-warning m-r-10"/>保温箱验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "4").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                            <div className="calendar-events m-b-20"
                                                                                 data-class="bg-success">
                                                                                <div data-toggle="modal"
                                                                                     data-target=".adddevice"
                                                                                     onClick={() => {
                                                                                         this.state.add_time = "bingxiang";
                                                                                     }}><i
                                                                                    className="fa fa-circle text-success m-r-10"/>冰箱/冰柜验证
                                                                                </div>
                                                                                <div className="row m-l-15">
                                                                                    {this.state.classification.filter(value => value.parent === "5").map((value, index) => {
                                                                                        return <div key={index}
                                                                                                    className="col-12"
                                                                                                    onClick={() => {
                                                                                                    }}>{value.name}</div>
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*新增验证报告样式 1907011501  e*/}
                </div>
            </div>
        )
    }
}

export {GsmReport,GsmReportClassification,GsmReport2List,GsmReportClassification2,GsmReport2}