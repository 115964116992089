import React from "react";
import $ from "jquery";
import {GsmMachineDetail2, GsmRemind, GsmPay, GsmAdmin, GsmNotificationWidget} from "./widget"
import echarts from "echarts"
import dayjs from "dayjs"

window.gsm_to_machine_detail2=(mid, timet)=> {
    window.operate_data = {name: timet + "近30分钟数据", machine_id: mid}
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMachineDetail2/>
    })

    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // specify chart configuration item and data
        $(function () {
            $.ajax({
                type: "POST",
                url: "/machine/detail/list7day2?i=" + mid + "&t=" + timet,
                success: function (data2) {
                    window.gsm_page.setState({info: data2.list0[0]}, () => {

                        $('#zero_config').DataTable({
                            language: {
                                "sProcessing": "处理中...",
                                "sLengthMenu": "显示 _MENU_ 项结果",
                                "sZeroRecords": "没有匹配结果",
                                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                                "sInfoPostFix": "",
                                "sSearch": "搜索:",
                                "sUrl": "",
                                "sEmptyTable": "表中数据为空",
                                "sLoadingRecords": "载入中...",
                                "sInfoThousands": ",",
                                "oPaginate": {
                                    "sFirst": "首页",
                                    "sPrevious": "上页",
                                    "sNext": "下页",
                                    "sLast": "末页"
                                },
                                "oAria": {
                                    "sSortAscending": ": 以升序排列此列",
                                    "sSortDescending": ": 以降序排列此列"
                                }
                            },
                            ajax: {
                                type: "POST",
                                url: "/machine/detail2?i=" + mid + "&t=" + timet,
                                dataSrc: ''
                            },
                            "order": [[0, "desc"]],
                            "columns": [
                                {"data": "id"},
                                {
                                    "data": "update_at",
                                    render: function (data, type, full, meta) {
                                        if (data == "NULL")
                                            return ""
                                        else
                                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                                    }
                                },
                                {
                                    "data": "id",
                                    render: function (data, type, full, meta) {
                                        if (full["longitude"] == 0.000000 && full["latitude"] == 0.000000) {
                                            return ""
                                        } else {
                                            return parseFloat(full["longitude"]).toFixed(6) + "," + parseFloat(full["latitude"]).toFixed(6)
                                        }
                                    }
                                },
                                {
                                    "data": "wendu",
                                    render: function (data, type, full, meta) {
                                        if (data == "NULL") {
                                            return ""
                                        } else {
                                            window.return_hts = "";
                                            data2.list0[0]["maxbaojingwendu"].split(",").map((value, index) => {
                                                if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat(data2.list0[0]["minbaojingwendu"].split(",")[index]) > parseFloat(data.split(",")[index])) {
                                                    window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index]))?"":parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                                } else {
                                                    window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index]))?"":parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                                }
                                            });
                                            return window.return_hts
                                        }
                                    }
                                },
                                {
                                    "data": "shidu",
                                    render: function (data, type, full, meta) {
                                        if (data == "NULL") {
                                            return ""
                                        } else {
                                            window.return_hts = "";
                                            data2.list0[0]["maxbaojingshidu"].split(",").map((value, index) => {
                                                if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat(data2.list0[0]["minbaojingshidu"].split(",")[index]) > parseFloat(data.split(",")[index])) {
                                                    window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index]))?"":parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                                } else {
                                                    window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index]))?"":parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                                }
                                            });
                                            return window.return_hts
                                        }
                                    }
                                },
                            ]
                        });

                        const new_data = data2.list.reverse();
                        const chartArray = [];

                        const wenduzd = [];
                        const wenduzs = [];
                        const wendupj = [];
                        const wendujc = [];
                        const wendubzc = [];
                        const shiduzd = [];
                        const shiduzs = [];
                        const shidupj = [];
                        const shidujc = [];
                        const shidubzc = [];

                        if (data2.list0[0]["minbaojingwendu"] !== "" && data2.list0[0]["minbaojingwendu"] !== "NULL" && data2.list0[0]["minbaojingwendu"] !== null && data2.list0[0]["minbaojingwendu"] !== undefined) {
                            for (let kk = 0; kk < data2.list0[0]["minbaojingwendu"].split(",").length; kk++) {
                                const wd_array = [];
                                const time_array = [];
                                flag:for (var i = 0; i < new_data.length; i++) {
                                    wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk]))?0:parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                                    time_array.push(new_data[i]["update_at"]);
                                }

                                if (wd_array.length > 0) {
                                    wenduzd.push(wd_array.length>0?Math.max.apply(null, wd_array).toFixed(1):0);
                            wenduzs.push(wd_array.length>0?Math.min.apply(null, wd_array).toFixed(1):0);
                                    wendupj.push(wd_array.length>0?(wd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / wd_array.length).toFixed(1):0);
                                    wendujc.push(wenduzd.length>0?(parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1):0);
                                    wendubzc.push(wd_array.length>1?Math.sqrt(wd_array.map(function (x) {
                                return parseFloat(x) - wendupj[wendupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (wd_array.length - 1)).toFixed(1):0);
                                }

                                const myChart = echarts.init(document.getElementById('basic-line-w' + kk));
                                const option = {
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            type: 'line'
                                        }
                                    },
                                    legend: {
                                        data: ['温度', '湿度']
                                    },

                                    color: ['#f62d51'],
                                    grid: {
                                        left: '1%',
                                        right: '2%',
                                        bottom: '3%',
                                        containLabel: true
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: time_array,
                                        scale: true,
                                        boundaryGap: false,
                                        axisLine: {onZero: false},
                                        splitLine: {show: false},
                                        splitNumber: 20,
                                        min: 'dataMin',
                                        max: 'dataMax'
                                    },
                                    yAxis: [
                                        {
                                            type: 'value',
                                            axisLabel: {
                                                formatter: '{value} ℃'
                                            }
                                        }
                                    ],
                                    dataZoom: [
                                        {
                                            type: 'inside',
                                            start: 0,
                                            end: 100
                                        },
                                        {
                                            show: true,
                                            type: 'slider',
                                            y: '90%',
                                            start: 0,
                                            end: 100
                                        }
                                    ],
                                    series: [
                                        {
                                            name: '温度' + (kk + 1),
                                            type: 'line',
                                            data: wd_array,
                                            markPoint: {
                                                data: [
                                                    {type: 'max', name: 'Max'},
                                                    {type: 'min', name: 'Min'}
                                                ]
                                            },
                                            markLine: {
                                                symbol: "none",               //去掉警戒线最后面的箭头
                                                label: {
                                                    position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                                },
                                                data: [{
                                                    silent: false,             //鼠标悬停事件  true没有，false有
                                                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                        type: "dashed",
                                                        color: "#2962FF",
                                                    },
                                                    yAxis: data2.list0[0]["minbaojingwendu"].split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                                }, {
                                                    silent: false,             //鼠标悬停事件  true没有，false有
                                                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                        type: "dashed",
                                                        color: "#2962FF",
                                                    },
                                                    yAxis: data2.list0[0]["maxbaojingwendu"].split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                                }]
                                            },
                                            lineStyle: {
                                                normal: {
                                                    width: 3,
                                                    shadowColor: 'rgba(0,0,0,0.1)',
                                                    shadowBlur: 10,
                                                    shadowOffsetY: 10
                                                }
                                            },
                                        }
                                    ]
                                };

                                myChart.setOption(option);
                                chartArray.push(myChart);
                            }
                        }
                        if (data2.list0[0]["minbaojingshidu"] !== "" && data2.list0[0]["minbaojingshidu"] !== "NULL" && data2.list0[0]["minbaojingshidu"] !== null && data2.list0[0]["minbaojingshidu"] !== undefined) {
                            for (let kk = 0; kk < data2.list0[0]["minbaojingshidu"].split(",").length; kk++) {
                                const sd_array = [];
                                const time_array = [];
                                flag:for (var i = 0; i < new_data.length; i++) {
                                    sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk]))?0:parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                                    time_array.push(new_data[i]["update_at"]);
                                }


                                if (sd_array.length > 0) {
                                    shiduzd.push(sd_array.length>0?Math.max.apply(null, sd_array).toFixed(1):0);
                            shiduzs.push(sd_array.length>0?Math.min.apply(null, sd_array).toFixed(1):0);
                                    shidupj.push(sd_array.length>0?(sd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / sd_array.length).toFixed(1):0);
                                    shidujc.push(shiduzd.length>0?(parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1):0);
                                    shidubzc.push(sd_array.length>1?Math.sqrt(sd_array.map(function (x) {
                                return parseFloat(x) - shidupj[shidupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (sd_array.length - 1)).toFixed(1):0);
                                }
                                const myChart = echarts.init(document.getElementById('basic-line-s' + kk));
                                const option = {
                                    tooltip: {
                                        trigger: 'axis',
                                        axisPointer: {
                                            type: 'line'
                                        }
                                    },
                                    legend: {
                                        data: ['温度', '湿度']
                                    },

                                    color: ['#2962FF'],
                                    grid: {
                                        left: '1%',
                                        right: '2%',
                                        bottom: '3%',
                                        containLabel: true
                                    },
                                    xAxis: {
                                        type: 'category',
                                        data: time_array,
                                        scale: true,
                                        boundaryGap: false,
                                        axisLine: {onZero: false},
                                        splitLine: {show: false},
                                        splitNumber: 20,
                                        min: 'dataMin',
                                        max: 'dataMax'
                                    },
                                    yAxis: [
                                        {
                                            type: 'value',
                                            axisLabel: {
                                                formatter: '{value} %'
                                            }
                                        }
                                    ],
                                    dataZoom: [
                                        {
                                            type: 'inside',
                                            start: 0,
                                            end: 100
                                        },
                                        {
                                            show: true,
                                            type: 'slider',
                                            y: '90%',
                                            start: 0,
                                            end: 100
                                        }
                                    ],
                                    series: [
                                        {
                                            name: '湿度' + (kk + 1),
                                            type: 'line',
                                            data: sd_array,
                                            markPoint: {
                                                data: [
                                                    {type: 'max', name: 'Max'},
                                                    {type: 'min', name: 'Min'}
                                                ]
                                            },
                                            markLine: {
                                                symbol: "none",               //去掉警戒线最后面的箭头
                                                label: {
                                                    position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                                },
                                                data: [{
                                                    silent: false,             //鼠标悬停事件  true没有，false有
                                                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                        type: "dashed",
                                                        color: "#f62d51",
                                                    },
                                                    yAxis: data2.list0[0]["minbaojingshidu"].split(",")[kk] // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                                }, {
                                                    silent: false,             //鼠标悬停事件  true没有，false有
                                                    lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                        type: "dashed",
                                                        color: "#f62d51",
                                                    },
                                                    yAxis: data2.list0[0]["maxbaojingshidu"].split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                                }]
                                            },
                                            lineStyle: {
                                                normal: {
                                                    width: 3,
                                                    shadowColor: 'rgba(0,0,0,0.1)',
                                                    shadowBlur: 10,
                                                    shadowOffsetY: 10
                                                }
                                            },
                                        }
                                    ]
                                };

                                myChart.setOption(option);
                                chartArray.push(myChart);
                            }
                        }

                        window.onresize = function () {
                            for (var i = 0; i < chartArray.length; i++)
                                chartArray[i].resize();
                        }

                        window.gsm_page.setState({
                            wenduzd: wenduzd.join(","),
                            wenduzs: wenduzs.join(","),
                            wendupj: wendupj.join(","),
                            wendujc: wendujc.join(","),
                            wendubzc: wendubzc.join(","),
                            shiduzd: shiduzd.join(","),
                            shiduzs: shiduzs.join(","),
                            shidupj: shidupj.join(","),
                            shidujc: shidujc.join(","),
                            shidubzc: shidubzc.join(","),
                        })
                    });

                }
            });

        })
    }, 1)

}

window.gsm_to_remind=()=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmRemind/>
    })


    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            bServerSide: true,
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            //dom: 'Bfrtip',
            buttons: [
                // {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/remind"
            },
            "order": [[7, "desc"]],
            "columns": [
                {//这个就是用来画第一列的小方块多选框的
                    "data": "id",
                    render: (data, type, full, mate) => {
                        return "<span class='mul-click-icon' style='cursor: pointer' onclick='window.gsm_page.state.click(\""+data+"\",this)'><i class='far fa-square fa-2x' /></span>"
                    }
                },
                {
                    "data": "customer",
                    render: (data, type, full, mate) => {
                        return full["customer_name"]
                    }
                },
                {
                    "data": "machine_id",
                    render: (data, type, full, mate) => {
                        if (full["itype"].indexOf("温度报警") >= 0 || full["itype"].indexOf("湿度报警") >= 0) {
                            return "<span style='cursor: pointer' onclick=\"window.gsm_history_save('window.gsm_to_machine_detail2(\\\'" + full["machine_id"] + "\\\',\\\'" + dayjs(full["create_at"]).format("YYYY-MM-DD HH:mm:ss") + "\\\')',false)\">" + data + "</span>"
                        } else {
                            return "<span style='cursor: pointer'>" + data + "</span>"
                        }
                    }
                },
                {
                    "data": "machine_id",
                    render: (data, type, full, mate) => {
                        return full["machine_name"]
                    }
                },
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "已处理":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            case "报警":
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>";
                                break;
                            case "离线":
                                return "<span class=\"btn btn-xs btn-secondary font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                {"data": "itype"},
                {"data": "message"},
                {"data": "sms_info"},
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        if (full["status"] == "已处理") {
                            return ((full["itype"].indexOf("温度报警") >= 0 || full["itype"].indexOf("湿度报警") >= 0) ? "<a onMouseOver=\"$(this).tooltip('show')\" onclick=\"window.gsm_history_save('window.gsm_to_machine_detail2(\\\'" + full["machine_id"] + "\\\',\\\'" + dayjs(full["create_at"]).format("YYYY-MM-DD HH:mm:ss") + "\\\')',false)\" href=\"javascript:void(0)\" class=\"text-warning p-r-10\" data-toggle=\"modal\" data-target=\".chakanbeizhu\" data-original-title=\"查看近30分钟数据\"><i class=\"fas fa-history\"></i></a>" : "") +
                                "<a onMouseOver=\"$(this).tooltip('show')\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["operate"] + "\\n\\n" + full["operate_name"] + "\\n" + full["operate_at"] + "\");' href=\"javascript:void(0)\" class=\"text-success p-r-10\" data-toggle=\"modal\" data-target=\".chakanbeizhu\" data-original-title=\"查看\"><i class=\"fas fa-eye\"></i></a>" +
                                ((window.user_permission.indexOf("[DeleteRemind]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "");
                        } else {
                            return ((full["itype"].indexOf("温度报警") >= 0 || full["itype"].indexOf("湿度报警") >= 0) ? "<a onMouseOver=\"$(this).tooltip('show')\" onclick=\"window.gsm_history_save('window.gsm_to_machine_detail2(\\\'" + full["machine_id"] + "\\\',\\\'" + dayjs(full["create_at"]).format("YYYY-MM-DD HH:mm:ss") + "\\\')',false)\" href=\"javascript:void(0)\" class=\"text-warning p-r-10\" data-toggle=\"modal\" data-target=\".chakanbeizhu\" data-original-title=\"查看近30分钟数据\"><i class=\"fas fa-history\"></i></a>" : "") +
                                ((window.user_permission.indexOf("[RemindOperate]") > -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" onclick='window.operate_id = " + full["id"] + ";' href=\"javascript:void(0)\" class=\"text-inverse p-r-10\" data-toggle=\"modal\" data-target=\".beizhu\" title=\"\" data-original-title=\"处理\"><i class=\"fas fa-pencil-alt\"></i></a>" : "") +
                                ((window.user_permission.indexOf("[DeleteRemind]") > -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "");
                        }
                    }
                }
            ]
        });
        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1);
}

window.gsm_to_pay=()=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmPay/>
    })


    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            pageLength: 20,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            //dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/pay",
                dataSrc: ''
            },
            "columns": [
                {"data": "customer_name"},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "已支付":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            case "未支付":
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                {"data": "itype"},
                {"data": "money"},
                {"data": "message"},
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1);
}

window.pay_to_machinepage=(machine_id, machine, month)=> {
    window.ajax({
            cache: true,
            type: "POST",
            url: "/pay/machine",
            data: {
                i: machine,
                mi: machine_id,
                mxt: month,
            },
            error: function () {
            },
            fail: function () {
            },
            success: function (response) {
                const json_data = JSON.parse(response);
                if (json_data.url == "") {
                    window.toastr.success('续费失败', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                } else {
                    window.open(json_data.url);
                }
            }
        }
    );
}

window.admin_checkifieldfunc=(e, id, name)=> {
    if (window.user_permission.indexOf("[CheckAdmin]") != -1) {
        var el = e;
        // el.checked = !el.checked;
        $.ajax({
            type: "POST",
            url: "/admin/check",
            data: {
                i: id,
                n: name,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('操作成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    //el.checked = !el.checked;
                    if (el.firstElementChild.className == "fas fa-times-circle text-secondary")
                        el.firstElementChild.className = "fas fa-check-circle text-success";
                    else
                        el.firstElementChild.className = "fas fa-times-circle text-secondary";
                }
            }
        });
    }
}

window.gsm_to_admin=()=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmAdmin/>
    })
    setTimeout(function () {
        $.fn.dataTable.ext.search = [];
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            //serverSide: false,
            processing: true,
            pageLength: 3,
            lengthChange: false,
            //dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/admin",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "正常":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                {"data": "account"},

                // 首页地图
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CHome]')\"><i class='fas fa-times-circle text-secondary'></i> 地图</div>";
                        } else if (full.ifield.indexOf("[CHome]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CHome]')\"><i class='fas fa-check-circle text-success'></i> 地图</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CHome]')\"><i class='fas fa-times-circle text-secondary'></i> 地图</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[XCXMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 租赁管理</div>";
                        } else if (full.ifield.indexOf("[XCXMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[XCXMachine]')\"><i class='fas fa-check-circle text-success'></i> 租赁管理</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[XCXMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 租赁管理</div>";
                        }

                        return str
                    }
                },

                // 设备
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Machine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Machine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Machine]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Machine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachine]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachine]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MoneyMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改续费金额</div>";
                        } else if (full.ifield.indexOf("[MoneyMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MoneyMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改续费金额</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MoneyMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改续费金额</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        } else if (full.ifield.indexOf("[MachineOperate]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineOperate]')\"><i class='fas fa-check-circle text-success'></i> 报修</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        } else if (full.ifield.indexOf("[MachineDetail]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineDetail]')\"><i class='fas fa-check-circle text-success'></i> 历史</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        }

                        return str
                    }
                },

                // 设备分类
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[MachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddMachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施列表</div>";
                        } else if (full.ifield.indexOf("[MachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施添加</div>";
                        } else if (full.ifield.indexOf("[AddMachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineOperate2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施操作</div>";
                        } else if (full.ifield.indexOf("[MachineOperate2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineOperate2]')\"><i class='fas fa-check-circle text-success'></i> 设施操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineOperate2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施操作</div>";
                        }

                        return str
                    }
                },

                // 设备厂家
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[MachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddMachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        return str
                    }
                },

                // 车辆
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[CarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachine]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MoneyCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改车辆续费金额</div>";
                        } else if (full.ifield.indexOf("[MoneyCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MoneyCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改车辆续费金额</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MoneyCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改车辆续费金额</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        } else if (full.ifield.indexOf("[CarMachineOperate]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachineOperate]')\"><i class='fas fa-check-circle text-success'></i> 报修</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        } else if (full.ifield.indexOf("[CarMachineDetail]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachineDetail]')\"><i class='fas fa-check-circle text-success'></i> 历史</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CarMachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        }

                        return str
                    }
                },

                // 客户
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[CustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 详细</div>";
                        } else if (full.ifield.indexOf("[CustomerMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerMachine]')\"><i class='fas fa-check-circle text-success'></i> 详细</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 详细</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerClassificationPermission]')\"><i class='fas fa-times-circle text-secondary'></i> 权限列表</div>";
                        } else if (full.ifield.indexOf("[CustomerClassificationPermission]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerClassificationPermission]')\"><i class='fas fa-check-circle text-success'></i> 权限列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CustomerClassificationPermission]')\"><i class='fas fa-times-circle text-secondary'></i> 权限列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 权限操作</div>";
                        } else if (full.ifield.indexOf("[CheckCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 权限操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 权限操作</div>";
                        }

                        return str
                    }
                },

                // 验证管理
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';
                        str += "<div class='d-flex flex-wrap th-width' style='min-width:450px;'>";
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        } else if (full.ifield.indexOf("[Report2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report2]')\"><i class='fas fa-check-circle text-success'></i> 管理</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportList2]')\"><i class='fas fa-times-circle text-secondary'></i> 列表（管理端）</div>";
                        } else if (full.ifield.indexOf("[ReportList2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportList2]')\"><i class='fas fa-check-circle text-success'></i> 列表（管理端）</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportList2]')\"><i class='fas fa-times-circle text-secondary'></i> 列表（管理端）</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report22]')\"><i class='fas fa-times-circle text-secondary'></i> 详细（管理端）</div>";
                        } else if (full.ifield.indexOf("[Report22]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report22]')\"><i class='fas fa-check-circle text-success'></i> 详细（管理端）</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report22]')\"><i class='fas fa-times-circle text-secondary'></i> 详细（管理端）</div>";
                        }


                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-1]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-1]')\"><i class='fas fa-check-circle text-success'></i> 流程1提交</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-2]')\"><i class='fas fa-check-circle text-success'></i> 流程2提交</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-3]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-3]')\"><i class='fas fa-check-circle text-success'></i> 流程3提交</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-4]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4提交</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-5]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-5]')\"><i class='fas fa-check-circle text-success'></i> 流程5提交</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-6]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-6]')\"><i class='fas fa-check-circle text-success'></i> 流程6提交</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[NextReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6提交</div>";
                        }


                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-1]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-1]')\"><i class='fas fa-check-circle text-success'></i> 流程1回退</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-2]')\"><i class='fas fa-check-circle text-success'></i> 流程2回退</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-3]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-3]')\"><i class='fas fa-check-circle text-success'></i> 流程3回退</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-4]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4回退</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-5]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-5]')\"><i class='fas fa-check-circle text-success'></i> 流程5回退</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-6]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-6]')\"><i class='fas fa-check-circle text-success'></i> 流程6回退</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[PreReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6回退</div>";
                        }

                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1添加到日历</div>";
                        } else if (full.ifield.indexOf("[AddReport2-1]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport2-1]')\"><i class='fas fa-check-circle text-success'></i> 流程1添加到日历</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1添加到日历</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3添加到日历</div>";
                        } else if (full.ifield.indexOf("[AddReport2-3]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport2-3]')\"><i class='fas fa-check-circle text-success'></i> 流程3添加到日历</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3添加到日历</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[EXReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2审核文件</div>";
                        } else if (full.ifield.indexOf("[EXReport2-2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[EXReport2-2]')\"><i class='fas fa-check-circle text-success'></i> 流程2审核文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[EXReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2审核文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加文件</div>";
                        } else if (full.ifield.indexOf("[AddFileReport2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddFileReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2添加文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2修改文件</div>";
                        } else if (full.ifield.indexOf("[ReviseFileReport2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseFileReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2修改文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2修改文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除文件</div>";
                        } else if (full.ifield.indexOf("[DeleteFileReport2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteFileReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2删除文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除文件</div>";
                        }

                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加布点图</div>";
                        } else if (full.ifield.indexOf("[AddImageReport2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddImageReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2添加布点图</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加布点图</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除布点图</div>";
                        } else if (full.ifield.indexOf("[DeleteImageReport2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteImageReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2删除布点图</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除布点图</div>";
                        }

                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5添加图片</div>";
                        } else if (full.ifield.indexOf("[AddImage2Report2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddImage2Report2]')\"><i class='fas fa-check-circle text-success'></i> 流程5添加图片</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5添加图片</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5删除图片</div>";
                        } else if (full.ifield.indexOf("[DeleteImage2Report2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteImage2Report2]')\"><i class='fas fa-check-circle text-success'></i> 流程5删除图片</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5删除图片</div>";
                        }

                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[EXReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4审核文件</div>";
                        } else if (full.ifield.indexOf("[EXReport2]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[EXReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程4审核文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[EXReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4审核文件</div>";
                        }

                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4添加文件</div>";
                        } else if (full.ifield.indexOf("[AddFileReport2-4]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddFileReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4添加文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4添加文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4修改文件</div>";
                        } else if (full.ifield.indexOf("[ReviseFileReport2-4]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseFileReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4修改文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4修改文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4删除文件</div>";
                        } else if (full.ifield.indexOf("[DeleteFileReport2-4]") != -1) {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteFileReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4删除文件</div>";
                        } else {
                            str+= "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4删除文件</div>";
                        }
                        str += "</div>";
                        return str
                    }
                },

                // 验证管理分类
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        } else if (full.ifield.indexOf("[ReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 管理</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        return str
                    }
                },

                //验证统计
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Report]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Report]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddReport]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReport]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReport]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseReport]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReport]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReport]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReport]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteReport]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReport]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReport]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        return str
                    }
                },

                // 验证报告分类
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[ReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        return str
                    }
                },

                // 报警
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Remind]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Remind]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Remind]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Remind]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteRemind]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteRemind]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteRemind]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteRemind]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[RemindOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 操作</div>";
                        } else if (full.ifield.indexOf("[RemindOperate]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[RemindOperate]')\"><i class='fas fa-check-circle text-success'></i> 操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[RemindOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 操作</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Notification]')\"><i class='fas fa-times-circle text-secondary'></i> 提醒</div>";
                        } else if (full.ifield.indexOf("[Notification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Notification]')\"><i class='fas fa-check-circle text-success'></i> 提醒</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Notification]')\"><i class='fas fa-times-circle text-secondary'></i> 提醒</div>";
                        }

                        return str
                    }
                },

                // 共享
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Share]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Share]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Share]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Share]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddShare]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddShare]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddShare]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddShare]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseShare]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseShare]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseShare]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseShare]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteShare]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteShare]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteShare]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteShare]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineImg]')\"><i class='fas fa-times-circle text-secondary'></i> 设备图</div>";
                        } else if (full.ifield.indexOf("[MachineImg]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineImg]')\"><i class='fas fa-check-circle text-success'></i> 设备图</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[MachineImg]')\"><i class='fas fa-times-circle text-secondary'></i> 设备图</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备列表</div>";
                        } else if (full.ifield.indexOf("[ShareMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ShareMachine]')\"><i class='fas fa-check-circle text-success'></i> 设备列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备权限操作</div>";
                        } else if (full.ifield.indexOf("[CheckShareMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckShareMachine]')\"><i class='fas fa-check-circle text-success'></i> 设备权限操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备权限操作</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 文件列表</div>";
                        } else if (full.ifield.indexOf("[ShareFile]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ShareFile]')\"><i class='fas fa-check-circle text-success'></i> 文件列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 文件列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 添加共享文件</div>";
                        } else if (full.ifield.indexOf("[AddShareFile]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddShareFile]')\"><i class='fas fa-check-circle text-success'></i> 添加共享文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 添加共享文件</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 删除共享文件</div>";
                        } else if (full.ifield.indexOf("[DeleteShareFile]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteShareFile]')\"><i class='fas fa-check-circle text-success'></i> 删除共享文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 删除共享文件</div>";
                        }

                        return str
                    }
                },

                // 管理员
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Admin]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Admin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Admin]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[Admin]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddAdmin]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[AddAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseAdmin]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[ReviseAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteAdmin]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[DeleteAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 权限</div>";
                        } else if (full.ifield.indexOf("[CheckAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckAdmin]')\"><i class='fas fa-check-circle text-success'></i> 权限</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[CheckAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 权限</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[LogI]')\"><i class='fas fa-times-circle text-secondary'></i> 系统日志</div>";
                        } else if (full.ifield.indexOf("[LogI]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[LogI]')\"><i class='fas fa-check-circle text-success'></i> 系统日志</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.admin_checkifieldfunc(this,'" + data + "','[LogI]')\"><i class='fas fa-times-circle text-secondary'></i> 系统日志</div>";
                        }

                        return str
                    }
                },


                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return ((window.user_permission.indexOf("[ReviseAdmin]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\"\n" +
                            "                                                           data-target=\".adddevice\" class=\"text-success p-r-10\"\n" +
                            "                                                           data-toggle=\"tooltip\" title=\"\" data-original-title=\"编辑\" onclick='window.gsm_page.state.new = false;window.gsm_page.state.id = \"" + full["id"] + "\";" +
                            "                window.gsm_page.mc_input.value = \"" + full["name"] + "\";" +
                            "                window.gsm_page.zh_input.value = \"" + full["account"] + "\";" +
                            "                window.gsm_page.mm_input.value = \"" + full["password"] + "\";" +
                            "                window.gsm_page.zt_input.value = \"" + full["status"] + "\";" +
                            "                window.gsm_page.ad_input.value = \"" + full["admin"] + "\";" +
                            "'><i class=\"fas fa-edit\"></i></a>" : "") +
                            ((window.user_permission.indexOf("[DeleteAdmin]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "");
                        ;
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}

window.customer_checkifieldfunc=(e, id, name)=> {
    if (window.user_permission.indexOf("[CheckCustomerClassification]") != -1) {
        var el = e;
        // el.checked = !el.checked;
        $.ajax({
            type: "POST",
            url: "/customer/classification/check",
            data: {
                i: id,
                n: name,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('操作成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    //el.checked = !el.checked;
                    if (el.firstElementChild.className == "fas fa-times-circle text-secondary")
                        el.firstElementChild.className = "fas fa-check-circle text-success";
                    else
                        el.firstElementChild.className = "fas fa-times-circle text-secondary";
                }
            }
        });
    }
}
