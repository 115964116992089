import React from "react";
import $ from "jquery";

//设备详细
class GsmLogI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            full2: false
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"card" + (this.state.full2 ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                日志数据
                                <div className="m-l-5 btn-group" id="dcbtn"></div>
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full2 = !this.state.full2;
                                            this.setState({
                                                full2: this.state.full2
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>名称
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作信息
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作时间
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row">
                                                        <th>序号</th>
                                                        <th>名称</th>
                                                        <th>操作</th>
                                                        <th>操作信息</th>
                                                        <th>操作时间</th>
                                                    </tr>
                                                    </tfoot>
                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {GsmLogI}