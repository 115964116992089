import React from "react";
import GsmHomepage from "./homepage";
import GsmHomepage2 from "./homepage2";
import "./base/history";
import {GsmNotificationWidget} from "./widget";

window.time_refresh_time = 60000;


class GsmApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification_flag: false,
            notification: [],
            notification_machine: {},
            name: "",
            page: null,
            menu: null,
            sms: 0,
            role: "",
            dxts: 1000, //短信提示
            sms_info: [],
            user_permission: "",
            refresh: () => {
                window.ajax({
                    cache: true,
                    type: "POST",
                    url: "/remind/notification",
                    error: function () {
                        // window.gsm_hide_progress();
                        // window.gsm_show_toast("toast-error", "登陆失败", 3000);
                    },
                    fail: function () {
                        // window.gsm_hide_progress();
                        // window.gsm_show_toast("toast-error", "登陆失败", 3000);
                    },
                    success: function (data) {
                        if (data != null && data != 'null') {
                            const json_data2 = JSON.parse(data);
                            const json_data = json_data2.data;

                            if (json_data2.bx != undefined && json_data2.bx != null && json_data2.bx != 'null') {
                                for (let i = 0; i < json_data2.bx.length; i++) {
                                    window.toastr.error("【" + json_data2.bx[i]["machine_id"] + "】" + (json_data2.bx[i]["remind_at"] === "NULL" ? "" : json_data2.bx[i]["remind_at"]) + " 设备报修", '消息', {
                                        "showMethod": "slideDown",
                                        "hideMethod": "slideUp",
                                        timeOut: 5000,
                                        positionClass: 'toast-top-center'
                                    });
                                }
                            }
                            if (json_data2.sms != undefined && json_data2.sms != null && json_data2.sms != 'null') {
                                if (json_data2.sms <= 0 && window.gsm_app.state.dxts >= 0) {
                                    window.gsm_app.state.dxts = -1;
                                    window.toastr.error('短信次数为0，请充值', '消息', {
                                        "showMethod": "slideDown",
                                        "hideMethod": "slideUp",
                                        timeOut: 0,
                                        positionClass: 'toast-top-center'
                                    });
                                } else if (json_data2.sms <= 100 && window.gsm_app.state.dxts >= 100) {
                                    window.gsm_app.state.dxts = 0;
                                    window.toastr.error('短信次数不足100，请充值', '消息', {
                                        "showMethod": "slideDown",
                                        "hideMethod": "slideUp",
                                        timeOut: 0,
                                        positionClass: 'toast-top-center'
                                    });
                                } else if (json_data2.sms <= 500 && window.gsm_app.state.dxts >= 500) {
                                    window.gsm_app.state.dxts = 100;
                                    window.toastr.error('短信次数不足500，请充值', '消息', {
                                        "showMethod": "slideDown",
                                        "hideMethod": "slideUp",
                                        timeOut: 0,
                                        positionClass: 'toast-top-center'
                                    });
                                } else if (json_data2.sms <= 1000 && window.gsm_app.state.dxts >= 1000) {
                                    window.gsm_app.state.dxts = 500;
                                    window.toastr.error('短信次数不足1000，请充值', '消息', {
                                        "showMethod": "slideDown",
                                        "hideMethod": "slideUp",
                                        timeOut: 0,
                                        positionClass: 'toast-top-center'
                                    });
                                }
                                window.gsm_app.setState({
                                    sms: json_data2.sms
                                });
                            }
                            if (json_data != undefined && json_data != null && json_data != 'null') {
                                if (json_data.length != 0) {
                                    const txflag = window.gsm_app.state.notification === window.gsm_page.state.tx
                                    window.gsm_app.setState({
                                        notification_flag: true,
                                        notification: json_data.concat(window.gsm_app.state.notification),
                                    }, () => {
                                        if (txflag) {
                                            window.gsm_page.setState({
                                                tx: window.gsm_app.state.notification
                                            })
                                        }
                                        for (let i = 0; i < json_data.length; i++) {
                                            if (json_data[i]["machine_id"] in window.gsm_app.state.notification_machine) {
                                                if (window.gsm_app.state.notification_machine[json_data[i]["machine_id"]] >= 5) {
                                                    delete window.gsm_app.state.notification_machine[json_data[i]["machine_id"]];
                                                    window.toastr.error("【" + json_data[i]["machine_id"] + "】" + json_data[i]["create_at"] + "：" + json_data[i]["message"], json_data[i]["itype"], {
                                                        "showMethod": "slideDown",
                                                        "hideMethod": "slideUp",
                                                        timeOut: 0,
                                                        positionClass: 'toast-top-right'
                                                    });
                                                } else {
                                                    window.gsm_app.state.notification_machine[json_data[i]["machine_id"]] = window.gsm_app.state.notification_machine[json_data[i]["machine_id"]] + 1;
                                                }
                                            } else {
                                                window.gsm_app.state.notification_machine[json_data[i]["machine_id"]] = 0
                                            }
                                        }
                                    })
                                }
                            }

                        }
                    }
                });
            }
        };
        window.user_permission = "";
    }

    componentDidMount() {
        window.ajax({
            cache: true,
            type: "POST",
            url: "/index2",
            error: function () {
                window.toastr.error('提示', '登陆失败', {
                    "positionClass": "toast-top-center",
                    "showMethod": "slideDown",
                    "hideMethod": "slideUp",
                    timeOut: 2000
                });
                // window.gsm_hide_progress();
                // window.gsm_show_toast("toast-error", "登陆失败", 3000);
            },
            fail: function () {
                window.toastr.error('提示', '登陆失败', {
                    "positionClass": "toast-top-center",
                    "showMethod": "slideDown",
                    "hideMethod": "slideUp",
                    timeOut: 2000
                });
                // window.gsm_hide_progress();
                // window.gsm_show_toast("toast-error", "登陆失败", 3000);
            },
            success: function (data) {
                const data_json = JSON.parse(data);
                if (data_json.page === "main") {
                    window.user_name = data_json.name;
                    window.user_permission = data_json.ifield;
                    window.user_itype = data_json.juese;
                    window.user_sms = data_json.dxsl;
                } else {
                    window.user_name = "";
                    window.user_sms = 0;
                    window.user_itype = "";
                    window.user_permission = "";
                }
                window.file_server = "https://www.alletong.com";
                window.gsm_app.setState({
                    name: window.user_name,
                    sms: window.user_sms,
                    role: window.user_itype,
                    user_permission: window.user_permission,
                    page: (window.user_itype !== "共享账号" ? ((window.user_permission.indexOf("[CHome]") != -1) ?
                        <GsmHomepage GsmFlag={true}/> :
                        <GsmHomepage2 GsmFlag={true}/>) : null)
                }, () => {
                    window.jz3();
                    if (window.user_permission.indexOf("[Notification]") != -1 || window.user_itype === "管理员")
                        setInterval(function () {
                            // console.log(123)
                            window.gsm_app.state.refresh();
                        }, 30000)
                });
                // this.state.page = (window.user_itype !== "共享账号" ? ((window.user_permission.indexOf("[CHome]") != -1) ?
                //     <GsmHomepage GsmFlag={true}/> :
                //     <GsmHomepage2 GsmFlag={true}/>) : null)
            }
        });

    }


    render() {
        window.gsm_app = this;
        return (
            [
                <div key={-1} className="modal fade smsinfodevice" tabIndex="-1" role="dialog"
                     aria-labelledby="myModalLabel"
                     aria-hidden="true" style={{display: "none"}}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="control-label">请选择套餐:</label>
                                        <select className="select2 form-control custom-select"
                                                style={{width: "100%", height: "36px"}}
                                                ref={input => this.dxtc_input = input} onChange={
                                            () => {
                                                if (this.dxtc_input.value != "") {
                                                    SmsFlag:for (let s = 0; s < this.state.sms_info.length; s++) {
                                                        if (this.state.sms_info[s]["id"] == this.dxtc_input.value) {
                                                            this.tcsl_input.value = this.state.sms_info[s]["sms"];
                                                            this.tcjg_input.value = this.state.sms_info[s]["money"];
                                                            break SmsFlag;
                                                        }
                                                    }
                                                } else {
                                                    this.tcsl_input.value = "";
                                                    this.tcjg_input.value = "";
                                                }
                                            }
                                        }>
                                            <optgroup label="">
                                                <option key={"dxtcls-1"}></option>
                                                {this.state.sms_info.map((value, index) => {
                                                    return <option key={"dxtcls" + index} value={value["id"]} onClick={
                                                        () => {
                                                        }
                                                    }>{value["name"]}</option>
                                                })}
                                            </optgroup>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="control-label">套餐数量:</label>
                                        <input type="text" className="form-control" id="recipient-name" disabled={true}
                                               ref={input => this.tcsl_input = input}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="control-label">套餐价格:</label>
                                        <input type="text" className="form-control" id="recipient-name" disabled={true}
                                               ref={input => this.tcjg_input = input}/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary waves-effect"
                                        data-dismiss="modal">取 消
                                </button>
                                <button type="button"
                                        className="btn btn-info waves-effect waves-light"
                                        onClick={() => {
                                            if (this.dxtc_input.value != "") {
                                                window.ajax({
                                                        cache: true,
                                                        type: "POST",
                                                        url: "/pay/sms",
                                                        data: {
                                                            i: this.dxtc_input.value,
                                                        },
                                                        error: function () {
                                                        },
                                                        fail: function () {
                                                        },
                                                        success: function (response) {
                                                            const json_data = JSON.parse(response);
                                                            if (json_data.url == "") {
                                                                window.toastr.success('续费失败', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                            } else {
                                                                window.open(json_data.url);
                                                            }
                                                        }
                                                    }
                                                );
                                            }
                                        }}> 提 交
                                </button>
                            </div>
                        </div>
                    </div>
                </div>,
                <header key={0} className="topbar">
                    <nav className="navbar top-navbar navbar-expand-md navbar-dark">

                        <div className="navbar-header">
                            <a className="nav-toggler waves-effect waves-light d-block d-md-none"
                               href="javascript:void(0)">
                                <i className="ti-menu ti-close"></i>
                            </a>
                            <a className="navbar-brand" href="javascript:void(0)" onClick={() => {
                                if ((this.state.user_permission.indexOf("[CHome]") != -1)) {
                                    window.gsm_history_save("window.gsm_to_homepage()", true)
                                    // document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                } else {
                                    window.gsm_history_save("window.gsm_to_homepage2()", true)
                                    // document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                }
                            }}>
                                <b className="logo-icon">
                                    <img src="https://p.upyun.com/demo/tmp/XmlObdyW.png"
                                         style={{width: "60%", maxWidth: "48px"}}
                                         className="dark-logo"/>
                                    <img src="https://p.upyun.com/demo/tmp/XmlObdyW.png"
                                         style={{width: "60%", maxWidth: "48px"}}
                                         className="light-logo"/>
                                </b>
                                <span className="logo-text text-left">
                                    <img src="https://p.upyun.com/demo/tmp/sEHTQXs3.png"
                                         style={{width: "80%", maxWidth: "130px"}}
                                         className="dark-logo"/>
                                    <img src="https://p.upyun.com/demo/tmp/sEHTQXs3.png"
                                         style={{width: "80%", maxWidth: "130px"}}
                                         className="light-logo"/>
                                </span>
                            </a>
                            <a className="topbartoggler d-block d-md-none waves-effect waves-light"
                               href="javascript:void(0)"
                               data-toggle="collapse" data-target="#navbarSupportedContent"
                               aria-controls="navbarSupportedContent" aria-expanded="false"
                               aria-label="Toggle navigation">
                                <i className="ti-more"></i>
                            </a>
                        </div>


                        <div className="navbar-collapse collapse" id="navbarSupportedContent">

                            <ul className="navbar-nav float-left mr-auto">

                                <li className="nav-item d-none d-md-block">
                                    <a className="nav-link sidebartoggler waves-effect waves-light"
                                       href="javascript:void(0)"
                                       data-sidebartype="mini-sidebar">
                                        <i className="sl-icon-menu font-14"></i>
                                    </a>
                                </li>
                                <li className="nav-item d-none d-md-block">
                                    <a className="nav-link dropdown-toggle waves-effect waves-dark"
                                       href="javascript:void(0)" onClick={
                                        window.gsm_history_back.bind(this)
                                    }>
                                        <i className="fas fa-arrow-left font-14"></i>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link waves-effect waves-dark" href="javascript:void(0)"
                                       onClick={() => {
                                           if ((this.state.user_permission.indexOf("[CHome]") != -1)) {
                                               window.gsm_history_save("window.gsm_to_homepage()", true)
                                               // document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                           } else {
                                               window.gsm_history_save("window.gsm_to_homepage2()", true)
                                               // document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                           }
                                       }}>
                                        <i className="fas fa-home font-14"/>
                                    </a>
                                </li>

                                {(this.state.user_permission.indexOf("[Notification]") != -1) ?
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           onClick={
                                               () => {
                                                   this.setState({
                                                       notification_flag: false
                                                   })
                                                   document.querySelector(".nav-link.dropdown-toggle.waves-effect.waves-dark.d-none").click();
                                               }
                                           }>
                                            <i className={"fas fa-bell font-14 " + (this.state.notification_flag ? "gsm-animation-repeat" : "")}></i>

                                        </a>
                                        <a className="nav-link dropdown-toggle waves-effect waves-dark d-none"
                                           href="javascript:void(0)"
                                           data-toggle="dropdown"
                                           aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-bell font-14"></i>
                                        </a>
                                        <div className="dropdown-menu mailbox animated bounceInUp">
                                <span className="with-arrow">
                                    <span className="bg-secondary"></span>
                                </span>
                                            <ul className="list-style-none">
                                                <li>
                                                    <div className="drop-title bg-secondary text-white">
                                                        <h4 className="m-b-0 m-t-5">报警通知</h4>
                                                        <span className="font-light">Notifications</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="message-center notifications">
                                                        {this.state.notification.map((value, index) => {
                                                            return <GsmNotificationWidget key={index} GsmVal={value}/>
                                                        })}
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="nav-link text-center m-b-5 bg-secondary"
                                                       href="javascript:void(0)"
                                                       onClick={
                                                           () => {
                                                               window.gsm_history_save("window.gsm_to_remind()", true)
                                                               document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                           }
                                                       }>
                                                        <strong>查看全部</strong>
                                                        <i className="fa fa-angle-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> : null}

                            </ul>
                            <ul className="navbar-nav float-right">
                                {this.state.role === "用户" ? <li className="nav-item">
                                    <a className="nav-link waves-effect waves-dark" href="javascript:void(0)"
                                       data-toggle="modal"
                                       data-target=".smsinfodevice" onClick={() => {
                                        const els = this;
                                        window.ajax({
                                            cache: true,
                                            type: "POST",
                                            url: "/pay/sms/info",
                                            error: function () {
                                            },
                                            fail: function () {
                                            },
                                            success: function (data) {
                                                els.setState({
                                                    sms_info: JSON.parse(data)
                                                })
                                            }
                                        })
                                    }}>
                                        <div className="btn-group btn-group-sm" role="group">
                                            <button type="button"
                                                    className="btn btn-outline-light"> 剩余短信：{this.state.sms}</button>
                                            <div className="btn btn-light "><i className="ti-plus"></i></div>
                                        </div>
                                    </a>
                                </li> : null}
                                <li className="nav-item">
                                    <a className="nav-link waves-effect waves-dark"
                                       href="javascript:window.gsm_exit();">
                                        {this.state.name} <i className="fas fa-sign-out-alt font-14"></i>
                                    </a>
                                </li>
                                <li className="nav-item dropdown d-none">
                                    <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                                       href=""
                                       data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <img src="/asset/boli/images/boliico.png" alt="user" className="rounded-circle"
                                             width="31"/>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right user-dd animated bounceInRight">
                                <span className="with-arrow">
                                    <span className="bg-primary"></span>
                                </span>
                                        <div
                                            className="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                                            <div className="">
                                                <img src="/asset/boli/images/boliico-w.png" alt="user"
                                                     className="img-circle"
                                                     width="60"/>
                                            </div>
                                            <div className="m-l-10">
                                                <h4 className="m-b-0">{this.state.name}</h4>
                                            </div>
                                        </div>

                                        <a className="dropdown-item" href="javascript:void(0)">
                                            <i className="ti-settings m-r-5 m-l-5"></i> 系统设置</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="javascript:window.gsm_exit();">
                                            <i className="fa fa-power-off m-r-5 m-l-5"></i> 退出登录</a>
                                        <div className="dropdown-divider"></div>

                                    </div>
                                </li>
                            </ul>

                        </div>
                    </nav>
                </header>,
                <aside key={1} className="left-sidebar">

                    <div className="scroll-sidebar">

                        <nav className="sidebar-nav" style={{overflowY: 'scroll', maxHeight: '100%'}}>
                            <ul id="sidebarnav">
                                <li className="nav-small-cap d-none">
                                    <i className="mdi mdi-dots-horizontal"></i>
                                    <span className="hide-menu">平台管理</span>
                                </li>
                                {this.state.role !== "共享账号" ?
                                    <li className={"sidebar-item m-t-10 " + (this.state.menu == null ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: null
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            if ((this.state.user_permission.indexOf("[CHome]") != -1)) {
                                                window.gsm_history_save("window.gsm_to_homepage()", true)
                                                document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                            } else {
                                                window.gsm_history_save("window.gsm_to_homepage2()", true)
                                                document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                            }
                                        }}>
                                            <i className="fas fa-home"></i>
                                            <span className="hide-menu">首页 </span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[Machine]") != -1 || this.state.user_permission.indexOf("[MachineClassification]") != -1 || this.state.user_permission.indexOf("[MachineSupplier]") != -1) ?
                                    <li className="sidebar-item">
                                        <a className="sidebar-link has-arrow sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false">
                                            <i className="fas fa-box"></i>
                                            <span className="hide-menu">设备管理 </span>
                                        </a>
                                        <ul aria-expanded="false" className="collapse  first-level">
                                            {(this.state.user_permission.indexOf("[Machine]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "Machine" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "Machine"
                                                        });
                                                    }}>
                                                    <a href="javascript:void(0)" className="sidebar-link"
                                                       onClick={() => {
                                                           window.gsm_history_save("window.gsm_to_machine(1)", true)
                                                           document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                       }}>
                                                        <i className="icon-Record"></i>
                                                        <span className="hide-menu"> 设备管理 </span>
                                                    </a>
                                                </li> : null}
                                            {(this.state.user_permission.indexOf("[MachineClassification]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "MachineClassification" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "MachineClassification"
                                                        });
                                                    }}>
                                                    <a href="javascript:void(0)" className="sidebar-link"
                                                       onClick={() => {
                                                           window.gsm_history_save("window.gsm_to_machineclassification()", true)
                                                           document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                       }}>
                                                        <i className="icon-Record"></i>
                                                        <span className="hide-menu"> 设备类型 </span>
                                                    </a>
                                                </li> : null}
                                            {(this.state.user_permission.indexOf("[MachineSupplier]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "MachineSupplier" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "MachineSupplier"
                                                        });
                                                    }}>
                                                    <a href="javascript:void(0)" className="sidebar-link"
                                                       onClick={() => {
                                                           window.gsm_history_save("window.gsm_to_machinesupplier()", true)
                                                           document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                       }}>
                                                        <i className="icon-Record"></i>
                                                        <span className="hide-menu"> 设备厂家 </span>
                                                    </a>
                                                </li> : null}
                                        </ul>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[CarMachine]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "CarMachine" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "CarMachine"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_car(1)", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-car"></i>
                                            <span className="hide-menu">车辆管理 </span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[MachineClassification2]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "MachineClassification2" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "MachineClassification2"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_machineclassification2()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-truck"></i>
                                            <span className="hide-menu">设施管理 </span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[CustomerClassification]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "CustomerClassification" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "CustomerClassification"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_customerclassification()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-user"></i>
                                            <span className="hide-menu">客户管理 </span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[CustomerClassificationPermission]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "CustomerClassificationPermission" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "CustomerClassificationPermission"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_customerpermission()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-lock"></i>
                                            <span className="hide-menu">权限管理 </span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[Report2]") != -1 || this.state.user_permission.indexOf("[ReportClassification2]") != -1 || this.state.user_permission.indexOf("[ReportList2]") != -1) ?
                                    <li className="sidebar-item">
                                        <a className="sidebar-link has-arrow sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false">
                                            <i className="fas fa-tasks"></i>
                                            <span className="hide-menu">验证管理 </span>
                                        </a>
                                        <ul aria-expanded="false" className="collapse  first-level">
                                            {(this.state.user_permission.indexOf("[ReportClassification2]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "ReportClassification2" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "Report2"
                                                        });
                                                    }}>
                                                    <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                                       href="javascript:void(0)"
                                                       aria-expanded="false" onClick={() => {
                                                        window.gsm_history_save("window.gsm_to_reportclassification2()", true)
                                                        document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                    }}>
                                                        <i className="fas fa-tasks"></i>
                                                        <span className="hide-menu">验证分类管理 </span>
                                                    </a>
                                                </li> : null}
                                            {(this.state.user_permission.indexOf("[ReportList2]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "ReportList2" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "Report2"
                                                        });
                                                    }}>
                                                    <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                                       href="javascript:void(0)"
                                                       aria-expanded="false" onClick={() => {
                                                        window.gsm_history_save("window.gsm_to_report2list()", true)
                                                        document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                    }}>
                                                        <i className="fas fa-tasks"></i>
                                                        <span className="hide-menu">验证管理列表 </span>
                                                    </a>
                                                </li> : null}
                                            {(this.state.user_permission.indexOf("[Report2]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "Report2" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "Report2"
                                                        });
                                                    }}>
                                                    <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                                       href="javascript:void(0)"
                                                       aria-expanded="false" onClick={() => {
                                                        window.gsm_history_save("window.gsm_to_report2()", true)
                                                        document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                    }}>
                                                        <i className="fas fa-tasks"></i>
                                                        <span className="hide-menu">验证管理 </span>
                                                    </a>
                                                </li> : null}
                                        </ul>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[Report]") != -1 || this.state.user_permission.indexOf("[ReportClassification]") != -1) ?
                                    <li className="sidebar-item">
                                        <a className="sidebar-link has-arrow sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false">
                                            <i className="fas fa-calendar-check"></i>
                                            <span className="hide-menu">验证统计 </span>
                                        </a>
                                        <ul aria-expanded="false" className="collapse  first-level">
                                            {(this.state.user_permission.indexOf("[Report]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "Report" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "Report"
                                                        });
                                                    }}>
                                                    <a href="javascript:void(0)" className="sidebar-link"
                                                       onClick={() => {
                                                           window.gsm_history_save("window.gsm_to_report()", true)
                                                           document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                       }}>
                                                        <i className="icon-Record"></i>
                                                        <span className="hide-menu"> 验证统计 </span>
                                                    </a>
                                                </li> : null}
                                            {(this.state.user_permission.indexOf("[ReportClassification]") != -1) ?
                                                <li className={"sidebar-item " + (this.state.menu == "ReportClassification" ? "selected" : "")}
                                                    onClick={() => {
                                                        this.setState({
                                                            menu: "ReportClassification"
                                                        });
                                                    }}>
                                                    <a href="javascript:void(0)" className="sidebar-link"
                                                       onClick={() => {
                                                           window.gsm_history_save("window.gsm_to_reportclassification()", true)
                                                           document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                       }}>
                                                        <i className="icon-Record"></i>
                                                        <span className="hide-menu"> 验证类型 </span>
                                                    </a>
                                                </li> : null}
                                        </ul>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[Remind]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "Remind" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "Remind"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_remind()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-bell"></i>
                                            <span className="hide-menu">报警管理 </span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[Share]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "Share" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "Share"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_share()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fab fa-staylinked"></i>
                                            <span className="hide-menu">共享管理 </span>
                                        </a>
                                    </li> : null}
                                <li className={"sidebar-item " + (this.state.menu == "MyShareFile" ? "selected" : "")}
                                    onClick={() => {
                                        this.setState({
                                            menu: "MyShareFile"
                                        });
                                    }}>
                                    <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                       href="javascript:void(0)"
                                       aria-expanded="false" onClick={() => {
                                        window.gsm_history_save("window.gsm_to_my_share_file()", true)
                                        document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                    }}>
                                        <i className="fab fa-staylinked"></i>
                                        <span className="hide-menu">我的资料 </span>
                                    </a>
                                </li>
                                <li className={"sidebar-item " + (this.state.menu == "MyShareMachine" ? "selected" : "")}
                                    onClick={() => {
                                        this.setState({
                                            menu: "MyShareMachine"
                                        });
                                    }}>
                                    <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                       href="javascript:void(0)"
                                       aria-expanded="false" onClick={() => {
                                        window.gsm_history_save("window.gsm_to_my_share_machine()", true)
                                        document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                    }}>
                                        <i className="fab fa-staylinked"></i>
                                        <span className="hide-menu">我的设备 </span>
                                    </a>
                                </li>
                                {this.state.role !== "共享账号" ?
                                    <li className={"sidebar-item " + (this.state.menu == "Money" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "Money"
                                            });
                                        }}>
                                        <a className="sidebar-link sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_pay()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-donate"></i>
                                            <span className="hide-menu">支付管理 </span>
                                        </a>
                                    </li> : null}

                                <li className="nav-small-cap d-none">
                                    <i className="mdi mdi-dots-horizontal"></i>
                                    <span className="hide-menu">系统设置</span>
                                </li>
                                {(this.state.user_permission.indexOf("[MachineImg]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "map_info" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "map_info"
                                            });
                                        }}>
                                        <a className="sidebar-link waves-effect waves-dark sidebar-link"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_map_info()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fab fa-elementor"></i>
                                            <span className="hide-menu">动态展示</span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[Admin]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "Admin" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "Admin"
                                            });
                                        }}>
                                        <a className="sidebar-link waves-effect waves-dark sidebar-link"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_admin()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-shield-alt"></i>
                                            <span className="hide-menu">系统管理</span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[LogI]") != -1) ?
                                    <li className={"sidebar-item " + (this.state.menu == "LogI" ? "selected" : "")}
                                        onClick={() => {
                                            this.setState({
                                                menu: "LogI"
                                            });
                                        }}>
                                        <a className="sidebar-link waves-effect waves-dark sidebar-link"
                                           href="javascript:void(0)"
                                           aria-expanded="false" onClick={() => {
                                            window.gsm_history_save("window.gsm_to_logi()", true)
                                            document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                        }}>
                                            <i className="fas fa-clipboard-list"></i>
                                            <span className="hide-menu">系统日志</span>
                                        </a>
                                    </li> : null}
                                {(this.state.user_permission.indexOf("[XCXMachine]") != -1) ?
                                    <li className="sidebar-item">
                                        <a className="sidebar-link has-arrow sidebar-link waves-effect waves-dark"
                                           href="javascript:void(0)"
                                           aria-expanded="false">
                                            <i className="fas fa-box"></i>
                                            <span className="hide-menu">租赁管理 </span>
                                        </a>
                                        <ul aria-expanded="false" className="collapse  first-level">
                                            <li className={"sidebar-item " + (this.state.menu == "XCXMachine1" ? "selected" : "")}
                                                onClick={() => {
                                                    this.setState({
                                                        menu: "XCXMachine1"
                                                    });
                                                }}>
                                                <a href="javascript:void(0)" className="sidebar-link"
                                                   onClick={() => {
                                                       window.gsm_history_save("window.gsm_to_card(1)", true)
                                                       document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                   }}>
                                                    <i className="icon-Record"></i>
                                                    <span className="hide-menu"> 设备管理 </span>
                                                </a>
                                            </li>
                                            <li className={"sidebar-item " + (this.state.menu == "XCXMachine2" ? "selected" : "")}
                                                onClick={() => {
                                                    this.setState({
                                                        menu: "XCXMachine2"
                                                    });
                                                }}>
                                                <a href="javascript:void(0)" className="sidebar-link"
                                                   onClick={() => {
                                                       window.gsm_history_save("window.gsm_to_card_order(1)", true)
                                                       document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                   }}>
                                                    <i className="icon-Record"></i>
                                                    <span className="hide-menu"> 订单管理 </span>
                                                </a>
                                            </li>
                                            <li className={"sidebar-item " + (this.state.menu == "XCXMachine3" ? "selected" : "")}
                                                onClick={() => {
                                                    this.setState({
                                                        menu: "XCXMachine3"
                                                    });
                                                }}>
                                                <a href="javascript:void(0)" className="sidebar-link"
                                                   onClick={() => {
                                                       window.gsm_history_save("window.gsm_to_card_worker(1)", true)
                                                       document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                   }}>
                                                    <i className="icon-Record"></i>
                                                    <span className="hide-menu"> 员工报表 </span>
                                                </a>
                                            </li>
                                            <li className={"sidebar-item " + (this.state.menu == "XCXMachine4" ? "selected" : "")}
                                                onClick={() => {
                                                    this.setState({
                                                        menu: "XCXMachine4"
                                                    });
                                                }}>
                                                <a href="javascript:void(0)" className="sidebar-link"
                                                   onClick={() => {
                                                       window.gsm_history_save("window.gsm_to_card_customer(1)", true)
                                                       document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();
                                                   }}>
                                                    <i className="icon-Record"></i>
                                                    <span className="hide-menu"> 客户报表 </span>
                                                </a>
                                            </li>
                                            {/*{(this.state.user_permission.indexOf("[MachineClassification]") != -1) ?*/}
                                            {/*    <li className={"sidebar-item " + (this.state.menu == "MachineClassification" ? "selected" : "")}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.setState({*/}
                                            {/*                menu: "MachineClassification"*/}
                                            {/*            });*/}
                                            {/*        }}>*/}
                                            {/*        <a href="javascript:void(0)" className="sidebar-link"*/}
                                            {/*           onClick={() => {*/}
                                            {/*               window.gsm_history_save("window.gsm_to_machineclassification()", true)*/}
                                            {/*               document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();*/}
                                            {/*           }}>*/}
                                            {/*            <i className="icon-Record"></i>*/}
                                            {/*            <span className="hide-menu"> 租赁类型 </span>*/}
                                            {/*        </a>*/}
                                            {/*    </li> : null}*/}
                                            {/*{(this.state.user_permission.indexOf("[MachineSupplier]") != -1) ?*/}
                                            {/*    <li className={"sidebar-item " + (this.state.menu == "MachineSupplier" ? "selected" : "")}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            this.setState({*/}
                                            {/*                menu: "MachineSupplier"*/}
                                            {/*            });*/}
                                            {/*        }}>*/}
                                            {/*        <a href="javascript:void(0)" className="sidebar-link"*/}
                                            {/*           onClick={() => {*/}
                                            {/*               window.gsm_history_save("window.gsm_to_machinesupplier()", true)*/}
                                            {/*               document.querySelector(".nav-link.sidebartoggler.waves-effect.waves-light").click();*/}
                                            {/*           }}>*/}
                                            {/*            <i className="icon-Record"></i>*/}
                                            {/*            <span className="hide-menu"> 租赁厂家 </span>*/}
                                            {/*        </a>*/}
                                            {/*    </li> : null}*/}
                                        </ul>
                                    </li> : null}
                                <li className="sidebar-item">
                                    <a className="sidebar-link waves-effect waves-dark sidebar-link"
                                       href="javascript:window.gsm_exit();"
                                       aria-expanded="false">
                                        <i className="fas fa-sign-out-alt"></i>
                                        <span className="hide-menu">退出登录</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </aside>,
                <div key={2} className="page-wrapper"
                     style={{background: "rgb(38, 40, 53)", padding: 0, marginTop: "50px"}}>
                    {this.state.page}
                </div>,
                <footer key={3} className="d-none footer text-center op3">
                    <h6>
                        <small>Power By：Boli.</small>
                    </h6>
                </footer>,
            ]
        )
    }
}

window.gsm_exit = () => {
    window.ajax({
        cache: true,
        type: 'POST',
        url: '/exit',
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            window.location.href = "/";
        }
    })
}

export default GsmApp;
