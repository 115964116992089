import React from "react";
import GsmHomepage from "./homepage";
import GsmHomepage2 from "./homepage2";

window.gsm_to_homepage = () => {
    window.gsm_app.setState({
        page: <GsmHomepage GsmFlag={false}/>
    })
}

window.gsm_to_homepage2 = () => {
    window.gsm_app.setState({
        page: <GsmHomepage2 GsmFlag={false}/>
    })
}