import React from "react";
import $ from "jquery";
import {
    GsmMachine,
    GsmCar,
    GsmMachineDetail,
    GsmCarDetail,
    GsmMachineAdd,
    GsmCarAdd,
    GsmMachineClassification,
    GsmMachineClassification2,
    GsmMachineSupplier
} from "./machine"
import echarts from "echarts"
import dayjs from "dayjs"


window.gsm_to_machine = (change, news) => {
    clearTimeout(window.time_refresh);
    if (news == undefined) {
        window.gsm_app.setState({
            page: null
        })
        window.gsm_app.setState({
            page: <GsmMachine GsmId={change == undefined ? 0 : change}/>
        })
    }
    window.operate_data = {};
    setTimeout(function () {
        if (news == undefined) {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/machine/head",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        lc: json_data.lc,
                        bw: json_data.bw,
                        lk: json_data.lk,
                        bx: json_data.bx,
                        kf: json_data.kf,
                    })

                }
            })
        }

        if (window.gsm_page.state.auto) {
            window.time_refresh = setTimeout(function () {
                window.gsm_to_machine(window.gsm_page.state.modestyle, 1);
            }, window.time_refresh_time);
        }
        $("#range_16").ionRangeSlider({
            //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
            grid: false,
            min: 1,
            max: 12,
            from: 1,
            postfix: " 月",
            onChange: function (data) {
                window.gsm_page.state.xfyf = parseInt(data.from);
                window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
            },
        });


        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();
        $('.timedeviceinput').datepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        if (change == undefined || change == 0) {
            let datatableelement = $('#zero_config').DataTable({
                destroy: news != undefined,
                columnDefs: [
                    {orderable: false, targets: 0},
                    {orderable: false, targets: -1}
                ],
                colReorder: {
                    fixedColumnsLeft: 1
                },
                language: {
                    "sProcessing": "处理中...",
                    "sLengthMenu": "显示 _MENU_ 项结果",
                    "sZeroRecords": "没有匹配结果",
                    "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                    "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                    "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                    "sInfoPostFix": "",
                    "sSearch": "搜索:",
                    "sUrl": "",
                    "sEmptyTable": "表中数据为空",
                    "sLoadingRecords": "载入中...",
                    "sInfoThousands": ",",
                    "oPaginate": {
                        "sFirst": "首页",
                        "sPrevious": "上页",
                        "sNext": "下页",
                        "sLast": "末页"
                    },
                    "oAria": {
                        "sSortAscending": ": 以升序排列此列",
                        "sSortDescending": ": 以降序排列此列"
                    }
                },
                searching: true,
                lengthChange: false,
                //serverSide: false,
                processing: true,
                // dom: 'Bfrtip',
                buttons: [
                    {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
                    {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                    // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                    {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
                ],
                ajax: {
                    type: "POST",
                    url: "/machine?key=" + window.gsm_page.state.machine_key,
                    dataSrc: ''
                },
                "columns": [
                    {//这个就是用来画第一列的小方块多选框的
                        "data": "id",
                        render: (data, type, full, mate) => {
                            return "<span class='mul-click-icon' style='cursor: pointer' onclick='window.gsm_page.state.click(\"" + data + "\",this)'><i class='far fa-square fa-2x' /></span>"
                        }
                    },
                    {"data": "id"},
                    {
                        "data": "machine_id",
                        render: (data, type, full, mate) => {
                            if (window.user_permission.indexOf("[MachineDetail]") != -1) {
                                return "<span style='cursor: pointer' onclick=\"window.gsm_history_save('window.gsm_to_machine_detail(" + full["id"] + ")',false)\">" + data + "</span>"
                            } else {
                                return data
                            }
                        }
                    },
                    {"data": "name"},
                    {
                        "data": "specification", render: function (data, type, full, meta) {
                            return data == "NULL" ? "" : data
                        }
                    },
                    {
                        "data": "status",
                        render: function (data, type, full, meta) {
                            switch (data) {
                                case "在线":
                                    return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                    break;
                                case "离线":
                                    return "<span class=\"btn btn-xs btn-secondary font-weight-100\">" + data + "</span>";
                                    break;
                                default:
                                    return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                            }
                        }
                    },
                    {
                        "data": "repair",
                        render: function (data, type, full, meta) {
                            switch (data) {
                                case "正常":
                                    return "<span class=\"btn btn-xs btn-success font-weight-100\">正常</span>";
                                    break;
                                case "报修":
                                    return "<span class=\"btn btn-xs btn-danger font-weight-100\">报修</span>";
                                    break;
                                default:
                                    return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>"
                            }
                        }
                    },
                    {
                        "data": "classification_name",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return data
                        }
                    },
                    {
                        "data": "classification2_name",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return data
                        }
                    },
                    {"data": "customer_name"},
                    {
                        "data": "end_at",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                        }
                    },
                    {
                        "data": "id",
                        render: function (data, type, full, meta) {
                            window.return_hts = "";
                            full["maxbaojingwendu"].split(",").map((value, index) => {
                                window.return_hts += "<span class='mr-2'>" + full["minbaojingwendu"].split(",")[index].split(".")[0] + " ~ " + value.split(".")[0] + "</span>"
                            });
                            return window.return_hts
                        }
                    },
                    {
                        "data": "wendu",
                        render: function (data, type, full, meta) {
                            if (data == "NULL") {
                                return ""
                            } else {
                                window.return_hts = "";
                                full["maxbaojingwendu"].split(",").map((value, index) => {
                                    if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat(full["minbaojingwendu"].split(",")[index]) > parseFloat(data.split(",")[index])) {
                                        window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    } else {
                                        window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    }
                                });
                                return window.return_hts
                            }
                        }
                    },
                    {
                        "data": "shidu",
                        render: function (data, type, full, meta) {
                            if (data == "NULL") {
                                return ""
                            } else {
                                window.return_hts = "";
                                full["maxbaojingshidu"].split(",").map((value, index) => {
                                    if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat(full["minbaojingshidu"].split(",")[index]) > parseFloat(data.split(",")[index])) {
                                        window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    } else {
                                        window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    }
                                });
                                return window.return_hts
                            }
                        }
                    },
                    {
                        "data": "data_at",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                        }
                    },
                    {
                        "data": "report_at",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                        }
                    },
                    {
                        "data": "id",
                        render: function (data, type, full, meta) {
                            window.operate_data["t" + full["id"]] = full;
                            return "<div class=\"btn-group\"><button type=\"button\" class=\"btn btn-info btn-xs dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"><i class=\"ti-settings\"></i></button>" +
                                "<div class=\"dropdown-menu animated fadeIn\">" +
                                ((window.user_permission.indexOf("[MachineDetail]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-success p-r-10\"onclick=\"window.gsm_history_save('window.gsm_to_machine_detail(" + full["id"] + ")',false)\"><i class=\"fas fa-eye\"></i> 查看</a> " : "") +
                                (full["repair"] == '报修' ? "<a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"dropdown-item text-danger p-r-10\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'><i class=\"fas fa-wrench\"></i> 报修</a> " : ((window.user_permission.indexOf("[MachineOperate]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\"  class=\"dropdown-item text-danger p-r-10\" onclick='window.operate_id = " + full["id"] + "'><i class=\"fas fa-wrench\"></i> 报修</a> " : "")) +
                                "<a href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file" +  full.report + "','" + full.name + (full.report.split(".").length > 1 ? ("." + full.report.split(".")[(full.report.split(".").length - 1)]) : "") + "')\" target=\"_blank\"  class=\"dropdown-item text-primary p-r-10\"><i class=\"fas fa-file-pdf\"></i> 校准报告</a> " +
                                // "<a href=\"javascript:void(0)\" class=\"dropdown-item text-warning p-r-10\" onclick=\"window.pay_to_machinepage('"+full.machine_id+"','"+full.id+"');\"><i class=\"fas fa-yen-sign\"></i> 续费</a> " +
                                "<a href=\"javascript:void(0)\" class=\"dropdown-item text-warning p-r-10\" data-toggle=\"modal\" data-target=\".xfmoneydevice\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";window.gsm_page.state.mid = \"" + full["machine_id"] + "\";window.gsm_page.xfdj_input.value = \"" + full["money"] + "\";window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2)'><i class=\"fas fa-yen-sign\"></i> 续费</a>  " +
                                ((window.user_permission.indexOf("[MoneyMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-info p-r-10\" data-toggle=\"modal\" data-target=\".moneydevice\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";window.gsm_page.xfje_input.value = \"" + full["money"] + "\";'><i class=\"fas fa-pencil-alt\"></i> 续费金额</a>  " : "") +
                                ((window.user_permission.indexOf("[ReviseMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-info p-r-10\" onclick=\"window.gsm_history_save('window.gsm_to_machine_revise(" + full["id"] + ")',false)\"><i class=\"fas fa-pencil-alt\"></i> 编辑</a>  " : "") +
                                ((window.user_permission.indexOf("[DeleteMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-danger p-r-10\" data-toggle=\"modal\" data-target=\".deletedevice\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i> 删除</a>" : "") +
                                "</div></div>";
                            //return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"/machine/detail?i=" + data + "&mi=" + full["machine_id"] + "\" class=\"text-success p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"查看\"><i class=\"fas fa-eye\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"报修\"><i class=\"fas fa-wrench\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"http://test.ddrenli.top/pdf.pdf\" download='http://test.ddrenli.top/pdf.pdf' target='_blank' class=\"text-primary p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"校准报告\"><i class=\"fas fa-file-pdf\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"编辑\"><i class=\"fas fa-pencil-alt\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-warning p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"续费\"><i class=\"fas fa-yen-sign\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"删除\"><i class=\"fas fa-trash\"></i></a>";
                        }
                    }
                ]
            });
        }
        // $('#dt-start, #dt-end').change(function () {
        //     datatableelement.draw();
        // });

        if (change == undefined || change == 0) {
            //导出
            // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
            $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
            $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');
        } else {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/machine?key=" + window.gsm_page.state.machine_key,
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        modeinfo: json_data
                    })
                }
            })
            $('.dt-buttons').remove();
            $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
            $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');
        }

        if (change == undefined || change == 0) {
            // table filter
            $('.text-inputs-searching tfoot th').each(function () {
                var title = $(this).text();
                $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
            });
            var tableSearching = $('.text-inputs-searching').DataTable();
            tableSearching.columns().every(function () {
                var that = this;
                $('input', this.footer()).on('keyup change', function () {
                    if (that.search() !== this.value) {
                        that
                            .search(this.value)
                            .draw();
                    }
                });
            });
            var r = $('#zero_config tfoot tr');
            r.find('th').each(function () {
                $(this).css('padding', 1);
            });
            $('#zero_config thead').append(r);

        }
        $('#date-range').datepicker({
            toggleActive: true
        });

    }, 1)
}

window.gsm_to_car = (change, news) => {
    clearTimeout(window.time_refresh);
    if (news == undefined) {
        window.gsm_app.setState({
            page: null
        })
        window.gsm_app.setState({
            page: <GsmCar GsmId={change == undefined ? 0 : change}/>
        })
    }
    window.operate_data = {};
    setTimeout(function () {
        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();
        $('.timedeviceinput').datepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $("#range_16").ionRangeSlider({
            //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
            grid: false,
            min: 1,
            max: 12,
            from: 1,
            postfix: " 月",
            onChange: function (data) {
                window.gsm_page.state.xfyf = parseInt(data.from);
                window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
            },
        });

        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        // 百度地图API功能
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("gsm-bdmap");
        map.centerAndZoom("成都", 8);
        // map.enableScrollWheelZoom();   //启用滚轮放大缩小，默认禁用

        // eslint-disable-next-line no-undef
        map.addControl(new BMap.NavigationControl());
        // eslint-disable-next-line no-undef
        map.addControl(new BMap.ScaleControl());
        // eslint-disable-next-line no-undef
        map.addControl(new BMap.OverviewMapControl({isOpen: true}));
        window.ajax({
            cache: true,
            type: "POST",
            url: "/car",
            error: function () {
            },
            fail: function () {
            },
            success: function (data) {
                const json_data = JSON.parse(data);

                // 编写自定义函数,创建标注
                function addMarker(point, machine_id) {
                    // eslint-disable-next-line no-undef
                    var marker = new BMap.Marker(point);
                    const machine_id_n = machine_id
                    marker.addEventListener("click", () => {
                        document.querySelectorAll("table thead th input")[1].value = machine_id_n
                        $(document.querySelectorAll("table thead th input")[1]).keyup()
                    });
                    map.addOverlay(marker);
                }

                // 随机向地图添加25个标注
                for (var i = 0; i < json_data.length; i++) {
                    // eslint-disable-next-line no-undef
                    var point = new BMap.Point(json_data[i].longitude, json_data[i].latitude);
                    addMarker(point, json_data[i].machine_id);
                }

                if (window.gsm_page.state.auto) {
                    window.time_refresh = setTimeout(function () {
                        window.gsm_to_car(window.gsm_page.state.modestyle, 1);
                    }, window.time_refresh_time);
                }
            }
        })

        if (change == undefined || change == 0) {
            let datatableelement = $('#zero_config').DataTable({
                destroy: news != undefined,
                columnDefs: [
                    {orderable: false, targets: 0},
                    {orderable: false, targets: -1}
                ],
                colReorder: {
                    fixedColumnsLeft: 1
                },
                language: {
                    "sProcessing": "处理中...",
                    "sLengthMenu": "显示 _MENU_ 项结果",
                    "sZeroRecords": "没有匹配结果",
                    "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                    "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                    "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                    "sInfoPostFix": "",
                    "sSearch": "搜索:",
                    "sUrl": "",
                    "sEmptyTable": "表中数据为空",
                    "sLoadingRecords": "载入中...",
                    "sInfoThousands": ",",
                    "oPaginate": {
                        "sFirst": "首页",
                        "sPrevious": "上页",
                        "sNext": "下页",
                        "sLast": "末页"
                    },
                    "oAria": {
                        "sSortAscending": ": 以升序排列此列",
                        "sSortDescending": ": 以降序排列此列"
                    }
                },
                searching: true,
                lengthChange: false,
                //serverSide: false,
                processing: true,
                // dom: 'Bfrtip',
                buttons: [{
                    extend: 'excel',
                    text: '<i class="far fa-file-excel"></i> EXCEL',
                    exportOptions: {columns: ':visible'}
                },
                    {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                    {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                    {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}],
                ajax: {
                    type: "POST",
                    url: "/car",
                    dataSrc: ''
                },
                "columns": [
                    {//这个就是用来画第一列的小方块多选框的
                        "data": "id",
                        render: (data, type, full, mate) => {
                            return "<span class='mul-click-icon' style='cursor: pointer' onclick='window.gsm_page.state.click(\"" + data + "\",this)'><i class='far fa-square fa-2x' /></span>"
                        }
                    },
                    {"data": "id"},
                    {
                        "data": "machine_id",
                        render: (data, type, full, mate) => {
                            if (window.user_permission.indexOf("[CarMachineDetail]") != -1) {
                                return "<span style='cursor: pointer' onclick=\"window.gsm_history_save('window.gsm_to_car_detail(" + full["id"] + ")',false)\">" + data + "</span>"
                            } else {
                                return data
                            }
                        }
                    },
                    {"data": "name"},
                    {
                        "data": "specification", render: function (data, type, full, meta) {
                            return data == "NULL" ? "" : data
                        }
                    },
                    {
                        "data": "status",
                        render: function (data, type, full, meta) {
                            switch (data) {
                                case "在线":
                                    return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                    break;
                                case "离线":
                                    return "<span class=\"btn btn-xs btn-secondary font-weight-100\">" + data + "</span>";
                                    break;
                                default:
                                    return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                            }
                        }
                    },
                    {
                        "data": "repair",
                        render: function (data, type, full, meta) {
                            switch (data) {
                                case "正常":
                                    return "<span class=\"btn btn-xs btn-success font-weight-100\">正常</span>";
                                    break;
                                case "报修":
                                    return "<span class=\"btn btn-xs btn-danger font-weight-100\">报修</span>";
                                    break;
                                default:
                                    return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>"
                            }
                        }
                    },
                    {
                        "data": "classification_name",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return data
                        }
                    },
                    {
                        "data": "classification2_name",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return data
                        }
                    },
                    {"data": "customer_name"},
                    {
                        "data": "end_at",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                        }
                    },
                    {
                        "data": "id",
                        render: function (data, type, full, meta) {
                            window.return_hts = "";
                            full["maxbaojingwendu"].split(",").map((value, index) => {
                                window.return_hts += "<span class='mr-2'>" + full["minbaojingwendu"].split(",")[index].split(".")[0] + " ~ " + value.split(".")[0] + "</span>"
                            });
                            return window.return_hts
                        }
                    },
                    {
                        "data": "wendu",
                        render: function (data, type, full, meta) {
                            if (data == "NULL") {
                                return ""
                            } else {
                                window.return_hts = "";
                                full["maxbaojingwendu"].split(",").map((value, index) => {
                                    if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat(full["minbaojingwendu"].split(",")[index]) > parseFloat(data.split(",")[index])) {
                                        window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    } else {
                                        window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    }
                                });
                                return window.return_hts
                            }
                        }
                    },
                    {
                        "data": "shidu",
                        render: function (data, type, full, meta) {
                            if (data == "NULL") {
                                return ""
                            } else {
                                window.return_hts = "";
                                full["maxbaojingshidu"].split(",").map((value, index) => {
                                    if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat(full["minbaojingshidu"].split(",")[index]) > parseFloat(data.split(",")[index])) {
                                        window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    } else {
                                        window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                    }
                                });
                                return window.return_hts
                            }
                        }
                    },
                    {
                        "data": "data_at",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                        }
                    },
                    {
                        "data": "report_at",
                        render: function (data, type, full, meta) {
                            if (data == "NULL")
                                return ""
                            else
                                return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                        }
                    },
                    {
                        "data": "id",
                        render: function (data, type, full, meta) {
                            window.operate_data["t" + full["id"]] = full;
                            return "<div class=\"btn-group\"><button type=\"button\" class=\"btn btn-info btn-xs dropdown-toggle\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\"><i class=\"ti-settings\"></i></button>" +
                                "<div class=\"dropdown-menu animated fadeIn\">" +
                                ((window.user_permission.indexOf("[CarMachineDetail]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-success p-r-10\"onclick=\"window.gsm_history_save('window.gsm_to_car_detail(" + full["id"] + ")',false)\"><i class=\"fas fa-eye\"></i> 查看</a> " : "") +
                                (full["repair"] == '报修' ? "<a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"dropdown-item text-danger p-r-10\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'><i class=\"fas fa-wrench\"></i> 报修</a> " : ((window.user_permission.indexOf("[CarMachineOperate]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\"  class=\"dropdown-item text-danger p-r-10\" onclick='window.operate_id = " + full["id"] + "'><i class=\"fas fa-wrench\"></i> 报修</a> " : "")) +
                                "<a href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file" +  full.report + "','" + full.name + (full.report.split(".").length > 1 ? ("." + full.report.split(".")[(full.report.split(".").length - 1)]) : "") + "')\" target=\"_blank\"  class=\"dropdown-item text-primary p-r-10\"><i class=\"fas fa-file-pdf\"></i> 校准报告</a> " +
                                "<a href=\"javascript:void(0)\" class=\"dropdown-item text-warning p-r-10\" data-toggle=\"modal\" data-target=\".xfmoneydevice\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";window.gsm_page.state.mid = \"" + full["machine_id"] + "\";window.gsm_page.xfdj_input.value = \"" + full["money"] + "\";window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2)'><i class=\"fas fa-yen-sign\"></i> 续费</a>  " +
                                ((window.user_permission.indexOf("[MoneyCarMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-info p-r-10\" data-toggle=\"modal\" data-target=\".moneydevice\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";window.gsm_page.xfje_input.value = \"" + full["money"] + "\";'><i class=\"fas fa-pencil-alt\"></i> 续费金额</a>  " : "") +
                                ((window.user_permission.indexOf("[ReviseCarMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-info p-r-10\" onclick=\"window.gsm_history_save('window.gsm_to_car_revise(" + full["id"] + ")',false)\"><i class=\"fas fa-pencil-alt\"></i> 编辑</a>  " : "") +
                                ((window.user_permission.indexOf("[DeleteCarMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"dropdown-item text-danger p-r-10\" data-toggle=\"modal\" data-target=\".deletedevice\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i> 删除</a>" : "") +
                                "</div></div>";
                            //return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"/machine/detail?i=" + data + "&mi=" + full["machine_id"] + "\" class=\"text-success p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"查看\"><i class=\"fas fa-eye\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"报修\"><i class=\"fas fa-wrench\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"http://test.ddrenli.top/pdf.pdf\" download='http://test.ddrenli.top/pdf.pdf' target='_blank' class=\"text-primary p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"校准报告\"><i class=\"fas fa-file-pdf\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"编辑\"><i class=\"fas fa-pencil-alt\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-warning p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"续费\"><i class=\"fas fa-yen-sign\"></i></a> <a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"删除\"><i class=\"fas fa-trash\"></i></a>";
                        }
                    }
                ]
            });
        }

        // $('#dt-start, #dt-end').change(function () {
        //     datatableelement.draw();
        // });

        if (change == undefined || change == 0) {
            //导出
            // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
            $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
            $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');
        } else {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/car",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        modeinfo: json_data
                    })
                }
            })
            $('.dt-buttons').remove();
            $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
            $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');
        }


        if (change == undefined || change == 0) {
            // table filter
            $('.text-inputs-searching tfoot th').each(function () {
                var title = $(this).text();
                $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
            });
            var tableSearching = $('.text-inputs-searching').DataTable();
            tableSearching.columns().every(function () {
                var that = this;
                $('input', this.footer()).on('keyup change', function () {
                    if (that.search() !== this.value) {
                        that
                            .search(this.value)
                            .draw();
                    }
                });
            });
            var r = $('#zero_config tfoot tr');
            r.find('th').each(function () {
                $(this).css('padding', 1);
            });
            $('#zero_config thead').append(r);
        }
        $('#date-range').datepicker({
            toggleActive: true
        });
    }, 1)
}

window.gsm_to_machine_detail_refresh = (t1, t2) => {
    $('#ts-error').on('click', function () {
        window.toastr.error('标题', '内容', {
            "showMethod": "slideDown",
            "hideMethod": "slideUp",
            timeOut: 2000,
            positionClass: 'toast-top-center'
        });
    });

    //datetime
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime',
        type: 'datetime'
    });
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime2',
        type: 'datetime'
    });

    // specify chart configuration item and data
    $(function () {
        $.ajax({
            type: "POST",
            url: "/machine/detail/list7day?i=" + window.operate_data["machine_id"] + "&t1=" + t1 + "&t2=" + t2,
            success: function (data) {
                const new_data = data.reverse();
                const chartArray = [];

                const wenduzd = [];
                const wenduzs = [];
                const wendupj = [];
                const wendujc = [];
                const wendubzc = [];
                const shiduzd = [];
                const shiduzs = [];
                const shidupj = [];
                const shidujc = [];
                const shidubzc = [];

                if (window.operate_data["minbaojingwendu"] !== "" && window.operate_data["minbaojingwendu"] !== "NULL" && window.operate_data["minbaojingwendu"] !== null && window.operate_data["minbaojingwendu"] !== undefined) {
                    for (let kk = 0; kk < (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",").length; kk++) {
                        const wd_array = [];
                        const time_array = [];
                        flag:for (var i = 0; i < new_data.length; i++) {
                            wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                            time_array.push(new_data[i]["update_at"]);
                        }

                        if (wd_array.length > 0) {
                            wenduzd.push(wd_array.length > 0 ? Math.max.apply(null, wd_array).toFixed(1) : 0);
                            wenduzs.push(wd_array.length > 0 ? Math.min.apply(null, wd_array).toFixed(1) : 0);
                            wendupj.push(wd_array.length > 0 ? (wd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / wd_array.length).toFixed(1) : 0);
                            wendujc.push(wenduzd.length > 0 ? (parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1) : 0);
                            wendubzc.push(wd_array.length > 1 ? Math.sqrt(wd_array.map(function (x) {
                                return parseFloat(x) - wendupj[wendupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (wd_array.length - 1)).toFixed(1) : 0);
                        }
                        const myChart = echarts.init(document.getElementById('basic-line-w' + kk));
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'line'
                                }
                            },
                            legend: {
                                data: ['温度', '湿度']
                            },

                            color: ['#f62d51'],
                            grid: {
                                left: '1%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: time_array,
                                scale: true,
                                boundaryGap: false,
                                axisLine: {onZero: false},
                                splitLine: {show: false},
                                splitNumber: 20,
                                min: 'dataMin',
                                max: 'dataMax'
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value} ℃'
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                    start: 0,
                                    end: 100
                                },
                                {
                                    show: true,
                                    type: 'slider',
                                    y: '90%',
                                    start: 0,
                                    end: 100
                                }
                            ],
                            series: [
                                {
                                    name: '温度' + (kk + 1),
                                    type: 'line',
                                    data: wd_array,
                                    markPoint: {
                                        data: [
                                            {type: 'max', name: 'Max'},
                                            {type: 'min', name: 'Min'}
                                        ]
                                    },
                                    markLine: {
                                        symbol: "none",               //去掉警戒线最后面的箭头
                                        label: {
                                            position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                        },
                                        data: [{
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#2962FF",
                                            },
                                            yAxis: (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }, {
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#2962FF",
                                            },
                                            yAxis: (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }]
                                    },
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            shadowColor: 'rgba(0,0,0,0.1)',
                                            shadowBlur: 10,
                                            shadowOffsetY: 10
                                        }
                                    },
                                }
                            ]
                        };

                        myChart.setOption(option);
                        chartArray.push(myChart);
                    }
                }
                if (window.operate_data["minbaojingshidu"] !== "" && window.operate_data["minbaojingshidu"] !== "NULL" && window.operate_data["minbaojingshidu"] !== null && window.operate_data["minbaojingshidu"] !== undefined) {
                    for (let kk = 0; kk < (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",").length; kk++) {
                        const sd_array = [];
                        const time_array = [];
                        flag:for (var i = 0; i < new_data.length; i++) {
                            sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                            time_array.push(new_data[i]["update_at"]);
                        }


                        if (sd_array.length > 0) {
                            shiduzd.push(sd_array.length > 0 ? Math.max.apply(null, sd_array).toFixed(1) : 0);
                            shiduzs.push(sd_array.length > 0 ? Math.min.apply(null, sd_array).toFixed(1) : 0);
                            shidupj.push(sd_array.length > 0 ? (sd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / sd_array.length).toFixed(1) : 0);
                            shidujc.push(shiduzd.length > 0 ? (parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1) : 0);
                            shidubzc.push(sd_array.length > 1 ? Math.sqrt(sd_array.map(function (x) {
                                return parseFloat(x) - shidupj[shidupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (sd_array.length - 1)).toFixed(1) : 0);
                        }

                        const myChart = echarts.init(document.getElementById('basic-line-s' + kk));
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'line'
                                }
                            },
                            legend: {
                                data: ['温度', '湿度']
                            },

                            color: ['#2962FF'],
                            grid: {
                                left: '1%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: time_array,
                                scale: true,
                                boundaryGap: false,
                                axisLine: {onZero: false},
                                splitLine: {show: false},
                                splitNumber: 20,
                                min: 'dataMin',
                                max: 'dataMax'
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value} %'
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                    start: 0,
                                    end: 100
                                },
                                {
                                    show: true,
                                    type: 'slider',
                                    y: '90%',
                                    start: 0,
                                    end: 100
                                }
                            ],
                            series: [
                                {
                                    name: '湿度' + (kk + 1),
                                    type: 'line',
                                    data: sd_array,
                                    markPoint: {
                                        data: [
                                            {type: 'max', name: 'Max'},
                                            {type: 'min', name: 'Min'}
                                        ]
                                    },
                                    markLine: {
                                        symbol: "none",               //去掉警戒线最后面的箭头
                                        label: {
                                            position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                        },
                                        data: [{
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#f62d51",
                                            },
                                            yAxis: (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[kk] // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }, {
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#f62d51",
                                            },
                                            yAxis: (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }]
                                    },
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            shadowColor: 'rgba(0,0,0,0.1)',
                                            shadowBlur: 10,
                                            shadowOffsetY: 10
                                        }
                                    },
                                }
                            ]
                        };

                        myChart.setOption(option);
                        chartArray.push(myChart);
                    }
                }

                window.onresize = function () {
                    for (var i = 0; i < chartArray.length; i++)
                        chartArray[i].resize();
                }


                window.gsm_page.setState({
                    wenduzd: wenduzd.join(","),
                    wenduzs: wenduzs.join(","),
                    wendupj: wendupj.join(","),
                    wendujc: wendujc.join(","),
                    wendubzc: wendubzc.join(","),
                    shiduzd: shiduzd.join(","),
                    shiduzs: shiduzs.join(","),
                    shidupj: shidupj.join(","),
                    shidujc: shidujc.join(","),
                    shidubzc: shidubzc.join(","),
                })

                let pdfwendu, pdfshidu
                if (wendupj.length == 0) {
                    pdfwendu = ''
                } else if (isNaN(wendupj[0])) {
                    pdfshidu = ''
                } else {
                    pdfwendu = "[温度：平均:" + wendupj.join(",") + " 最大:" + wenduzd.join(",") + " 最小:" + wenduzs.join(",") + " 极差:" + wendujc.join(",") + " 标准差:" + wendubzc.join(",") + "]"
                }
                if (shidupj.length == 0) {
                    pdfshidu = ''
                } else if (isNaN(shidupj[0])) {
                    pdfshidu = ''
                } else {
                    pdfshidu = "[湿度：平均:" + shidupj.join(",") + " 最大:" + shiduzd.join(",") + " 最小:" + shiduzs.join(",") + " 极差:" + shidujc.join(",") + " 标准差:" + shidubzc.join(",") + "]"
                }

                // table
                $('#zero_config').DataTable({
                    destroy: true,
                    bServerSide: true,
                    searching: true,
                    language: {
                        "sProcessing": "处理中...",
                        "sLengthMenu": " _MENU_ 条",
                        "sZeroRecords": "没有匹配结果",
                        "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                        "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                        "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                        "sInfoPostFix": "",
                        "sSearch": "搜索:",
                        "sUrl": "",
                        "sEmptyTable": "表中数据为空",
                        "sLoadingRecords": "载入中...",
                        "sInfoThousands": ",",
                        "oPaginate": {
                            "sFirst": "首页",
                            "sPrevious": "上页",
                            "sNext": "下页",
                            "sLast": "末页"
                        },
                        "oAria": {
                            "sSortAscending": ": 以升序排列此列",
                            "sSortDescending": ": 以降序排列此列"
                        }
                    },
                    // lengthChange: false,
                    scrollY: 410,
                    lengthMenu: [ 10, 100, 500, 1000 ],
                    //serverSide: false,
                    processing: true,
                    dom: '<"#lbtnDetail"l>Bfrtip',
                    buttons: [
                        {
                            extend: 'excel',
                            text: '<i class="far fa-file-excel"></i> EXCEL',
                            exportOptions: {columns: ':visible'},
                            messageTop: pdfwendu + " " + pdfshidu,
                            title: window.operate_data["name"]+ "（" + window.operate_data["machine_id"] + "）"
                        },
                        {
                            extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', customize: function (doc) {
                                doc.content[2].table.widths =
                                    Array(doc.content[2].table.body[0].length + 1).join('*').split('');
                            }, exportOptions: {columns: ':visible'}, messageTop: pdfwendu + " " + pdfshidu, title: window.operate_data["name"]+ "（" + window.operate_data["machine_id"] + "）"
                        },
                        // {
                        //     extend: 'print',
                        //     text: '<i class="fas fa-print"></i> 打印',
                        //     exportOptions: {columns: ':visible'},
                        //     messageTop: pdfwendu + " " + pdfshidu
                        // },
                        {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
                    ],
                    ajax: {
                        type: "POST",
                        url: "/machine/detail?i=" + window.operate_data["machine_id"] + "&t1=" + t1 + "&t2=" + t2,
                    },
                    "order": [[0, "desc"]],
                    "columns": [
                        {"data": "id"},
                        {
                            "data": "update_at",
                            render: function (data, type, full, meta) {
                                if (data == "NULL")
                                    return ""
                                else
                                    return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                            }
                        },
                        {
                            "data": "wendu",
                            render: function (data, type, full, meta) {
                                if (data == "NULL") {
                                    return ""
                                } else {
                                    window.return_hts = "";
                                    (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                                        if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                            window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        } else {
                                            window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        }
                                    });
                                    return window.return_hts
                                }
                            }
                        },
                        {
                            "data": "shidu",
                            render: function (data, type, full, meta) {
                                if (data == "NULL") {
                                    return ""
                                } else {
                                    window.return_hts = "";
                                    (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                                        if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                            window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        } else {
                                            window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        }
                                    });
                                    return window.return_hts
                                }
                            }
                        },

                    ]
                });
                //导出
                // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
                document.getElementById('lbtn').appendChild(document.getElementById('lbtnDetail'));
                $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
                $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');
                $('#lbtnDetail label').addClass('btn waves-effect waves-light btn-light mb-0');

            }
        });

    })
}

window.gsm_to_machine_detail = (id) => {
    window.operate_data = window.operate_data["t" + id];
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMachineDetail/>
    })

    setTimeout(() => {
        $('.timedeviceinput').daterangepicker();
    }, 500);

    setTimeout(function () {
        window.gsm_to_machine_detail_refresh('', '')
    }, 1)

}

window.gsm_to_car_detail_refresh = (t1, t2) => {
    $('#ts-error').on('click', function () {
        window.toastr.error('标题', '内容', {
            "showMethod": "slideDown",
            "hideMethod": "slideUp",
            timeOut: 2000,
            positionClass: 'toast-top-center'
        });
    });

    //datetime
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime',
        type: 'datetime'
    });
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime2',
        type: 'datetime'
    });

    // specify chart configuration item and data
    $(function () {
        $.ajax({
            type: "POST",
            url: "/car/detail/list7day?i=" + window.operate_data["machine_id"] + "&t1=" + t1 + "&t2=" + t2,
            success: function (data) {
                const new_data = data.reverse();
                const chartArray = [];

                const wenduzd = [];
                const wenduzs = [];
                const wendupj = [];
                const wendujc = [];
                const wendubzc = [];
                const shiduzd = [];
                const shiduzs = [];
                const shidupj = [];
                const shidujc = [];
                const shidubzc = [];

                let car_bdmap_points = [];
                for (var i = 0; i < new_data.length; i++) {
                    // eslint-disable-next-line no-undef
                    car_bdmap_points.push(new BMap.Point(isNaN(parseFloat(new_data[i].longitude)) ? 0.00 : parseFloat(new_data[i].longitude), isNaN(parseFloat(new_data[i].latitude)) ? 0.00 : parseFloat(new_data[i].latitude)))
                }

                var map;   //百度地图对象
                var car_icon; //汽车图标
                var car_bdmap_label; //信息标签
                var car_bdmap_centerPoint;

                var car_bdmap_timer; //定时器
                var car_bdmap_index = car_bdmap_points.length - 1; //记录播放到第几个point

                var aniInput, followChk, playBtn, pauseBtn, resetBtn; //几个控制按钮


                document.querySelector(".gsm-car-bdmap-controller").innerHTML = document.querySelector(".gsm-car-bdmap-controller").innerHTML;
                aniInput = document.querySelector(".gsm-car-bdmap-controller .bdmap-ani");
                followChk = document.querySelector(".gsm-car-bdmap-controller .bdmap-follow");
                playBtn = document.querySelector(".gsm-car-bdmap-controller .bdmap-play");
                pauseBtn = document.querySelector(".gsm-car-bdmap-controller .bdmap-pause");
                resetBtn = document.querySelector(".gsm-car-bdmap-controller .bdmap-reset");

                //初始化地图,选取第一个点为起始点
                // eslint-disable-next-line no-undef
                map = new BMap.Map("gsm-car-bdmap");
                if (car_bdmap_points.length > 0) {
                    map.centerAndZoom(car_bdmap_points[car_bdmap_index], 15);
                    map.enableScrollWheelZoom();
                    // eslint-disable-next-line no-undef
                    map.addControl(new BMap.NavigationControl());
                    // eslint-disable-next-line no-undef
                    map.addControl(new BMap.ScaleControl());
                    // eslint-disable-next-line no-undef
                    map.addControl(new BMap.OverviewMapControl({isOpen: true}));

                    // eslint-disable-next-line no-undef
                    map.addOverlay(new BMap.Polyline(car_bdmap_points, {
                        strokeColor: "black",
                        strokeWeight: 5,
                        strokeOpacity: 0.5
                    }));
                    // eslint-disable-next-line no-undef
                    car_bdmap_centerPoint = new BMap.Point((car_bdmap_points[car_bdmap_index].lng + car_bdmap_points[car_bdmap_points.length - 1].lng) / 2, (car_bdmap_points[car_bdmap_index].lat + car_bdmap_points[car_bdmap_points.length - 1].lat) / 2);
                    map.panTo(car_bdmap_centerPoint);
                    // eslint-disable-next-line no-undef
                    car_bdmap_label = new BMap.Label("", {offset: new BMap.Size(0, -100)});
                    // eslint-disable-next-line no-undef
                    car_icon = new BMap.Icon("https://p.upyun.com/demo/tmp/zkm5801Q.png", new BMap.Size(30, 16));
                    // eslint-disable-next-line no-undef
                    car_icon = new BMap.Marker(car_bdmap_points[car_bdmap_index], {icon: car_icon});
                    car_icon.setLabel(car_bdmap_label);
                    car_bdmap_label.setStyle({
                        color: "#fff",
                        fontSize: "12px",
                        backgroundColor: "rgba(0,0,0,.5)",
                        border: "0",
                        padding: "5px 10px",
                        borderRadius: "5px"
                    });
                    car_bdmap_label.setContent("温度: " + parseFloat(new_data[car_bdmap_index].wendu).toFixed(2) + "<br>湿度: " + parseFloat(new_data[car_bdmap_index].shidu).toFixed(2) + "<br>经度: " + parseFloat(new_data[car_bdmap_index].longitude).toFixed(6) + "<br>纬度: " + parseFloat(new_data[car_bdmap_index].latitude).toFixed(6) + "<br>时间: " + new_data[car_bdmap_index].update_at);
                    map.addOverlay(car_icon);


                    playBtn.disabled = false;
                    resetBtn.disabled = false;

                    playBtn.addEventListener("click", function () {
                        playBtn.disabled = true;
                        pauseBtn.disabled = false;

                        car_bdmap_timer = setInterval(function () {
                            const point = car_bdmap_points[car_bdmap_index];
                            // eslint-disable-next-line no-undef
                            map.addOverlay(new BMap.Polyline([car_bdmap_points[car_bdmap_index - 1], point], {
                                strokeColor: "red",
                                strokeWeight: 5,
                                strokeOpacity: 1
                            }));
                            car_bdmap_label.setContent("温度: " + parseFloat(new_data[car_bdmap_index].wendu).toFixed(2) + "<br>湿度: " + parseFloat(new_data[car_bdmap_index].shidu).toFixed(2) + "<br>经度: " + parseFloat(new_data[car_bdmap_index].longitude).toFixed(6) + "<br>纬度: " + parseFloat(new_data[car_bdmap_index].latitude).toFixed(6) + "<br>时间: " + new_data[car_bdmap_index].update_at);
                            car_icon.setPosition(car_bdmap_points[car_bdmap_index - 1]);
                            car_bdmap_index--;
                            if (followChk.checked) {
                                map.panTo(point);
                            }
                            if (car_bdmap_index > 0) {
                                // car_bdmap_timer = window.setTimeout("car_bdmap_play_func(" + car_bdmap_index + ")", 200);
                                // window.clearInterval(car_bdmap_timer);
                            } else {
                                playBtn.disabled = true;
                                pauseBtn.disabled = true;
                                map.panTo(point);
                                window.clearInterval(car_bdmap_timer);
                            }
                        }, isNaN(parseInt(aniInput.value)) ? 500 : parseInt(aniInput.value))
                    }, false);

                    pauseBtn.addEventListener("click", function () {
                        playBtn.disabled = false;
                        pauseBtn.disabled = true;

                        if (car_bdmap_timer) {
                            window.clearInterval(car_bdmap_timer);
                        }
                    }, false);

                    resetBtn.addEventListener("click", function () {
                        followChk.checked = false;
                        playBtn.disabled = false;
                        pauseBtn.disabled = true;

                        if (car_bdmap_timer) {
                            window.clearTimeout(car_bdmap_timer);
                        }
                        // eslint-disable-next-line no-undef
                        map.addOverlay(new BMap.Polyline(car_bdmap_points, {
                            strokeColor: "black",
                            strokeWeight: 5,
                            strokeOpacity: 1
                        }));
                        car_bdmap_index = car_bdmap_points.length - 1;
                        car_icon.setPosition(car_bdmap_points[car_bdmap_index]);
                        map.panTo(car_bdmap_centerPoint);
                    }, false);
                }


                if (window.operate_data["minbaojingwendu"] !== "" && window.operate_data["minbaojingwendu"] !== "NULL" && window.operate_data["minbaojingwendu"] !== null && window.operate_data["minbaojingwendu"] !== undefined) {
                    for (let kk = 0; kk < (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",").length; kk++) {
                        const wd_array = [];
                        const time_array = [];
                        for (var i = 0; i < new_data.length; i++) {
                            wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                            time_array.push(new_data[i]["update_at"]);
                        }

                        if (wd_array.length > 0) {
                            wenduzd.push(wd_array.length > 0 ? Math.max.apply(null, wd_array).toFixed(1) : 0);
                            wenduzs.push(wd_array.length > 0 ? Math.min.apply(null, wd_array).toFixed(1) : 0);
                            wendupj.push(wd_array.length > 0 ? (wd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / wd_array.length).toFixed(1) : 0);
                            wendujc.push(wenduzd.length > 0 ? (parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1) : 0);
                            wendubzc.push(wd_array.length > 1 ? Math.sqrt(wd_array.map(function (x) {
                                return parseFloat(x) - wendupj[wendupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (wd_array.length - 1)).toFixed(1) : 0);
                        }
                        const myChart = echarts.init(document.getElementById('basic-line-w' + kk));
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'line'
                                }
                            },
                            legend: {
                                data: ['温度', '湿度']
                            },

                            color: ['#f62d51'],
                            grid: {
                                left: '1%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: time_array,
                                scale: true,
                                boundaryGap: false,
                                axisLine: {onZero: false},
                                splitLine: {show: false},
                                splitNumber: 20,
                                min: 'dataMin',
                                max: 'dataMax'
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value} ℃'
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                    start: 0,
                                    end: 100
                                },
                                {
                                    show: true,
                                    type: 'slider',
                                    y: '90%',
                                    start: 0,
                                    end: 100
                                }
                            ],
                            series: [
                                {
                                    name: '温度' + (kk + 1),
                                    type: 'line',
                                    data: wd_array,
                                    markPoint: {
                                        data: [
                                            {type: 'max', name: 'Max'},
                                            {type: 'min', name: 'Min'}
                                        ]
                                    },
                                    markLine: {
                                        symbol: "none",               //去掉警戒线最后面的箭头
                                        label: {
                                            position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                        },
                                        data: [{
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#2962FF",
                                            },
                                            yAxis: (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }, {
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#2962FF",
                                            },
                                            yAxis: (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }]
                                    },
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            shadowColor: 'rgba(0,0,0,0.1)',
                                            shadowBlur: 10,
                                            shadowOffsetY: 10
                                        }
                                    },
                                }
                            ]
                        };

                        myChart.setOption(option);
                        chartArray.push(myChart);
                    }
                }
                if (window.operate_data["minbaojingshidu"] !== "" && window.operate_data["minbaojingshidu"] !== "NULL" && window.operate_data["minbaojingshidu"] !== null && window.operate_data["minbaojingshidu"] !== undefined) {
                    for (let kk = 0; kk < (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",").length; kk++) {
                        const sd_array = [];
                        const time_array = [];
                        for (var i = 0; i < new_data.length; i++) {
                            sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                            time_array.push(new_data[i]["update_at"]);
                        }


                        if (sd_array.length > 0) {
                            shiduzd.push(sd_array.length > 0 ? Math.max.apply(null, sd_array).toFixed(1) : 0);
                            shiduzs.push(sd_array.length > 0 ? Math.min.apply(null, sd_array).toFixed(1) : 0);
                            shidupj.push(sd_array.length > 0 ? (sd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / sd_array.length).toFixed(1) : 0);
                            shidujc.push(shiduzd.length > 0 ? (parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1) : 0);
                            shidubzc.push(sd_array.length > 1 ? Math.sqrt(sd_array.map(function (x) {
                                return parseFloat(x) - shidupj[shidupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (sd_array.length - 1)).toFixed(1) : 0);
                        }
                        const myChart = echarts.init(document.getElementById('basic-line-s' + kk));
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'line'
                                }
                            },
                            legend: {
                                data: ['温度', '湿度']
                            },

                            color: ['#2962FF'],
                            grid: {
                                left: '1%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: time_array,
                                scale: true,
                                boundaryGap: false,
                                axisLine: {onZero: false},
                                splitLine: {show: false},
                                splitNumber: 20,
                                min: 'dataMin',
                                max: 'dataMax'
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value} %'
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                    start: 0,
                                    end: 100
                                },
                                {
                                    show: true,
                                    type: 'slider',
                                    y: '90%',
                                    start: 0,
                                    end: 100
                                }
                            ],
                            series: [
                                {
                                    name: '湿度' + (kk + 1),
                                    type: 'line',
                                    data: sd_array,
                                    markPoint: {
                                        data: [
                                            {type: 'max', name: 'Max'},
                                            {type: 'min', name: 'Min'}
                                        ]
                                    },
                                    markLine: {
                                        symbol: "none",               //去掉警戒线最后面的箭头
                                        label: {
                                            position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                        },
                                        data: [{
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#f62d51",
                                            },
                                            yAxis: (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[kk] // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }, {
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#f62d51",
                                            },
                                            yAxis: (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }]
                                    },
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            shadowColor: 'rgba(0,0,0,0.1)',
                                            shadowBlur: 10,
                                            shadowOffsetY: 10
                                        }
                                    },
                                }
                            ]
                        };

                        myChart.setOption(option);
                        chartArray.push(myChart);
                    }
                }

                window.onresize = function () {
                    for (var i = 0; i < chartArray.length; i++)
                        chartArray[i].resize();
                }

                window.gsm_page.setState({
                    wenduzd: wenduzd.join(","),
                    wenduzs: wenduzs.join(","),
                    wendupj: wendupj.join(","),
                    wendujc: wendujc.join(","),
                    wendubzc: wendubzc.join(","),
                    shiduzd: shiduzd.join(","),
                    shiduzs: shiduzs.join(","),
                    shidupj: shidupj.join(","),
                    shidujc: shidujc.join(","),
                    shidubzc: shidubzc.join(","),
                })

                let pdfwendu, pdfshidu
                if (wendupj.length == 0) {
                    pdfwendu = ''
                } else if (isNaN(wendupj[0])) {
                    pdfshidu = ''
                } else {
                    pdfwendu = "[温度：平均:" + wendupj.join(",") + " 最大:" + wenduzd.join(",") + " 最小:" + wenduzs.join(",") + " 极差:" + wendujc.join(",") + " 标准差:" + wendubzc.join(",") + "]"
                }
                if (shidupj.length == 0) {
                    pdfshidu = ''
                } else if (isNaN(shidupj[0])) {
                    pdfshidu = ''
                } else {
                    pdfshidu = "[湿度：平均:" + shidupj.join(",") + " 最大:" + shiduzd.join(",") + " 最小:" + shiduzs.join(",") + " 极差:" + shidujc.join(",") + " 标准差:" + shidubzc.join(",") + "]"
                }
                //table
                $('#zero_config').DataTable({
                    destroy: true,
                    bServerSide: true,
                    searching: true,
                    language: {
                        "sProcessing": "处理中...",
                        "sLengthMenu": " _MENU_ 条",
                        "sZeroRecords": "没有匹配结果",
                        "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                        "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                        "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                        "sInfoPostFix": "",
                        "sSearch": "搜索:",
                        "sUrl": "",
                        "sEmptyTable": "表中数据为空",
                        "sLoadingRecords": "载入中...",
                        "sInfoThousands": ",",
                        "oPaginate": {
                            "sFirst": "首页",
                            "sPrevious": "上页",
                            "sNext": "下页",
                            "sLast": "末页"
                        },
                        "oAria": {
                            "sSortAscending": ": 以升序排列此列",
                            "sSortDescending": ": 以降序排列此列"
                        }
                    },
                    // lengthChange: false,
                    scrollY: 410,
                    lengthMenu: [ 10, 100, 500, 1000 ],
                    //serverSide: false,
                    processing: true,
                    dom: '<"#lbtnDetail"l>Bfrtip',
                    buttons: [
                        {
                            extend: 'excel',
                            text: '<i class="far fa-file-excel"></i> EXCEL',
                            exportOptions: {columns: ':visible'},
                            messageTop: pdfwendu + " " + pdfshidu,
                            title: window.operate_data["name"]+ "（" + window.operate_data["machine_id"] + "）"
                        },
                        {
                            extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', customize: function (doc) {
                                doc.content[2].table.widths =
                                    Array(doc.content[2].table.body[0].length + 1).join('*').split('');
                            }, exportOptions: {columns: ':visible'}, messageTop: pdfwendu + " " + pdfshidu, title: window.operate_data["name"]+ "（" + window.operate_data["machine_id"] + "）"
                        },
                        // {
                        //     extend: 'print',
                        //     text: '<i class="fas fa-print"></i> 打印',
                        //     exportOptions: {columns: ':visible'},
                        //     messageTop: pdfwendu + " " + pdfshidu
                        // },
                        {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
                    ],
                    ajax: {
                        type: "POST",
                        url: "/car/detail?i=" + window.operate_data["machine_id"] + "&t1=" + t1 + "&t2=" + t2,
                    },
                    "order": [[0, "desc"]],
                    "columns": [
                        {"data": "id"},
                        {
                            "data": "update_at",
                            render: function (data, type, full, meta) {
                                if (data == "NULL")
                                    return ""
                                else
                                    return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                            }
                        },
                        {
                            "data": "longitude,latitude",
                            render: function (data, type, full, meta) {
                                return parseFloat(full["longitude"]).toFixed(6) + "," + parseFloat(full["latitude"]).toFixed(6)
                            }
                        },
                        {
                            "data": "wendu",
                            render: function (data, type, full, meta) {
                                if (data == "NULL") {
                                    return ""
                                } else {
                                    window.return_hts = "";
                                    (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                                        if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                            window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        } else {
                                            window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        }
                                    });
                                    return window.return_hts
                                }
                            }
                        },
                        {
                            "data": "shidu",
                            render: function (data, type, full, meta) {
                                if (data == "NULL") {
                                    return ""
                                } else {
                                    window.return_hts = "";
                                    (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                                        if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                            window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        } else {
                                            window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        }
                                    });
                                    return window.return_hts
                                }
                            }
                        },
                    ]
                });
                //导出
                // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
                document.getElementById('lbtn').appendChild(document.getElementById('lbtnDetail'));
                $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
                $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');
                $('#lbtnDetail label').addClass('btn waves-effect waves-light btn-light mb-0');

            }
        });

    })
}

window.gsm_to_car_detail = (id) => {
    window.operate_data = window.operate_data["t" + id];
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCarDetail/>
    })
    setTimeout(() => {
        $('.timedeviceinput').daterangepicker();
    }, 500);

    setTimeout(function () {
        window.gsm_to_car_detail_refresh('', '')
    }, 1)

}

window.gsm_to_machine_revise = (id) => {
    var json_data = window.operate_data["t" + id];
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMachineAdd/>
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/r/supplier",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.supplier = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.supplier.push(<option key={i}
                                                            value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                supplier: window.gsm_page.state.supplier
            }, () => {
                window.gsm_page.cj_input.value = json_data["supplier"]
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/r/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.classification = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.classification.push(<option key={i}
                                                                  value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                classification: window.gsm_page.state.classification
            }, () => {
                window.gsm_page.fl_input.value = json_data["classification"]
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/r/classification2",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.classification2 = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.classification2.push(<option key={i}
                                                                   value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                classification2: window.gsm_page.state.classification2
            }, () => {
                if (json_data["classification2"]!=="NULL") {
                    window.gsm_page.fl2_input.value = json_data["classification2"];
                }
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/r/customer",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.customer = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.customer.push(<option key={i}
                                                            value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                customer: window.gsm_page.state.customer
            }, () => {
                window.gsm_page.cc_input.value = json_data["customer"]
            })
        }
    })
    setTimeout(function () {
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));
        // 百度地图API功能
        window.gsm_page.setState({
            id: id,
            file: json_data["report"] == "NULL" ? "" : json_data["report"],
            check: json_data["remind"],
            new: false
        })
        window.gsm_page.mc_input.value = json_data["name"]
        window.gsm_page.xlh_input.value = json_data["machine_id"]
        window.gsm_page.djfs_input.value = json_data["duijie"]
        window.gsm_page.dh_input.value = json_data["phone"]
        window.gsm_page.dh2_input.value = json_data["phone2"]
        window.gsm_page.zt_input.value = json_data["status"]
        window.gsm_page.bx_input.value = json_data["repair"]
        window.gsm_page.zgwd_input.value = json_data["maxbaojingwendu"] == "NULL" ? "" : json_data["maxbaojingwendu"]
        window.gsm_page.zdwd_input.value = json_data["minbaojingwendu"] == "NULL" ? "" : json_data["minbaojingwendu"]
        window.gsm_page.zgsd_input.value = json_data["maxbaojingshidu"] == "NULL" ? "" : json_data["maxbaojingshidu"]
        window.gsm_page.zdsd_input.value = json_data["minbaojingshidu"] == "NULL" ? "" : json_data["minbaojingshidu"]
        window.gsm_page.zgwd2_input.value = json_data["maxbaojingwendu2"] == "NULL" ? "" : json_data["maxbaojingwendu2"]
        window.gsm_page.zdwd2_input.value = json_data["minbaojingwendu2"] == "NULL" ? "" : json_data["minbaojingwendu2"]
        window.gsm_page.zgsd2_input.value = json_data["maxbaojingshidu2"] == "NULL" ? "" : json_data["maxbaojingshidu2"]
        window.gsm_page.zdsd2_input.value = json_data["minbaojingshidu2"] == "NULL" ? "" : json_data["minbaojingshidu2"]
        window.gsm_page.jg_input.value = json_data["time"] == "NULL" ? 5 : json_data["time"]
        window.gsm_page.dq_input.value = json_data["report_at"] == "NULL" ? "" : dayjs(json_data["report_at"]).format("YYYY-MM-DD")
        window.gsm_page.wz_jd_input.value = json_data["longitude"] == "NULL" ? 0.00 : json_data["longitude"]
        window.gsm_page.wz_wd_input.value = json_data["latitude"] == "NULL" ? 0.00 : json_data["latitude"]
        window.gsm_page.dh_jg_input.value = json_data["phone_space"] == "NULL" ? 0 : json_data["phone_space"]
        window.gsm_page.dh_jg2_input.value = json_data["phone_space2"] == "NULL" ? 0 : json_data["phone_space2"]
        window.gsm_page.ggxh_input.value = json_data["specification"] == "NULL" ? "" : json_data["specification"]

        // var map = new BMap.Map("allmap");    // 创建Map实例
        // map.centerAndZoom(new BMap.Point(104.071222, 30.626222), 18);  // 初始化地图,设置中心点坐标和地图级别
        // //添加地图类型控件
        // map.addControl(new BMap.MapTypeControl({
        //     mapTypes: [
        //         BMAP_NORMAL_MAP,
        //         BMAP_HYBRID_MAP
        //     ]
        // }));
        // map.setCurrentCity("成都");          // 设置地图显示的城市 此项是必须设置的
        // map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        if (json_data["longitude"] != "NULL" && json_data["latitude"] != "NULL") {
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(json_data["longitude"], json_data["latitude"])));
        }

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)

}

window.gsm_to_machine_add = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMachineAdd/>
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/a/supplier",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.supplier = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.supplier.push(<option key={i}
                                                            value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                supplier: window.gsm_page.state.supplier
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/a/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.classification = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.classification.push(<option key={i}
                                                                  value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                classification: window.gsm_page.state.classification
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/a/classification2",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.classification2 = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.classification2.push(<option key={i}
                                                                   value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                classification2: window.gsm_page.state.classification2
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/a/customer",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.customer = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.customer.push(<option key={i}
                                                            value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                customer: window.gsm_page.state.customer
            })
        }
    })
    setTimeout(function () {
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));

        window.gsm_page.zgwd_input.value = ""
        window.gsm_page.zdwd_input.value = ""
        window.gsm_page.zgsd_input.value = ""
        window.gsm_page.zdsd_input.value = ""
        window.gsm_page.zgwd2_input.value = ""
        window.gsm_page.zdwd2_input.value = ""
        window.gsm_page.zgsd2_input.value = ""
        window.gsm_page.zdsd2_input.value = ""
        window.gsm_page.jg_input.value = 5
        window.gsm_page.wz_jd_input.value = 0.00
        window.gsm_page.wz_wd_input.value = 0.00
        window.gsm_page.dh_jg_input.value = 0
        window.gsm_page.dh_jg2_input.value = 0
        // 百度地图API功能
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)
}

window.gsm_to_car_add = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCarAdd/>
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/a/supplier",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.supplier = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.supplier.push(<option key={i}
                                                            value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                supplier: window.gsm_page.state.supplier
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/a/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.classification = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.classification.push(<option key={i}
                                                                  value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                classification: window.gsm_page.state.classification
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/a/classification2",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.classification2 = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.classification2.push(<option key={i}
                                                                   value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                classification2: window.gsm_page.state.classification2
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/a/customer",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.customer = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.customer.push(<option key={i}
                                                            value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                customer: window.gsm_page.state.customer
            })
        }
    })
    setTimeout(function () {
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));


        window.gsm_page.zgwd_input.value = ""
        window.gsm_page.zdwd_input.value = ""
        window.gsm_page.zgsd_input.value = ""
        window.gsm_page.zdsd_input.value = ""
        window.gsm_page.zgwd2_input.value = ""
        window.gsm_page.zdwd2_input.value = ""
        window.gsm_page.zgsd2_input.value = ""
        window.gsm_page.zdsd2_input.value = ""
        window.gsm_page.jg_input.value = 5
        window.gsm_page.wz_jd_input.value = 0.00
        window.gsm_page.wz_wd_input.value = 0.00
        window.gsm_page.dh_jg_input.value = 0
        window.gsm_page.dh_jg2_input.value = 0

        // 百度地图API功能
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)
}

window.gsm_to_car_revise = (id) => {
    var json_data = window.operate_data["t" + id];
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCarAdd/>
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/r/supplier",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.supplier = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.supplier.push(<option key={i}
                                                            value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                supplier: window.gsm_page.state.supplier
            }, () => {
                window.gsm_page.cj_input.value = json_data["supplier"]
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/r/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.classification = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.classification.push(<option key={i}
                                                                  value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                classification: window.gsm_page.state.classification
            }, () => {
                window.gsm_page.fl_input.value = json_data["classification2"]
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/r/classification2",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.classification2 = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.classification2.push(<option key={i}
                                                                   value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                classification2: window.gsm_page.state.classification2
            }, () => {
                if (json_data["classification2"]!=="NULL") {
                    window.gsm_page.fl2_input.value = json_data["classification2"];
                }
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/car/r/customer",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.customer = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.customer.push(<option key={i}
                                                            value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                customer: window.gsm_page.state.customer
            }, () => {
                window.gsm_page.cc_input.value = json_data["customer"]
            })
        }
    })
    setTimeout(function () {
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));
        // 百度地图API功能

        window.gsm_page.setState({
            id: id,
            file: json_data["report"] == "NULL" ? "" : json_data["report"],
            check: json_data["remind"],
            new: false
        })
        window.gsm_page.mc_input.value = json_data["name"]
        window.gsm_page.xlh_input.value = json_data["machine_id"]
        window.gsm_page.djfs_input.value = json_data["duijie"]
        window.gsm_page.dh_input.value = json_data["phone"]
        window.gsm_page.dh2_input.value = json_data["phone2"]
        window.gsm_page.zt_input.value = json_data["status"]
        window.gsm_page.bx_input.value = json_data["repair"]
        window.gsm_page.zgwd_input.value = json_data["maxbaojingwendu"] == "NULL" ? "" : json_data["maxbaojingwendu"]
        window.gsm_page.zdwd_input.value = json_data["minbaojingwendu"] == "NULL" ? "" : json_data["minbaojingwendu"]
        window.gsm_page.zgsd_input.value = json_data["maxbaojingshidu"] == "NULL" ? "" : json_data["maxbaojingshidu"]
        window.gsm_page.zdsd_input.value = json_data["minbaojingshidu"] == "NULL" ? "" : json_data["minbaojingshidu"]
        window.gsm_page.zgwd2_input.value = json_data["maxbaojingwendu2"] == "NULL" ? "" : json_data["maxbaojingwendu2"]
        window.gsm_page.zdwd2_input.value = json_data["minbaojingwendu2"] == "NULL" ? "" : json_data["minbaojingwendu2"]
        window.gsm_page.zgsd2_input.value = json_data["maxbaojingshidu2"] == "NULL" ? "" : json_data["maxbaojingshidu2"]
        window.gsm_page.zdsd2_input.value = json_data["minbaojingshidu2"] == "NULL" ? "" : json_data["minbaojingshidu2"]
        window.gsm_page.jg_input.value = json_data["time"] == "NULL" ? 5 : json_data["time"]
        window.gsm_page.dq_input.value = json_data["report_at"] == "NULL" ? "" : dayjs(json_data["report_at"]).format("YYYY-MM-DD")
        window.gsm_page.wz_jd_input.value = json_data["longitude"] == "NULL" ? 0.00 : json_data["longitude"]
        window.gsm_page.wz_wd_input.value = json_data["latitude"] == "NULL" ? 0.00 : json_data["latitude"]
        window.gsm_page.dh_jg_input.value = json_data["phone_space"] == "NULL" ? 0 : json_data["phone_space"]
        window.gsm_page.dh_jg2_input.value = json_data["phone_space2"] == "NULL" ? 0 : json_data["phone_space2"]
        window.gsm_page.ggxh_input.value = json_data["specification"] == "NULL" ? "" : json_data["specification"]
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        if (json_data["longitude"] != "NULL" && json_data["latitude"] != "NULL") {
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(json_data["longitude"], json_data["latitude"])));
        }

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)

}

window.gsm_to_machineclassification = () => {
    window.gsm_app.setState({
        page: <GsmMachineClassification/>
    })

    function add_tree_node(id, name, mj, tj, ckg, parent) {

        let add_string = "<div class=\"\" style=\"width: calc(100% - 20px);margin: 0px 10px;\" gsm-id=\"tree" + id + "\"><div class=\"card\"><div class='card-body'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-bodyx\">" + name +
            (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
            "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
            // ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary d-none\" data-toggle=\"\" data-target=\"\">新增</a>\n" : "") +
            // ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
        //     (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
        //     (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
        //     ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const parent0 = parent;
        let count = 1;
        // if ((window.user_permission.indexOf("[AddMachineClassification]") != -1)) {
        //     count--
        //     add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
        //         window.gsm_page.setState({
        //             new: true,
        //             id: id0,
        //             parent: parent0,
        //         })
        //         window.gsm_page.mc_input.value = "";
        //         window.gsm_page.mj_input.value = "";
        //         window.gsm_page.tj_input.value = "";
        //         window.gsm_page.ckg_input.value = "";
        //     }, false);
        // }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[1 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[1 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
            document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].firstElementChild.innerHTML = parseInt(document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].firstElementChild.innerHTML) + 1;
        }

    }

    function add_tree_node1(id, name, mj, tj, ckg, parent) {

        let add_string = "<div class=\"col-6\" gsm-id=\"tree" + id + "\"><div class='card sc1' style=\"height:calc((100vh - 194px) / 3);overflow-y:scroll; overflow-x:hidden; border-radius: 5px;\"><div class='card-bodyx p0'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-header\">" + name + "(<span>0</span>)" +
            (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
            "<div class=\"m-l-5 btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const parent0 = parent;
        let count = 2;
        if ((window.user_permission.indexOf("[AddMachineClassification]") != -1)) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
                window.gsm_page.mj_input.value = "";
                window.gsm_page.tj_input.value = "";
                window.gsm_page.ckg_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            console.log(json_data)

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                if (json_data[i].parent == "NULL") {
                    add_tree_node1(json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                } else {
                    add_tree_node(json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                }
            }
        }
    })
}

//设施管理
window.gsm_to_machineclassification2 = () => {
    window.gsm_app.setState({
        page: <GsmMachineClassification2/>
    })

    function add_tree_node(repair, full, id, name, mj, tj, ckg, parent) {

        // let add_string = "<div class=\"\" style=\"width: calc(100% - 20px);margin: 0px 10px;\" gsm-id=\"tree" + id + "\"><div class=\"card\"><div class='card-body'>\n" +
        //     "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
        //     "<div class=\"card-bodyx\">" + name +
        //     (repair == '报修' ? "<div class=\"badge badge-danger m-l-10\"><a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" style=\"color:white\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a></div>":"") +
        //     (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
        //     (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
        //     (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
        //     // ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary d-none\" data-toggle=\"\" data-target=\"\">新增</a>\n" : "") +
        //
        //     (repair == '报修' ? "<a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"btn btn-primary\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a> " : ((window.user_permission.indexOf("[MachineOperate2]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\"  class=\"btn btn-primary\" onclick='window.operate_id = " + full["id"] + "'>报修</a> " : "")) +
        //     ((window.user_permission.indexOf("[ReviseMachineClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteMachineClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<div class='row'></div>\n" +
        //     "</div></div></div>";
        let add_string = "<div class=\"\" gsm-id=\"tree" + id + "\"><div class=\"card border-success\">\n" +
            "<div class=\"card-header " + (repair == '报修' ? "bg-danger" : "bg-success") + " d-flex flex-wrap justify-content-between align-items-center text-center\"><h4 class=\"col-12 m-b-0 text-white\"> " + name + " </h4></div>\n" +
            "<div class='card-body'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-bodyx\" style=\"padding: 1rem 0rem 0\">\n" +

            // (repair == '报修' ? "<div class=\"badge badge-danger m-l-10\"><a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" style=\"color:white\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a></div>":"") +
            "<div class=\"row text-center p-10\" style=\"padding-bottom: 0px;\">\n" +
            (mj != "NULL" && mj != "" ? "<div class=\"col-3 text-muted\">面积</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"col-3 text-muted\">体积</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"col-6 text-muted\">长宽高</div>" : "") +
            (mj != "NULL" && mj != "" ? "<div class=\"col-3 h5\"><span classname=\"mr-2\">" + mj + "</span></div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"col-3 h5\"><span classname=\"mr-2\">" + tj + "</span></div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"col-6 h5\"><span classname=\"mr-2\">" + ckg + "</span></div>" : "") +
            "</div>\n" +

            "<div class=\"col-12 d-flex justify-content-center btn-group btn-group-sm h-link\" role=\"group\">\n" +
            // ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary d-none\" data-toggle=\"\" data-target=\"\">新增</a>\n" : "") +
            (repair == '报修' ? "<a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"btn btn-primary\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a> " : ((window.user_permission.indexOf("[MachineOperate2]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\"  class=\"btn btn-primary\" onclick='window.operate_id = " + full["id"] + "'>报修</a> " : "")) +
            ((window.user_permission.indexOf("[ReviseMachineClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteMachineClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
        //     (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
        //     (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
        //     ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const bx0 = repair;
        const parent0 = parent;
        let count = 2;
        if ((window.user_permission.indexOf("[AddMachineClassification2]") != -1)) {
            count--

            add_div.firstElementChild.firstElementChild.children[1].children[1].lastElementChild.children[0].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
                window.gsm_page.mj_input.value = "";
                window.gsm_page.tj_input.value = "";
                window.gsm_page.bx_input.value = "正常";
                window.gsm_page.ckg_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseMachineClassification2]") != -1)) {
                add_div.firstElementChild.firstElementChild.children[1].children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.bx_input.value = bx0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteMachineClassification2]") != -1)) {
                add_div.firstElementChild.firstElementChild.children[1].children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
            document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].children[0].innerHTML = parseInt(document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].children[0].innerHTML) + 1;
        }

    }

    function add_tree_node1(id, name, mj, tj, ckg, parent) {

        let add_string = "<div class=\"col-2_5\" gsm-id=\"tree" + id + "\"><div class='card sc2' style=\"height:calc(100vh - 110px);overflow-y:scroll; overflow-x:hidden; border-radius: 10px;\"><div class='card-bodyx p0'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-header text-center\">" + name + "（<span>0</span>）" +
            (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
            "<div class=\"m-l-5 btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[AddMachineClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const parent0 = parent;
        let count = 2;
        if ((window.user_permission.indexOf("[AddMachineClassification]") != -1)) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
                window.gsm_page.mj_input.value = "";
                window.gsm_page.tj_input.value = "";
                window.gsm_page.ckg_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseMachineClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteMachineClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/machine2/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                if (json_data[i].parent == "NULL") {
                    add_tree_node1(json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                } else {
                    add_tree_node(json_data[i].repair, json_data[i], json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                }
            }
        }
    })
}

window.gsm_to_machinesupplier = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMachineSupplier/>
    })
    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/machine/supplier",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "正常":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                                break;
                        }
                    }
                },
                {"data": "person"},
                {"data": "phone"},
                {"data": "address"},
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return 0
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return 0
                    }
                },
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return ((window.user_permission.indexOf("[ReviseMachineSupplier]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\"  data-toggle=\"modal\" data-target=\".adddevice\" title=\"\" data-original-title=\"编辑\" onclick='window.gsm_page.state.new = false;window.gsm_page.state.id = \"" + full["id"] + "\";" +
                            "                window.gsm_page.mc_input.value = \"" + full["name"] + "\";" +
                            "                window.gsm_page.zt_input.value = \"" + full["status"] + "\";" +
                            "                window.gsm_page.lxr_input.value = \"" + full["person"] + "\";" +
                            "                window.gsm_page.lxdh_input.value = \"" + full["phone"] + "\";" +
                            "                window.gsm_page.lxdz_input.value = \"" + full["address"] + "\";" +
                            "'><i class=\"fas fa-pencil-alt\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[DeleteMachineSupplier]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "");
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');


        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);

    }, 1)
}
