import React from "react";
import $ from "jquery";

//共享管理
class GsmShare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                共享管理 {(window.user_permission.indexOf("[AddShare]") != -1) ?
                                <a href="javascript:void(0)"
                                   className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                                   data-toggle="modal"
                                   data-target=".adddevice"
                                   onClick={() => {
                                       window.gsm_page.setState({
                                           new: true,
                                       });
                                       window.gsm_page.ms_input.value = "";
                                       window.gsm_page.zt_input.value = "";
                                       window.gsm_page.bd_input.value = "";
                                       window.gsm_page.zh_input.value = "";
                                       window.gsm_page.mm_input.value = "";
                                   }}><i
                                    className="ti-plus m-r-5"></i>新增账号</a> : null}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>

                            <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel"
                                 aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">账号:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.zh_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">绑定账号:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.bd_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">密码:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.mm_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">状态:</label>
                                                    <select className="select2 form-control custom-select"
                                                            style={{width: "100%", height: "36px"}}
                                                            ref={input => this.zt_input = input}>
                                                        <optgroup label="">
                                                            <option value="正常">正常</option>
                                                            <option value="停用">停用</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">账号描述:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.ms_input = input}/>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light"
                                                    onClick={() => {
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: (this.state.new ? "/share/add" : "/share/revise"),
                                                            data: {
                                                                ms: this.ms_input.value,
                                                                zt: this.zt_input.value,
                                                                zh: this.zh_input.value,
                                                                mm: this.mm_input.value,
                                                                bd: this.bd_input.value,
                                                                i: this.state.id,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                if (data === "\"success\"") {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_share();
                                                                } else {
                                                                    window.toastr.error('帐号重复', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确定删除该账号吗？</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/share/delete",
                                                            data: {
                                                                i: this.state.id,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('删除成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                window.gsm_to_share();
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>用户名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>账号状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享账号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>绑定账号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享描述
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>添加时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>用户名称</th>
                                                        <th>账号状态</th>
                                                        <th>共享账号</th>
                                                        <th>绑定账号</th>
                                                        <th>共享描述</th>
                                                        <th>添加时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//共享设备管理
class GsmShareMachine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                共享设备管理 {window.operate_data["n"]}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备序列号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享温度
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享湿度
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享经度
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享纬度
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享历史
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>设备序列号</th>
                                                        <th>设备名称</th>
                                                        <th>时间</th>
                                                        <th>共享温度</th>
                                                        <th>共享湿度</th>
                                                        <th>共享经度</th>
                                                        <th>共享纬度</th>
                                                        <th>共享历史</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//共享资料管理
class GsmShareFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                共享资料管理 {window.operate_data["n"]}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            {(window.user_permission.indexOf("[AddShareFile]") != -1) ?
                                <div className="row">
                                    <div className="col-3">
                                        <div className="card">
                                            <div className="card-body d-flex">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="btn btn-dark">上传资料</span>
                                                    </div>
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input"
                                                               id="inputGroupFile01" onChange={
                                                            () => {
                                                                window.sharefile_fileuploadfunc()
                                                            }
                                                        }/>
                                                        <label className="custom-file-label"
                                                               htmlFor="inputGroupFile01"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>资料
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>资料</th>
                                                        <th>时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//我的共享设备管理
class GsmMyShareMachine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            lc: 0,
            bw: 0,
            lk: 0,
            bx: 0,
            kf: 0,
            xfyf: 1,
            mid: "",
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                我的共享设备
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*<div className="input-daterange input-group col-sm-4 p-b-10"*/}
                                            {/*     id="date-range">*/}
                                            {/*    <input type="text" className="form-control" id="dt-start"*/}
                                            {/*           name="dt-start"/>*/}
                                            {/*    <div className="input-group-append">*/}
                                            {/*        <span className="input-group-text bg-info b-0 text-white">至</span>*/}
                                            {/*    </div>*/}
                                            {/*    <input type="text" className="form-control" id="dt-end" name="dt-end"/>*/}
                                            {/*</div>*/}
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending" aria-label=""
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备序列号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>故障报修
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备分类
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备用户
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>温区℃
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>实时温度
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>实时湿度
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>采集时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tfoot>
                                                    <tr role="row">
                                                        <th>序号</th>
                                                        <th>设备序列号</th>
                                                        <th>设备名称</th>
                                                        <th>设备状态</th>
                                                        <th>故障报修</th>
                                                        <th>设备分类</th>
                                                        <th>设备用户</th>
                                                        <th>温区℃</th>
                                                        <th>实时温度</th>
                                                        <th>实时湿度</th>
                                                        <th>采集时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>
                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//我的共享资料管理
class GsmMyShareFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                我的共享资料
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享人
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>资料
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>共享人</th>
                                                        <th>资料</th>
                                                        <th>时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设备详细
class GsmMyShareMachineDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            full2: false,
            wenduzd: "",
            wenduzs: "",
            wendupj: "",
            wendujc: "",
            wendubzc: "",
            shiduzd: "",
            shiduzs: "",
            shidupj: "",
            shidujc: "",
            shidubzc: "",
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                {/*<input type="datetime" ref={input => this.time_input1 = input}/>*/}
                {/*<input type="datetime" ref={input => this.time_input2 = input}/>*/}
                {/*<button onClick={() => {*/}
                {/*    window.gsm_to_machine_detail_refresh(this.time_input1.value, this.time_input2.value);*/}
                {/*}}>点击查询*/}
                {/*</button>*/}
                <div class="row">
                    <div class="col-6">
                        <div class="card">
                            <div class="card-body d-flex">
                                <div class="btn waves-effect waves-light btn-danger">温度</div>
                                <div class="btn waves-effect waves-light btn-light">平均:{this.state.wendupj}</div>
                                <div class="btn waves-effect waves-light btn-light">最大:{this.state.wenduzd}</div>
                                <div class="btn waves-effect waves-light btn-light">最小:{this.state.wenduzs}</div>
                                <div class="btn waves-effect waves-light btn-light">极差:{this.state.wendujc}</div>
                                <div class="btn waves-effect waves-light btn-light">标准差:{this.state.wendubzc}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <div class="card-body d-flex">
                                <div class="btn waves-effect waves-light btn-info">湿度</div>
                                <div class="btn waves-effect waves-light btn-light">平均:{this.state.shidupj}</div>
                                <div class="btn waves-effect waves-light btn-light">最大:{this.state.shiduzd}</div>
                                <div class="btn waves-effect waves-light btn-light">最小:{this.state.shiduzs}</div>
                                <div class="btn waves-effect waves-light btn-light">极差:{this.state.shidujc}</div>
                                <div class="btn waves-effect waves-light btn-light">标准差:{this.state.shidubzc}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {((this.props.GsmVal.indexOf("[wendu]") != -1) || (this.props.GsmVal.indexOf("[shidu]") != -1)) ?
                        <div className="col-12">
                            <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                                <div className="card-header">
                                    折线数据: {window.operate_data.name}({window.operate_data.machine_id})
                                    <div className="card-actions">
                                        <a className="btn-minimize" data-action="expand" onClick={
                                            () => {
                                                this.state.full = !this.state.full;
                                                this.setState({
                                                    full: this.state.full
                                                })
                                            }
                                        }><i
                                            className="mdi mdi-arrow-expand"></i></a>
                                    </div>
                                </div>
                                {(this.props.GsmVal.indexOf("[wendu]") != -1) ?
                                    ((window.operate_data["maxbaojingwendu"] == undefined || window.operate_data["maxbaojingwendu"] == null || window.operate_data["maxbaojingwendu"] == "" || window.operate_data["maxbaojingwendu"] == "NULL") ? null : (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                                        if (value !== "NULL" && value !== "")
                                            return <div key={"maxbaojingwendu" + index}
                                                        className="card-body analytics-info">
                                                <h4 className="card-title text-danger"><i
                                                    className="fas fa-thermometer-half m-r-5"></i>{"温度" + (index + 1)}
                                                </h4>
                                                <div id={"basic-line-w" + index}
                                                     style={{
                                                         height: "400px",
                                                         userSelect: "none",
                                                         position: "relative"
                                                     }}></div>
                                            </div>
                                    })) : null}
                                {(this.props.GsmVal.indexOf("[shidu]") != -1) ?
                                    ((window.operate_data["maxbaojingshidu"] == undefined || window.operate_data["maxbaojingshidu"] == null || window.operate_data["maxbaojingshidu"] == "" || window.operate_data["maxbaojingshidu"] == "NULL") ? null : (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                                        if (value !== "NULL" && value !== "")
                                            return <div key={"maxbaojingwendu" + index}
                                                        className="card-body analytics-info">
                                                <h4 className="card-title text-info"><i
                                                    className="fas fa-thermometer-half m-r-5"></i>{"湿度" + (index + 1)}
                                                </h4>
                                                <div id={"basic-line-s" + index}
                                                     style={{
                                                         height: "400px",
                                                         userSelect: "none",
                                                         position: "relative"
                                                     }}></div>
                                            </div>
                                    })) : null}
                            </div>
                        </div> : null}

                    <div className="col-12">
                        <div className={"card" + (this.state.full2 ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                历史数据: {window.operate_data.name}({window.operate_data.machine_id})
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full2 = !this.state.full2;
                                            this.setState({
                                                full2: this.state.full2
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="card">
                                        <div class="card-body d-flex">
                                            <div class="input-group">
                                                <div class="input-group-prepend"><span class="input-group-text"><i
                                                    class="fas fa-calendar-alt"></i></span></div>
                                                <input type="text" class="form-control ptdatetime" lay-key="1"/>
                                                <div class="input-group-append"><span
                                                    class="input-group-text">至</span></div>
                                                <input type="text" class="form-control ptdatetime2" lay-key="2"/>
                                                <div class="input-group-append">
                                                    <button class="btn btn-dark" type="button">查询</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="card">
                                        <div className="card-body d-flex">
                                            <div class="btn waves-effect waves-light btn-dark">导出</div>
                                            {/*新导出 20200223*/}
                                            {/*<button className="btn waves-effect waves-light btn-light mr-1"*/}
                                            {/*        type='button' onClick={() => {*/}
                                            {/*    this.props.GsmXlsx(this.state.modestyle, 1, this.time_input1.value, this.time_input2.value);*/}
                                            {/*}}>*/}
                                            {/*    <i class="far fa-file-excel"></i> EXCEL*/}
                                            {/*</button>*/}
                                            <div className="m-l-5 btn-group" id="dcbtn"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>采集时间
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备位置
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备温度
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备湿度
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tfoot>
                                                    <tr role="row">
                                                        <th>序号</th>
                                                        <th>采集时间</th>
                                                        <th>设备位置</th>
                                                        <th>设备温度</th>
                                                        <th>设备湿度</th>
                                                    </tr>
                                                    </tfoot>
                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {GsmShare, GsmShareMachine, GsmShareFile, GsmMyShareMachine, GsmMyShareFile, GsmMyShareMachineDetail}