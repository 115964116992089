import React from "react";
import $ from "jquery";
import {GsmMapInfo} from "./map_info"

window.gsm_to_map_info=()=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmMapInfo/>
    })
    setTimeout(function () {
        $.ajax({
            type: "POST",
            url: "/map_info/revise",
            success: function (data) {
                let mcharraycl = {};
                let mcharray1 = [];
                let mcharray2 = [];
                if (data.data2.machine !== "") {
                    for (var i = 0; i < data.data2.machine.split(",").length; i++) {
                        mcharray2.push({
                            id: data.data2.machine.split(",")[i].split("|")[0],
                            cx: parseFloat(data.data2.machine.split(",")[i].split("|")[1]),
                            cy: parseFloat(data.data2.machine.split(",")[i].split("|")[2]),
                            scx: 0,
                            scy: 0,
                            tmd: 1
                        })
                    }
                }
                mcharray1 = data.data.filter(value => {
                    mcharraycl[value["classification_name"]] = 1;
                    for (var i = 0; i < mcharray2.length; i++) {
                        if (mcharray2[i].id == value.id) {
                            Object.keys(value).map(value1 => {
                                mcharray2[i][value1] = value[value1];
                            })
                            return false
                        }
                    }
                    return true
                });
                window.gsm_page.setState({
                    machinecl: Object.keys(mcharraycl),
                    machine: mcharray1,
                    mapmachine: mcharray2,
                    background: data.data2.background
                })
            }
        });
    });
}

window.map_info_fileuploadfunc=()=> {
    var xhr;
    var fileObj = document.getElementById("inputGroupFile01").files[0];
    var form = new FormData();
    // form["enctype"] = "multipart/form-data";
    form["Access-Control-Allow-Origin"] = "*";
    form.append("file", fileObj, fileObj.name);

    xhr = new XMLHttpRequest();
    xhr.open("post", window.file_server + "/upload", true);
    xhr.onload = function (evt) {
        var data = JSON.parse(evt.target.responseText);
        window.gsm_page.setState({
            background: "/file" + data.path
        })

    };
    xhr.onerror = function (evt) {
    };
    xhr.send(form);
}