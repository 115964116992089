import React from "react";
import T from "toastr/build/toastr.min.js";
import "./boli/css/style.min.css";
import "./boli/css/pt.css";
import "./javascript/base/serialize.js";
import "./javascript/base/ajax.js";
import "toastr/build/toastr.min.css";
import "./boli/libs/datatables.net-bs4/css/dataTables.bootstrap4.css";
import "./stylesheet/util.css";
import "bootstrap/dist/js/bootstrap";
import GsmApp from './javascript/index';
import $ from "jquery";


import "./boli/js/app.js";
import "./boli/js/app.init.js";
// import "./boli/js/app-style-switcher.js";
// import "./boli/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js";
import "perfect-scrollbar";
// import "./boli/extra-libs/sparkline/sparkline.js";
// import "./boli/js/waves.js";
import "./boli/js/sidebarmenu.js";
import "./boli/js/custom.min.js";


// <!-- 临时 -->
import "./boli/libs/daterangepicker/daterangepicker.css";
import "./boli/libs/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css";
import "./boli/extra-libs/ion-rangeslider/css/ion.rangeSlider.css";
import "./boli/extra-libs/ion-rangeslider/css/ion.rangeSlider.skinModern.css";
import "./boli/libs/magnific-popup/dist/magnific-popup.css";


import "./javascript/index.js";
// import "./javascript/widget.js";
import "./javascript/page.js";
// import "./javascript/homepage.js";
// import "./javascript/homepage2.js";
import "./javascript/homepage_page.js";
// import "./javascript/customer.js";
import "./javascript/customer_page.js";
// import "./javascript/machine.js";
import "./javascript/machine_page.js";
// import "./javascript/share.js";
import "./javascript/share_page.js";
// import "./javascript/logi.js";
import "./javascript/logi_page.js";
// import "./javascript/map_info.js";
import "./javascript/card_page.js";
import "./javascript/map_info_page.js";
import "./javascript/report.js";
import "./javascript/report_page.js";


import "./boli/extra-libs/treeview/dist/bootstrap-treeview.js";
import "./boli/extra-libs/ion-rangeslider/js/ion-rangeSlider/ion.rangeSlider";
// import "./boli/libs/bootstrap-switch/dist/js/bootstrap-switch";
import "datatables/media/js/jquery.dataTables";
import "daterangepicker/daterangepicker";
import "bootstrap-datepicker/js/bootstrap-datepicker";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "pdfmake-lite/build/pdfmake"
// import "pdfmake-lite/build/vfs_fonts"
// import "./javascript/base/pdfmake";
import "./javascript/base/file";
import "./javascript/base/vfs_fonts2";
// import "./javascript/base/vfs_fonts";

{/*<script src="/asset/boli/extra-libs/treeview/dist/bootstrap-treeview.min.js"></script>*/}
// import "./boli/libs/daterangepicker/daterangepicker.js";

{/*<script src="/asset/boli/libs/bootstrap-switch/dist/js/bootstrap-switch.min.js"></script>*/}
{/*<script src="/asset/boli/libs/daterangepicker/daterangepicker.js"></script>*/}
{/*<script src="/asset/boli/libs/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js"></script>*/}
{/*<script src="/asset/boli/libs/moment/moment.js"></script>*/}


export default React.memo((props) => {

    React.useLayoutEffect(() => {
        window.toastr = T;
        document.body.className = "sc light ts-theme-light sc3";
        window.jz1C();
        // window.jz2();
        // window.jz4();



    }, []);

    return <div>
        <div id="main-wrapper">
            <GsmApp/>
        </div>
    </div>;
});