import React from "react";
import $ from "jquery";

let page_home_refresh = null;

//首页
class GsmHomepage2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sb: false,
            mapmachine: [],
            background: ""
        }
        this.op();
        page_home_refresh = setInterval(this.op, 60000);
    }

    componentWillUnmount() {
        clearInterval(page_home_refresh);
    }

    op = () => {
        $.ajax({
            type: "POST",
            url: "/map_info/revise",
            success: function (data) {
                // console.log(data)
                let mcharray2 = [];
                if (data.data2.machine !== "") {
                    for (var i = 0; i < data.data2.machine.split(",").length; i++) {
                        mcharray2.push({
                            id: data.data2.machine.split(",")[i].split("|")[0],
                            cx: parseFloat(data.data2.machine.split(",")[i].split("|")[1]),
                            cy: parseFloat(data.data2.machine.split(",")[i].split("|")[2]),
                            scx: 0,
                            scy: 0,
                            tmd: 1
                        })
                    }
                }
                data.data.filter(value => {
                    for (var i = 0; i < mcharray2.length; i++) {
                        if (mcharray2[i].id == value.id) {
                            Object.keys(value).map(value1 => {
                                mcharray2[i][value1] = value[value1];
                            })
                            return false
                        }
                    }
                    return true
                });
                window.gsm_page.setState({
                    mapmachine: mcharray2,
                    background: data.data2.background
                })
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps)
    }


    render() {
        window.gsm_page = this;
        return (
            <div className="bgc" style={{height: "calc(100vh - 50px)", padding: "0px!important"}}
                 onContextMenu={(event) => {
                     event.preventDefault()
                 }}>
                {
                    this.state.sb ? <div className="d-flex flex-wrap align-content-center justify-content-center"
                                         style={{height: "100%"}}>
                        <div className="col-sm-12"
                             style={{
                                 height: "calc(100vh - 50px)",
                                 background: `url('${this.state.background}') no-repeat`,
                                 backgroundSize: "100% 100%",
                                 backgroundPosition: "50% 50%"
                             }}>
                            {this.state.mapmachine.map((value, index) => {
                                return <div className="cpane_box" key={value.machine_id + "nnnn2" + index} style={{
                                    position: "absolute",
                                    // background: "#fff",
                                    opacity: value.tmd,
                                    left: value.cx + "%",
                                    top: value.cy + "%"
                                }}>

                                    {/*<div className="watch_light">*/}
                                    {/*    <div className={"light1 " + function (valuen) {*/}
                                    {/*        if (valuen.status == "在线") {*/}
                                    {/*            return "light1_success"*/}
                                    {/*        } else if (valuen.status == "离线") {*/}
                                    {/*            return "light1_secondary"*/}
                                    {/*        } else {*/}
                                    {/*            return "light1_danger"*/}
                                    {/*        }*/}
                                    {/*    }(value)}></div>*/}
                                    {/*    <div className="watch_light">*/}
                                    {/*        <div className={"light2 " + function (valuen) {*/}
                                    {/*            if (valuen.status == "在线") {*/}
                                    {/*                return "light2_success"*/}
                                    {/*            } else if (valuen.status == "离线") {*/}
                                    {/*                return "light2_secondary"*/}
                                    {/*            } else {*/}
                                    {/*                return "light2_danger"*/}
                                    {/*            }*/}
                                    {/*        }(value)}></div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="cpane_box_icon shadowtext d-none"><i
                                        className={"mdi mdi-" + function (valuen) {
                                            // console.log(valuen)
                                            if (valuen.classification_id == "1" || valuen.classification_tree.indexOf("[1]") >= 0) {
                                                return "car-connected"
                                            } else if (valuen.classification_id == "2" || valuen.classification_tree.indexOf("[2]") >= 0) {
                                                return "mdi mdi-amplifier"
                                            } else if (valuen.classification_id == "3" || valuen.classification_tree.indexOf("[3]") >= 0) {
                                                return "mdi mdi-cube-outline"
                                            } else if (valuen.classification_id == "4" || valuen.classification_tree.indexOf("[4]") >= 0) {
                                                return "mdi mdi-stove"
                                            } else {
                                                return "mdi mdi-home-map-marker"
                                            }
                                        }(value)}/></div>
                                    <span className="ztooltip"><div className="ztooltiptext">{value.name}</div><div
                                        className={function (valuen) {
                                            if (valuen.status == "在线") {
                                                return "breath breath-light-green"
                                            } else if (valuen.status == "离线") {
                                                return "breath breath-light-gray"
                                            } else {
                                                return "breath breath-light-red"
                                            }
                                        }(value)}/><div className={function (valuen) {
                                        if (valuen.status == "在线") {
                                            return "text-success-2"
                                        } else if (valuen.status == "离线") {
                                            return "text-secondary"
                                        } else {
                                            return "text-danger-2"
                                        }
                                    }(value)}>{value.wendu.split(",").map(value => (isNaN(parseFloat(value)) ? '' : parseFloat(value).toFixed(2).slice(0, -1)+'℃')).join(",")}</div><div
                                        className={function (valuen) {
                                            if (valuen.status == "在线") {
                                                return "text-success-2"
                                            } else if (valuen.status == "离线") {
                                                return "text-secondary"
                                            } else {
                                                return "text-danger-2"
                                            }
                                        }(value)}>{value.shidu.split(",").map(value => (isNaN(parseFloat(value)) ? '' : parseFloat(value).toFixed(2).slice(0, -1)+'%RH')).join(",")}</div></span>
                                </div>
                            })}
                            <div className="h-link btn waves-effect waves-light btn-outline-success btn-rounded btn-lg"
                                 style={{position: "fixed", bottom: "13%", right: "3%"}} onClick={() => {
                                window.gsm_history_save("window.gsm_to_map_info()", true)
                            }}><i className="fas fa-magic"></i> 编辑
                            </div>
                            <div className="h-link btn waves-effect waves-light btn-outline-info btn-rounded btn-lg"
                                 style={{position: "fixed", bottom: "5%", right: "3%"}} onClick={() => {
                                this.setState({
                                    sb: false
                                })
                            }}><i className="fas fa-chevron-left"></i> 返回
                            </div>
                        </div>
                    </div> : <div
                        className="d-flex flex-wrap align-content-center justify-content-center animated slideInDown"
                        style={{height: "100%"}}>
                        <div className="col-12 logo-w"></div>
                        {(window.user_permission.indexOf("[Machine]") != -1 || window.user_permission.indexOf("[MachineClassification]") != -1 || window.user_permission.indexOf("[MachineSupplier]") != -1) ?
                            <div className="col-sm-3" style={{minWidth: 250, maxWidth: 280}} onClick={() => {
                                window.gsm_history_save("window.gsm_to_machine(1)", true)
                            }}>
                                <div className="card bgd card-hover">
                                    <div className="text-center text-meta pt3eb5">
                                        <div className="d-flex align-items-center justify-content-center m-t-30 m-b-40">
                                            <div>
                                                <i className="fas fa-box fa-5x" style={{color: "#4486d2"}}></i>
                                            </div>
                                        </div>
                                        <div className="h3 text-white p-t-30">设备管理</div>
                                    </div>
                                </div>
                            </div> : null}

                        {(window.user_permission.indexOf("[Report2]") != -1 || window.user_permission.indexOf("[ReportClassification2]") != -1) ?
                            <div className="col-sm-3" style={{minWidth: 250, maxWidth: 280}} onClick={() => {
                                window.gsm_history_save("window.gsm_to_report2()", true)
                            }}>
                                <div className="card bgd card-hover">
                                    <div className="text-center text-meta pt3eb5">
                                        <div className="d-flex align-items-center justify-content-center m-t-30 m-b-40">
                                            <div>
                                                <i className="fas fa-tasks fa-5x" style={{color: "#e6a64c"}}></i>
                                            </div>
                                        </div>
                                        <div className="h3 text-white p-t-30">验证管理</div>
                                    </div>
                                </div>
                            </div> : null}
                        {(window.user_permission.indexOf("[Remind]") != -1) ?
                            <div className="col-sm-3" style={{minWidth: 250, maxWidth: 280}} onClick={() => {
                                window.gsm_history_save("window.gsm_to_remind()", true)
                            }}>
                                <div className="card bgd card-hover">
                                    <div className="text-center text-meta pt3eb5">
                                        <div className="d-flex align-items-center justify-content-center m-t-30 m-b-40">
                                            <div>
                                                <i className="fas fa-bell fa-5x" style={{color: "#6facdb"}}></i>
                                            </div>
                                        </div>
                                        <div className="h3 text-white p-t-30">报警管理</div>
                                    </div>
                                </div>
                            </div> : null}
                        {(window.user_permission.indexOf("[CarMachine]") != -1) ?
                            <div className="col-sm-3" style={{minWidth: 250, maxWidth: 280}} onClick={() => {
                                window.gsm_history_save("window.gsm_to_car(1)", true)
                            }}>
                                <div className="card bgd card-hover">
                                    <div className="text-center text-meta pt3eb5">
                                        <div className="d-flex align-items-center justify-content-center m-t-30 m-b-40">
                                            <div>
                                                <i className="fa fa-car fa-5x" style={{color: "#d1703d"}}></i>
                                            </div>
                                        </div>
                                        <div className="h3 text-white p-t-30">车辆管理</div>
                                    </div>
                                </div>
                            </div> : null}
                        {/*{(window.user_permission.indexOf("[CustomerClassification]") != -1) ?*/}
                        {/*    <div className="col-sm-3" style={{minWidth: 250, maxWidth: 280}} onClick={() => {*/}
                        {/*        window.gsm_history_save("window.gsm_to_customerclassification()", true)*/}
                        {/*    }}>*/}
                        {/*        <div className="card bgd card-hover">*/}
                        {/*            <div className="text-center text-meta pt3eb5">*/}
                        {/*                <div className="d-flex align-items-center justify-content-center m-t-30 m-b-40">*/}
                        {/*                    <div>*/}
                        {/*                        <i className="fas fa-user fa-5x" style={{color:"#d1703d"}}></i>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="h3 text-white p-t-30">客户管理</div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div> : null}*/}
                        {(window.user_permission.indexOf("[MachineImg]") != -1) ?
                            <div className="col-sm-3" style={{minWidth: 250, maxWidth: 280}} onClick={() => {
                                this.setState({
                                    sb: true
                                })
                            }}>
                                <div className="card bgd card-hover">
                                    <div className="text-center text-meta pt3eb5">
                                        <div className="d-flex align-items-center justify-content-center m-t-30 m-b-40">
                                            <div>
                                                <i className="fab fa-elementor fa-5x" style={{color: "#1bb2a1"}}></i>
                                            </div>
                                        </div>
                                        <div className="h3 text-white p-t-30">动态展示</div>
                                    </div>
                                </div>
                            </div> : null}
                    </div>
                }


                <footer className="footer text-center d-none">
                    All Rights Reserved by AdminBite admin. Designed and Developed by
                    <a href="https://wrappixel.com">WrapPixel</a>.
                </footer>

            </div>
        )
    }
}

export default GsmHomepage2;
