import jQuery from "jquery";
import $ from "jquery";
window.jz4=()=> {
    "use strict";
    // eslint-disable-next-line no-unused-expressions
    $(".preloader").fadeOut(), $(".left-sidebar").hover(function () {
        $(".navbar-header").addClass("expand-logo")
    }, function () {
        $(".navbar-header").removeClass("expand-logo")
    }), $(".nav-toggler").on("click", function () {
        // eslint-disable-next-line no-unused-expressions
        $("#main-wrapper").toggleClass("show-sidebar"), $(".nav-toggler i").toggleClass("ti-menu")
    }), $(".nav-lock").on("click", function () {
        // eslint-disable-next-line no-unused-expressions
        $("body").toggleClass("lock-nav"), $(".nav-lock i").toggleClass("mdi-toggle-switch-off"), $("body, .page-wrapper").trigger("resize")
    }), $(".search-box a, .search-box .app-search .srh-btn").on("click", function () {
        // eslint-disable-next-line no-unused-expressions
        $(".app-search").toggle(200), $(".app-search input").focus()
    }), $(function () {
        // eslint-disable-next-line no-unused-expressions
        $(".service-panel-toggle").on("click", function () {
            $(".customizer").toggleClass("show-service-panel")
        }), $(".page-wrapper").on("click", function () {
            $(".customizer").removeClass("show-service-panel")
        })
    }), $(".floating-labels .form-control").on("focus blur", function (e) {
        $(this).parents(".form-group").toggleClass("focused", "focus" === e.type || 0 < this.value.length)
    }).trigger("blur"), $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    }), $(function () {
        $('[data-toggle="popover"]').popover()
    }), $(".message-center, .customizer-body, .scrollable").perfectScrollbar({wheelPropagation: !0}), $("body, .page-wrapper").trigger("resize"), $(".page-wrapper").delay(20).show(), $(".list-task li label").click(function () {
        $(this).toggleClass("task-done")
    }), $('a[data-action="collapse"]').on("click", function (e) {
        // eslint-disable-next-line no-unused-expressions
        e.preventDefault(), $(this).closest(".card").find('[data-action="collapse"] i').toggleClass("ti-minus ti-plus"), $(this).closest(".card").children(".card-body").collapse("toggle")
    }), $('a[data-action="expand"]').on("click", function (e) {
        // eslint-disable-next-line no-unused-expressions
        e.preventDefault(), $(this).closest(".card").find('[data-action="expand"] i').toggleClass("mdi-arrow-expand mdi-arrow-compress"), $(this).closest(".card").toggleClass("card-fullscreen")
    }), $('a[data-action="close"]').on("click", function () {
        $(this).closest(".card").removeClass().slideUp("fast")
    }), $(document).on("click", ".mega-dropdown", function (e) {
        e.stopPropagation()
    });
    var o, a = function () {
        $(".lastmonth").sparkline([6, 10, 9, 11, 9, 10, 12], {
            type: "bar",
            height: "35",
            barWidth: "4",
            width: "100%",
            resize: !0,
            barSpacing: "8",
            barColor: "#2961ff"
        })
    };
    // eslint-disable-next-line no-unused-expressions
    $(window).resize(function (e) {
        // eslint-disable-next-line no-unused-expressions
        clearTimeout(o), o = setTimeout(a, 500)
    }), a(), $(".show-left-part").on("click", function () {
        // eslint-disable-next-line no-unused-expressions
        $(".left-part").toggleClass("show-panel"), $(".show-left-part").toggleClass("ti-menu")
    })


};

(function ($) {

    //动态加载animate
    var loadStyles = function (url) {
        var hasSameStyle = false;
        var links = $('link');
        for (var i = 0; i < links.length; i++) {
            if (links.eq(i).attr('href') == url) {
                hasSameStyle = true;
                return
            }
        }

        if (!hasSameStyle) {
            var link = document.createElement("link");
            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = url;
            document.getElementsByTagName("head")[0].appendChild(link);
        }
    }

    loadStyles('https://cdn.bootcss.com/animate.css/3.7.0/animate.min.css');

    //显示提示信息    toast
    $.fn.toast = function (options) {
        var $this = $(this);
        var _this = this;
        return this.each(function () {
            $(this).css({
                position: 'relative'
            });
            var top = '';       //bottom的位置
            var translateInfo = '';     //居中和不居中时的tarnslate

            var box = '';   //消息元素
            var defaults = {
                position: "absolute",               //不是body的话就absolute
                animateIn: "fadeInUp-hastrans",      //进入的动画
                animateOut: "fadeOut",                //结束的动画
                padding: "10px 20px",              //padding
                background: "rgba(7,17,27,0.66)",     //背景色
                borderRadius: "6px",                    //圆角
                duration: 3000,                     //定时器时间
                animateDuration: 500,                      //执行动画时间
                fontSize: 14,                       //字体大小
                content: "这是一个提示信息",       //提示内容
                color: "#fff",                   //文字颜色
                top: "80%",                    //bottom底部的位置    具体的数值 或者center  垂直居中
                zIndex: 1000001,                  //层级
                isCenter: true,                     //是否垂直水平居中显示
                closePrev: true,                     //在打开下一个toast的时候立即关闭上一个toast
            }

            var opt = $.extend(defaults, options || {});
            var t = '';

            // setTimeout(function(){
            //      box.addClass('show');
            // },10);

            top = opt.isCenter === true ? '50%' : opt.top;

            defaults.isLowerIe9 = function () {
                return (!window.FormData);
            }

            // translateY(-50%)
            // translateInfo = opt.isCenter===true? 'translate3d(-50%,0,0)':'translate3d(-50%,-50%,0)';

            defaults.createMessage = function () {
                if (opt.closePrev) {
                    $('.cpt-toast').remove();
                }
                box = $("<span class='animated " + opt.animateIn + " cpt-toast'></span>").css({
                    "position": opt.position,
                    "padding": opt.padding,
                    "background": opt.background,
                    "font-size": opt.fontSize,
                    "-webkit-border-radius": opt.borderRadius,
                    "-moz-border-radius": opt.borderRadius,
                    "border-radius": opt.borderRadius,
                    "color": opt.color,
                    "top": top,
                    "z-index": opt.zIndex,
                    "-webkit-transform": 'translate3d(-50%,-50%,0)',
                    "-moz-transform": 'translate3d(-50%,-50%,0)',
                    "transform": 'translate3d(-50%,-50%,0)',
                    '-webkit-animation-duration': opt.animateDuration / 1000 + 's',
                    '-moz-animation-duration': opt.animateDuration / 1000 + 's',
                    'animation-duration': opt.animateDuration / 1000 + 's',
                }).html(opt.content).appendTo($this);
                defaults.colseMessage();
            }

            defaults.colseMessage = function () {
                var isLowerIe9 = defaults.isLowerIe9();
                if (!isLowerIe9) {
                    t = setTimeout(function () {
                        box.removeClass(opt.animateIn).addClass(opt.animateOut).on('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                            box.remove();
                        });
                    }, opt.duration);
                } else {
                    t = setTimeout(function () {
                        box.remove();
                    }, opt.duration);
                }
            }

            defaults.createMessage();
        })
    };

    $.fn.kxbdSuperMarquee = function (options) {
        var opts = $.extend({}, $.fn.kxbdSuperMarquee.defaults, options);
        return this.each(function () {
            var $marquee = $(this);
            var _scrollObj = $marquee.get(0);
            var scrollW = $marquee.width();
            var scrollH = $marquee.height();
            var $element = $marquee.children();
            var $kids = $element.children();
            var scrollSize = 0;
            var _type = (opts.direction == 'left' || opts.direction == 'right') ? 1 : 0;
            var scrollId, rollId, isMove, marqueeId;
            var t, b, c, d, e;
            var _size, _len;
            var $nav, $navBtns;
            var arrPos = [];
            var numView = 0;
            var numRoll = 0;
            var numMoved = 0;
            $element.css(_type ? 'width' : 'height', 10000);
            var navHtml = '<ul>';
            if (opts.isEqual) {
                _size = $kids[_type ? 'outerWidth' : 'outerHeight']();
                _len = $kids.length;
                scrollSize = _size * _len;
                for (var i = 0; i < _len; i++) {
                    arrPos.push(i * _size);
                    navHtml += '<li>' + (i + 1) + '</li>';
                }
            } else {
                $kids.each(function (i) {
                    arrPos.push(scrollSize);
                    scrollSize += $(this)[_type ? 'outerWidth' : 'outerHeight']();
                    navHtml += '<li>' + (i + 1) + '</li>';
                });
            }
            navHtml += '</ul>';
            if (scrollSize < (_type ? scrollW : scrollH)) return;
            $element.append($kids.clone()).css(_type ? 'width' : 'height', scrollSize * 2);
            if (opts.navId) {
                $nav = $(opts.navId).append(navHtml).hover(stop, start);
                $navBtns = $('li', $nav);
                $navBtns.each(function (i) {
                    $(this).bind(opts.eventNav, function () {
                        if (isMove) return;
                        if (numView == i) return;
                        rollFunc(arrPos[i]);
                        $navBtns.eq(numView).removeClass('navOn');
                        numView = i;
                        $(this).addClass('navOn');
                    });
                });
                $navBtns.eq(numView).addClass('navOn');
            }
            if (opts.direction == 'right' || opts.direction == 'down') {
                _scrollObj[_type ? 'scrollLeft' : 'scrollTop'] = scrollSize;
            } else {
                _scrollObj[_type ? 'scrollLeft' : 'scrollTop'] = 0;
            }
            if (opts.isMarquee) {
                marqueeId = setTimeout(scrollFunc, opts.scrollDelay);
                $marquee.hover(function () {
                    clearInterval(marqueeId);
                }, function () {
                    clearInterval(marqueeId);
                    marqueeId = setTimeout(scrollFunc, opts.scrollDelay);
                });
                if (opts.controlBtn) {
                    $.each(opts.controlBtn, function (i, val) {
                        $(val).bind(opts.eventA, function () {
                            opts.direction = i;
                            opts.oldAmount = opts.scrollAmount;
                            opts.scrollAmount = opts.newAmount;
                        }).bind(opts.eventB, function () {
                            opts.scrollAmount = opts.oldAmount;
                        });
                    });
                }
            } else {
                if (opts.isAuto) {
                    start();
                    $marquee.hover(stop, start);
                }
                if (opts.btnGo) {
                    $.each(opts.btnGo, function (i, val) {
                        $(val).bind(opts.eventGo, function () {
                            if (isMove == true) return;
                            opts.direction = i;
                            rollFunc();
                            if (opts.isAuto) {
                                stop();
                                start();
                            }
                        });
                    });
                }
            }

            function scrollFunc() {
                var _dir = (opts.direction == 'left' || opts.direction == 'right') ? 'scrollLeft' : 'scrollTop';
                if (opts.isMarquee) {
                    if (opts.loop > 0) {
                        numMoved += opts.scrollAmount;
                        if (numMoved > scrollSize * opts.loop) {
                            _scrollObj[_dir] = 0;
                            return clearInterval(marqueeId);
                        }
                    }
                    var newPos = _scrollObj[_dir] + (opts.direction == 'left' || opts.direction == 'up' ? 1 : -1) * opts.scrollAmount;
                } else {
                    if (opts.duration) {
                        if (t++ < d) {
                            isMove = true;
                            var newPos = Math.ceil(easeOutQuad(t, b, c, d));
                            if (t == d) {
                                newPos = e;
                            }
                        } else {
                            newPos = e;
                            clearInterval(scrollId);
                            isMove = false;
                            return;
                        }
                    } else {
                        var newPos = e;
                        clearInterval(scrollId);
                    }
                }
                if (opts.direction == 'left' || opts.direction == 'up') {
                    if (newPos >= scrollSize) {
                        newPos -= scrollSize;
                    }
                } else {
                    if (newPos <= 0) {
                        newPos += scrollSize;
                    }
                }
                _scrollObj[_dir] = newPos;
                if (opts.isMarquee) {
                    marqueeId = setTimeout(scrollFunc, opts.scrollDelay);
                } else if (t < d) {
                    if (scrollId) clearTimeout(scrollId);
                    scrollId = setTimeout(scrollFunc, opts.scrollDelay);
                } else {
                    isMove = false;
                }
            };

            function rollFunc(pPos) {
                isMove = true;
                var _dir = (opts.direction == 'left' || opts.direction == 'right') ? 'scrollLeft' : 'scrollTop';
                var _neg = opts.direction == 'left' || opts.direction == 'up' ? 1 : -1;
                numRoll = numRoll + _neg;
                if (pPos == undefined && opts.navId) {
                    $navBtns.eq(numView).removeClass('navOn');
                    numView += _neg;
                    if (numView >= _len) {
                        numView = 0;
                    } else if (numView < 0) {
                        numView = _len - 1;
                    }
                    $navBtns.eq(numView).addClass('navOn');
                    numRoll = numView;
                }
                var _temp = numRoll < 0 ? scrollSize : 0;
                t = 0;
                b = _scrollObj[_dir];
                e = (pPos != undefined) ? pPos : _temp + (opts.distance * numRoll) % scrollSize;
                if (_neg == 1) {
                    if (e > b) {
                        c = e - b;
                    } else {
                        c = e + scrollSize - b;
                    }
                } else {
                    if (e > b) {
                        c = e - scrollSize - b;
                    } else {
                        c = e - b;
                    }
                }
                d = opts.duration;
                if (scrollId) clearTimeout(scrollId);
                scrollId = setTimeout(scrollFunc, opts.scrollDelay);
            }

            function start() {
                rollId = setInterval(function () {
                    rollFunc();
                }, opts.time * 1000);
            }

            function stop() {
                clearInterval(rollId);
            }

            function easeOutQuad(t, b, c, d) {
                return -c * (t /= d) * (t - 2) + b;
            }

            function easeOutQuint(t, b, c, d) {
                return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
            }
        });
    };
    $.fn.kxbdSuperMarquee.defaults = {
        isMarquee: false,
        isEqual: true,
        loop: 0,
        newAmount: 3,
        eventA: 'mousedown',
        eventB: 'mouseup',
        isAuto: true,
        time: 5,
        duration: 50,
        eventGo: 'click',
        direction: 'left',
        scrollAmount: 1,
        scrollDelay: 10,
        eventNav: 'click'
    };
    $.fn.kxbdSuperMarquee.setDefaults = function (settings) {
        $.extend($.fn.kxbdSuperMarquee.defaults, settings);
    };
})(jQuery);


var showMessage = function (content, duration, isCenter, animateIn, animateOut) {
    var animateIn = animateIn || 'fadeIn';
    var animateOut = animateOut || 'fadeOut';
    var content = content || '这是一个提示信息';
    var duration = duration || '3000';
    var isCenter = isCenter || false;
    $('body').toast({
        position: 'fixed',
        animateIn: animateIn,
        animateOut: animateOut,
        content: content,
        duration: duration,
        isCenter: isCenter,
    });
}