import React from "react";
import $ from "jquery";
import {GsmLogI} from "./logi"
import dayjs from "dayjs"

window.gsm_to_logi_refresh=()=> {
    $('#ts-error').on('click', function () {
        window.toastr.error('标题', '内容', {
            "showMethod": "slideDown",
            "hideMethod": "slideUp",
            timeOut: 2000,
            positionClass: 'toast-top-center'
        });
    });
    $('#zero_config').DataTable({
        destroy: true,
        serverSide: true,
        bServerSide: true,
        searching: true,
        pageLength: 20,
        lengthChange: false,
        language: {
            "sProcessing": "处理中...",
            "sLengthMenu": "显示 _MENU_ 项结果",
            "sZeroRecords": "没有匹配结果",
            "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
            "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
            "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
            "sInfoPostFix": "",
            "sSearch": "搜索:",
            "sUrl": "",
            "sEmptyTable": "表中数据为空",
            "sLoadingRecords": "载入中...",
            "sInfoThousands": ",",
            "oPaginate": {
                "sFirst": "首页",
                "sPrevious": "上页",
                "sNext": "下页",
                "sLast": "末页"
            },
            "oAria": {
                "sSortAscending": ": 以升序排列此列",
                "sSortDescending": ": 以降序排列此列"
            }
        },
        ajax: {
            type: "POST",
            url: "/logi",
        },
        "order": [[ 0, "desc" ]],
        "columns": [
            {"data": "id"},
            {"data": "name"},
            {"data": "itype"},
            {"data": "describe"},
            {
                "data": "create_at",
                render: function (data, type, full, meta) {
                    if (data == "NULL")
                        return ""
                    else
                        return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                }
            },
        ]
    });
    //导出
    // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
    $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
    $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');
    // table filter
    $('.text-inputs-searching tfoot th').each(function () {
        var title = $(this).text();
        $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
    });
    var tableSearching = $('.text-inputs-searching').DataTable();
    tableSearching.columns().every(function () {
        var that = this;
        $('input', this.footer()).on('keyup change', function () {
            if (that.search() !== this.value) {
                that
                    .search(this.value)
                    .draw();
            }
        });
    });
    var r = $('#zero_config tfoot tr');
    r.find('th').each(function () {
        $(this).css('padding', 1);
    });
    $('#zero_config thead').append(r);
}

window.gsm_to_logi=()=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmLogI/>
    })

    setTimeout(function () {
        window.gsm_to_logi_refresh()
    }, 1)

}
