import React from "react";
import $ from "jquery";

class GsmMapInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            background: "",
            machinecl: [],
            machine: [],
            cx: 1000 / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth,
            cy: 1000 / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight,
            scx: 0,
            scy: 0,
            mapmachine: [],
        };
        window.gsm_page = this;
    }

    render() {
        return (
            <div className="cpane" onContextMenu={(event)=>{event.preventDefault()}}>
                <div className='cpane_container' >
                    <div className='cpane_side-panel' style={{
                                position: "absolute",
                                background: "#fff",
                                left: this.state.cx + "%",
                                top: this.state.cy + "%"
                            }} >
                        <div className='cpane_items'>
                            <div className="p-t-5 p-b-5" style={{cursor: "move",background: "rgb(242, 243, 244)"}} draggable={true} onDragStart={(el) => {
                                    this.setState({
                                        scx: 100 * el.pageX / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth,
                                        scy: 100 * el.pageY / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight,
                                    })
                                }} onDragEnd={(el) => {
                                    let necx = this.state.cx + 100 * el.pageX / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth - this.state.scx;
                                    let necy = this.state.cy + 100 * el.pageY / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight - this.state.scy;
                                    if (necx > 100) {
                                        necx = 99
                                    }
                                    if (necx < 0) {
                                        necx = 0
                                    }
                                    if (necy > 100) {
                                        necy = 99
                                    }
                                    if (necy < 0) {
                                        necy = 0
                                    }
                                    this.setState({
                                        cx: necx,
                                        cy: necy,
                                    })
                                }}>按住拖拽</div>
                            <div className="machinelist capne_drop sc2">

                                <div className="waves-effect waves-light btn btn-secondary d-block m-10" onClick={() => {
                                    let mch_string = [];
                                    for (var i = 0; i < this.state.mapmachine.length; i++) {
                                        mch_string.push(this.state.mapmachine[i].id + "|" + this.state.mapmachine[i].cx + "|" + this.state.mapmachine[i].cy)
                                    }
                                    $.ajax({
                                        type: "POST",
                                        url: "/map_info/save",
                                        data: {bc: window.gsm_page.state.background, mch: mch_string.join(",")},
                                        success: function (data) {
                                            //showMessage('保存成功',2000,true,'fadeIn','fadeOut');
                                            window.toastr.success('保存成功', '消息', {
                                                "showMethod": "slideDown",
                                                "hideMethod": "slideUp",
                                                timeOut: 2000,
                                                positionClass: 'toast-top-center'
                                            });
                                        }
                                    });
                                }}>保存
                                </div>

                                <div className="card m-b-1" onClick={() => {
                                    document.getElementById("inputGroupFile01").click();
                                }}><div className="card-header text-left">
                                            <a href="javascript:void(0);">
                                                点击上传背景图
                                            </a>
                                        </div>
                                    <div className="custom-file d-none">
                                        <input type="file" className="custom-file-input"
                                               id="inputGroupFile01" onChange={
                                            () => {
                                                window.map_info_fileuploadfunc()
                                            }
                                        }/>
                                        <label className="custom-file-label"
                                               htmlFor="inputGroupFile01"></label>
                                    </div>
                                </div>


                                <div className="accordion" id="#accordion" role="tablist" aria-multiselectable="true">

                                {this.state.machinecl.map((value2, index2) => {
                                    return <div key={"nnnnc" + index2} className="card m-b-1">
                                        <div className="card-header text-left"><a data-toggle="collapse" href={"#collapse"+index2} data-parent="#accordion">{value2}</a></div>
                                            <div id={"#collapse"+index2} className="collapse show d-flex flex-wrap">
                                            {this.state.machine.filter(value => value.classification_name == value2).map((value, index) => {
                                                return <div key={index2 + "nnnn" + index} className="card-body cpane_list col-6" draggable={true} onDragStart={(el) => {
                                                    this.setState({
                                                        scx: 100 * el.pageX / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth,
                                                        scy: 100 * el.pageY / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight,
                                                    })
                                                }} onDragEnd={(el) => {
                                                    var cons = value;
                                                    cons.scx = 0;
                                                    cons.scy = 0;
                                                    cons.tmd = 1;
                                                    cons.cx = this.state.cx + 100 * el.pageX / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth - this.state.scx;
                                                    cons.cy = this.state.cy + 100 * (el.pageY + 64) / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight - this.state.scy;
                                                    if (cons.cx > 100) {
                                                        cons.cx = 99
                                                    }
                                                    if (cons.cx < 0) {
                                                        cons.cx = 0
                                                    }
                                                    if (cons.cy > 100) {
                                                        cons.cy = 99
                                                    }
                                                    if (cons.cy < 0) {
                                                        cons.cy = 0
                                                    }
                                                    this.state.mapmachine.push(cons);
                                                    this.setState({
                                                        machine: this.state.machine.filter((value2) => value2 !== value),
                                                        mapmachine: this.state.mapmachine,
                                                    })
                                                }}>{value.machine_id}<br/>{value.name}</div>
                                            })}

                                            </div>
                                    </div>
                                })
                                }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='cpane_canvas' style={this.state.background === "" ? {
                        backgroundSize: "100% 100%",
                    } : {
                        background: "url(" + this.state.background + ") 50% 50% / 100% 100% no-repeat"
                    }}>
                    </div>

                </div>

                {this.state.mapmachine.map((value, index) => {
                    return <div className="cpane_box" key={value.machine_id+"nnnn2" + index} style={{
                        position: "absolute",
                        // background: "#fff",
                        opacity: value.tmd,
                        left: value.cx + "%",
                        top: value.cy + "%"
                    }} draggable={true} onDragStart={(el) => {

                       this.state.mapmachine[index].tmd = 0;
                        this.state.mapmachine[index].scx = 100 * el.pageX / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth;
                        this.state.mapmachine[index].scy = 100 * el.pageY / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight;
                        this.setState({
                            mapmachine: this.state.mapmachine,
                        })
                    }} onDragEnd={(el) => {
                        this.state.mapmachine[index].tmd = 1;
                        this.state.mapmachine[index].cx += 100 * el.pageX / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientWidth - this.state.mapmachine[index].scx;
                        this.state.mapmachine[index].cy += 100 * el.pageY / document.getElementsByClassName("page-wrapper")[0].firstElementChild.clientHeight - this.state.mapmachine[index].scy;
                        if (this.state.mapmachine[index].cx > 100) {
                            this.state.mapmachine[index].cx = 99
                        }
                        if (this.state.mapmachine[index].cx < 0) {
                            this.state.mapmachine[index].cx = 0
                        }
                        if (this.state.mapmachine[index].cy > 100) {
                            this.state.mapmachine[index].cy = 99
                        }
                        if (this.state.mapmachine[index].cy < 0) {
                            this.state.mapmachine[index].cy = 0
                        }
                        this.setState({
                            mapmachine: this.state.mapmachine,
                        })
                    }}>

                    {/*<div className="watch_light">*/}
                    {/*  <div className={"light1 " + function (valuen) {*/}
                    {/*    if(valuen.status=="在线"){*/}
                    {/*        return "light1_success"*/}
                    {/*    }else if(valuen.status=="离线"){*/}
                    {/*        return "light1_secondary"*/}
                    {/*    }else {*/}
                    {/*        return "light1_danger"*/}
                    {/*    }*/}
                    {/*}(value)}></div>*/}
                    {/*  <div className="watch_light">*/}
                    {/*    <div className={"light2 " + function (valuen) {*/}
                    {/*    if(valuen.status=="在线"){*/}
                    {/*        return "light2_success"*/}
                    {/*    }else if(valuen.status=="离线"){*/}
                    {/*        return "light2_secondary"*/}
                    {/*    }else {*/}
                    {/*        return "light2_danger"*/}
                    {/*    }*/}
                    {/*}(value)}></div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="cpane_box_icon shadowtext d-none"><i className={"mdi mdi-" + function (valuen) {
                        if(valuen.classification_id=="1"||valuen.classification_tree.indexOf("[1]")>=0){
                            return "car-connected"
                        }else if(valuen.classification_id=="2"||valuen.classification_tree.indexOf("[2]")>=0){
                            return "mdi mdi-amplifier"
                        }else if(valuen.classification_id=="3"||valuen.classification_tree.indexOf("[3]")>=0){
                            return "mdi mdi-cube-outline"
                        }else if(valuen.classification_id=="4"||valuen.classification_tree.indexOf("[4]")>=0){
                            return "mdi mdi-stove"
                        }else {
                            return "mdi mdi-home-map-marker"
                        }
                    }(value)}/></div><span className="ztooltip"><div className="ztooltiptext">{value.name}</div><div className={function (valuen) {
                        if (valuen.status == "在线") {
                            return "breath breath-light-green"
                        } else if (valuen.status == "离线") {
                            return "breath breath-light-gray"
                        } else {
                            return "breath breath-light-red"
                        }
                    }(value)}/><div className={function (valuen) {
                        if (valuen.status == "在线") {
                            return "text-success-2"
                        } else if (valuen.status == "离线") {
                            return "text-secondary"
                        } else {
                            return "text-danger-2"
                        }
                    }(value)}>{value.wendu.split(",").map(value=>(isNaN(parseFloat(value))?'':parseFloat(value).toFixed(2).slice(0, -1)+'℃')).join(",")}</div><div className={function (valuen) {
                        if (valuen.status == "在线") {
                            return "text-success-2"
                        } else if (valuen.status == "离线") {
                            return "text-secondary"
                        } else {
                            return "text-danger-2"
                        }
                    }(value)}>{value.shidu.split(",").map(value=>(isNaN(parseFloat(value))?'':parseFloat(value).toFixed(2).slice(0, -1)+'%RH')).join(",")}</div></span><span className="cpane_box_close h-link" onClick={() => {

                        this.state.machine.push(value);
                        this.setState({
                            machine: this.state.machine,
                            mapmachine: this.state.mapmachine.filter((value2) => value2 !== value),
                        })
                    }}><i className="far fa-times-circle"></i></span></div>
                })}

            </div>
        );
    }
}

export {GsmMapInfo}
