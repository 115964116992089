import React from "react";
//客户分类
class GsmCustomerClassification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            parent: "",
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                客户管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="myadmin-dd-empty dd" id="nestable">
                                    <ol className="dd-list">
                                        <li className="dd-item dd3-item" gsm-id="root">
                                            <div className="dd-handle dd3-handle"></div>
                                            <div className="dd3-content">
                                                客户管理
                                                <div className="btn-group btn-group-sm h-link" role="group">
                                                    {(window.user_permission.indexOf("[AddCustomerClassification]") != -1) ?
                                                        <a href="#" className="btn btn-primary" data-toggle="modal"
                                                           data-target=".adddevice" onClick={
                                                            () => {
                                                                window.gsm_history_save("window.gsm_to_customer_add(true,'','')", false)
                                                            }
                                                        }>新增</a> : null}
                                                </div>
                                            </div>
                                            <ol></ol>
                                        </li>
                                    </ol>
                                </div>
                                <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">确定删除该客户吗？</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: "/customer/classification/delete",
                                                                data: {
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('删除成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_customerclassification();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//客户设备
class GsmCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            parent: "",
            id: "",
            full: false,
            xfyf: 1,
            mid: "",
        }
    }

    render() {
        window.gsm_page = this;
        return (
            [<div key={0} className="left-part bg-light fixed-left-part" style={{top: "64px!important"}}>
                <a className="ti-menu ti-close btn btn-success show-left-part d-block d-md-none"
                   href="javascript:void(0)"></a>
                <div className="scrollable position-relative" style={{height: "100%", paddingTop: "74px"}}>

                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="input-search" className="sr-only">搜索:</label>
                            <input type="text" className="form-control" id="input-search" placeholder="查询分类..."/>
                        </div>
                        <div id="search-output"></div>
                        <div id="treeview-searchable" className="treeview"></div>
                    </div>

                </div>
            </div>,
                <div key={1} className="right-part">
                    <div className="modal fade xfmoneydevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div id="range_16" className="form-group"/>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费单价:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   disabled={true}
                                                   ref={input => this.xfdj_input = input}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费合计:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   disabled={true}
                                                   ref={input => this.xfhj_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.pay_to_machinepage(this.state.mid, this.state.id, this.state.xfyf);
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade chakanbeizhu" tabIndex="-1" role="dialog"
                         aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <div className="card">
                                        <div className="card-header bg-info">
                                            <h4 className="m-b-0 text-white">查看报修</h4>
                                        </div>
                                        <form>
                                            <div className="form-body">
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5" readOnly></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-actions">
                                                    <div className="card-body text-right ">
                                                        <button type="button" className="btn btn-secondary"
                                                                data-dismiss="modal">取 消
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade beizhu" tabIndex="-1" role="dialog"
                         aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <div className="card">
                                        <div className="card-header bg-info">
                                            <h4 className="m-b-0 text-white">填写报修</h4>
                                        </div>
                                        <form>
                                            <div className="form-body">
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5"
                                                                                  ref={input => this.czb_input = input}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-actions">
                                                    <div className="card-body text-right ">
                                                        <button type="button" className="btn btn-info" onClick={
                                                            () => {
                                                                window.ajax({
                                                                        cache: true,
                                                                        type: "POST",
                                                                        url: "/machine/operate",
                                                                        data: {
                                                                            i: window.operate_id,
                                                                            t: this.czb_input.value
                                                                        },
                                                                        error: function () {
                                                                        },
                                                                        fail: function () {
                                                                        },
                                                                        success: function (response) {
                                                                            window.toastr.success('处理成功', '消息', {
                                                                                "showMethod": "slideDown",
                                                                                "hideMethod": "slideUp",
                                                                                timeOut: 2000,
                                                                                positionClass: 'toast-top-center'
                                                                            });
                                                                            document.querySelector(".modal.fade.show").click();
                                                                            window.loadingmachinelist(window.machine_c,window.machine_k);
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }><i
                                                            className="fa fa-check"></i> 提 交
                                                        </button>
                                                        <button type="button" className="btn btn-secondary"
                                                                data-dismiss="modal">取 消
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-20">
                        <div className="card">
                            <div className="card-header">
                                <span id="customer_info_mz">{window.operate_data.name}</span>
                                <div className="card-actions">
                                    <a className="" data-action="collapse"><i className="ti-minus"></i></a>
                                </div>
                            </div>
                            <div className="card-body collapse show">
                                <small className="text-muted">负责人</small>
                                <h6 id="customer_info_fzr">{window.operate_data.person}</h6>
                                <small className="text-muted p-t-30 db">联系方式</small>
                                <h6 id="customer_info_lxfs">{window.operate_data.phone}</h6>
                                <small className="text-muted p-t-30 db">联系地址</small>
                                <h6 id="customer_info_lxdz">{window.operate_data.address}</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                设备信息
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand"><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>设备ID
                                                        </th>
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" style={{width: "0px"}}>设备名称
                                                        </th>
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" style={{width: "0px"}}>规格型号
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>故障报修
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>续费金额
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>到期时间
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>采集时间
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ]
        )
    }
}

//管理员管理
class GsmCustomerPermission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                客户权限管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>

                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>客户名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>客户状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>客户账号
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>首页地图
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备分类
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备厂家
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>车辆
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>客户
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证管理
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证管理分类
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证统计
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证统计分类
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享帐号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>管理员
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>添加时间
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>客户名称</th>
                                                        <th>客户状态</th>
                                                        <th>客户账号</th>

                                                        <th>设备</th>
                                                        <th>设备分类</th>
                                                        <th>设备厂家</th>
                                                        <th>车辆</th>
                                                        <th>报警</th>
                                                        <th>客户</th>
                                                        <th>验证管理</th>
                                                        <th>验证管理分类</th>
                                                        <th>验证统计</th>
                                                        <th>验证统计分类</th>
                                                        <th>共享帐号</th>
                                                        <th>管理员</th>

                                                        <th>添加时间</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//用户添加
class GsmCustomerAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            check: 1,
            id: "",
            supplier: [],
            classification: [],
            customer: [],
            file: "",
            parent: "",
            full: false
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">新增设备</h4>
                                <h6 className="card-subtitle"></h6>
                            </div>
                            <hr/>
                            <form className="form-horizontal">
                                <div className="card-body">
                                    <h4 className="card-title">基础信息</h4>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">客户名称</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.mc_input = input}/>
                                        </div>
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">状态</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.zt_input = input}>
                                                <optgroup label="">
                                                    <option value="正常">正常</option>
                                                    <option value="停用">停用</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">联系人</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.lxr_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">联系电话</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.lxdh_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">联系地址</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.lxdz_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警次数</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.cs_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">账号</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.zh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">密码</label>
                                        <div className="col-sm-3">
                                            <input type="password" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.mm_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">省</label>
                                        <div className="col-sm-2">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.sheng_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">市</label>
                                        <div className="col-sm-2">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.shi_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">区</label>
                                        <div className="col-sm-2">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.qu_input = input}/>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="card-body">
                                    <h4 className="card-title">定位信息</h4>
                                    <div className="form-group row">
                                        <label htmlFor="com1"
                                               className="col-sm-2 text-right control-label col-form-label"></label>
                                        <div className="col-sm-8">
                                            <div id="allmap"></div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">经度</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname"
                                                   placeholder="" ref={input => this.wz_jd_input = input}/>
                                        </div>
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">纬度</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname"
                                                   placeholder="" ref={input => this.wz_wd_input = input}/>
                                        </div>
                                    </div>

                                </div>
                                <hr/>
                                <div className="card-body row">
                                    <div className="col-sm-10">
                                        <div className="form-group m-b-0 text-right ">
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light" onClick={
                                                () => {
                                                    window.ajax({
                                                        cache: true,
                                                        type: "POST",
                                                        url: (this.state.new ? "/customer/classification/add" : "/customer/classification/revise"),
                                                        data: {
                                                            name: this.mc_input.value,
                                                            zt: this.zt_input.value,
                                                            lxr: this.lxr_input.value,
                                                            lxdh: this.lxdh_input.value,
                                                            lxdz: this.lxdz_input.value,
                                                            zh: this.zh_input.value,
                                                            mm: this.mm_input.value,
                                                            cs: this.cs_input.value,
                                                            i: this.state.id,
                                                            wz_jd: this.wz_jd_input.value,
                                                            wz_wd: this.wz_wd_input.value,
                                                            parent: this.state.parent,
                                                            wz_sheng: this.sheng_input.value,
                                                            wz_shi: this.shi_input.value,
                                                            wz_qu: this.qu_input.value,
                                                        },
                                                        error: function () {
                                                        },
                                                        fail: function () {
                                                        },
                                                        success: function (data) {
                                                            if (data === "\"success\"") {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                // document.querySelector(".modal.fade.show").click();
                                                                window.gsm_history_back();
                                                            } else {
                                                                window.toastr.error('账号重复', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                            }
                                                        }
                                                    })
                                                }
                                            }>提交
                                            </button>
                                            <button type="submit"
                                                    className="btn btn-dark waves-effect waves-light" onClick={
                                                () => {
                                                    window.gsm_history_back();
                                                }
                                            }>取消
                                            </button>
                                        </div>

                                    </div>

                                    <div className="col-sm-2"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {GsmCustomerClassification,GsmCustomer,GsmCustomerPermission,GsmCustomerAdd}
