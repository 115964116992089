import React from "react";
import $ from "jquery";
import {
    GsmCard,
    GsmCardWorker,
    GsmCardCustomer,
    GsmCardOrder,
    GsmCardDetail,
    GsmCardAdd,
    GsmCardClassification,
    GsmCardClassification2,
    GsmCardSupplier
} from "./card"
import echarts from "echarts"
import dayjs from "dayjs"
import XLSX from "xlsx";

window.gsm_to_card_xlsx = (change, news, time1, time2) => {

}
// 租赁设备管理
window.gsm_to_card = (change, news, time1, time2) => {
    if (news == undefined) {
        window.gsm_app.setState({
            page: null
        })
        window.gsm_app.setState({
            page: <GsmCard GsmXlsx={() => {
                //导出
                window.ajax({
                    cache: true,
                    type: "POST",
                    url: "/card?key=" + window.gsm_page.state.card_key + "&t1=" + time1 + "&t2=" + time2,
                    error: function () {
                    },
                    fail: function () {
                    },
                    success: function (data) {
                        const json_data = JSON.parse(data);
                        const ws = XLSX.utils.aoa_to_sheet([[
                            "序号",
                            "公司名称",
                            "项目名称",
                            "设备序列号",
                            "设备状态",
                            "设备用户",
                            "租赁时间",
                            "空闲时间(h)",
                            "运输时间(h)",
                            "项目时间(h)"]].concat(json_data.map((value, index) => {
                            return [
                                value["id"],
                                value["name"] === "NULL" ? "" : (value["name"].split("]").length > 1 ? value["name"].split("]")[0].split("[")[1] : ""),
                                value["name"] === "NULL" ? "" : (value["name"].split("]").length > 1 ? value["name"].split("]")[1] : value["name"]),
                                value["machine_id"],
                                ((data, full) => {
                                    if (full["rent_itype"] === "管理员" && data === "0") {
                                        return "在库";
                                    } else {
                                        switch (data) {
                                            case "0":
                                                return "空闲";
                                                break;
                                            case "1":
                                                return "使用中";
                                                break;
                                            case "2":
                                                return "运输中";
                                                break;
                                            default:
                                                return "遗失中"
                                        }
                                    }
                                })(value["status"], value),
                                value["rent_name"],
                                value["rent_at"] === "NULL" ? "" : dayjs(value["rent_at"]).format("YYYY-MM-DD"),
                                value["num0"] === "NULL" ? "" : value["num0"],
                                value["num2"] === "NULL" ? "" : value["num2"],
                                value["num1"] === "NULL" ? "" : value["num1"],
                            ];
                        })));
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "导出数据");
                        /* generate XLSX file and send to client */
                        XLSX.writeFile(wb, "[" + dayjs().format("YYYYMMDDHHmmss") + "]导出信息(" + json_data.length + ").xlsx");

                    }
                })
            }} GsmId={change == undefined ? 0 : change}/>
        })
    }
    window.operate_data = {};
    setTimeout(function () {
        if (news == undefined) {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/card/head",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        total: json_data.total,
                        use: json_data.use,
                        rent: json_data.rent,
                        lost: json_data.lost,
                    })

                }
            })
        }


        $("#range_16").ionRangeSlider({
            //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
            grid: false,
            min: 1,
            max: 12,
            from: 1,
            postfix: " 月",
            onChange: function (data) {
                window.gsm_page.state.xfyf = parseInt(data.from);
                window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
            },
        });


        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        let datatableelement = $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/card?key=" + window.gsm_page.state.card_key + "&t1=" + time1 + "&t2=" + time2,
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {
                    "data": "name",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data.split("]").length > 1 ? data.split("]")[0].split("[")[1] : ""
                    }
                },
                {
                    "data": "name",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data.split("]").length > 1 ? data.split("]")[1] : data
                    }
                },
                {"data": "machine_id",},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {

                        if (full["rent_itype"] === "管理员" && data === "0") {
                            return "<span class=\"btn btn-xs btn-primary font-weight-100\">在库</span>";
                        } else {
                            switch (data) {
                                case "0":
                                    return "<span class=\"btn btn-xs btn-secondary font-weight-100\">空闲</span>";
                                    break;
                                case "1":
                                    return "<span class=\"btn btn-xs btn-info font-weight-100\">使用中</span>";
                                    break;
                                case "2":
                                    return "<span class=\"btn btn-xs btn-warning font-weight-100\">运输中</span>";
                                    break;
                                default:
                                    return "<span class=\"btn btn-xs btn-danger font-weight-100\">遗失中</span>"
                            }
                        }
                    }
                },
                {"data": "rent_name"},
                {
                    "data": "rent_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "num0",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return "0"
                        else
                            return data
                    }
                },
                {
                    "data": "num2",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return "0"
                        else
                            return data
                    }
                },
                {
                    "data": "num1",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return "0"
                        else
                            return data
                    }
                },
            ]
        });

        // $('#dt-start, #dt-end').change(function () {
        //     datatableelement.draw();
        // });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');


        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);

        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime',
            type: 'datetime'
        });
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime2',
            type: 'datetime'
        });
        $('#date-range').datepicker({
            toggleActive: true
        });

    }, 1)
}
//员工报表
window.gsm_to_card_worker = (change, news, time1, time2) => {
    if (news == undefined) {
        window.gsm_app.setState({
            page: null
        })
        window.gsm_app.setState({
            page: <GsmCardWorker GsmXlsx={() => {
                //导出
                window.ajax({
                    cache: true,
                    type: "POST",
                    url: "/card/worker?key=" + window.gsm_page.state.card_key + "&t1=" + time1 + "&t2=" + time2,
                    error: function () {
                    },
                    fail: function () {
                    },
                    success: function (data) {
                        const json_data = JSON.parse(data);
                        const ws = XLSX.utils.aoa_to_sheet([[
                            "序号",
                            "员工名称",
                            "工单数量",
                            "工单设备数量",]].concat(json_data.map((value, index) => {
                            return [
                                value["id"],
                                value["name"] === "NULL" ? "" : value["name"],
                                value["num0"] === "NULL" ? "" : value["num0"],
                                value["num1"] === "NULL" ? "" : value["num1"],
                            ];
                        })));
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "导出数据");
                        /* generate XLSX file and send to client */
                        XLSX.writeFile(wb, "[" + dayjs().format("YYYYMMDDHHmmss") + "]导出信息(" + json_data.length + ").xlsx");

                    }
                })
            }} GsmId={change == undefined ? 0 : change}/>
        })
    }
    window.operate_data = {};
    setTimeout(function () {
        if (news == undefined) {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/card/head",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        total: json_data.total,
                        use: json_data.use,
                        rent: json_data.rent,
                        lost: json_data.lost,
                    })

                }
            })
        }


        $("#range_16").ionRangeSlider({
            //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
            grid: false,
            min: 1,
            max: 12,
            from: 1,
            postfix: " 月",
            onChange: function (data) {
                window.gsm_page.state.xfyf = parseInt(data.from);
                window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
            },
        });


        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        let datatableelement = $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/card/worker?key=" + window.gsm_page.state.card_key + "&t1=" + time1 + "&t2=" + time2,
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {
                    "data": "name",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "num0",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return "0"
                        else
                            return data
                    }
                },
                {
                    "data": "num1",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return "0"
                        else
                            return data
                    }
                },
            ]
        });

        // $('#dt-start, #dt-end').change(function () {
        //     datatableelement.draw();
        // });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');


        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);

        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime',
            type: 'datetime'
        });
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime2',
            type: 'datetime'
        });
        $('#date-range').datepicker({
            toggleActive: true
        });

    }, 1)
}
//客户项目报表
window.gsm_to_card_customer = (change, news, time1, time2) => {
    if (news == undefined) {
        window.gsm_app.setState({
            page: null
        })
        window.gsm_app.setState({
            page: <GsmCardCustomer GsmXlsx={() => {
                //导出
                window.ajax({
                    cache: true,
                    type: "POST",
                    url: "/card/customer?key=" + window.gsm_page.state.card_key + "&t1=" + time1 + "&t2=" + time2,
                    error: function () {
                    },
                    fail: function () {
                    },
                    success: function (data) {
                        const json_data = JSON.parse(data);
                        const ws = XLSX.utils.aoa_to_sheet([[
                            "序号",
                            "订单号",
                            "公司名称",
                            "项目名称",
                            "项目开始时间",
                            "项目结束时间",
                            "项目所用设备sn号",]].concat(json_data.map((value, index) => {
                            return [
                                value["id"],
                                value["order_id"],
                                value["comy"],
                                value["pj"],
                                value["out_at"] === "NULL" ? "" : dayjs(value["out_at"]).format("YYYY-MM-DD HH:mm:ss"),
                                value["finish_at"] === "NULL" ? "" : dayjs(value["finish_at"]).format("YYYY-MM-DD HH:mm:ss"),
                                value["mids"],
                            ];
                        })));
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "导出数据");
                        /* generate XLSX file and send to client */
                        XLSX.writeFile(wb, "[" + dayjs().format("YYYYMMDDHHmmss") + "]导出信息(" + json_data.length + ").xlsx");

                    }
                })
            }} GsmId={change == undefined ? 0 : change}/>
        })
    }
    window.operate_data = {};
    setTimeout(function () {
        if (news == undefined) {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/card/head",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        total: json_data.total,
                        use: json_data.use,
                        rent: json_data.rent,
                        lost: json_data.lost,
                    })

                }
            })
        }


        $("#range_16").ionRangeSlider({
            //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
            grid: false,
            min: 1,
            max: 12,
            from: 1,
            postfix: " 月",
            onChange: function (data) {
                window.gsm_page.state.xfyf = parseInt(data.from);
                window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
            },
        });


        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        let datatableelement = $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/card/customer?key=" + window.gsm_page.state.card_key + "&t1=" + time1 + "&t2=" + time2,
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "order_id"},
                {"data": "comy"},
                {"data": "pj",},
                {
                    "data": "out_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                    }
                },
                {
                    "data": "finish_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                    }
                },
                {"data": "mids"},
            ]
        });

        // $('#dt-start, #dt-end').change(function () {
        //     datatableelement.draw();
        // });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn waves-effect waves-light btn-light mr-1');


        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);

        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime',
            type: 'datetime'
        });
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime2',
            type: 'datetime'
        });
        $('#date-range').datepicker({
            toggleActive: true
        });

    }, 1)
}
//租赁订单管理
window.gsm_to_card_order = (change, news) => {
    if (news == undefined) {
        window.gsm_app.setState({
            page: null
        })
        window.gsm_app.setState({
            page: <GsmCardOrder GsmId={change == undefined ? 0 : change}/>
        })
    }
    window.operate_data = {};
    setTimeout(function () {
        if (news == undefined) {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/card/head",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    window.gsm_page.setState({
                        total: json_data.total,
                        use: json_data.use,
                        rent: json_data.rent,
                        lost: json_data.lost,
                    })

                }
            })
        }


        $("#range_16").ionRangeSlider({
            //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
            grid: false,
            min: 1,
            max: 12,
            from: 1,
            postfix: " 月",
            onChange: function (data) {
                window.gsm_page.state.xfyf = parseInt(data.from);
                window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
            },
        });


        // switch按钮
        // $(".bt-switch input[type='checkbox'], .bt-switch input[type='radio']").bootstrapSwitch();

        // 日期选择
        $('.daterange').daterangepicker();

        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });


        // $.fn.dataTable.ext.search.push(
        //     function (settings, data, dataIndex) {
        //         var min = moment($('#dt-start').val()).valueOf();
        //         var max = moment($('#dt-end').val()).valueOf();
        //         var age = moment(data[10]).valueOf() || 0; // use data for the age column
        //
        //         if ((isNaN(min) && isNaN(max)) ||
        //             (isNaN(min) && age <= max) ||
        //             (min <= age && isNaN(max)) ||
        //             (min <= age && age <= max)) {
        //             return true;
        //         }
        //         return false;
        //     }
        // );

        let datatableelement = $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            dom: 'Bfrtip',
            buttons: [
                // {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL',exportOptions: {columns: ':visible'}},
                // {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                // {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                // {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/card/order",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {
                    "data": "name",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data.split("]").length > 1 ? data.split("]")[0].split("[")[1] : ""
                    }
                },
                {
                    "data": "name2",
                    render: function (data, type, full, meta) {
                        if (full["name"] == "NULL")
                            return ""
                        else
                            return full["name"].split("]").length > 1 ? full["name"].split("]")[1] : full["name"]
                    }
                },
                {"data": "order_id"},
                {
                    "data": "mode",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "0":
                                return "<span class=\"btn btn-xs btn-primary font-weight-100\">录入</span>";
                                break;
                            case "1":
                                return "<span class=\"btn btn-xs btn-warning font-weight-100\">租借</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">归还</span>"
                        }
                    }
                },
                {
                    "data": "out_name",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "in_name",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "out_at", render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "in_at", render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "0":
                                return "<span class=\"btn btn-xs btn-warning font-weight-100\">运输中</span>";
                                break;
                            case "1":
                                return "<span class=\"btn btn-xs btn-secondary font-weight-100\">已取消</span>";
                                break;
                            case "2":
                                return "<span class=\"btn btn-xs btn-warning font-weight-100\">未服务</span>";
                                break;
                            case "3":
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">正在服务</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">已完成</span>"
                        }
                    }
                },
                {"data": "total"},
                {"data": "now"},
                {"data": "use"},
                {"data": "lost"},
                {
                    "data": "finish_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {"data": "remark"},
            ]
        });

        // $('#dt-start, #dt-end').change(function () {
        //     datatableelement.draw();
        // });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');


        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);


        $('#date-range').datepicker({
            toggleActive: true
        });

    }, 1)
}

window.gsm_to_card_detail_refresh = (t1, t2) => {
    $('#ts-error').on('click', function () {
        window.toastr.error('标题', '内容', {
            "showMethod": "slideDown",
            "hideMethod": "slideUp",
            timeOut: 2000,
            positionClass: 'toast-top-center'
        });
    });

    //datetime
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime',
        type: 'datetime'
    });
    // eslint-disable-next-line no-undef
    laydate.render({
        elem: '.ptdatetime2',
        type: 'datetime'
    });

    // specify chart configuration item and data
    $(function () {
        $.ajax({
            type: "POST",
            url: "/card/detail/list7day?i=" + window.operate_data["card_id"] + "&t1=" + t1 + "&t2=" + t2,
            success: function (data) {
                const new_data = data.reverse();
                const chartArray = [];

                const wenduzd = [];
                const wenduzs = [];
                const wendupj = [];
                const wendujc = [];
                const wendubzc = [];
                const shiduzd = [];
                const shiduzs = [];
                const shidupj = [];
                const shidujc = [];
                const shidubzc = [];

                if (window.operate_data["minbaojingwendu"] !== "" && window.operate_data["minbaojingwendu"] !== "NULL" && window.operate_data["minbaojingwendu"] !== null && window.operate_data["minbaojingwendu"] !== undefined) {
                    for (let kk = 0; kk < (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",").length; kk++) {
                        const wd_array = [];
                        const time_array = [];
                        flag:for (var i = 0; i < new_data.length; i++) {
                            wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                            time_array.push(new_data[i]["update_at"]);
                        }

                        if (wd_array.length > 0) {
                            wenduzd.push(wd_array.length > 0 ? Math.max.apply(null, wd_array).toFixed(1) : 0);
                            wenduzs.push(wd_array.length > 0 ? Math.min.apply(null, wd_array).toFixed(1) : 0);
                            wendupj.push(wd_array.length > 0 ? (wd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / wd_array.length).toFixed(1) : 0);
                            wendujc.push(wenduzd.length > 0 ? (parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1) : 0);
                            wendubzc.push(wd_array.length > 1 ? Math.sqrt(wd_array.map(function (x) {
                                return parseFloat(x) - wendupj[wendupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (wd_array.length - 1)).toFixed(1) : 0);
                        }
                        const myChart = echarts.init(document.getElementById('basic-line-w' + kk));
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'line'
                                }
                            },
                            legend: {
                                data: ['温度', '湿度']
                            },

                            color: ['#f62d51'],
                            grid: {
                                left: '1%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: time_array,
                                scale: true,
                                boundaryGap: false,
                                axisLine: {onZero: false},
                                splitLine: {show: false},
                                splitNumber: 20,
                                min: 'dataMin',
                                max: 'dataMax'
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value} ℃'
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                    start: 0,
                                    end: 100
                                },
                                {
                                    show: true,
                                    type: 'slider',
                                    y: '90%',
                                    start: 0,
                                    end: 100
                                }
                            ],
                            series: [
                                {
                                    name: '温度' + (kk + 1),
                                    type: 'line',
                                    data: wd_array,
                                    markPoint: {
                                        data: [
                                            {type: 'max', name: 'Max'},
                                            {type: 'min', name: 'Min'}
                                        ]
                                    },
                                    markLine: {
                                        symbol: "none",               //去掉警戒线最后面的箭头
                                        label: {
                                            position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                        },
                                        data: [{
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#2962FF",
                                            },
                                            yAxis: (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }, {
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#2962FF",
                                            },
                                            yAxis: (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }]
                                    },
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            shadowColor: 'rgba(0,0,0,0.1)',
                                            shadowBlur: 10,
                                            shadowOffsetY: 10
                                        }
                                    },
                                }
                            ]
                        };

                        myChart.setOption(option);
                        chartArray.push(myChart);
                    }
                }
                if (window.operate_data["minbaojingshidu"] !== "" && window.operate_data["minbaojingshidu"] !== "NULL" && window.operate_data["minbaojingshidu"] !== null && window.operate_data["minbaojingshidu"] !== undefined) {
                    for (let kk = 0; kk < (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",").length; kk++) {
                        const sd_array = [];
                        const time_array = [];
                        flag:for (var i = 0; i < new_data.length; i++) {
                            sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                            time_array.push(new_data[i]["update_at"]);
                        }


                        if (sd_array.length > 0) {
                            shiduzd.push(sd_array.length > 0 ? Math.max.apply(null, sd_array).toFixed(1) : 0);
                            shiduzs.push(sd_array.length > 0 ? Math.min.apply(null, sd_array).toFixed(1) : 0);
                            shidupj.push(sd_array.length > 0 ? (sd_array.reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / sd_array.length).toFixed(1) : 0);
                            shidujc.push(shiduzd.length > 0 ? (parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1) : 0);
                            shidubzc.push(sd_array.length > 1 ? Math.sqrt(sd_array.map(function (x) {
                                return parseFloat(x) - shidupj[shidupj.length - 1];
                            }).map(function (x) {
                                return parseFloat(x) * parseFloat(x);
                            }).reduce(function (x, y) {
                                return parseFloat(x) + parseFloat(y);
                            }) / (sd_array.length - 1)).toFixed(1) : 0);
                        }

                        const myChart = echarts.init(document.getElementById('basic-line-s' + kk));
                        const option = {
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'line'
                                }
                            },
                            legend: {
                                data: ['温度', '湿度']
                            },

                            color: ['#2962FF'],
                            grid: {
                                left: '1%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'category',
                                data: time_array,
                                scale: true,
                                boundaryGap: false,
                                axisLine: {onZero: false},
                                splitLine: {show: false},
                                splitNumber: 20,
                                min: 'dataMin',
                                max: 'dataMax'
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value} %'
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    type: 'inside',
                                    start: 0,
                                    end: 100
                                },
                                {
                                    show: true,
                                    type: 'slider',
                                    y: '90%',
                                    start: 0,
                                    end: 100
                                }
                            ],
                            series: [
                                {
                                    name: '湿度' + (kk + 1),
                                    type: 'line',
                                    data: sd_array,
                                    markPoint: {
                                        data: [
                                            {type: 'max', name: 'Max'},
                                            {type: 'min', name: 'Min'}
                                        ]
                                    },
                                    markLine: {
                                        symbol: "none",               //去掉警戒线最后面的箭头
                                        label: {
                                            position: "start"          //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                                        },
                                        data: [{
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#f62d51",
                                            },
                                            yAxis: (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[kk] // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }, {
                                            silent: false,             //鼠标悬停事件  true没有，false有
                                            lineStyle: {               //警戒线的样式  ，虚实  颜色
                                                type: "dashed",
                                                color: "#f62d51",
                                            },
                                            yAxis: (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",")[kk]// 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                                        }]
                                    },
                                    lineStyle: {
                                        normal: {
                                            width: 3,
                                            shadowColor: 'rgba(0,0,0,0.1)',
                                            shadowBlur: 10,
                                            shadowOffsetY: 10
                                        }
                                    },
                                }
                            ]
                        };

                        myChart.setOption(option);
                        chartArray.push(myChart);
                    }
                }

                window.onresize = function () {
                    for (var i = 0; i < chartArray.length; i++)
                        chartArray[i].resize();
                }


                window.gsm_page.setState({
                    wenduzd: wenduzd.join(","),
                    wenduzs: wenduzs.join(","),
                    wendupj: wendupj.join(","),
                    wendujc: wendujc.join(","),
                    wendubzc: wendubzc.join(","),
                    shiduzd: shiduzd.join(","),
                    shiduzs: shiduzs.join(","),
                    shidupj: shidupj.join(","),
                    shidujc: shidujc.join(","),
                    shidubzc: shidubzc.join(","),
                })

                let pdfwendu, pdfshidu
                if (wendupj.length == 0) {
                    pdfwendu = ''
                } else if (isNaN(wendupj[0])) {
                    pdfshidu = ''
                } else {
                    pdfwendu = "[温度：平均:" + wendupj.join(",") + " 最大:" + wenduzd.join(",") + " 最小:" + wenduzs.join(",") + " 极差:" + wendujc.join(",") + " 标准差:" + wendubzc.join(",") + "]"
                }
                if (shidupj.length == 0) {
                    pdfshidu = ''
                } else if (isNaN(shidupj[0])) {
                    pdfshidu = ''
                } else {
                    pdfshidu = "[湿度：平均:" + shidupj.join(",") + " 最大:" + shiduzd.join(",") + " 最小:" + shiduzs.join(",") + " 极差:" + shidujc.join(",") + " 标准差:" + shidubzc.join(",") + "]"
                }

                // table
                $('#zero_config').DataTable({
                    destroy: true,
                    bServerSide: true,
                    searching: false,
                    language: {
                        "sProcessing": "处理中...",
                        "sLengthMenu": "显示 _MENU_ 项结果",
                        "sZeroRecords": "没有匹配结果",
                        "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                        "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                        "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                        "sInfoPostFix": "",
                        "sSearch": "搜索:",
                        "sUrl": "",
                        "sEmptyTable": "表中数据为空",
                        "sLoadingRecords": "载入中...",
                        "sInfoThousands": ",",
                        "oPaginate": {
                            "sFirst": "首页",
                            "sPrevious": "上页",
                            "sNext": "下页",
                            "sLast": "末页"
                        },
                        "oAria": {
                            "sSortAscending": ": 以升序排列此列",
                            "sSortDescending": ": 以降序排列此列"
                        }
                    },
                    lengthChange: false,
                    //serverSide: false,
                    processing: true,
                    dom: 'Bfrtip',
                    buttons: [
                        {
                            extend: 'excel',
                            text: '<i class="far fa-file-excel"></i> EXCEL',
                            exportOptions: {columns: ':visible'},
                            messageTop: pdfwendu + " " + pdfshidu,
                            title: window.operate_data["name"]+ "（" + window.operate_data["machine_id"] + "）"
                        },
                        {
                            extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', customize: function (doc) {
                                doc.content[2].table.widths =
                                    Array(doc.content[2].table.body[0].length + 1).join('*').split('');
                            }, exportOptions: {columns: ':visible'}, messageTop: pdfwendu + " " + pdfshidu, title: window.operate_data["name"]+ "（" + window.operate_data["machine_id"] + "）"
                        },
                        {
                            extend: 'print',
                            text: '<i class="fas fa-print"></i> 打印',
                            exportOptions: {columns: ':visible'},
                            messageTop: pdfwendu + " " + pdfshidu
                        },
                        {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
                    ],
                    ajax: {
                        type: "POST",
                        url: "/card/detail?i=" + window.operate_data["card_id"] + "&t1=" + t1 + "&t2=" + t2,
                    },
                    "order": [[0, "desc"]],
                    "columns": [
                        {"data": "id"},
                        {
                            "data": "update_at",
                            render: function (data, type, full, meta) {
                                if (data == "NULL")
                                    return ""
                                else
                                    return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                            }
                        },
                        {
                            "data": "wendu",
                            render: function (data, type, full, meta) {
                                if (data == "NULL") {
                                    return ""
                                } else {
                                    window.return_hts = "";
                                    (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                                        if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                            window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        } else {
                                            window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        }
                                    });
                                    return window.return_hts
                                }
                            }
                        },
                        {
                            "data": "shidu",
                            render: function (data, type, full, meta) {
                                if (data == "NULL") {
                                    return ""
                                } else {
                                    window.return_hts = "";
                                    (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                                        if (parseFloat(value) < parseFloat(data.split(",")[index]) || parseFloat((window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",")[index]) > parseFloat(data.split(",")[index])) {
                                            window.return_hts += "<span class='text-danger mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        } else {
                                            window.return_hts += "<span class='mr-2'>" + (isNaN(parseFloat(data.split(",")[index])) ? "" : parseFloat(data.split(",")[index]).toFixed(2).slice(0, -1)) + "</span>"
                                        }
                                    });
                                    return window.return_hts
                                }
                            }
                        },

                    ]
                });
                //导出
                // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
                $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
                $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-sm btn-outline-info mr-1');

            }
        });

    })
}

window.gsm_to_card_detail = (id) => {
    window.operate_data = window.operate_data["t" + id];
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCardDetail/>
    })

    setTimeout(function () {
        window.gsm_to_card_detail_refresh('', '')
    }, 1)

}

window.gsm_to_card_revise = (id) => {
    var json_data = window.operate_data["t" + id];
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCardAdd/>
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/r/supplier",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.supplier = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.supplier.push(<option key={i}
                                                            value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                supplier: window.gsm_page.state.supplier
            }, () => {
                window.gsm_page.cj_input.value = json_data["supplier"]
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/r/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.classification = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.classification.push(<option key={i}
                                                                  value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                classification: window.gsm_page.state.classification
            }, () => {
                window.gsm_page.fl_input.value = json_data["classification"]
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/r/customer",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data1 = JSON.parse(data);
            window.gsm_page.state.customer = [];
            for (let i = 0; i < json_data1.length; i++) {
                window.gsm_page.state.customer.push(<option key={i}
                                                            value={json_data1[i].id}>{json_data1[i].name}</option>)
            }
            window.gsm_page.setState({
                customer: window.gsm_page.state.customer
            }, () => {
                window.gsm_page.cc_input.value = json_data["customer"]
            })
        }
    })
    setTimeout(function () {
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));
        // 百度地图API功能
        window.gsm_page.setState({
            id: id,
            file: json_data["report"] == "NULL" ? "" : json_data["report"],
            check: json_data["remind"],
            new: false
        })
        window.gsm_page.mc_input.value = json_data["name"]
        window.gsm_page.xlh_input.value = json_data["card_id"]
        window.gsm_page.djfs_input.value = json_data["duijie"]
        window.gsm_page.dh_input.value = json_data["phone"]
        window.gsm_page.dh2_input.value = json_data["phone2"]
        window.gsm_page.zt_input.value = json_data["status"]
        window.gsm_page.bx_input.value = json_data["repair"]
        window.gsm_page.zgwd_input.value = json_data["maxbaojingwendu"] == "NULL" ? "" : json_data["maxbaojingwendu"]
        window.gsm_page.zdwd_input.value = json_data["minbaojingwendu"] == "NULL" ? "" : json_data["minbaojingwendu"]
        window.gsm_page.zgsd_input.value = json_data["maxbaojingshidu"] == "NULL" ? "" : json_data["maxbaojingshidu"]
        window.gsm_page.zdsd_input.value = json_data["minbaojingshidu"] == "NULL" ? "" : json_data["minbaojingshidu"]
        window.gsm_page.zgwd2_input.value = json_data["maxbaojingwendu2"] == "NULL" ? "" : json_data["maxbaojingwendu2"]
        window.gsm_page.zdwd2_input.value = json_data["minbaojingwendu2"] == "NULL" ? "" : json_data["minbaojingwendu2"]
        window.gsm_page.zgsd2_input.value = json_data["maxbaojingshidu2"] == "NULL" ? "" : json_data["maxbaojingshidu2"]
        window.gsm_page.zdsd2_input.value = json_data["minbaojingshidu2"] == "NULL" ? "" : json_data["minbaojingshidu2"]
        window.gsm_page.jg_input.value = json_data["time"] == "NULL" ? 5 : json_data["time"]
        window.gsm_page.dq_input.value = json_data["report_at"] == "NULL" ? "" : dayjs(json_data["report_at"]).format("YYYY-MM-DD")
        window.gsm_page.wz_jd_input.value = json_data["longitude"] == "NULL" ? 0.00 : json_data["longitude"]
        window.gsm_page.wz_wd_input.value = json_data["latitude"] == "NULL" ? 0.00 : json_data["latitude"]
        window.gsm_page.dh_jg_input.value = json_data["phone_space"] == "NULL" ? 0 : json_data["phone_space"]
        window.gsm_page.dh_jg2_input.value = json_data["phone_space2"] == "NULL" ? 0 : json_data["phone_space2"]
        window.gsm_page.ggxh_input.value = json_data["specification"] == "NULL" ? "" : json_data["specification"]

        // var map = new BMap.Map("allmap");    // 创建Map实例
        // map.centerAndZoom(new BMap.Point(104.071222, 30.626222), 18);  // 初始化地图,设置中心点坐标和地图级别
        // //添加地图类型控件
        // map.addControl(new BMap.MapTypeControl({
        //     mapTypes: [
        //         BMAP_NORMAL_MAP,
        //         BMAP_HYBRID_MAP
        //     ]
        // }));
        // map.setCurrentCity("成都");          // 设置地图显示的城市 此项是必须设置的
        // map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        if (json_data["longitude"] != "NULL" && json_data["latitude"] != "NULL") {
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(json_data["longitude"], json_data["latitude"])));
        }

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)

}

window.gsm_to_card_add = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCardAdd/>
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/a/supplier",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.supplier = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.supplier.push(<option key={i}
                                                            value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                supplier: window.gsm_page.state.supplier
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/a/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.classification = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.classification.push(<option key={i}
                                                                  value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                classification: window.gsm_page.state.classification
            })
        }
    })
    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/a/customer",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            window.gsm_page.state.customer = [];
            for (let i = 0; i < json_data.length; i++) {
                window.gsm_page.state.customer.push(<option key={i}
                                                            value={json_data[i].id}>{json_data[i].name}</option>)
            }
            window.gsm_page.setState({
                customer: window.gsm_page.state.customer
            })
        }
    })
    setTimeout(function () {
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));

        window.gsm_page.zgwd_input.value = ""
        window.gsm_page.zdwd_input.value = ""
        window.gsm_page.zgsd_input.value = ""
        window.gsm_page.zdsd_input.value = ""
        window.gsm_page.zgwd2_input.value = ""
        window.gsm_page.zdwd2_input.value = ""
        window.gsm_page.zgsd2_input.value = ""
        window.gsm_page.zdsd2_input.value = ""
        window.gsm_page.jg_input.value = 5
        window.gsm_page.wz_jd_input.value = 0.00
        window.gsm_page.wz_wd_input.value = 0.00
        window.gsm_page.dh_jg_input.value = 0
        window.gsm_page.dh_jg2_input.value = 0
        // 百度地图API功能
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)
}

window.gsm_to_cardclassification = () => {
    window.gsm_app.setState({
        page: <GsmCardClassification/>
    })

    function add_tree_node(id, name, mj, tj, ckg, parent) {

        let add_string = "<div class=\"\" style=\"width: calc(100% - 20px);margin: 0px 10px;\" gsm-id=\"tree" + id + "\"><div class=\"card\"><div class='card-body'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-bodyx\">" + name +
            (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
            "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
            // ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary d-none\" data-toggle=\"\" data-target=\"\">新增</a>\n" : "") +
            // ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
        //     (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
        //     (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
        //     ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const parent0 = parent;
        let count = 1;
        // if ((window.user_permission.indexOf("[AddCardClassification]") != -1)) {
        //     count--
        //     add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
        //         window.gsm_page.setState({
        //             new: true,
        //             id: id0,
        //             parent: parent0,
        //         })
        //         window.gsm_page.mc_input.value = "";
        //         window.gsm_page.mj_input.value = "";
        //         window.gsm_page.tj_input.value = "";
        //         window.gsm_page.ckg_input.value = "";
        //     }, false);
        // }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseCardClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[1 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteCardClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[1 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
            document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].firstElementChild.innerHTML = parseInt(document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].firstElementChild.innerHTML) + 1;
        }

    }

    function add_tree_node1(id, name, mj, tj, ckg, parent) {

        let add_string = "<div class=\"col-6\" gsm-id=\"tree" + id + "\"><div class='card sc1' style=\"height:calc((100vh - 194px) / 3);overflow-y:scroll; overflow-x:hidden; border-radius: 5px;\"><div class='card-bodyx p0'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-header\">" + name + "(<span>0</span>)" +
            (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
            "<div class=\"m-l-5 btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const parent0 = parent;
        let count = 2;
        if ((window.user_permission.indexOf("[AddCardClassification]") != -1)) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
                window.gsm_page.mj_input.value = "";
                window.gsm_page.tj_input.value = "";
                window.gsm_page.ckg_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseCardClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteCardClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/card/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);
            console.log(json_data)

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                if (json_data[i].parent == "NULL") {
                    add_tree_node1(json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                } else {
                    add_tree_node(json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                }
            }
        }
    })
}

//设施管理
window.gsm_to_cardclassification2 = () => {
    window.gsm_app.setState({
        page: <GsmCardClassification2/>
    })

    function add_tree_node(repair, full, id, name, mj, tj, ckg, parent) {

        // let add_string = "<div class=\"\" style=\"width: calc(100% - 20px);margin: 0px 10px;\" gsm-id=\"tree" + id + "\"><div class=\"card\"><div class='card-body'>\n" +
        //     "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
        //     "<div class=\"card-bodyx\">" + name +
        //     (repair == '报修' ? "<div class=\"badge badge-danger m-l-10\"><a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" style=\"color:white\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a></div>":"") +
        //     (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
        //     (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
        //     (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
        //     // ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary d-none\" data-toggle=\"\" data-target=\"\">新增</a>\n" : "") +
        //
        //     (repair == '报修' ? "<a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"btn btn-primary\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a> " : ((window.user_permission.indexOf("[CardOperate2]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\"  class=\"btn btn-primary\" onclick='window.operate_id = " + full["id"] + "'>报修</a> " : "")) +
        //     ((window.user_permission.indexOf("[ReviseCardClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteCardClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<div class='row'></div>\n" +
        //     "</div></div></div>";
        let add_string = "<div class=\"\" gsm-id=\"tree" + id + "\"><div class=\"card border-success\">\n" +
            "<div class=\"card-header " + (repair == '报修' ? "bg-danger" : "bg-success") + " d-flex flex-wrap justify-content-between align-items-center text-center\"><h4 class=\"col-12 m-b-0 text-white\"> " + name + " </h4></div>\n" +
            "<div class='card-body'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-bodyx\" style=\"padding: 1rem 0rem 0\">\n" +

            // (repair == '报修' ? "<div class=\"badge badge-danger m-l-10\"><a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" style=\"color:white\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a></div>":"") +
            "<div class=\"row text-center p-10\" style=\"padding-bottom: 0px;\">\n" +
            (mj != "NULL" && mj != "" ? "<div class=\"col-3 text-muted\">面积</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"col-3 text-muted\">体积</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"col-6 text-muted\">长宽高</div>" : "") +
            (mj != "NULL" && mj != "" ? "<div class=\"col-3 h5\"><span classname=\"mr-2\">" + mj + "</span></div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"col-3 h5\"><span classname=\"mr-2\">" + tj + "</span></div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"col-6 h5\"><span classname=\"mr-2\">" + ckg + "</span></div>" : "") +
            "</div>\n" +

            "<div class=\"col-12 d-flex justify-content-center btn-group btn-group-sm h-link\" role=\"group\">\n" +
            // ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary d-none\" data-toggle=\"\" data-target=\"\">新增</a>\n" : "") +
            (repair == '报修' ? "<a href=\"javascript:void(0)\"  data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"btn btn-primary\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'>报修</a> " : ((window.user_permission.indexOf("[CardOperate2]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\"  class=\"btn btn-primary\" onclick='window.operate_id = " + full["id"] + "'>报修</a> " : "")) +
            ((window.user_permission.indexOf("[ReviseCardClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteCardClassification2]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
        //     (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
        //     (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
        //     ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const bx0 = repair;
        const parent0 = parent;
        let count = 2;
        if ((window.user_permission.indexOf("[AddCardClassification2]") != -1)) {
            count--

            add_div.firstElementChild.firstElementChild.children[1].children[1].lastElementChild.children[0].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
                window.gsm_page.mj_input.value = "";
                window.gsm_page.tj_input.value = "";
                window.gsm_page.bx_input.value = "正常";
                window.gsm_page.ckg_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseCardClassification2]") != -1)) {
                add_div.firstElementChild.firstElementChild.children[1].children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.bx_input.value = bx0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteCardClassification2]") != -1)) {
                add_div.firstElementChild.firstElementChild.children[1].children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
            document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].children[0].innerHTML = parseInt(document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.children[1].children[0].innerHTML) + 1;
        }

    }

    function add_tree_node1(id, name, mj, tj, ckg, parent) {

        let add_string = "<div class=\"col-2_5\" gsm-id=\"tree" + id + "\"><div class='card sc2' style=\"height:calc(100vh - 110px);overflow-y:scroll; overflow-x:hidden; border-radius: 10px;\"><div class='card-bodyx p0'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-header text-center\">" + name + "（<span>0</span>）" +
            (mj != "NULL" && mj != "" ? "<div class=\"badge badge-info m-l-10\">面积：" + mj + "</div>" : "") +
            (tj != "NULL" && tj != "" ? "<div class=\"badge badge-info m-l-10\">体积：" + tj + "</div>" : "") +
            (ckg != "NULL" && ckg != "" ? "<div class=\"badge badge-info m-l-10 m-r-10\">长宽高：" + ckg + "</div>" : "") +
            "<div class=\"m-l-5 btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[AddCardClassification]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteCardClassification]") != -1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const mj0 = mj;
        const tj0 = tj;
        const ckg0 = ckg;
        const id0 = id;
        const parent0 = parent;
        let count = 2;
        if ((window.user_permission.indexOf("[AddCardClassification]") != -1)) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
                window.gsm_page.mj_input.value = "";
                window.gsm_page.tj_input.value = "";
                window.gsm_page.ckg_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseCardClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                    window.gsm_page.mj_input.value = mj0;
                    window.gsm_page.tj_input.value = tj0;
                    window.gsm_page.ckg_input.value = ckg0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteCardClassification]") != -1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/card2/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                if (json_data[i].parent == "NULL") {
                    add_tree_node1(json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                } else {
                    add_tree_node(json_data[i].repair, json_data[i], json_data[i].id, json_data[i].name, json_data[i].mj, json_data[i].tj, json_data[i].ckg, json_data[i].parent);
                }
            }
        }
    })
}

window.gsm_to_cardsupplier = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCardSupplier/>
    })
    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            ajax: {
                type: "POST",
                url: "/card/supplier",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "正常":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                                break;
                        }
                    }
                },
                {"data": "person"},
                {"data": "phone"},
                {"data": "address"},
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return 0
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return 0
                    }
                },
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return ((window.user_permission.indexOf("[ReviseCardSupplier]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\"  data-toggle=\"modal\" data-target=\".adddevice\" title=\"\" data-original-title=\"编辑\" onclick='window.gsm_page.state.new = false;window.gsm_page.state.id = \"" + full["id"] + "\";" +
                            "                window.gsm_page.mc_input.value = \"" + full["name"] + "\";" +
                            "                window.gsm_page.zt_input.value = \"" + full["status"] + "\";" +
                            "                window.gsm_page.lxr_input.value = \"" + full["person"] + "\";" +
                            "                window.gsm_page.lxdh_input.value = \"" + full["phone"] + "\";" +
                            "                window.gsm_page.lxdz_input.value = \"" + full["address"] + "\";" +
                            "'><i class=\"fas fa-pencil-alt\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[DeleteCardSupplier]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "");
                    }
                }
            ]
        });
    }, 1)
}
