import React from "react";
import $ from "jquery";
import XLSX from "xlsx";
import dayjs from "dayjs";

//设备
class GsmMachine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            auto: false,
            lc: 0,
            bw: 0,
            lk: 0,
            bx: 0,
            kf: 0,
            xfyf: 1,
            mid: "",
            modestyle: props.GsmId,
            modeinfo: [],
            machine_key: "",
            mul: [],
            click: (e, el) => {
                if (this.state.mul.indexOf(e) > -1) {
                    this.state.mul = this.state.mul.filter(value => value !== e)
                    el.firstElementChild.className = "far fa-square fa-2x";
                } else {
                    this.state.mul.push(e);
                    el.firstElementChild.className = "fas fa-check-square fa-2x";
                }
            },
            allclick: () => {
                if (this.state.mul.length !== document.getElementsByClassName("mul-click-icon").length) {
                    this.state.mul = [];
                    const ela = document.getElementsByClassName("mul-click-icon");
                    for (let i = 0; i < ela.length; i++) {
                        ela[i].click();
                    }
                } else {
                    this.state.mul = [];
                    const ela = document.getElementsByClassName("mul-click-icon");
                    for (let i = 0; i < ela.length; i++) {
                        if (ela[i].firstElementChild.className === "fas fa-check-square fa-2x") {
                            ela[i].firstElementChild.className = "far fa-square fa-2x";
                        }
                    }
                }
                // jQuery(".mdi-checkbox-blank-outline,.mdi-checkbox-marked-outline").click();
                // if (this.state.mul.indexOf(e) >-1) {
                //     this.state.mul = this.state.mul.filter(value => value!==e)
                //     el.firstElementChild.className = "far fa-square fa-2x";
                // } else{
                //     this.state.mul.push(e);
                //     el.firstElementChild.className = "fas fa-check-square fa-2x";
                // }
            }
        }

        if (props.GsmId == 1) {
            const ek = this;
            window.ajax({
                cache: true,
                type: "POST",
                url: "/machine",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    ek.setState({
                        modeinfo: json_data
                    })
                }
            })
        }


    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card-group">
                            <div className="card" onClick={() => {
                                this.state.machine_key = 1;
                                window.gsm_to_machine(this.state.modestyle, 1);
                            }}>
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div className="text-dark">
                                            <h2>{this.state.lc}</h2>
                                            <h6>冷藏车</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-dark display-6"><i
                                                className="mdi mdi-car-connected"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" onClick={() => {
                                this.state.machine_key = 2;
                                window.gsm_to_machine(this.state.modestyle, 1);
                            }}>
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div className="text-dark">
                                            <h2>{this.state.bw}</h2>
                                            <h6>保温箱</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-dark display-6"><i className="mdi mdi-amplifier"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" onClick={() => {
                                this.state.machine_key = 3;
                                window.gsm_to_machine(this.state.modestyle, 1);
                            }}>
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div className="text-dark">
                                            <h2>{this.state.lk}</h2>
                                            <h6>冷库</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-dark display-6"><i
                                                className="mdi mdi-cube-outline"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" onClick={() => {
                                this.state.machine_key = 4;
                                window.gsm_to_machine(this.state.modestyle, 1);
                            }}>
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div className="text-dark">
                                            <h2>{this.state.bx}</h2>
                                            <h6>冰箱</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-dark display-6"><i
                                                className="mdi mdi-stove"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card" onClick={() => {
                                this.state.machine_key = 5;
                                window.gsm_to_machine(this.state.modestyle, 1);
                            }}>
                                <div className="card-body">
                                    <div className="d-flex no-block align-items-center">
                                        <div className="text-dark">
                                            <h2>{this.state.kf}</h2>
                                            <h6>库房</h6>
                                        </div>
                                        <div className="ml-auto">
                                            <span className="text-dark display-6"><i
                                                className="mdi mdi-home-map-marker"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal fade xfmoneydevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div id="range_16" className="form-group"/>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费单价:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   disabled={true}
                                                   ref={input => this.xfdj_input = input}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费合计:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   disabled={true}
                                                   ref={input => this.xfhj_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.pay_to_machinepage(this.state.mid, this.state.id, this.state.xfyf);
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade timedevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">校准日期:</label>
                                            <input type="datetime" className="timedeviceinput form-control"
                                                   id="recipient-name"
                                                   ref={input => this.xfje2_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/machine/jz",
                                                    data: {
                                                        xfje: this.xfje2_input.value.split("/")[2] + "-" + this.xfje2_input.value.split("/")[0] + "-" + this.xfje2_input.value.split("/")[1],
                                                        i: window.operate_id,
                                                    },
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        window.toastr.success('提交成功', '消息', {
                                                            "showMethod": "slideDown",
                                                            "hideMethod": "slideUp",
                                                            timeOut: 2000,
                                                            positionClass: 'toast-top-center'
                                                        });
                                                        document.querySelector(".modal.fade.show").click();
                                                        window.gsm_to_machine(0, 1);
                                                    }
                                                })
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade moneydevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费金额:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   ref={input => this.xfje_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/machine/money",
                                                    data: {
                                                        xfje: this.xfje_input.value,
                                                        i: this.state.id,
                                                    },
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        window.toastr.success('提交成功', '消息', {
                                                            "showMethod": "slideDown",
                                                            "hideMethod": "slideUp",
                                                            timeOut: 2000,
                                                            positionClass: 'toast-top-center'
                                                        });
                                                        document.querySelector(".modal.fade.show").click();
                                                        window.gsm_to_machine(0, 1);
                                                    }
                                                })
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade chakanbeizhu" tabIndex="-1" role="dialog"
                         aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <div className="card">
                                        <div className="card-header bg-info">
                                            <h4 className="m-b-0 text-white">查看报修</h4>
                                        </div>
                                        <form>
                                            <div className="form-body">
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5" readOnly></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-actions">
                                                    <div className="card-body text-right ">
                                                        <button type="button" className="btn btn-secondary"
                                                                data-dismiss="modal">取 消
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade beizhu" tabIndex="-1" role="dialog"
                         aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <div className="card">
                                        <div className="card-header bg-info">
                                            <h4 className="m-b-0 text-white">填写报修</h4>
                                        </div>
                                        <form>
                                            <div className="form-body">
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5"
                                                                                  ref={input => this.czb_input = input}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-actions">
                                                    <div className="card-body text-right ">
                                                        <button type="button" className="btn btn-info" onClick={
                                                            () => {
                                                                window.ajax({
                                                                        cache: true,
                                                                        type: "POST",
                                                                        url: "/machine/operate",
                                                                        data: {
                                                                            i: window.operate_id,
                                                                            t: this.czb_input.value
                                                                        },
                                                                        error: function () {
                                                                        },
                                                                        fail: function () {
                                                                        },
                                                                        success: function (response) {
                                                                            window.toastr.success('处理成功', '消息', {
                                                                                "showMethod": "slideDown",
                                                                                "hideMethod": "slideUp",
                                                                                timeOut: 2000,
                                                                                positionClass: 'toast-top-center'
                                                                            });
                                                                            document.querySelector(".modal.fade.show").click();
                                                                            window.gsm_to_machine(0, 1);
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }><i
                                                            className="fa fa-check"></i> 提 交
                                                        </button>
                                                        <button type="button" className="btn btn-secondary"
                                                                data-dismiss="modal">取 消
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">确定删除该设备吗？</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-danger waves-effect waves-light"
                                            onClick={() => {
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/machine/delete",
                                                    data: {
                                                        i: this.state.id
                                                    },
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        window.toastr.success('删除成功', '消息', {
                                                            "showMethod": "slideDown",
                                                            "hideMethod": "slideUp",
                                                            timeOut: 2000,
                                                            positionClass: 'toast-top-center'
                                                        });
                                                        document.querySelector(".modal.fade.show").click();
                                                        window.gsm_to_machine(0, 1);
                                                    }
                                                })
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                设备管理
                                {(window.user_permission.indexOf("[AddMachine]") != -1) ?
                                    <a href="javascript:void(0)"
                                       className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                                       onClick={
                                           () => {
                                               window.gsm_history_save("window.gsm_to_machine_add()", false);
                                           }
                                       }
                                    ><i className="ti-plus m-r-5"></i>新增设备</a> : null}
                                {this.state.modestyle === 0 && <a href="javascript:void(0)"
                                                                  className="btn waves-effect waves-light btn-outline-info btn-sm m-l-10"
                                                                  onClick={() => {
                                                                      window.gsm_page.state.allclick();
                                                                  }}>
                                    <i class="ti-check m-r-5"/>全选
                                </a>}
                                {this.state.modestyle === 0 && <a href="javascript:void(0)"
                                                                  className="btn waves-effect waves-light btn-outline-info btn-sm m-l-10"
                                                                  onClick={() => {
                                                                      window.operate_id = window.gsm_page.state.mul.join(",");
                                                                  }}
                                                                  data-toggle="modal"
                                                                  data-target=".timedevice">
                                    <i class="ti-time m-r-5"/>批量校准
                                </a>}
                                {/*<div className="m-l-5 btn-group" id="dcbtn"></div>*/}
                                <div className="m-l-5 btn-group">

                                </div>
                                <div className="card-actions">
                                    <a className="btn-minimize m-r-15" style={{position: "relative"}} onClick={
                                        () => {
                                            this.state.auto = !this.state.auto;
                                            if (this.state.auto) {
                                                window.time_refresh = setTimeout(function () {
                                                    window.gsm_to_machine(window.gsm_page.state.modestyle, 1);
                                                }, window.time_refresh_time);
                                            } else {
                                                clearTimeout(window.time_refresh)
                                            }
                                            this.setState({
                                                auto: this.state.auto
                                            })
                                        }
                                    }>{this.state.auto && <div className="qpie">
                                        <div className="qpie-content"></div>
                                    </div>}<i className={"mdi mdi-refresh " + (this.state.auto ? "text-info" : "")}
                                              style={{position: "absolute", zIndex: "3"}}></i></a>
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            $('.dt-buttons').remove();
                                            if (this.state.modestyle === 0) {
                                                this.state.modestyle = 1;
                                                const ek = this;
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/machine",
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        const json_data = JSON.parse(data)
                                                        ek.setState({
                                                            modeinfo: json_data
                                                        })
                                                    }
                                                })
                                            } else {
                                                this.state.modestyle = 0;
                                                window.gsm_to_machine(0, 1);
                                            }
                                            this.setState({
                                                modestyle: this.state.modestyle
                                            })
                                        }
                                    }><i className="sl-icon-grid"></i></a>
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.modestyle === 0 ? <div className="table-responsive">
                                        <div id="zero_config_wrapper"
                                             className="dataTables_wrapper container-fluid dt-bootstrap4">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="zero_config_length"></div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div id="zero_config_filter" className="dataTables_filter"></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="d-none input-daterange input-group col-sm-4 p-b-10"
                                                     id="date-range">
                                                    <input type="text" className="form-control" id="dt-start"
                                                           name="dt-start"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text bg-info b-0 text-white">至</span>
                                                    </div>
                                                    <input type="text" className="form-control" id="dt-end" name="dt-end"/>
                                                </div>
                                                <div className="col-sm-12">
                                                    <table id="zero_config"
                                                           className="table table-striped table-bordered dataTable text-inputs-searching"
                                                           role="grid" aria-describedby="zero_config_info"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="sorting text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config"
                                                                rowSpan="1" colSpan="1" aria-sort="ascending" aria-label=""
                                                                style={{width: "0px"}}>
                                                            </th>
                                                            <th className="sorting text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config"
                                                                rowSpan="1" colSpan="1" aria-sort="ascending" aria-label=""
                                                                style={{width: "0px"}}>序号
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备序列号
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备名称
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>规格型号
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备状态
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>故障报修
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备分类
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设施分类
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备用户
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>到期时间
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>温区℃
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>实时温度
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>实时湿度
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>采集时间
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>校准时间
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>操作
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tfoot>
                                                        <tr role="row">
                                                            <th></th>
                                                            <th>序号</th>
                                                            <th>设备序列号</th>
                                                            <th>设备名称</th>
                                                            <th>规格型号</th>
                                                            <th>设备状态</th>
                                                            <th>故障报修</th>
                                                            <th>设备分类</th>
                                                            <th>设施分类</th>
                                                            <th>设备用户</th>
                                                            <th>到期时间</th>
                                                            <th>温区℃</th>
                                                            <th>实时温度</th>
                                                            <th>实时湿度</th>
                                                            <th>采集时间</th>
                                                            <th>校准时间</th>
                                                            <th>操作</th>
                                                        </tr>
                                                        </tfoot>
                                                        <tbody>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers"
                                                         id="zero_config_paginate"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> :
                                    <div className="row sc2" style={{
                                        height: "69vh",
                                        overflowY: "scroll"
                                    }}>{this.state.modeinfo.map(((value, index) => {
                                        return <div key={"machine" + index} className="col-2_5">

                                            <div className="card border-success" onClick={
                                                () => {
                                                    if (window.user_permission.indexOf("[MachineDetail]") != -1) {
                                                        window.operate_data["t" + value["id"]] = value;
                                                        window.gsm_history_save('window.gsm_to_machine_detail(' + value["id"] + ')', false)
                                                    }
                                                }
                                            }>
                                                <div
                                                    className={"card-header " + (value.status == "在线" ? 'bg-success' : (value.status == "离线" ? 'bg-secondary' : "bg-danger")) + " d-flex flex-wrap justify-content-between align-items-center text-center"}>
                                                    <h4 className="col-12 m-b-0 text-white">{value.repair == "报修" ?
                                                        <span className="h5 m-b-0 text-danger"><i
                                                            className="fas fa-wrench"></i></span> : ""} {value.name} </h4>
                                                    <h4 className="col-12 m-b-0 text-white"><span
                                                        className="h-link d-none"><i
                                                        className="fas fa-eye"></i></span> {value.machine_id} </h4>
                                                </div>
                                                <div className="card-bodyx " style={{padding: "2.5rem 0rem"}}>
                                                    <div className="row text-center p-10">
                                                        <div
                                                            className={value.shidu !== "NULL" ? "col-6" : "col-12"}>实时温度
                                                        </div>
                                                        {value.shidu !== "NULL" && <div className="col-6">实时湿度</div>}
                                                        <div
                                                            className={value.shidu !== "NULL" ? "col-6 h3" : "col-12 h3"}
                                                            dangerouslySetInnerHTML={{
                                                                __html: [1].map(value2 => {
                                                                        window.return_hts = "";
                                                                        if (value.wendu == "NULL") {
                                                                            window.return_hts = "ERR"
                                                                        } else {
                                                                            value.maxbaojingwendu.split(",").map((value3, index3) => {
                                                                                if (parseFloat(value3) < parseFloat(value.wendu.split(",")[index3]) || parseFloat(value.minbaojingwendu.split(",")[index3]) > parseFloat(value.wendu.split(",")[index3])) {
                                                                                    window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='text-danger mr-2'>" + parseFloat(value.wendu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                                } else {
                                                                                    window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='mr-2'>" + parseFloat(value.wendu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                                }
                                                                            });
                                                                        }
                                                                        return window.return_hts
                                                                    }
                                                                )
                                                            }}>

                                                        </div>
                                                        {value.shidu !== "NULL" &&
                                                        <div className="col-6 h3" dangerouslySetInnerHTML={{
                                                            __html: [1].map(value2 => {
                                                                    window.return_hts = "";
                                                                    if (value.shidu == "NULL") {
                                                                        window.return_hts = "ERR"
                                                                    } else {
                                                                        value.maxbaojingshidu.split(",").map((value3, index3) => {

                                                                            if (parseFloat(value3) < parseFloat(value.shidu.split(",")[index3]) || parseFloat(value.minbaojingshidu.split(",")[index3]) > parseFloat(value.shidu.split(",")[index3])) {
                                                                                window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='text-danger mr-2'>" + parseFloat(value.shidu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                            } else {
                                                                                window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='mr-2'>" + parseFloat(value.shidu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                            }
                                                                        });
                                                                    }
                                                                    return window.return_hts
                                                                }
                                                            )
                                                        }}>

                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }))}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//车辆
class GsmCar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            auto: false,
            xfyf: 1,
            mid: "",
            modestyle: props.GsmId,
            modeinfo: [],
            mul: [],
            click: (e, el) => {
                if (this.state.mul.indexOf(e) > -1) {
                    this.state.mul = this.state.mul.filter(value => value !== e)
                    el.firstElementChild.className = "far fa-square fa-2x";
                } else {
                    this.state.mul.push(e);
                    el.firstElementChild.className = "fas fa-check-square fa-2x";
                }
            },
            allclick: () => {
                if (this.state.mul.length !== document.getElementsByClassName("mul-click-icon").length) {
                    this.state.mul = [];
                    const ela = document.getElementsByClassName("mul-click-icon");
                    for (let i = 0; i < ela.length; i++) {
                        ela[i].click();
                    }
                } else {
                    this.state.mul = [];
                    const ela = document.getElementsByClassName("mul-click-icon");
                    for (let i = 0; i < ela.length; i++) {
                        if (ela[i].firstElementChild.className === "fas fa-check-square fa-2x") {
                            ela[i].firstElementChild.className = "far fa-square fa-2x";
                        }
                    }
                }
                // jQuery(".mdi-checkbox-blank-outline,.mdi-checkbox-marked-outline").click();
                // if (this.state.mul.indexOf(e) >-1) {
                //     this.state.mul = this.state.mul.filter(value => value!==e)
                //     el.firstElementChild.className = "far fa-square fa-2x";
                // } else{
                //     this.state.mul.push(e);
                //     el.firstElementChild.className = "fas fa-check-square fa-2x";
                // }
            }
        }
        if (props.GsmId == 1) {
            const ek = this;
            window.ajax({
                cache: true,
                type: "POST",
                url: "/car",
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data)
                    ek.setState({
                        modeinfo: json_data
                    })
                }
            })
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card" style={{height: '75vh'}} id="gsm-bdmap">
                        </div>
                    </div>
                    <div className="modal fade chakanbeizhu" tabIndex="-1" role="dialog"
                         aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <div className="card">
                                        <div className="card-header bg-info">
                                            <h4 className="m-b-0 text-white">查看报修</h4>
                                        </div>
                                        <form>
                                            <div className="form-body">
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5" readOnly></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-actions">
                                                    <div className="card-body text-right ">
                                                        <button type="button" className="btn btn-secondary"
                                                                data-dismiss="modal">取 消
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade xfmoneydevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div id="range_16" className="form-group"/>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费单价:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   disabled={true}
                                                   ref={input => this.xfdj_input = input}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费合计:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   disabled={true}
                                                   ref={input => this.xfhj_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.pay_to_machinepage(this.state.mid, this.state.id, this.state.xfyf);
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade moneydevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">续费金额:</label>
                                            <input type="money" className="form-control" id="recipient-name"
                                                   ref={input => this.xfje_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/car/money",
                                                    data: {
                                                        xfje: this.xfje_input.value,
                                                        i: this.state.id,
                                                    },
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        window.toastr.success('提交成功', '消息', {
                                                            "showMethod": "slideDown",
                                                            "hideMethod": "slideUp",
                                                            timeOut: 2000,
                                                            positionClass: 'toast-top-center'
                                                        });
                                                        document.querySelector(".modal.fade.show").click();
                                                        window.gsm_to_car(0, 1);
                                                    }
                                                })
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade beizhu" tabIndex="-1" role="dialog"
                         aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">

                                    <div className="card">
                                        <div className="card-header bg-info">
                                            <h4 className="m-b-0 text-white">填写报修</h4>
                                        </div>
                                        <form>
                                            <div className="form-body">
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5"
                                                                                  ref={input => this.czb_input = input}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-actions">
                                                    <div className="card-body text-right ">
                                                        <button type="button" className="btn btn-info" onClick={
                                                            () => {
                                                                window.ajax({
                                                                        cache: true,
                                                                        type: "POST",
                                                                        url: "/car/operate",
                                                                        data: {
                                                                            i: window.operate_id,
                                                                            t: this.czb_input.value
                                                                        },
                                                                        error: function () {
                                                                        },
                                                                        fail: function () {
                                                                        },
                                                                        success: function (response) {
                                                                            window.toastr.success('处理成功', '消息', {
                                                                                "showMethod": "slideDown",
                                                                                "hideMethod": "slideUp",
                                                                                timeOut: 2000,
                                                                                positionClass: 'toast-top-center'
                                                                            });
                                                                            document.querySelector(".modal.fade.show").click();
                                                                            window.gsm_to_car(0, 1);
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        }><i
                                                            className="fa fa-check"></i> 提 交
                                                        </button>
                                                        <button type="button" className="btn btn-secondary"
                                                                data-dismiss="modal">取 消
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade timedevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel"
                         aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="recipient-name"
                                                   className="control-label">校准日期:</label>
                                            <input type="datetime" className="timedeviceinput form-control"
                                                   id="recipient-name"
                                                   ref={input => this.xfje2_input = input}/>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/machine/jz",
                                                    data: {
                                                        xfje: this.xfje2_input.value.split("/")[2] + "-" + this.xfje2_input.value.split("/")[0] + "-" + this.xfje2_input.value.split("/")[1],
                                                        i: window.operate_id,
                                                    },
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        window.toastr.success('提交成功', '消息', {
                                                            "showMethod": "slideDown",
                                                            "hideMethod": "slideUp",
                                                            timeOut: 2000,
                                                            positionClass: 'toast-top-center'
                                                        });
                                                        document.querySelector(".modal.fade.show").click();
                                                        window.gsm_to_car(0, 1);
                                                    }
                                                })
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                         aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                        <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">确定删除该设备吗？</h4>
                                    <button type="button" className="close" data-dismiss="modal"
                                            aria-hidden="true">×
                                    </button>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary waves-effect"
                                            data-dismiss="modal">取 消
                                    </button>
                                    <button type="button"
                                            className="btn btn-danger waves-effect waves-light"
                                            onClick={() => {
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/car/delete",
                                                    data: {
                                                        i: this.state.id
                                                    },
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        window.toastr.success('删除成功', '消息', {
                                                            "showMethod": "slideDown",
                                                            "hideMethod": "slideUp",
                                                            timeOut: 2000,
                                                            positionClass: 'toast-top-center'
                                                        });
                                                        document.querySelector(".modal.fade.show").click();
                                                        window.gsm_to_car(0, 1);
                                                    }
                                                })
                                            }}> 提 交
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                车辆管理
                                {(window.user_permission.indexOf("[AddCarMachine]") != -1) ?
                                    <a href="javascript:void(0)"
                                       className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                                       onClick={
                                           () => {
                                               window.gsm_history_save("window.gsm_to_car_add()", false);
                                           }
                                       }
                                    ><i className="ti-plus m-r-5"></i>新增设备</a> : null}
                                {this.state.modestyle === 0 && <a href="javascript:void(0)"
                                                                  className="btn waves-effect waves-light btn-outline-info btn-sm m-l-10"
                                                                  onClick={() => {
                                                                      window.gsm_page.state.allclick();
                                                                  }}>
                                    <i className="ti-check m-r-5"/>全选
                                </a>}
                                {this.state.modestyle === 0 && <a href="javascript:void(0)"
                                                                  className="btn waves-effect waves-light btn-outline-info btn-sm m-l-10"
                                                                  onClick={() => {
                                                                      window.operate_id = window.gsm_page.state.mul.join(",");
                                                                  }}
                                                                  data-toggle="modal"
                                                                  data-target=".timedevice">
                                    <i className="ti-time m-r-5"/>批量校准
                                </a>}
                                <div className="card-actions">
                                    <a className="btn-minimize m-r-15" style={{position: "relative"}} onClick={
                                        () => {
                                            this.state.auto = !this.state.auto;
                                            if (this.state.auto) {
                                                window.time_refresh = setTimeout(function () {
                                                    window.gsm_to_car(window.gsm_page.state.modestyle, 1);
                                                }, window.time_refresh_time);
                                            } else {
                                                clearTimeout(window.time_refresh)
                                            }
                                            this.setState({
                                                auto: this.state.auto
                                            })
                                        }
                                    }>{this.state.auto && <div className="qpie">
                                        <div className="qpie-content"></div>
                                    </div>}<i className={"mdi mdi-refresh " + (this.state.auto ? "text-info" : "")}
                                              style={{position: "absolute", zIndex: "3"}}></i></a>
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            $('.dt-buttons').remove();
                                            if (this.state.modestyle === 0) {
                                                this.state.modestyle = 1;
                                                const ek = this;
                                                window.ajax({
                                                    cache: true,
                                                    type: "POST",
                                                    url: "/car",
                                                    error: function () {
                                                    },
                                                    fail: function () {
                                                    },
                                                    success: function (data) {
                                                        const json_data = JSON.parse(data)
                                                        ek.setState({
                                                            modeinfo: json_data
                                                        })
                                                    }
                                                })
                                            } else {
                                                this.state.modestyle = 0;
                                                window.gsm_to_car(0, 1);
                                            }
                                            this.setState({
                                                modestyle: this.state.modestyle
                                            })
                                        }
                                    }><i
                                        className="sl-icon-grid"></i></a>
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                {this.state.modestyle === 0 ? <div className="table-responsive">
                                        <div id="zero_config_wrapper"
                                             className="dataTables_wrapper container-fluid dt-bootstrap4">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="zero_config_length"></div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div id="zero_config_filter" className="dataTables_filter"></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/*<div className="input-daterange input-group col-sm-4 p-b-10"
                                                 id="date-range">
                                                <input type="text" className="form-control" id="dt-start"
                                                       name="dt-start"/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text bg-info b-0 text-white">至</span>
                                                </div>
                                                <input type="text" className="form-control" id="dt-end" name="dt-end"/>
                                            </div>*/}
                                                <div className="col-sm-12">
                                                    <table id="zero_config"
                                                           className="table table-striped table-bordered dataTable text-inputs-searching"
                                                           role="grid" aria-describedby="zero_config_info"
                                                           style={{width: "100%"}}>
                                                        <thead>
                                                        <tr role="row">
                                                            <th className="sorting text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config"
                                                                rowSpan="1" colSpan="1" aria-sort="ascending" aria-label=""
                                                                style={{width: "0px"}}>
                                                            </th>
                                                            <th className="sorting text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config"
                                                                rowSpan="1" colSpan="1" aria-sort="ascending" aria-label=""
                                                                style={{width: "0px"}}>序号
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备序列号
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备名称
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>规格型号
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备状态
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>故障报修
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备分类
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设施分类
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>设备用户
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>到期时间
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>温区℃
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>实时温度
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>实时湿度
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>采集时间
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>校准时间
                                                            </th>
                                                            <th className="text-nowrap" tabIndex="0"
                                                                aria-controls="zero_config" rowSpan="1"
                                                                colSpan="1" style={{width: "0px"}}>操作
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tfoot>
                                                        <tr role="row">
                                                            <th></th>
                                                            <th>序号</th>
                                                            <th>设备序列号</th>
                                                            <th>设备名称</th>
                                                            <th>规格型号</th>
                                                            <th>设备状态</th>
                                                            <th>故障报修</th>
                                                            <th>设备分类</th>
                                                            <th>设施分类</th>
                                                            <th>设备用户</th>
                                                            <th>到期时间</th>
                                                            <th>温区℃</th>
                                                            <th>实时温度</th>
                                                            <th>实时湿度</th>
                                                            <th>采集时间</th>
                                                            <th>校准时间</th>
                                                            <th>操作</th>
                                                        </tr>
                                                        </tfoot>
                                                        <tbody>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <div className="dataTables_paginate paging_simple_numbers"
                                                         id="zero_config_paginate"></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    : <div className="row sc2" style={{
                                        height: "69vh",
                                        overflowY: "scroll"
                                    }}>{this.state.modeinfo.map(((value, index) => {
                                        return <div key={"machine" + index} className="col-2_5">
                                            <div className="card border-success" onClick={
                                                () => {
                                                    if (window.user_permission.indexOf("[CarMachineDetail]") != -1) {
                                                        window.operate_data["t" + value["id"]] = value;
                                                        window.gsm_history_save('window.gsm_to_car_detail(' + value["id"] + ')', false)
                                                    }

                                                }
                                            }>
                                                <div
                                                    className={"card-header " + (value.status == "在线" ? 'bg-success' : (value.status == "离线" ? 'bg-secondary' : "bg-danger")) + " d-flex flex-wrap justify-content-between align-items-center text-center"}>
                                                    <h4 className="col-12 m-b-0 text-white">{value.repair == "报修" ?
                                                        <span className="h5 m-b-0 text-danger"><i
                                                            className="fas fa-wrench"></i></span> : ""} {value.name} </h4>
                                                    <h4 className="col-12 m-b-0 text-white"><span
                                                        className="h-link d-none"><i
                                                        className="fas fa-eye"></i></span> {value.machine_id} </h4>
                                                </div>
                                                <div className="card-bodyx " style={{padding: "2.5rem 0rem"}}>
                                                    <div className="row text-center p-10">
                                                        <div
                                                            className={value.shidu !== "NULL" ? "col-6" : "col-12"}>实时温度
                                                        </div>
                                                        {value.shidu !== "NULL" && <div className="col-6">实时湿度</div>}
                                                        <div
                                                            className={value.shidu !== "NULL" ? "col-6 h3" : "col-12 h3"}
                                                            dangerouslySetInnerHTML={{
                                                                __html: [1].map(value2 => {
                                                                        window.return_hts = "";
                                                                        if (value.wendu == "NULL") {
                                                                            window.return_hts = "ERR"
                                                                        } else {
                                                                            value.maxbaojingwendu.split(",").map((value3, index3) => {

                                                                                if (parseFloat(value3) < parseFloat(value.wendu.split(",")[index3]) || parseFloat(value.minbaojingwendu.split(",")[index3]) > parseFloat(value.wendu.split(",")[index3])) {
                                                                                    window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='text-danger mr-2'>" + parseFloat(value.wendu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                                } else {
                                                                                    window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='mr-2'>" + parseFloat(value.wendu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                                }
                                                                            });
                                                                        }
                                                                        return window.return_hts
                                                                    }
                                                                )
                                                            }}>

                                                        </div>
                                                        {value.shidu !== "NULL" &&
                                                        <div className="col-6 h3" dangerouslySetInnerHTML={{
                                                            __html: [1].map(value2 => {
                                                                    window.return_hts = "";
                                                                    if (value.shidu == "NULL") {
                                                                        window.return_hts = "ERR"
                                                                    } else {
                                                                        value.maxbaojingshidu.split(",").map((value3, index3) => {

                                                                            if (parseFloat(value3) < parseFloat(value.shidu.split(",")[index3]) || parseFloat(value.minbaojingshidu.split(",")[index3]) > parseFloat(value.shidu.split(",")[index3])) {
                                                                                window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='text-danger mr-2'>" + parseFloat(value.shidu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                            } else {
                                                                                window.return_hts += (index3 > 0 ? "<br/>" : "") + "<span className='mr-2'>" + parseFloat(value.shidu.split(",")[index3]).toFixed(2).slice(0, -1) + "</span>"
                                                                            }
                                                                        });
                                                                    }
                                                                    return window.return_hts
                                                                }
                                                            )
                                                        }}>

                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div>{value.machine_id}</div>*/}
                                            {/*(window.user_permission.indexOf("[CarMachineDetail]") != -1)?:*/}
                                            {/*<div onClick={*/}
                                            {/*    () => {*/}
                                            {/*        window.operate_data["t" + value["id"]] = value;*/}
                                            {/*        window.gsm_history_save('window.gsm_to_car_detail(' + value["id"] + ')', false)*/}
                                            {/*    }*/}
                                            {/*}>查看*/}
                                            {/*</div>*/}
                                            {/*:null*/}
                                        </div>
                                    }))}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设备详细
class GsmMachineDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            full2: false,
            wenduzd: "",
            wenduzs: "",
            wendupj: "",
            wendujc: "",
            wendubzc: "",
            shiduzd: "",
            shiduzs: "",
            shidupj: "",
            shidujc: "",
            shidubzc: "",
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                {/*<a onClick={() => {*/}
                {/*}} href="javascript:void(0)" data-toggle="modal" data-target=".timedevice">*/}
                {/*    <button>查询范围批量导出</button>*/}
                {/*</a>*/}
                <div className="modal fade timedevice" tabIndex="-1" role="dialog"
                     aria-labelledby="myModalLabel"
                     aria-hidden="true" style={{display: "none"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="control-label">导出日期:</label>
                                        <input type="datetime" className="timedeviceinput form-control"
                                               id="recipient-name"
                                               ref={input => this.xfje2_input = input}/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary waves-effect"
                                        data-dismiss="modal">取 消
                                </button>
                                <button type="button"
                                        className="btn btn-info waves-effect waves-light"
                                        onClick={() => {
                                            window.ajax({
                                                cache: true,
                                                type: "POST",
                                                url: "/machine/detail/export",
                                                data: {
                                                    t1: this.xfje2_input.value.split(" - ")[0],
                                                    t2: this.xfje2_input.value.split(" - ")[1],
                                                    i: window.operate_data["machine_id"],
                                                },
                                                error: function () {
                                                },
                                                fail: function () {
                                                },
                                                success: function (data) {
                                                    const json_data = JSON.parse(data);

                                                    const new_data = JSON.parse(data).reverse();
                                                    const wenduzd = [];
                                                    const wenduzs = [];
                                                    const wendupj = [];
                                                    const wendujc = [];
                                                    const wendubzc = [];
                                                    const shiduzd = [];
                                                    const shiduzs = [];
                                                    const shidupj = [];
                                                    const shidujc = [];
                                                    const shidubzc = [];

                                                    if (window.operate_data["minbaojingwendu"] !== "" && window.operate_data["minbaojingwendu"] !== "NULL" && window.operate_data["minbaojingwendu"] !== null && window.operate_data["minbaojingwendu"] !== undefined) {
                                                        for (let kk = 0; kk < (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",").length; kk++) {
                                                            const wd_array = [];
                                                            const time_array = [];
                                                            flag:for (var i = 0; i < new_data.length; i++) {
                                                                wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                                                                time_array.push(new_data[i]["update_at"]);
                                                            }

                                                            if (wd_array.length > 0) {
                                                                wenduzd.push(wd_array.length > 0 ? Math.max.apply(null, wd_array).toFixed(1) : 0);
                                                                wenduzs.push(wd_array.length > 0 ? Math.min.apply(null, wd_array).toFixed(1) : 0);
                                                                wendupj.push(wd_array.length > 0 ? (wd_array.reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / wd_array.length).toFixed(1) : 0);
                                                                wendujc.push(wenduzd.length > 0 ? (parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1) : 0);
                                                                wendubzc.push(wd_array.length > 1 ? Math.sqrt(wd_array.map(function (x) {
                                                                    return parseFloat(x) - wendupj[wendupj.length - 1];
                                                                }).map(function (x) {
                                                                    return parseFloat(x) * parseFloat(x);
                                                                }).reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / (wd_array.length - 1)).toFixed(1) : 0);
                                                            }
                                                        }
                                                    }
                                                    if (window.operate_data["minbaojingshidu"] !== "" && window.operate_data["minbaojingshidu"] !== "NULL" && window.operate_data["minbaojingshidu"] !== null && window.operate_data["minbaojingshidu"] !== undefined) {
                                                        for (let kk = 0; kk < (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",").length; kk++) {
                                                            const sd_array = [];
                                                            const time_array = [];
                                                            flag:for (var i = 0; i < new_data.length; i++) {
                                                                sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                                                                time_array.push(new_data[i]["update_at"]);
                                                            }


                                                            if (sd_array.length > 0) {
                                                                shiduzd.push(sd_array.length > 0 ? Math.max.apply(null, sd_array).toFixed(1) : 0);
                                                                shiduzs.push(sd_array.length > 0 ? Math.min.apply(null, sd_array).toFixed(1) : 0);
                                                                shidupj.push(sd_array.length > 0 ? (sd_array.reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / sd_array.length).toFixed(1) : 0);
                                                                shidujc.push(shiduzd.length > 0 ? (parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1) : 0);
                                                                shidubzc.push(sd_array.length > 1 ? Math.sqrt(sd_array.map(function (x) {
                                                                    return parseFloat(x) - shidupj[shidupj.length - 1];
                                                                }).map(function (x) {
                                                                    return parseFloat(x) * parseFloat(x);
                                                                }).reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / (sd_array.length - 1)).toFixed(1) : 0);
                                                            }
                                                        }
                                                    }

                                                    let pdfwendu, pdfshidu
                                                    if (wendupj.length == 0) {
                                                        pdfwendu = ''
                                                    } else if (isNaN(wendupj[0])) {
                                                        pdfshidu = ''
                                                    } else {
                                                        pdfwendu = "[温度：平均:" + wendupj.join(",") + " 最大:" + wenduzd.join(",") + " 最小:" + wenduzs.join(",") + " 极差:" + wendujc.join(",") + " 标准差:" + wendubzc.join(",") + "]"
                                                    }
                                                    if (shidupj.length == 0) {
                                                        pdfshidu = ''
                                                    } else if (isNaN(shidupj[0])) {
                                                        pdfshidu = ''
                                                    } else {
                                                        pdfshidu = "[湿度：平均:" + shidupj.join(",") + " 最大:" + shiduzd.join(",") + " 最小:" + shiduzs.join(",") + " 极差:" + shidujc.join(",") + " 标准差:" + shidubzc.join(",") + "]"
                                                    }

                                                    const ws = XLSX.utils.aoa_to_sheet([[
                                                        "序号",
                                                        "采集时间",
                                                        "温度",
                                                        "湿度",
                                                        "经度",
                                                        "纬度",]].concat(json_data.map((value, index) => {
                                                        return [
                                                            value["id"],
                                                            value["update_at"],
                                                            value["wendu"].split(",").map(value1 => {
                                                                if (value1 === "NULL") {
                                                                    return "无"
                                                                } else {
                                                                    return isNaN(parseFloat(value1)) ? 0 : parseFloat(value1).toFixed(1)
                                                                }
                                                            }).join(","),
                                                            value["shidu"].split(",").map(value1 => {
                                                                if (value1 === "NULL") {
                                                                    return "无"
                                                                } else {
                                                                    return isNaN(parseFloat(value1)) ? 0 : parseFloat(value1).toFixed(1)
                                                                }
                                                            }).join(","),
                                                            value["longitude"].replace("NULL", ""),
                                                            value["latitude"].replace("NULL", ""),
                                                        ];
                                                    })));
                                                    const wb = XLSX.utils.book_new();
                                                    XLSX.utils.book_append_sheet(wb, ws, "导出数据");
                                                    /* generate XLSX file and send to client */
                                                    XLSX.writeFile(wb, "[" + dayjs().format("YYYYMMDDHHmmss") + "]" + window.operate_data["machine_id"] + "导出信息(" + json_data.length + ")" + pdfwendu + pdfshidu + ".xlsx");
                                                    document.querySelector(".modal.fade.show").click();
                                                }
                                            })
                                        }}> 提 交
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body d-flex">
                                <div className="btn waves-effect waves-light btn-danger">温度</div>
                                <div className="btn waves-effect waves-light btn-light">平均:{this.state.wendupj}</div>
                                <div className="btn waves-effect waves-light btn-light">最大:{this.state.wenduzd}</div>
                                <div className="btn waves-effect waves-light btn-light">最小:{this.state.wenduzs}</div>
                                <div className="btn waves-effect waves-light btn-light">极差:{this.state.wendujc}</div>
                                <div className="btn waves-effect waves-light btn-light">标准差:{this.state.wendubzc}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body d-flex">
                                <div className="btn waves-effect waves-light btn-info">湿度</div>
                                <div className="btn waves-effect waves-light btn-light">平均:{this.state.shidupj}</div>
                                <div className="btn waves-effect waves-light btn-light">最大:{this.state.shiduzd}</div>
                                <div className="btn waves-effect waves-light btn-light">最小:{this.state.shiduzs}</div>
                                <div className="btn waves-effect waves-light btn-light">极差:{this.state.shidujc}</div>
                                <div className="btn waves-effect waves-light btn-light">标准差:{this.state.shidubzc}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                折线数据: {window.operate_data.name}({window.operate_data.machine_id})
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            {(window.operate_data["maxbaojingwendu"] == undefined || window.operate_data["maxbaojingwendu"] == null || window.operate_data["maxbaojingwendu"] == "" || window.operate_data["maxbaojingwendu"] == "NULL") ? null : (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                                if (value !== "NULL" && value !== "")
                                    return <div key={"maxbaojingwendu" + index} className="card-body analytics-info">
                                        <h4 className="card-title text-danger"><i
                                            className="fas fa-thermometer-half m-r-5"></i>{"温度" + (index + 1)}
                                        </h4>
                                        <div id={"basic-line-w" + index}
                                             style={{height: "400px", userSelect: "none", position: "relative"}}></div>
                                    </div>
                            })}
                            {(window.operate_data["maxbaojingshidu"] == undefined || window.operate_data["maxbaojingshidu"] == null || window.operate_data["maxbaojingshidu"] == "" || window.operate_data["maxbaojingshidu"] == "NULL") ? null : (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                                if (value !== "NULL" && value !== "")
                                    return <div key={"maxbaojingwendu" + index} className="card-body analytics-info">
                                        <h4 className="card-title text-info"><i
                                            className="fas fa-thermometer-half m-r-5"></i>{"湿度" + (index + 1)}
                                        </h4>
                                        <div id={"basic-line-s" + index}
                                             style={{height: "400px", userSelect: "none", position: "relative"}}></div>
                                    </div>
                            })}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className={"card" + (this.state.full2 ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                历史数据: {window.operate_data.name}({window.operate_data.machine_id})
                                {/*<div className="m-l-5 btn-group" id="dcbtn"></div>*/}
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full2 = !this.state.full2;
                                            this.setState({
                                                full2: this.state.full2
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="card">
                                        <div className="card-body d-flex">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                        <span className="input-group-text"><i
                                            className="fas fa-calendar-alt"></i></span>
                                                </div>
                                                <input type="text" className="form-control ptdatetime"
                                                       ref={input => this.time_input1 = input}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">至</span>
                                                </div>
                                                <input type="text" className="form-control ptdatetime2"
                                                       ref={input => this.time_input2 = input}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-dark" type="button" onClick={() => {
                                                        window.gsm_to_machine_detail_refresh(this.time_input1.value, this.time_input2.value);
                                                    }}>查询
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="card">
                                        <div className="card-body d-flex">
                                            <div class="btn waves-effect waves-light btn-dark">导出</div>
                                            {/*新导出 20200223*/}
                                            {/*<button className="btn waves-effect waves-light btn-light mr-1"*/}
                                            {/*        type='button' onClick={() => {*/}
                                            {/*    this.props.GsmXlsx(this.state.modestyle, 1, this.time_input1.value, this.time_input2.value);*/}
                                            {/*}}>*/}
                                            {/*    <i class="far fa-file-excel"></i> EXCEL*/}
                                            {/*</button>*/}
                                            <div className="m-l-5 btn-group" id="lbtn"></div>
                                            <div className="m-l-5 btn-group" id="dcbtn"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>采集时间
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备温度
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备湿度
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//车辆详细
class GsmCarDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            full2: false,
            wenduzd: "",
            wenduzs: "",
            wendupj: "",
            wendujc: "",
            wendubzc: "",
            shiduzd: "",
            shiduzs: "",
            shidupj: "",
            shidujc: "",
            shidubzc: "",
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                {/*<a onClick={() => {*/}
                {/*}} href="javascript:void(0)" data-toggle="modal" data-target=".timedevice">*/}
                {/*    <button>查询范围批量导出</button>*/}
                {/*</a>*/}
                <div className="modal fade timedevice" tabIndex="-1" role="dialog"
                     aria-labelledby="myModalLabel"
                     aria-hidden="true" style={{display: "none"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="recipient-name"
                                               className="control-label">导出日期:</label>
                                        <input type="datetime" className="timedeviceinput form-control"
                                               id="recipient-name"
                                               ref={input => this.xfje2_input = input}/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary waves-effect"
                                        data-dismiss="modal">取 消
                                </button>
                                <button type="button"
                                        className="btn btn-info waves-effect waves-light"
                                        onClick={() => {
                                            window.ajax({
                                                cache: true,
                                                type: "POST",
                                                url: "/machine/detail/export",
                                                data: {
                                                    t1: this.xfje2_input.value.split(" - ")[0],
                                                    t2: this.xfje2_input.value.split(" - ")[1],
                                                    i: window.operate_data["machine_id"],
                                                },
                                                error: function () {
                                                },
                                                fail: function () {
                                                },
                                                success: function (data) {
                                                    const json_data = JSON.parse(data);

                                                    const new_data = JSON.parse(data).reverse();
                                                    const wenduzd = [];
                                                    const wenduzs = [];
                                                    const wendupj = [];
                                                    const wendujc = [];
                                                    const wendubzc = [];
                                                    const shiduzd = [];
                                                    const shiduzs = [];
                                                    const shidupj = [];
                                                    const shidujc = [];
                                                    const shidubzc = [];

                                                    if (window.operate_data["minbaojingwendu"] !== "" && window.operate_data["minbaojingwendu"] !== "NULL" && window.operate_data["minbaojingwendu"] !== null && window.operate_data["minbaojingwendu"] !== undefined) {
                                                        for (let kk = 0; kk < (window.operate_data["minbaojingwendu"] === undefined ? "" : window.operate_data["minbaojingwendu"]).split(",").length; kk++) {
                                                            const wd_array = [];
                                                            const time_array = [];
                                                            flag:for (var i = 0; i < new_data.length; i++) {
                                                                wd_array.push(isNaN(parseFloat(new_data[i]["wendu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["wendu"].split(",")[kk]).toFixed(1));
                                                                time_array.push(new_data[i]["update_at"]);
                                                            }

                                                            if (wd_array.length > 0) {
                                                                wenduzd.push(wd_array.length > 0 ? Math.max.apply(null, wd_array).toFixed(1) : 0);
                                                                wenduzs.push(wd_array.length > 0 ? Math.min.apply(null, wd_array).toFixed(1) : 0);
                                                                wendupj.push(wd_array.length > 0 ? (wd_array.reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / wd_array.length).toFixed(1) : 0);
                                                                wendujc.push(wenduzd.length > 0 ? (parseFloat(wenduzd[wenduzd.length - 1]) - parseFloat(wenduzs[wenduzs.length - 1])).toFixed(1) : 0);
                                                                wendubzc.push(wd_array.length > 1 ? Math.sqrt(wd_array.map(function (x) {
                                                                    return parseFloat(x) - wendupj[wendupj.length - 1];
                                                                }).map(function (x) {
                                                                    return parseFloat(x) * parseFloat(x);
                                                                }).reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / (wd_array.length - 1)).toFixed(1) : 0);
                                                            }
                                                        }
                                                    }
                                                    if (window.operate_data["minbaojingshidu"] !== "" && window.operate_data["minbaojingshidu"] !== "NULL" && window.operate_data["minbaojingshidu"] !== null && window.operate_data["minbaojingshidu"] !== undefined) {
                                                        for (let kk = 0; kk < (window.operate_data["minbaojingshidu"] === undefined ? "" : window.operate_data["minbaojingshidu"]).split(",").length; kk++) {
                                                            const sd_array = [];
                                                            const time_array = [];
                                                            flag:for (var i = 0; i < new_data.length; i++) {
                                                                sd_array.push(isNaN(parseFloat(new_data[i]["shidu"].split(",")[kk])) ? 0 : parseFloat(new_data[i]["shidu"].split(",")[kk]).toFixed(1));
                                                                time_array.push(new_data[i]["update_at"]);
                                                            }


                                                            if (sd_array.length > 0) {
                                                                shiduzd.push(sd_array.length > 0 ? Math.max.apply(null, sd_array).toFixed(1) : 0);
                                                                shiduzs.push(sd_array.length > 0 ? Math.min.apply(null, sd_array).toFixed(1) : 0);
                                                                shidupj.push(sd_array.length > 0 ? (sd_array.reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / sd_array.length).toFixed(1) : 0);
                                                                shidujc.push(shiduzd.length > 0 ? (parseFloat(shiduzd[shiduzd.length - 1]) - parseFloat(shiduzs[shiduzs.length - 1])).toFixed(1) : 0);
                                                                shidubzc.push(sd_array.length > 1 ? Math.sqrt(sd_array.map(function (x) {
                                                                    return parseFloat(x) - shidupj[shidupj.length - 1];
                                                                }).map(function (x) {
                                                                    return parseFloat(x) * parseFloat(x);
                                                                }).reduce(function (x, y) {
                                                                    return parseFloat(x) + parseFloat(y);
                                                                }) / (sd_array.length - 1)).toFixed(1) : 0);
                                                            }
                                                        }
                                                    }

                                                    let pdfwendu, pdfshidu
                                                    if (wendupj.length == 0) {
                                                        pdfwendu = ''
                                                    } else if (isNaN(wendupj[0])) {
                                                        pdfshidu = ''
                                                    } else {
                                                        pdfwendu = "[温度：平均:" + wendupj.join(",") + " 最大:" + wenduzd.join(",") + " 最小:" + wenduzs.join(",") + " 极差:" + wendujc.join(",") + " 标准差:" + wendubzc.join(",") + "]"
                                                    }
                                                    if (shidupj.length == 0) {
                                                        pdfshidu = ''
                                                    } else if (isNaN(shidupj[0])) {
                                                        pdfshidu = ''
                                                    } else {
                                                        pdfshidu = "[湿度：平均:" + shidupj.join(",") + " 最大:" + shiduzd.join(",") + " 最小:" + shiduzs.join(",") + " 极差:" + shidujc.join(",") + " 标准差:" + shidubzc.join(",") + "]"
                                                    }

                                                    const ws = XLSX.utils.aoa_to_sheet([[
                                                        "序号",
                                                        "采集时间",
                                                        "温度",
                                                        "湿度",
                                                        "经度",
                                                        "纬度",]].concat(json_data.map((value, index) => {
                                                        return [
                                                            value["id"],
                                                            value["update_at"],
                                                            value["wendu"].split(",").map(value1 => {
                                                                if (value1 === "NULL") {
                                                                    return "无"
                                                                } else {
                                                                    return isNaN(parseFloat(value1)) ? 0 : parseFloat(value1).toFixed(1)
                                                                }
                                                            }).join(","),
                                                            value["shidu"].split(",").map(value1 => {
                                                                if (value1 === "NULL") {
                                                                    return "无"
                                                                } else {
                                                                    return isNaN(parseFloat(value1)) ? 0 : parseFloat(value1).toFixed(1)
                                                                }
                                                            }).join(","),
                                                            value["longitude"].replace("NULL", ""),
                                                            value["latitude"].replace("NULL", ""),
                                                        ];
                                                    })));
                                                    const wb = XLSX.utils.book_new();
                                                    XLSX.utils.book_append_sheet(wb, ws, "导出数据");
                                                    /* generate XLSX file and send to client */
                                                    XLSX.writeFile(wb, "[" + dayjs().format("YYYYMMDDHHmmss") + "]" + window.operate_data["machine_id"] + "导出信息(" + json_data.length + ")" + pdfwendu + pdfshidu + ".xlsx");
                                                    document.querySelector(".modal.fade.show").click();
                                                }
                                            })
                                        }}> 提 交
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body d-flex">
                                <div className="btn waves-effect waves-light btn-danger">温度</div>
                                <div className="btn waves-effect waves-light btn-light">平均:{this.state.wendupj}</div>
                                <div className="btn waves-effect waves-light btn-light">最大:{this.state.wenduzd}</div>
                                <div className="btn waves-effect waves-light btn-light">最小:{this.state.wenduzs}</div>
                                <div className="btn waves-effect waves-light btn-light">极差:{this.state.wendujc}</div>
                                <div className="btn waves-effect waves-light btn-light">标准差:{this.state.wendubzc}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body d-flex">
                                <div className="btn waves-effect waves-light btn-info">湿度</div>
                                <div className="btn waves-effect waves-light btn-light">平均:{this.state.shidupj}</div>
                                <div className="btn waves-effect waves-light btn-light">最大:{this.state.shiduzd}</div>
                                <div className="btn waves-effect waves-light btn-light">最小:{this.state.shiduzs}</div>
                                <div className="btn waves-effect waves-light btn-light">极差:{this.state.shidujc}</div>
                                <div className="btn waves-effect waves-light btn-light">标准差:{this.state.shidubzc}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12" style={{position: "relative"}}>
                        <div className="gsm-car-bdmap-controller"
                             style={{position: "absolute", zIndex: "10", left: "0", right: "0"}} align="center">
                            <input className="bdmap-ani" type="number" defaultValue={500}
                                   style={{display: "none"}}/>{/*<span>动画时间</span>*/}
                            <input className="bdmap-follow" type="checkbox" defaultChecked={true}
                                   style={{display: "none"}}/>{/*<span>画面跟随</span>*/}
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <input className="bdmap-play btn btn-dark btn-xs" type="button" value="播放" disabled/>
                                <input className="bdmap-pause btn btn-dark btn-xs" type="button" value="暂停" disabled/>
                                <input className="bdmap-reset btn btn-dark btn-xs" type="button" value="重置" disabled/>
                            </div>
                        </div>
                        <div className="card" style={{height: 500}} id="gsm-car-bdmap">
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                折线数据: {window.operate_data.name}({window.operate_data.machine_id})
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            {(window.operate_data["maxbaojingwendu"] == undefined || window.operate_data["maxbaojingwendu"] == null || window.operate_data["maxbaojingwendu"] == "" || window.operate_data["maxbaojingwendu"] == "NULL") ? null : (window.operate_data["maxbaojingwendu"] === undefined ? "" : window.operate_data["maxbaojingwendu"]).split(",").map((value, index) => {
                                if (value !== "NULL" && value !== "")
                                    return <div key={"maxbaojingwendu" + index} className="card-body analytics-info">
                                        <h4 className="card-title text-danger"><i
                                            className="fas fa-thermometer-half m-r-5"></i>{"温度" + (index + 1)}
                                        </h4>
                                        <div id={"basic-line-w" + index}
                                             style={{height: "400px", userSelect: "none", position: "relative"}}></div>
                                    </div>
                            })}
                            {(window.operate_data["maxbaojingshidu"] == undefined || window.operate_data["maxbaojingshidu"] == null || window.operate_data["maxbaojingshidu"] == "" || window.operate_data["maxbaojingshidu"] == "NULL") ? null : (window.operate_data["maxbaojingshidu"] === undefined ? "" : window.operate_data["maxbaojingshidu"]).split(",").map((value, index) => {
                                if (value !== "NULL" && value !== "")
                                    return <div key={"maxbaojingwendu" + index} className="card-body analytics-info">
                                        <h4 className="card-title text-info"><i
                                            className="fas fa-thermometer-half m-r-5"></i>{"湿度" + (index + 1)}
                                        </h4>
                                        <div id={"basic-line-s" + index}
                                             style={{height: "400px", userSelect: "none", position: "relative"}}></div>
                                    </div>
                            })}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className={"card" + (this.state.full2 ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                历史数据: {window.operate_data.name}({window.operate_data.machine_id})
                                {/*<div className="m-l-5 btn-group" id="dcbtn"></div>*/}
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full2 = !this.state.full2;
                                            this.setState({
                                                full2: this.state.full2
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="card">
                                        <div className="card-body d-flex">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                        <span className="input-group-text"><i
                                            className="fas fa-calendar-alt"></i></span>
                                                </div>
                                                <input type="text" className="form-control ptdatetime"
                                                       ref={input => this.time_input1 = input}/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">至</span>
                                                </div>
                                                <input type="text" className="form-control ptdatetime2"
                                                       ref={input => this.time_input2 = input}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-dark" type="button" onClick={() => {
                                                        window.gsm_to_car_detail_refresh(this.time_input1.value, this.time_input2.value);
                                                    }}>查询
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="card">
                                        <div className="card-body d-flex">
                                            <div class="btn waves-effect waves-light btn-dark">导出</div>
                                            {/*新导出 20200223*/}
                                            {/*<button className="btn waves-effect waves-light btn-light mr-1"*/}
                                            {/*        type='button' onClick={() => {*/}
                                            {/*    this.props.GsmXlsx(this.state.modestyle, 1, this.time_input1.value, this.time_input2.value);*/}
                                            {/*}}>*/}
                                            {/*    <i class="far fa-file-excel"></i> EXCEL*/}
                                            {/*</button>*/}
                                            <div className="m-l-5 btn-group" id="lbtn"></div>
                                            <div className="m-l-5 btn-group" id="dcbtn"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>采集时间
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备位置
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备温度
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备湿度
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

//设备添加
class GsmMachineAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            check: 1,
            id: "",
            supplier: [],
            classification: [],
            classification2: [],
            customer: [],
            file: "",
            full: false
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{this.state.new ? "新增设备" : "修改设备"}</h4>
                                <h6 className="card-subtitle"></h6>
                            </div>
                            <hr/>
                            <form className="form-horizontal">
                                <div className="card-body">
                                    <h4 className="card-title">基础信息</h4>
                                    <div className="form-group row">
                                        <label htmlFor="fname"
                                               className="col-sm-2 text-right control-label col-form-label"><i
                                            className="text-danger">*</i> 设备序列号</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="fname" placeholder=""
                                                   ref={input => this.xlh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">设备名称</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.mc_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">设备状态</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.zt_input = input}>
                                                <optgroup label="">
                                                    <option value="在线">在线</option>
                                                    {/*<option value="离线">离线</option>*/}
                                                    {/*<option value="报警">报警</option>*/}
                                                    <option value="停用">停用</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                        <label htmlFor="cono1"
                                               className="col-sm-2 text-right control-label col-form-label">故障报修</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.bx_input = input}>
                                                <optgroup label="">
                                                    <option value="正常">正常</option>
                                                    <option value="报修">报修</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">设备厂家</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.cj_input = input}>
                                                <optgroup label="">
                                                    {this.state.supplier}
                                                </optgroup>
                                            </select>
                                        </div>
                                        <label htmlFor="cono1"
                                               className="col-sm-2 text-right control-label col-form-label">设备分类</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.fl_input = input}>
                                                <optgroup label="">
                                                    {this.state.classification}
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="cono1"
                                               className="col-sm-2 text-right control-label col-form-label">设施分类</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.fl2_input = input}>
                                                <optgroup label="">
                                                    {this.state.classification2}
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">设备用户</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.cc_input = input}>
                                                <optgroup label="">
                                                    {this.state.customer}
                                                </optgroup>
                                            </select>
                                        </div>
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">对接方式</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.djfs_input = input}>
                                                <optgroup label="">
                                                    <option value="泽大仪器">泽大仪器</option>
                                                    <option value="温度114">温度114</option>
                                                    <option value="广州赛信">广州赛信</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警温度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdwd_input = input}/>

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgwd_input = input}/>
                                            </div>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警湿度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdsd_input = input}/>

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgsd_input = input}/>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警电话</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">短信间隔</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh_jg_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警温度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdwd2_input = input}/>

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgwd2_input = input}/>
                                            </div>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警湿度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdsd2_input = input}/>

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgsd2_input = input}/>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警电话</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh2_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警短信间隔</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh_jg2_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">规格型号</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.ggxh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">采集时间间隔</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.jg_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">校准报告</label>
                                        <div className="col-sm-3">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">上传</span>
                                                </div>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input"
                                                           id="inputGroupFile01"
                                                           ref={input => this.wj_input = input} onChange={
                                                        () => {
                                                            const el = this;
                                                            this.state.upload = true;
                                                            var xhr;
                                                            var fileObj = document.getElementById("inputGroupFile01").files[0];
                                                            var form = new FormData();
                                                            form["enctype"] = "multipart/form-data";
                                                            form["Access-Control-Allow-Origin"] = "*";
                                                            form.append("file", fileObj, fileObj.name);

                                                            xhr = new XMLHttpRequest();
                                                            xhr.open("post", window.file_server + "/upload", true);
                                                            xhr.onload = function (evt) {
                                                                var data = JSON.parse(evt.target.responseText);
                                                                el.setState(
                                                                    {
                                                                        file: data.path
                                                                    }
                                                                )
                                                                el.state.upload = false;
                                                            };
                                                            xhr.onerror = function (evt) {
                                                            };
                                                            xhr.send(form);
                                                        }
                                                    }/>
                                                    <label className="custom-file-label"
                                                           htmlFor="inputGroupFile01" style={{
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis"
                                                    }}>{this.state.file}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">校准报告到期时间</label>
                                        <div className="col-sm-3">
                                            <input type="date" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dq_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">是否报警</label>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio11" name="customRadio"
                                                           className="custom-control-input"
                                                           checked={this.state.check == 1}
                                                           onClick={() => {
                                                               this.setState({check: 1})
                                                           }} onChange={() => {
                                                    }}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio11" onClick={() => {
                                                        this.setState({check: 1})
                                                    }}>开启</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio22" name="customRadio"
                                                           className="custom-control-input"
                                                           checked={this.state.check == 0}
                                                           onClick={() => {
                                                               this.setState({check: 0})
                                                           }} onChange={() => {
                                                    }}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio22" onClick={() => {
                                                        this.setState({check: 0})
                                                    }}>关闭</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr/>
                                <div className="card-body">
                                    <h4 className="card-title">定位信息</h4>
                                    <div className="form-group row">
                                        <label htmlFor="com1"
                                               className="col-sm-2 text-right control-label col-form-label"></label>
                                        <div className="col-sm-8">
                                            <div id="allmap"></div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">经度</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lonname"
                                                   placeholder="" ref={input => this.wz_jd_input = input}/>
                                        </div>
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">纬度</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="latname"
                                                   placeholder="" ref={input => this.wz_wd_input = input}/>
                                        </div>
                                    </div>

                                </div>
                                <hr/>
                                <div className="card-body row">
                                    <div className="col-sm-10">
                                        <div className="form-group m-b-0 text-right ">
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light" onClick={
                                                () => {
                                                    window.ajax({
                                                        cache: true,
                                                        type: "POST",
                                                        url: (this.state.new ? "/machine/add" : "/machine/revise"),
                                                        data: {
                                                            name: this.mc_input.value,
                                                            xlh: this.xlh_input.value,
                                                            zt: this.zt_input.value,
                                                            bx: this.bx_input.value,
                                                            cj: this.cj_input.value,
                                                            cc: this.cc_input.value,
                                                            fl: this.fl_input.value,
                                                            fl2: this.fl2_input.value,
                                                            zgwd: this.zgwd_input.value,
                                                            zdwd: this.zdwd_input.value,
                                                            zgsd: this.zgsd_input.value,
                                                            zdsd: this.zdsd_input.value,
                                                            zgwd2: this.zgwd2_input.value,
                                                            zdwd2: this.zdwd2_input.value,
                                                            zgsd2: this.zgsd2_input.value,
                                                            zdsd2: this.zdsd2_input.value,
                                                            djfs: this.djfs_input.value,
                                                            dh: this.dh_input.value,
                                                            dh2: this.dh2_input.value,
                                                            jg: this.jg_input.value,
                                                            bg: this.state.file,
                                                            dq: this.dq_input.value,
                                                            bj: this.state.check,
                                                            wz_jd: this.wz_jd_input.value,
                                                            wz_wd: this.wz_wd_input.value,
                                                            ggxh: this.ggxh_input.value,
                                                            dh_jg: this.dh_jg_input.value,
                                                            dh_jg2: this.dh_jg2_input.value,
                                                            i: this.state.id,
                                                        },
                                                        error: function () {
                                                        },
                                                        fail: function () {
                                                        },
                                                        success: function (data) {
                                                            if (data === "\"success\"") {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                // document.querySelector(".modal.fade.show").click();
                                                                window.gsm_history_back();
                                                            } else {
                                                                window.toastr.error('设备重复', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                            }
                                                        }
                                                    })
                                                }
                                            }>提交
                                            </button>
                                            <button type="submit"
                                                    className="btn btn-dark waves-effect waves-light" onClick={
                                                () => {
                                                    window.gsm_history_back();
                                                }
                                            }>取消
                                            </button>
                                        </div>

                                    </div>

                                    <div className="col-sm-2"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设备添加
class GsmCarAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            check: 1,
            id: "",
            supplier: [],
            classification: [],
            classification2: [],
            customer: [],
            file: "",
            full: false
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{this.state.new ? "新增设备" : "修改设备"}</h4>
                                <h6 className="card-subtitle"></h6>
                            </div>
                            <hr/>
                            <form className="form-horizontal">
                                <div className="card-body">
                                    <h4 className="card-title">基础信息</h4>
                                    <div className="form-group row">
                                        <label htmlFor="fname"
                                               className="col-sm-2 text-right control-label col-form-label"><i
                                            className="text-danger">*</i> 设备序列号</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="fname" placeholder=""
                                                   ref={input => this.xlh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">设备名称</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.mc_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">设备状态</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.zt_input = input}>
                                                <optgroup label="">
                                                    <option value="在线">在线</option>
                                                    {/*<option value="离线">离线</option>*/}
                                                    {/*<option value="报警">报警</option>*/}
                                                    <option value="停用">停用</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                        <label htmlFor="cono1"
                                               className="col-sm-2 text-right control-label col-form-label">故障报修</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.bx_input = input}>
                                                <optgroup label="">
                                                    <option value="正常">正常</option>
                                                    <option value="报修">报修</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">设备厂家</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.cj_input = input}>
                                                <optgroup label="">
                                                    {this.state.supplier}
                                                </optgroup>
                                            </select>
                                        </div>
                                        <label htmlFor="cono1"
                                               className="col-sm-2 text-right control-label col-form-label">设备分类</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.fl_input = input}>
                                                <optgroup label="">
                                                    {this.state.classification}
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="cono1"
                                               className="col-sm-2 text-right control-label col-form-label">设施分类</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.fl2_input = input}>
                                                <optgroup label="">
                                                    {this.state.classification2}
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">设备用户</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.cc_input = input}>
                                                <optgroup label="">
                                                    {this.state.customer}
                                                </optgroup>
                                            </select>
                                        </div>
                                        <label htmlFor="email1"
                                               className="col-sm-2 text-right control-label col-form-label">对接方式</label>
                                        <div className="col-sm-3">
                                            <select className="select2 form-control custom-select"
                                                    style={{width: "100%", height: "36px"}}
                                                    ref={input => this.djfs_input = input}>
                                                <optgroup label="">
                                                    <option value="泽大仪器">泽大仪器</option>
                                                    <option value="温度114">温度114</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警温度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdwd_input = input}/>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgwd_input = input}/>
                                            </div>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警湿度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdsd_input = input}/>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgsd_input = input}/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">报警电话</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">短信间隔</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh_jg_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警温度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdwd2_input = input}/>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgwd2_input = input}/>
                                            </div>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警湿度</label>
                                        <div className="col-sm-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最低</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zdsd2_input = input}/>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">最高</span>
                                                </div>
                                                <input type="text" className="form-control" placeholder=""
                                                       aria-label=""
                                                       aria-describedby="" ref={input => this.zgsd2_input = input}/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警电话</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh2_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">预警短信间隔</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dh_jg2_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">规格型号</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.ggxh_input = input}/>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">采集时间间隔</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.jg_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">校准报告</label>
                                        <div className="col-sm-3">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">上传</span>
                                                </div>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input"
                                                           id="inputGroupFile01"
                                                           ref={input => this.wj_input = input} onChange={
                                                        () => {
                                                            const el = this;
                                                            this.state.upload = true;
                                                            var xhr;
                                                            var fileObj = document.getElementById("inputGroupFile01").files[0];
                                                            var form = new FormData();
                                                            form["enctype"] = "multipart/form-data";
                                                            form["Access-Control-Allow-Origin"] = "*";
                                                            form.append("file", fileObj, fileObj.name);

                                                            xhr = new XMLHttpRequest();
                                                            xhr.open("post", window.file_server + "/upload", true);
                                                            xhr.onload = function (evt) {
                                                                var data = JSON.parse(evt.target.responseText);
                                                                el.setState(
                                                                    {
                                                                        file: data.path
                                                                    }
                                                                )
                                                                el.state.upload = false;
                                                            };
                                                            xhr.onerror = function (evt) {
                                                            };
                                                            xhr.send(form);
                                                        }
                                                    }/>
                                                    <label className="custom-file-label"
                                                           htmlFor="inputGroupFile01" style={{
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow: "ellipsis"
                                                    }}>{this.state.file}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">校准报告到期时间</label>
                                        <div className="col-sm-3">
                                            <input type="date" className="form-control" id="lname" placeholder=""
                                                   ref={input => this.dq_input = input}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="lname"
                                               className="col-sm-2 text-right control-label col-form-label">是否报警</label>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio11" name="customRadio"
                                                           className="custom-control-input"
                                                           checked={this.state.check == 1}
                                                           onClick={() => {
                                                               this.setState({check: 1})
                                                           }} onChange={() => {
                                                    }}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio11" onClick={() => {
                                                        this.setState({check: 1})
                                                    }}>开启</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio22" name="customRadio"
                                                           className="custom-control-input"
                                                           checked={this.state.check == 0}
                                                           onClick={() => {
                                                               this.setState({check: 0})
                                                           }} onChange={() => {
                                                    }}/>
                                                    <label className="custom-control-label"
                                                           htmlFor="customRadio22" onClick={() => {
                                                        this.setState({check: 0})
                                                    }}>关闭</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr/>
                                <div className="card-body">
                                    <h4 className="card-title">定位信息</h4>
                                    <div className="form-group row">
                                        <label htmlFor="com1"
                                               className="col-sm-2 text-right control-label col-form-label"></label>
                                        <div className="col-sm-8">
                                            <div id="allmap"></div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">经度</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname"
                                                   placeholder="" ref={input => this.wz_jd_input = input}/>
                                        </div>
                                        <label
                                            className="col-sm-2 text-right control-label col-form-label">纬度</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="lname"
                                                   placeholder="" ref={input => this.wz_wd_input = input}/>
                                        </div>
                                    </div>

                                </div>
                                <hr/>
                                <div className="card-body row">
                                    <div className="col-sm-10">
                                        <div className="form-group m-b-0 text-right ">
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light" onClick={
                                                () => {
                                                    window.ajax({
                                                        cache: true,
                                                        type: "POST",
                                                        url: (this.state.new ? "/car/add" : "/car/revise"),
                                                        data: {
                                                            name: this.mc_input.value,
                                                            xlh: this.xlh_input.value,
                                                            zt: this.zt_input.value,
                                                            bx: this.bx_input.value,
                                                            cj: this.cj_input.value,
                                                            cc: this.cc_input.value,
                                                            fl: this.fl_input.value,
                                                            fl2: this.fl2_input.value,
                                                            zgwd: this.zgwd_input.value,
                                                            zdwd: this.zdwd_input.value,
                                                            zgsd: this.zgsd_input.value,
                                                            zdsd: this.zdsd_input.value,
                                                            djfs: this.djfs_input.value,
                                                            zgwd2: this.zgwd2_input.value,
                                                            zdwd2: this.zdwd2_input.value,
                                                            zgsd2: this.zgsd2_input.value,
                                                            zdsd2: this.zdsd2_input.value,
                                                            dh: this.dh_input.value,
                                                            dh2: this.dh2_input.value,
                                                            jg: this.jg_input.value,
                                                            bg: this.state.file,
                                                            dq: this.dq_input.value,
                                                            bj: this.state.check,
                                                            wz_jd: this.wz_jd_input.value,
                                                            wz_wd: this.wz_wd_input.value,
                                                            ggxh: this.ggxh_input.value,
                                                            dh_jg: this.dh_jg_input.value,
                                                            dh_jg2: this.dh_jg2_input.value,
                                                            i: this.state.id,
                                                        },
                                                        error: function () {
                                                        },
                                                        fail: function () {
                                                        },
                                                        success: function (data) {
                                                            if (data === "\"success\"") {
                                                                window.toastr.success('提交成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                // document.querySelector(".modal.fade.show").click();
                                                                window.gsm_history_back();
                                                            } else {
                                                                window.toastr.error('设备重复', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                            }
                                                        }
                                                    })
                                                }
                                            }>提交
                                            </button>
                                            <button type="submit"
                                                    className="btn btn-dark waves-effect waves-light" onClick={
                                                () => {
                                                    window.gsm_history_back();
                                                }
                                            }>取消
                                            </button>
                                        </div>

                                    </div>

                                    <div className="col-sm-2"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设备分类
class GsmMachineClassification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            parent: "",
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"cardx" + (this.state.full ? " card-fullscreen" : "")}>
                            {/*<div className="card-header">
                                设备分类管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>*/}
                            <div className="card-body">
                                <div className="myadmin-dd-empty" id="nestable">
                                    <ol className="dd-list">
                                        <li className="dd-item dd3-item" gsm-id="root">
                                            <div className="dd-handle dd3-handle d-none"></div>
                                            <div className="dd3-content d-none">
                                                设备分类管理
                                                <div className="btn-group btn-group-sm h-link" role="group">
                                                </div>
                                            </div>
                                            {/*<ol></ol>*/}
                                            <div className="row"></div>
                                        </li>
                                    </ol>
                                </div>
                                <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel"
                                     aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">设备名称:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.mc_input = input}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">面积:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.mj_input = input}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">体积:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.tj_input = input}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">长宽高:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.ckg_input = input}/>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-info waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: (this.state.new ? "/machine/classification/add" : "/machine/classification/revise"),
                                                                data: {
                                                                    name: this.mc_input.value,
                                                                    mj: this.mj_input.value,
                                                                    tj: this.tj_input.value,
                                                                    ckg: this.ckg_input.value,
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_machineclassification();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">确定删除该分类吗？</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: "/machine/classification/delete",
                                                                data: {
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('删除成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_machineclassification();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设施分类管理
class GsmMachineClassification2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            parent: "",
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"cardx" + (this.state.full ? " card-fullscreen" : "")}>
                            {/*<div className="card-header">
                                设备分类管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>*/}
                            <div className="card-body">
                                <div className="myadmin-dd-empty" id="nestable">
                                    <ol className="dd-list">
                                        <li className="dd-item dd3-item" gsm-id="root">
                                            <div className="dd-handle dd3-handle d-none"></div>
                                            <div className="dd3-content d-none">
                                                设施分类管理
                                                <div className="btn-group btn-group-sm h-link" role="group">
                                                </div>
                                            </div>
                                            {/*<ol></ol>*/}
                                            <div className="row"></div>
                                        </li>
                                    </ol>
                                </div>
                                <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel"
                                     aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">设施名称:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.mc_input = input}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">报修:</label>
                                                        <select className="select2 form-control custom-select"
                                                                style={{width: "100%", height: "36px"}}
                                                                ref={input => this.bx_input = input}>
                                                            <optgroup label="">
                                                                <option value="正常">正常</option>
                                                                <option value="报修">报修</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">面积:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.mj_input = input}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">体积:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.tj_input = input}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="recipient-name"
                                                               className="control-label">长宽高:</label>
                                                        <input type="text" className="form-control" id="recipient-name"
                                                               ref={input => this.ckg_input = input}/>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-info waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: (this.state.new ? "/machine2/classification/add" : "/machine2/classification/revise"),
                                                                data: {
                                                                    name: this.mc_input.value,
                                                                    mj: this.mj_input.value,
                                                                    tj: this.tj_input.value,
                                                                    ckg: this.ckg_input.value,
                                                                    bx: this.bx_input.value,
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_machineclassification2();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">确定删除该分类吗？</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: "/machine2/classification/delete",
                                                                data: {
                                                                    i: this.state.id,
                                                                    parent: this.state.parent,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('删除成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_machineclassification2();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade chakanbeizhu" tabIndex="-1" role="dialog"
                                     aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-body">

                                                <div className="card">
                                                    <div className="card-header bg-info">
                                                        <h4 className="m-b-0 text-white">查看报修</h4>
                                                    </div>
                                                    <form>
                                                        <div className="form-body">
                                                            <div className="card-body">

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5" readOnly></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="form-actions">
                                                                <div className="card-body text-right ">
                                                                    <button type="button" className="btn btn-secondary"
                                                                            data-dismiss="modal">取 消
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade beizhu" tabIndex="-1" role="dialog"
                                     aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-body">

                                                <div className="card">
                                                    <div className="card-header bg-info">
                                                        <h4 className="m-b-0 text-white">填写报修</h4>
                                                    </div>
                                                    <form>
                                                        <div className="form-body">
                                                            <div className="card-body">

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5"
                                                                                  ref={input => this.czb_input = input}></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="form-actions">
                                                                <div className="card-body text-right ">
                                                                    <button type="button" className="btn btn-info"
                                                                            onClick={
                                                                                () => {
                                                                                    window.ajax({
                                                                                            cache: true,
                                                                                            type: "POST",
                                                                                            url: "/machine2/operate",
                                                                                            data: {
                                                                                                i: window.operate_id,
                                                                                                t: this.czb_input.value
                                                                                            },
                                                                                            error: function () {
                                                                                            },
                                                                                            fail: function () {
                                                                                            },
                                                                                            success: function (response) {
                                                                                                window.toastr.success('处理成功', '消息', {
                                                                                                    "showMethod": "slideDown",
                                                                                                    "hideMethod": "slideUp",
                                                                                                    timeOut: 2000,
                                                                                                    positionClass: 'toast-top-center'
                                                                                                });
                                                                                                document.querySelector(".modal.fade.show").click();
                                                                                                window.gsm_to_machineclassification2();
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }><i
                                                                        className="fa fa-check"></i> 提 交
                                                                    </button>
                                                                    <button type="button" className="btn btn-secondary"
                                                                            data-dismiss="modal">取 消
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//设备厂家
class GsmMachineSupplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                设备厂家管理 {(window.user_permission.indexOf("[AddMachineSupplier]") != -1) ?
                                <a href="javascript:void(0)"
                                   className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                                   data-toggle="modal" data-target=".adddevice" onClick={() => {
                                    this.state.new = true
                                }}><i className="ti-plus m-r-5"></i>新增设备厂家</a> : null}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" style={{width: "0px"}}>设备厂家名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备厂家状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备厂家联系人
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备厂家联系电话
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备厂家联系地址
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>绑定设备数量
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>绑定用户数量
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>添加时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tfoot>
                                                    <tr role="row">
                                                        <th>序号</th>
                                                        <th>设备厂家名称</th>
                                                        <th>设备厂家状态</th>
                                                        <th>设备厂家联系人</th>
                                                        <th>设备厂家联系电话</th>
                                                        <th>设备厂家联系地址</th>
                                                        <th>绑定设备数量</th>
                                                        <th>绑定用户数量</th>
                                                        <th>添加时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                             aria-labelledby="myModalLabel"
                             aria-hidden="true" style={{display: "none"}}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name"
                                                       className="control-label">厂商名称:</label>
                                                <input type="text" className="form-control" id="recipient-name"
                                                       ref={input => this.mc_input = input}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name"
                                                       className="control-label">状态:</label>
                                                <select className="select2 form-control custom-select"
                                                        style={{width: "100%", height: "36px"}}
                                                        ref={input => this.zt_input = input}>
                                                    <optgroup label="">
                                                        <option value="正常">正常</option>
                                                        <option value="停用">停用</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name"
                                                       className="control-label">联系人:</label>
                                                <input type="text" className="form-control" id="recipient-name"
                                                       ref={input => this.lxr_input = input}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name"
                                                       className="control-label">联系电话:</label>
                                                <input type="text" className="form-control" id="recipient-name"
                                                       ref={input => this.lxdh_input = input}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="recipient-name"
                                                       className="control-label">联系地址:</label>
                                                <input type="text" className="form-control" id="recipient-name"
                                                       ref={input => this.lxdz_input = input}/>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary waves-effect"
                                                data-dismiss="modal">取 消
                                        </button>
                                        <button type="button"
                                                className="btn btn-info waves-effect waves-light"
                                                onClick={() => {
                                                    window.ajax({
                                                        cache: true,
                                                        type: "POST",
                                                        url: (this.state.new ? "/machine/supplier/add" : "/machine/supplier/revise"),
                                                        data: {
                                                            name: this.mc_input.value,
                                                            zt: this.zt_input.value,
                                                            lxr: this.lxr_input.value,
                                                            lxdh: this.lxdh_input.value,
                                                            lxdz: this.lxdz_input.value,
                                                            i: this.state.id,
                                                        },
                                                        error: function () {
                                                        },
                                                        fail: function () {
                                                        },
                                                        success: function (data) {
                                                            window.toastr.success('提交成功', '消息', {
                                                                "showMethod": "slideDown",
                                                                "hideMethod": "slideUp",
                                                                timeOut: 2000,
                                                                positionClass: 'toast-top-center'
                                                            });
                                                            document.querySelector(".modal.fade.show").click();
                                                            window.gsm_to_machinesupplier();
                                                        }
                                                    })
                                                }}> 提 交
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                             aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">确定删除该厂商吗？</h4>
                                        <button type="button" className="close" data-dismiss="modal"
                                                aria-hidden="true">×
                                        </button>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary waves-effect"
                                                data-dismiss="modal">取 消
                                        </button>
                                        <button type="button"
                                                className="btn btn-danger waves-effect waves-light"
                                                onClick={() => {
                                                    window.ajax({
                                                        cache: true,
                                                        type: "POST",
                                                        url: "/machine/supplier/delete",
                                                        data: {
                                                            i: this.state.id
                                                        },
                                                        error: function () {
                                                        },
                                                        fail: function () {
                                                        },
                                                        success: function (data) {
                                                            window.toastr.success('删除成功', '消息', {
                                                                "showMethod": "slideDown",
                                                                "hideMethod": "slideUp",
                                                                timeOut: 2000,
                                                                positionClass: 'toast-top-center'
                                                            });
                                                            document.querySelector(".modal.fade.show").click();
                                                            window.gsm_to_machinesupplier();
                                                        }
                                                    })
                                                }}> 提 交
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export {
    GsmMachine,
    GsmCar,
    GsmMachineDetail,
    GsmCarDetail,
    GsmMachineAdd,
    GsmCarAdd,
    GsmMachineClassification,
    GsmMachineClassification2,
    GsmMachineSupplier
}
