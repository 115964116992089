import React from "react";
import "./boli/css/style.min.css";
import "./boli/css/pt.css";
import "./javascript/base/serialize.js";
import "./javascript/base/ajax.js";
import T from "toastr/build/toastr.min.js";
import "toastr/build/toastr.min.css";
// import "popper.js";
// import "bootstrap";
// import "imagesloaded/imagesloaded.pkgd.min"
// import "./boli/js/imagesloaded.pkgd.min.js";
import $ from 'jquery';


export default React.memo((props) => {


    const iPWD = React.useRef(null);
    const tFunc = {
        load: () => {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/login",
                error: function () {
                    window.toastr.error('提示', '登陆失败', {
                        "positionClass": "toast-top-center",
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000
                    });
                    // window.gsm_hide_progress();
                    // window.gsm_show_toast("toast-error", "登陆失败", 3000);
                },
                fail: function () {
                    window.toastr.error('提示', '登陆失败', {
                        "positionClass": "toast-top-center",
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000
                    });
                    // window.gsm_hide_progress();
                    // window.gsm_show_toast("toast-error", "登陆失败", 3000);
                },
                success: function (data) {
                    const data_json = JSON.parse(data);
                    if (data_json.page === "main") {
                        props.LLFunc("main");
                    }
                    $(".preloader").fadeOut();
                }
            });
        },
        login: () => {
            if (document.getElementById("login_account").value == "" || document.getElementById("login_password").value == "") {
                // window.gsm_show_toast("toast-error", "账号或密码不能为空", 3000);
                window.toastr.info('提示', '账号或密码不能为空', {
                    "positionClass": "toast-top-center",
                    "showMethod": "slideDown",
                    "hideMethod": "slideUp",
                    timeOut: 2000
                });
            } else {
                // window.gsm_show_progress();
                window.ajax({
                    cache: true,
                    type: "POST",
                    url: "/check",
                    data: global.serialize(document.getElementById("loginform").getElementsByTagName("form")[0]),
                    error: function () {
                        window.toastr.error('提示', '登陆失败', {
                            "positionClass": "toast-top-center",
                            "showMethod": "slideDown",
                            "hideMethod": "slideUp",
                            timeOut: 2000
                        });
                        // window.gsm_hide_progress();
                        // window.gsm_show_toast("toast-error", "登陆失败", 3000);
                    },
                    fail: function () {
                        window.toastr.error('提示', '登陆失败', {
                            "positionClass": "toast-top-center",
                            "showMethod": "slideDown",
                            "hideMethod": "slideUp",
                            timeOut: 2000
                        });
                        // window.gsm_hide_progress();
                        // window.gsm_show_toast("toast-error", "登陆失败", 3000);
                    },
                    success: function (data) {
                        if (data === "success") {
                            props.LLFunc("main");
                        } else {
                            window.toastr.error('提示', '账号密码错误', {
                                "positionClass": "toast-top-center",
                                "showMethod": "slideDown",
                                "hideMethod": "slideUp",
                                timeOut: 2000
                            });
                        }
                    }
                });
            }
        }
    };

    React.useEffect(() => {
        window.toastr = T;
        tFunc.load();
    }, []);

    React.useLayoutEffect(() => {
        document.body.className = "light ts-theme-light ofh";
        $(".ts-shapes-canvas .ts-background-image").each(function () {
            // console.log('456');
            var $this = $(this);
            $this.css({
                "animation-duration": (Math.floor(Math.random() * 10) + 5) + "s"
            });
            $this.wrap('<div class="ts-shape"></div>');
            if ($this.attr('data-bg-opacity')) {
                $this.css("opacity", $this.attr('data-bg-opacity'));
            }
        });
        //Background
        $("[data-bg-color], [data-bg-image], [data-bg-particles]").each(function () {
            var $this = $(this);
            // console.log('789');
            if ($this.hasClass("ts-separate-bg-element")) {
                $this.append('<div class="ts-background">');

                // Background Color

                if ($("[data-bg-color]")) {
                    $this.find(".ts-background").css("background-color", $this.attr("data-bg-color"));
                }

                // Particles

                if ($this.attr("data-bg-particles-line-color") || $this.attr("data-bg-particles-dot-color")) {
                    $this.find(".ts-background").append('<div class="ts-background-particles">');
                    $(".ts-background-particles").each(function () {
                        var lineColor = $this.attr("data-bg-particles-line-color");
                        var dotColor = $this.attr("data-bg-particles-dot-color");
                        var parallax = $this.attr("data-bg-particles-parallax");
                        $(this).particleground({
                            density: 15000,
                            lineWidth: 0.2,
                            lineColor: lineColor,
                            dotColor: dotColor,
                            parallax: parallax,
                            proximity: 200
                        });
                    });
                }

                // Background Image

                if ($this.attr("data-bg-image") !== undefined) {
                    $this.find(".ts-background").append('<div class="ts-background-image">');
                    $this.find(".ts-background-image").css("background-image", "url(" + $this.attr("data-bg-image") + ")");
                    $this.find(".ts-background-image").css("background-size", $this.attr("data-bg-size"));
                    $this.find(".ts-background-image").css("background-position", $this.attr("data-bg-position"));
                    $this.find(".ts-background-image").css("opacity", $this.attr("data-bg-image-opacity"));

                    $this.find(".ts-background-image").css("background-size", $this.attr("data-bg-size"));
                    $this.find(".ts-background-image").css("background-repeat", $this.attr("data-bg-repeat"));
                    $this.find(".ts-background-image").css("background-position", $this.attr("data-bg-position"));
                    $this.find(".ts-background-image").css("background-blend-mode", $this.attr("data-bg-blend-mode"));
                }

                // Parallax effect

                if ($this.attr("data-bg-parallax") !== undefined) {
                    $this.find(".ts-background-image").addClass("ts-parallax-element");
                }
            } else {

                if ($this.attr("data-bg-color") !== undefined) {
                    $this.css("background-color", $this.attr("data-bg-color"));
                    if ($this.hasClass("btn")) {
                        $this.css("border-color", $this.attr("data-bg-color"));
                    }
                }

                if ($this.attr("data-bg-image") !== undefined) {
                    $this.css("background-image", "url(" + $this.attr("data-bg-image") + ")");

                    $this.css("background-size", $this.attr("data-bg-size"));
                    $this.css("background-repeat", $this.attr("data-bg-repeat"));
                    $this.css("background-position", $this.attr("data-bg-position"));
                    $this.css("background-blend-mode", $this.attr("data-bg-blend-mode"));
                }

            }
        });
        // $('[data-toggle="tooltip"]').tooltip();
    }, []);

    return <div id="login-page-id">

        <div className="preloader">
            <div className="lds-ripple">
                <div className="lds-pos"></div>
                <div className="lds-pos"></div>
            </div>
        </div>
        {/*https://p.upyun.com/demo/tmp/AwLVK6ab.png*/}
        <div className='main-wrapper'>

            <div className='auth-wrapper d-flex no-block justify-content-center align-items-center'
                 style={{background: 'url("/boli/images/big/auth-bg-bk.png") no-repeat center center'}}>
                <div className='b-r-15 login-card auth-box'>
                    <div id='loginform'>
                        <div className='logo'><span className='db'><img src='https://p.upyun.com/demo/tmp/IQxBaMEe.png'
                                                                        style={{width: "90%"}}/></span>
                            <h5 className='font-medium m-b-20 m-t-40' id='company'></h5></div>
                        <div className='row'>
                            <div className='col-12'>
                                <form className='form-horizontal m-t-40' id='loginform'>
                                    <div className='input-group mb-3'>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text' id='basic-addon1' style={{borderTopLeftRadius:'500px',borderBottomLeftRadius:'500px'}}>
                                                <i className='ti-user'></i>
                                            </span>
                                        </div>
                                        <input
                                            style={{borderTopRightRadius:'500px',borderBottomRightRadius:'500px'}}
                                            type='text' autoComplete='off' className='form-control form-control-lg'
                                               placeholder='账户' aria-label='Username' aria-describedby='basic-addon1'
                                               id='login_account' name='account' onKeyDown={(e) => {
                                            //监听回车键，回车键的键值为13
                                            if (e.keyCode === 13) {
                                                iPWD.current.focus();
                                            }
                                        }}/>
                                    </div>
                                    <div className='input-group mb-3'>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text' id='basic-addon2' style={{borderTopLeftRadius:'500px',borderBottomLeftRadius:'500px'}}>
                                                <i className='ti-lock'></i>
                                            </span>
                                        </div>
                                        <input
                                            style={{borderTopRightRadius:'500px',borderBottomRightRadius:'500px'}}
                                            type='password' autoComplete='off' ref={iPWD}
                                               className='form-control form-control-lg'
                                               placeholder='密码' aria-label='Password' aria-describedby='basic-addon1'
                                               id='login_password' name='password' onKeyDown={(e) => {
                                            //监听回车键，回车键的键值为13
                                            if (e.keyCode === 13) {
                                                tFunc.login();
                                            }
                                        }}/>
                                    </div>
                                    <div className='form-group text-center'>
                                        <div className='col-xs-12 p-b-20'>
                                            <div className='btn btn-block btn-lg btn-info' style={{borderRadius:'500px'}} onClick={tFunc.login}>登 录
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group m-b-0 m-t-30'>
                                        <div className='col-sm-12 text-center nomo'>
                                            <h6>
                                                <small id='version'>版本：2.75</small>
                                            </h6>
                                        </div>
                                        <div className='col-sm-12 text-center nomo op3'>
                                            <h6>
                                                <small>Power By：Boli.</small>
                                            </h6>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/*<div className='ts-background ts-shapes-canvas position-fixed ts-separate-bg-element' data-bg-color='#fff'>*/}
        {/*    <div className='ts-background-image ts-animate ts-scale' data-bg-image='./boli/images/bg/14.png'*/}
        {/*         data-bg-opacity='.1'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-scale' data-bg-image='./boli/images/bg/12.png'*/}
        {/*         data-bg-opacity='.3'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-scale' data-bg-image='./boli/images/bg/11.png'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-bounce2' data-bg-image='./boli/images/bg/10.png'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-scale2' data-bg-image='./boli/images/bg/8.png'*/}
        {/*         data-bg-opacity='.8'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-bounce' data-bg-image='./boli/images/bg/6.png'*/}
        {/*         data-bg-opacity='.8'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-bounce' data-bg-image='./boli/images/bg/4.png'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-bounce2' data-bg-image='./boli/images/bg/2.png'></div>*/}
        {/*    <div className='ts-background-image ts-animate ts-bounce' data-bg-image='./boli/images/bg/1.png'*/}
        {/*         data-bg-opacity='.8'></div>*/}
        {/*</div>*/}
        {/*new bg video*/}
        <div
            style={{
                position: 'absolute',
                zIndex: '-1',
                top: '0px',
                left: '0px',
                bottom: '0px',
                right: '0px',
                overflow: 'hidden',
                backgroundSize: 'cover',
                backgroundColor: 'transparent',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 50%',
                backgroundImage: 'none'
            }}>
            <video
                style={{
                    margin: 'auto',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    visibility: 'visible',
                    opacity: '1',
                    width: 'auto',
                    height: '200vh'
                }}
                autoPlay loop muted>
                <source src="https://su.alletong.com/cdn/v7.mp4" type="video/mp4"/>
            </video>
        </div>
        {/*new bg video*/}
    </div>
        ;
});
