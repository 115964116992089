import React from "react";

class GsmMachineDetail2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: true,
            id: "",
            full: false,
            full2: false,
            wenduzd: "",
            wenduzs: "",
            wendupj: "",
            wendujc: "",
            wendubzc: "",
            shiduzd: "",
            shiduzs: "",
            shidupj: "",
            shidujc: "",
            shidubzc: "",
            info: {},
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body d-flex">
                                <div className="btn waves-effect waves-light btn-danger">温度</div>
                                <div className="btn waves-effect waves-light btn-light">平均:{this.state.wendupj}</div>
                                <div className="btn waves-effect waves-light btn-light">最大:{this.state.wenduzd}</div>
                                <div className="btn waves-effect waves-light btn-light">最小:{this.state.wenduzs}</div>
                                <div className="btn waves-effect waves-light btn-light">极差:{this.state.wendujc}</div>
                                <div className="btn waves-effect waves-light btn-light">标准差:{this.state.wendubzc}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body d-flex">
                                <div className="btn waves-effect waves-light btn-info">湿度</div>
                                <div className="btn waves-effect waves-light btn-light">平均:{this.state.shidupj}</div>
                                <div className="btn waves-effect waves-light btn-light">最大:{this.state.shiduzd}</div>
                                <div className="btn waves-effect waves-light btn-light">最小:{this.state.shiduzs}</div>
                                <div className="btn waves-effect waves-light btn-light">极差:{this.state.shidujc}</div>
                                <div className="btn waves-effect waves-light btn-light">标准差:{this.state.shidubzc}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                折线数据: {this.state.info.name}({this.state.info.machine_id})
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            {(this.state.info["maxbaojingwendu"] == undefined || this.state.info["maxbaojingwendu"] == null || this.state.info["maxbaojingwendu"] == "" || this.state.info["maxbaojingwendu"] == "NULL") ? null : this.state.info["maxbaojingwendu"].split(",").map((value, index) => {
                                if (value !== "NULL" && value !== "")
                                    return <div key={"maxbaojingwendu" + index} className="card-body analytics-info">
                                        <h4 className="card-title text-danger"><i
                                            className="fas fa-thermometer-half m-r-5"></i>{"温度" + (index + 1)}
                                        </h4>
                                        <div id={"basic-line-w" + index}
                                             style={{height: "400px", userSelect: "none", position: "relative"}}></div>
                                    </div>
                            })}
                            {(this.state.info["maxbaojingshidu"] == undefined || this.state.info["maxbaojingshidu"] == null || this.state.info["maxbaojingshidu"] == "" || this.state.info["maxbaojingshidu"] == "NULL") ? null : this.state.info["maxbaojingshidu"].split(",").map((value, index) => {
                                if (value !== "NULL" && value !== "")
                                    return <div key={"maxbaojingwendu" + index} className="card-body analytics-info">
                                        <h4 className="card-title text-info"><i
                                            className="fas fa-thermometer-half m-r-5"></i>{"湿度" + (index + 1)}
                                        </h4>
                                        <div id={"basic-line-s" + index}
                                             style={{height: "400px", userSelect: "none", position: "relative"}}></div>
                                    </div>
                            })}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className={"card" + (this.state.full2 ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                历史数据: {this.state.info.name}({this.state.info.machine_id})
                                <div className="m-l-5 btn-group" id="dcbtn"></div>
                                <div className="card-actions">
                                    <a className="btn-minimize" data-action="expand" onClick={
                                        () => {
                                            this.state.full2 = !this.state.full2;
                                            this.setState({
                                                full2: this.state.full2
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="sorting" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>采集时间
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备位置
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备温度
                                                        </th>
                                                        <th className="" tabIndex="0" aria-controls="zero_config"
                                                            rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备湿度
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

//报警提醒
class GsmRemind extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            full: false,
            mul: [],
            click: (e, el) => {
                if (this.state.mul.indexOf(e) > -1) {
                    this.state.mul = this.state.mul.filter(value => value !== e)
                    el.firstElementChild.className = "far fa-square fa-2x";
                } else {
                    this.state.mul.push(e);
                    el.firstElementChild.className = "fas fa-check-square fa-2x";
                }
            },
            allclick: () => {
                if (this.state.mul.length !== document.getElementsByClassName("mul-click-icon").length) {
                    this.state.mul = [];
                    const ela = document.getElementsByClassName("mul-click-icon");
                    for (let i = 0; i < ela.length; i++) {
                        ela[i].click();
                    }
                } else {
                    this.state.mul = [];
                    const ela = document.getElementsByClassName("mul-click-icon");
                    for (let i = 0; i < ela.length; i++) {
                        if (ela[i].firstElementChild.className === "fas fa-check-square fa-2x") {
                            ela[i].firstElementChild.className = "far fa-square fa-2x";
                        }
                    }
                }
                // jQuery(".mdi-checkbox-blank-outline,.mdi-checkbox-marked-outline").click();
                // if (this.state.mul.indexOf(e) >-1) {
                //     this.state.mul = this.state.mul.filter(value => value!==e)
                //     el.firstElementChild.className = "far fa-square fa-2x";
                // } else{
                //     this.state.mul.push(e);
                //     el.firstElementChild.className = "fas fa-check-square fa-2x";
                // }
            }
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                报警管理
                                <a href="javascript:void(0)"
                                   className="btn waves-effect waves-light btn-outline-info btn-sm m-l-10"
                                   onClick={() => {
                                       window.gsm_page.state.allclick();
                                   }}>
                                    <i className="ti-check m-r-5"/>全选
                                </a>
                                <a href="javascript:void(0)"
                                   className="btn waves-effect waves-light btn-outline-info btn-sm m-l-10"
                                   onClick={() => {
                                       window.operate_id = window.gsm_page.state.mul.join(",");
                                   }}
                                   data-toggle="modal"
                                   data-target=".beizhu">
                                    <i className="ti-clipboard m-r-5"/>批量备注
                                </a>
                                <div className="modal fade chakanbeizhu" tabIndex="-1" role="dialog"
                                     aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-body">

                                                <div className="card">
                                                    <div className="card-header bg-info">
                                                        <h4 className="m-b-0 text-white">查看备注</h4>
                                                    </div>
                                                    <form>
                                                        <div className="form-body">
                                                            <div className="card-body">

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5" readOnly></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="form-actions">
                                                                <div className="card-body text-right ">
                                                                    <button type="button" className="btn btn-secondary"
                                                                            data-dismiss="modal">取 消
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade beizhu" tabIndex="-1" role="dialog"
                                     aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-body">

                                                <div className="card">
                                                    <div className="card-header bg-info">
                                                        <h4 className="m-b-0 text-white">填写备注</h4>
                                                    </div>
                                                    <form>
                                                        <div className="form-body">
                                                            <div className="card-body">

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                        <textarea className="form-control"
                                                                                  rows="5"
                                                                                  ref={input => this.cz_input = input}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {/*点击事件已处理*/}
                                                                        <span onClick={
                                                                            () => {
                                                                                this.cz_input.value = this.cz_input.value + "已处理"
                                                                            }
                                                                        }>已处理</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="form-actions">
                                                                <div className="card-body text-right ">
                                                                    <button type="button" className="btn btn-info"
                                                                            onClick={
                                                                                () => {
                                                                                    window.ajax({
                                                                                            cache: true,
                                                                                            type: "POST",
                                                                                            url: "/remind/operate",
                                                                                            data: {
                                                                                                i: window.operate_id,
                                                                                                t: this.cz_input.value
                                                                                            },
                                                                                            error: function () {
                                                                                            },
                                                                                            fail: function () {
                                                                                            },
                                                                                            success: function (response) {
                                                                                                window.toastr.success('处理成功', '消息', {
                                                                                                    "showMethod": "slideDown",
                                                                                                    "hideMethod": "slideUp",
                                                                                                    timeOut: 2000,
                                                                                                    positionClass: 'toast-top-center'
                                                                                                });
                                                                                                document.querySelector(".modal.fade.show").click();
                                                                                                window.gsm_to_remind();
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }><i
                                                                        className="fa fa-check"/> 提 交
                                                                    </button>
                                                                    <button type="button" className="btn btn-secondary"
                                                                            data-dismiss="modal">取 消
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                     aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                    <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title">确定删除该提醒吗？</h4>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-hidden="true">×
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary waves-effect"
                                                        data-dismiss="modal">取 消
                                                </button>
                                                <button type="button"
                                                        className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            // console.log(this.state.id)
                                                            window.ajax({
                                                                cache: true,
                                                                type: "POST",
                                                                url: "/remind/delete",
                                                                data: {
                                                                    i: this.state.id,
                                                                },
                                                                error: function () {
                                                                },
                                                                fail: function () {
                                                                },
                                                                success: function (data) {
                                                                    window.toastr.success('删除成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_remind();
                                                                }
                                                            })
                                                        }}> 提 交
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">

                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>
                                                        </th>
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>用户名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备编号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警类型
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警信息
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>短信状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警处理
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>用户名称</th>
                                                        <th>设备编号</th>
                                                        <th>设备名称</th>
                                                        <th>报警状态</th>
                                                        <th>报警类型</th>
                                                        <th>报警信息</th>
                                                        <th>短信状态</th>
                                                        <th>报警时间</th>
                                                        <th>报警处理</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//充值续费管理
class GsmPay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                充值续费管理
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>用户名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>充值续费状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>充值续费类型
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>充值续费金额
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>充值续费信息
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>充值续费时间
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>用户名称</th>
                                                        <th>充值续费状态</th>
                                                        <th>充值续费类型</th>
                                                        <th>充值续费金额</th>
                                                        <th>充值续费信息</th>
                                                        <th>充值续费时间</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

//管理员管理
class GsmAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            new: false,
            id: "",
            full: false,
        }
    }

    render() {
        window.gsm_page = this;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                        <div className={"card" + (this.state.full ? " card-fullscreen" : "")}>
                            <div className="card-header">
                                管理员管理 {(window.user_permission.indexOf("[AddAdmin]") != -1) ?
                                <a href="javascript:void(0)"
                                   className="btn waves-effect waves-light btn-outline-info btn-sm  m-l-10"
                                   data-toggle="modal"
                                   data-target=".adddevice"
                                   onClick={() => {
                                       window.gsm_page.setState({
                                           new: true,
                                       });
                                       window.gsm_page.mc_input.value = "";
                                       window.gsm_page.zt_input.value = "";
                                       window.gsm_page.zh_input.value = "";
                                       window.gsm_page.mm_input.value = "";
                                       window.gsm_page.ad_input.value = "-1";
                                   }}><i
                                    className="ti-plus m-r-5"></i>新增账号</a> : null}
                                <div className="card-actions">
                                    <a className="btn-minimize" onClick={
                                        () => {
                                            this.state.full = !this.state.full;
                                            this.setState({
                                                full: this.state.full
                                            })
                                        }
                                    }><i
                                        className="mdi mdi-arrow-expand"></i></a>
                                </div>
                            </div>

                            <div className="modal fade adddevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel"
                                 aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">账号名称:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.mc_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">状态:</label>
                                                    <select className="select2 form-control custom-select"
                                                            style={{width: "100%", height: "36px"}}
                                                            ref={input => this.zt_input = input}>
                                                        <optgroup label="">
                                                            <option value="正常">正常</option>
                                                            <option value="停用">停用</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">账号:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.zh_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">密码:</label>
                                                    <input type="text" className="form-control" id="recipient-name"
                                                           ref={input => this.mm_input = input}/>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="recipient-name"
                                                           className="control-label">小程序角色:</label>
                                                    <select className="select2 form-control custom-select"
                                                            style={{width: "100%", height: "36px"}}
                                                            ref={input => this.ad_input = input}>
                                                        <optgroup label="">
                                                            <option value="0">员工</option>
                                                            <option value="1">管理员</option>
                                                            <option value="-1">平台管理员</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-info waves-effect waves-light"
                                                    onClick={() => {
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: (this.state.new ? "/admin/add" : "/admin/revise"),
                                                            data: {
                                                                name: this.mc_input.value,
                                                                zt: this.zt_input.value,
                                                                zh: this.zh_input.value,
                                                                mm: this.mm_input.value,
                                                                ad: this.ad_input.value,
                                                                i: this.state.id,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                if (data === "\"success\"") {
                                                                    window.toastr.success('提交成功', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                    document.querySelector(".modal.fade.show").click();
                                                                    window.gsm_to_admin();
                                                                } else {
                                                                    window.toastr.error('账号重复', '消息', {
                                                                        "showMethod": "slideDown",
                                                                        "hideMethod": "slideUp",
                                                                        timeOut: 2000,
                                                                        positionClass: 'toast-top-center'
                                                                    });
                                                                }
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade deletedevice" tabIndex="-1" role="dialog"
                                 aria-labelledby="myModalLabel" aria-hidden="true" style={{display: "none"}}>
                                <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">确定删除该账号吗？</h4>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-hidden="true">×
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal">取 消
                                            </button>
                                            <button type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => {
                                                        window.ajax({
                                                            cache: true,
                                                            type: "POST",
                                                            url: "/admin/delete",
                                                            data: {
                                                                i: this.state.id,
                                                            },
                                                            error: function () {
                                                            },
                                                            fail: function () {
                                                            },
                                                            success: function (data) {
                                                                window.toastr.success('删除成功', '消息', {
                                                                    "showMethod": "slideDown",
                                                                    "hideMethod": "slideUp",
                                                                    timeOut: 2000,
                                                                    positionClass: 'toast-top-center'
                                                                });
                                                                document.querySelector(".modal.fade.show").click();
                                                                window.gsm_to_admin();
                                                            }
                                                        })
                                                    }}> 提 交
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                <div className="table-responsive">
                                    <div id="zero_config_wrapper"
                                         className="dataTables_wrapper container-fluid dt-bootstrap4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_length" id="zero_config_length"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="zero_config_filter" className="dataTables_filter"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="zero_config"
                                                       className="table table-striped table-bordered dataTable text-inputs-searching"
                                                       role="grid" aria-describedby="zero_config_info"
                                                       style={{width: "100%"}}>
                                                    <thead>
                                                    <tr role="row">
                                                        <th className="text-nowrap sorting" tabIndex="0"
                                                            aria-controls="zero_config"
                                                            rowSpan="1" colSpan="1" aria-sort="ascending"
                                                            aria-label="Name: activate to sort column descending"
                                                            style={{width: "0px"}}>序号
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>管理员名称
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>管理员状态
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>管理员账号
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>首页地图
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备分类
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>设备厂家
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>车辆
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>客户
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证管理
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证管理分类
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证统计
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>验证统计分类
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>报警
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>共享
                                                        </th>

                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>管理员
                                                        </th>


                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>添加时间
                                                        </th>
                                                        <th className="text-nowrap" tabIndex="0"
                                                            aria-controls="zero_config" rowSpan="1"
                                                            colSpan="1" style={{width: "0px"}}>操作
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tfoot>
                                                    <tr role="row" className="xx">
                                                        <th>序号</th>
                                                        <th>管理员名称</th>
                                                        <th>管理员状态</th>
                                                        <th>管理员账号</th>

                                                        <th>设备</th>
                                                        <th>设备分类</th>
                                                        <th>设备厂家</th>
                                                        <th>车辆</th>
                                                        <th>客户</th>
                                                        <th>验证管理</th>
                                                        <th>验证管理分类</th>
                                                        <th>验证统计</th>
                                                        <th>验证统计分类</th>
                                                        <th>报警</th>
                                                        <th>共享</th>
                                                        <th>管理员</th>

                                                        <th>添加时间</th>
                                                        <th>操作</th>
                                                    </tr>
                                                    </tfoot>

                                                    <tbody>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 col-md-5">
                                            </div>
                                            <div className="col-sm-12 col-md-7">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                     id="zero_config_paginate"></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


class GsmNotificationWidget extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a href="javascript:void(0)" className="message-item">
                <span
                    className={"btn btn-" + (this.props.GsmVal["itype"] == "温度报警" ? "success" : (this.props.GsmVal["itype"] == "温度报警" ? "info" : "danger")) + " btn-circle"}><i
                    className="fa fa-link"></i></span>
                <div className="mail-contnet">
                    <h5 className="message-title">【{this.props.GsmVal["machine_id"]}】{this.props.GsmVal["message"]}</h5>
                    <span className="time">{this.props.GsmVal["create_at"]}</span>
                </div>
            </a>)
    }
}

export {GsmMachineDetail2, GsmRemind, GsmPay, GsmAdmin, GsmNotificationWidget}
