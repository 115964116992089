import React from "react";
import $ from "jquery";
import {GsmCustomerClassification, GsmCustomer, GsmCustomerPermission, GsmCustomerAdd} from "./customer"
import echarts from "echarts"
import dayjs from "dayjs"

window.gsm_to_customerclassification = () => {
    window.gsm_app.setState({
        page: <GsmCustomerClassification/>
    })

    function add_tree_node(id, name, parent,dq_count) {

        let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
            "<div class=\"dd-handle dd3-handle\"></div>\n" +
            "<div class=\"dd3-content\">" + name +(dq_count==="NULL"?"":"<span class=\"m-l-5 btn btn-sm waves-effect waves-light btn-light\">到期数量"+ dq_count+"</span>")+
            "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[AddCustomerClassification]") != -1) ? "<a href=\"javascript:void(0)\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[CustomerMachine]") != -1) ? "<a href=\"javascript:void(0)\" class=\"btn btn-primary\" onclick=\"window.operate_id=" + id + ";window.gsm_history_save('window.gsm_to_customer()',false);\">查看设备</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseCustomerClassification]") != -1) ? "<a href=\"javascript:void(0)\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") +
            ((window.user_permission.indexOf("[DeleteCustomerClassification]") != -1) ? "<a href=\"javascript:void(0)\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") +
            "</div>\n" +
            "</div>\n" +
            "<ol></ol>\n" +
            "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;


        const id0 = id;
        const parent0 = parent;
        let count = 4;
        if ((window.user_permission.indexOf("[AddCustomerClassification]") != -1)) {
            add_div.firstElementChild.children[1].lastElementChild.children[4 - count].addEventListener("click", function () {
                window.gsm_history_save("window.gsm_to_customer_add(true,'" + id0 + "','" + parent0 + "')", false)
                // window.gsm_page.setState({
                //     new: true,
                //     id: id0,
                //     parent: parent0,
                // });
            }, false);
            count--;
        }
        if ((window.user_permission.indexOf("[CustomerMachine]") != -1)) {
            count--;
        }
        if ((window.user_permission.indexOf("[ReviseCustomerClassification]") != -1)) {
            add_div.firstElementChild.children[1].lastElementChild.children[4 - count].addEventListener("click", function () {
                window.gsm_history_save("window.gsm_to_customer_add(false,'" + id0 + "','" + parent0 + "')", false)
            }, false);
            count--;
        }

        if ((window.user_permission.indexOf("[DeleteCustomerClassification]") != -1)) {
            add_div.firstElementChild.children[1].lastElementChild.children[4 - count].addEventListener("click", function () {
                window.gsm_page.setState({
                    new: false,
                    id: id0,
                    parent: parent0,
                })
            }, false);
            count--;
        }
        if ((parent == "NULL") || (document.querySelector("[gsm-id=tree" + parent + "]") == undefined)) {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/customer/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                add_tree_node(json_data[i].id, json_data[i].name, json_data[i].parent, json_data[i].dq_count);
            }
        }
    })
}

window.gsm_to_customer = () => {
    window.ajax({
        cache: true,
        type: "POST",
        url: "/customer/classification/one?i=" + window.operate_id,
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            window.operate_data = JSON.parse(data)[0];
            window.gsm_app.setState({
                page: null
            })

            window.gsm_app.setState({
                page: <GsmCustomer/>
            })

            setTimeout(function () {
                $.ajax({
                    type: "POST",
                    url: "/customer?i=" + window.operate_id,
                    success: function (data) {
                        var useData = {};

                        for (var i = 0; i < data.length; i++) {
                            if (data[i]["parent"] == "NULL") {
                                if (useData["tree"] == null) {
                                    useData["tree"] = [];
                                }
                                useData["tree"].push(data[i]);
                            } else {
                                if (useData["tree" + data[i]["parent"]] == null) {
                                    useData["tree" + data[i]["parent"]] = [];
                                }
                                useData["tree" + data[i]["parent"]].push(data[i]);
                            }
                        }

                        function rangedata(parent) {
                            var ret = [];
                            if (useData["tree" + parent] != undefined) {
                                for (var i = 0; i < useData["tree" + parent].length; i++) {
                                    var push = {
                                        text: "<span onclick=\"window.loadingmachinelist('" + window.operate_id + "','" + useData["tree" + parent][i]["id"] + "')\">" + useData["tree" + parent][i]["name"] + "(" + (useData["tree" + parent][i]["count"] == "NULL" ? 0 : useData["tree" + parent][i]["count"]) + ")</span>",
                                        href: "",
                                        nodes: rangedata(useData["tree" + parent][i]["id"])
                                    };
                                    if (push["nodes"].length == 0) {
                                        delete push["nodes"]
                                    }
                                    ret.push(push);
                                }
                            }
                            return ret;
                        }

                        var $searchableTree = $('#treeview-searchable').treeview({
                            selectedBackColor: "#03a9f3",
                            onhoverColor: "rgba(0, 0, 0, 0.05)",
                            expandIcon: 'ti-plus',
                            collapseIcon: 'ti-minus',
                            nodeIcon: 'fa fa-folder',
                            data: rangedata(""),
                        });
                        var search = function (e) {
                            var pattern = $('#input-search').val();
                            var options = {
                                ignoreCase: $('#chk-ignore-case').is(':checked'),
                                exactMatch: $('#chk-exact-match').is(':checked'),
                                revealResults: $('#chk-reveal-results').is(':checked')
                            };
                            var results = $searchableTree.treeview('search', [pattern, options]);

                            var output = '<p>' + results.length + ' matches found</p>';
                            $.each(results, function (index, result) {
                                output += '<p style="cursor: pointer">- ' + result.text + '</p>';
                            });
                            $('#search-output').html(output);
                        }

                        $('#btn-search').on('click', search);
                        $('#input-search').on('keyup', search);

                        $('#btn-clear-search').on('click', function (e) {
                            $searchableTree.treeview('clearSearch');
                            $('#input-search').val('');
                            $('#search-output').html('');
                        });
                    }
                });


                $('#zero_config').DataTable({
                    language: {
                        "sProcessing": "处理中...",
                        "sLengthMenu": "显示 _MENU_ 项结果",
                        "sZeroRecords": "没有匹配结果",
                        "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                        "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                        "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                        "sInfoPostFix": "",
                        "sSearch": "搜索:",
                        "sUrl": "",
                        "sEmptyTable": "表中数据为空",
                        "sLoadingRecords": "载入中...",
                        "sInfoThousands": ",",
                        "oPaginate": {
                            "sFirst": "首页",
                            "sPrevious": "上页",
                            "sNext": "下页",
                            "sLast": "末页"
                        },
                        "oAria": {
                            "sSortAscending": ": 以升序排列此列",
                            "sSortDescending": ": 以降序排列此列"
                        }
                    },
                });
            }, 1);
        }
    })

}

window.gsm_to_customerpermission = () => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCustomerPermission/>
    })
    setTimeout(function () {
        $.fn.dataTable.ext.search = [];
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            pageLength: 3,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            //dom: 'Bfrtip',
            buttons: [
                {extend: 'excel', text: '<i class="far fa-file-excel"></i> EXCEL', exportOptions: {columns: ':visible'}},
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/customer/classification/permission",
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                // 客户状态
                {
                    "data": "status",
                    render: function (data, type, full, meta) {
                        switch (data) {
                            case "正常":
                                return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                                break;
                            default:
                                return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                        }
                    }
                },
                // 客户账号
                {"data": "account"},
                // 首页地图
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CHome]')\"><i class='fas fa-times-circle text-secondary'></i> 地图</div>";
                        } else if (full.ifield.indexOf("[CHome]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CHome]')\"><i class='fas fa-check-circle text-success'></i> 地图</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CHome]')\"><i class='fas fa-times-circle text-secondary'></i> 地图</div>";
                        }


                        return str
                    }
                },
                // 设备
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Machine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Machine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Machine]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Machine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachine]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachine]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MoneyMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改续费金额</div>";
                        } else if (full.ifield.indexOf("[MoneyMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MoneyMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改续费金额</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MoneyMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改续费金额</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        } else if (full.ifield.indexOf("[MachineOperate]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineOperate]')\"><i class='fas fa-check-circle text-success'></i> 报修</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        } else if (full.ifield.indexOf("[MachineDetail]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineDetail]')\"><i class='fas fa-check-circle text-success'></i> 历史</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        }


                        return str
                    }
                },
                // 设备分类
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[MachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddMachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachineClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施列表</div>";
                        } else if (full.ifield.indexOf("[MachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施添加</div>";
                        } else if (full.ifield.indexOf("[AddMachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachineClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification2]')\"><i class='fas fa-check-circle text-success'></i> 设施删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineOperate2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施操作</div>";
                        } else if (full.ifield.indexOf("[MachineOperate2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineOperate2]')\"><i class='fas fa-check-circle text-success'></i> 设施操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineOperate2]')\"><i class='fas fa-times-circle text-secondary'></i> 设施操作</div>";
                        }

                        return str
                    }
                },
                // 设备厂家
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[MachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddMachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseMachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteMachineSupplier]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineSupplier]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteMachineSupplier]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }


                        return str
                    }
                },
                // 车辆
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[CarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachine]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MoneyCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改续费金额</div>";
                        } else if (full.ifield.indexOf("[MoneyCarMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MoneyCarMachine]')\"><i class='fas fa-check-circle text-success'></i> 修改续费金额</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MoneyCarMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 修改续费金额</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        } else if (full.ifield.indexOf("[CarMachineOperate]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachineOperate]')\"><i class='fas fa-check-circle text-success'></i> 报修</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachineOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 报修</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        } else if (full.ifield.indexOf("[CarMachineDetail]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachineDetail]')\"><i class='fas fa-check-circle text-success'></i> 历史</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CarMachineDetail]')\"><i class='fas fa-times-circle text-secondary'></i> 历史</div>";
                        }


                        return str
                    }
                },
                // 报警
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Remind]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Remind]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Remind]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Remind]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteRemind]')\"><i class='fas fa-times-circle text-secondary'> 删除</i></div>";
                        } else if (full.ifield.indexOf("[DeleteRemind]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteRemind]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteRemind]')\"><i class='fas fa-times-circle text-secondary'> 删除</i></div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[RemindOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 操作</div>";
                        } else if (full.ifield.indexOf("[RemindOperate]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[RemindOperate]')\"><i class='fas fa-check-circle text-success'></i> 操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[RemindOperate]')\"><i class='fas fa-times-circle text-secondary'></i> 操作</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Notification]')\"><i class='fas fa-times-circle text-secondary'></i> 提醒</div>";
                        } else if (full.ifield.indexOf("[Notification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Notification]')\"><i class='fas fa-check-circle text-success'></i> 提醒</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Notification]')\"><i class='fas fa-times-circle text-secondary'></i> 提醒</div>";
                        }


                        return str
                    }
                },
                // 客户
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[CustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 详细</div>";
                        } else if (full.ifield.indexOf("[CustomerMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerMachine]')\"><i class='fas fa-check-circle text-success'></i> 详细</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 详细</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerClassificationPermission]')\"><i class='fas fa-times-circle text-secondary'></i> 权限列表</div>";
                        } else if (full.ifield.indexOf("[CustomerClassificationPermission]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerClassificationPermission]')\"><i class='fas fa-check-circle text-success'></i> 权限列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CustomerClassificationPermission]')\"><i class='fas fa-times-circle text-secondary'></i> 权限列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 权限操作</div>";
                        } else if (full.ifield.indexOf("[CheckCustomerClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckCustomerClassification]')\"><i class='fas fa-check-circle text-success'></i> 权限操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckCustomerClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 权限操作</div>";
                        }


                        return str
                    }
                },
                // 验证管理
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';
                        str += "<div class='d-flex flex-wrap th-width' style='min-width:450px;'>";
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        } else if (full.ifield.indexOf("[Report2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report2]')\"><i class='fas fa-check-circle text-success'></i> 管理</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportList2]')\"><i class='fas fa-times-circle text-secondary'></i> 列表（管理端）</div>";
                        } else if (full.ifield.indexOf("[ReportList2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportList2]')\"><i class='fas fa-check-circle text-success'></i> 列表（管理端）</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportList2]')\"><i class='fas fa-times-circle text-secondary'></i> 列表（管理端）</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report22]')\"><i class='fas fa-times-circle text-secondary'></i> 详细（管理端）</div>";
                        } else if (full.ifield.indexOf("[Report22]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report22]')\"><i class='fas fa-check-circle text-success'></i> 详细（管理端）</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report22]')\"><i class='fas fa-times-circle text-secondary'></i> 详细（管理端）</div>";
                        }


                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-1]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-1]')\"><i class='fas fa-check-circle text-success'></i> 流程1提交</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-2]')\"><i class='fas fa-check-circle text-success'></i> 流程2提交</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-3]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-3]')\"><i class='fas fa-check-circle text-success'></i> 流程3提交</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-4]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4提交</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-5]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-5]')\"><i class='fas fa-check-circle text-success'></i> 流程5提交</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5提交</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6提交</div>";
                        } else if (full.ifield.indexOf("[NextReport2-6]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-6]')\"><i class='fas fa-check-circle text-success'></i> 流程6提交</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[NextReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6提交</div>";
                        }


                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-1]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-1]')\"><i class='fas fa-check-circle text-success'></i> 流程1回退</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-2]')\"><i class='fas fa-check-circle text-success'></i> 流程2回退</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-3]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-3]')\"><i class='fas fa-check-circle text-success'></i> 流程3回退</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-4]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4回退</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-5]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-5]')\"><i class='fas fa-check-circle text-success'></i> 流程5回退</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-5]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5回退</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6回退</div>";
                        } else if (full.ifield.indexOf("[PreReport2-6]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-6]')\"><i class='fas fa-check-circle text-success'></i> 流程6回退</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[PreReport2-6]')\"><i class='fas fa-times-circle text-secondary'></i> 流程6回退</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1添加到日历</div>";
                        } else if (full.ifield.indexOf("[AddReport2-1]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport2-1]')\"><i class='fas fa-check-circle text-success'></i> 流程1添加到日历</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport2-1]')\"><i class='fas fa-times-circle text-secondary'></i> 流程1添加到日历</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3添加到日历</div>";
                        } else if (full.ifield.indexOf("[AddReport2-3]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport2-3]')\"><i class='fas fa-check-circle text-success'></i> 流程3添加到日历</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport2-3]')\"><i class='fas fa-times-circle text-secondary'></i> 流程3添加到日历</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[EXReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2审核文件</div>";
                        } else if (full.ifield.indexOf("[EXReport2-2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[EXReport2-2]')\"><i class='fas fa-check-circle text-success'></i> 流程2审核文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[EXReport2-2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2审核文件</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加文件</div>";
                        } else if (full.ifield.indexOf("[AddFileReport2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddFileReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2添加文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2修改文件</div>";
                        } else if (full.ifield.indexOf("[ReviseFileReport2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseFileReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2修改文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2修改文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除文件</div>";
                        } else if (full.ifield.indexOf("[DeleteFileReport2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteFileReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2删除文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteFileReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除文件</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加布点图</div>";
                        } else if (full.ifield.indexOf("[AddImageReport2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddImageReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2添加布点图</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2添加布点图</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除布点图</div>";
                        } else if (full.ifield.indexOf("[DeleteImageReport2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteImageReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程2删除布点图</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteImageReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程2删除布点图</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5添加图片</div>";
                        } else if (full.ifield.indexOf("[AddImage2Report2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddImage2Report2]')\"><i class='fas fa-check-circle text-success'></i> 流程5添加图片</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5添加图片</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5删除图片</div>";
                        } else if (full.ifield.indexOf("[DeleteImage2Report2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteImage2Report2]')\"><i class='fas fa-check-circle text-success'></i> 流程5删除图片</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteImage2Report2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程5删除图片</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[EXReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4审核文件</div>";
                        } else if (full.ifield.indexOf("[EXReport2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[EXReport2]')\"><i class='fas fa-check-circle text-success'></i> 流程4审核文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[EXReport2]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4审核文件</div>";
                        }


                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4添加文件</div>";
                        } else if (full.ifield.indexOf("[AddFileReport2-4]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddFileReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4添加文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4添加文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4修改文件</div>";
                        } else if (full.ifield.indexOf("[ReviseFileReport2-4]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseFileReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4修改文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4修改文件</div>";
                        }
                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4删除文件</div>";
                        } else if (full.ifield.indexOf("[DeleteFileReport2-4]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteFileReport2-4]')\"><i class='fas fa-check-circle text-success'></i> 流程4删除文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteFileReport2-4]')\"><i class='fas fa-times-circle text-secondary'></i> 流程4删除文件</div>";
                        }
                        str += "</div>";
                        return str
                    }
                },
                // 验证管理分类
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        } else if (full.ifield.indexOf("[ReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 管理</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 管理</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteReportClassification2]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReportClassification2]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReportClassification2]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        return str
                    }
                },
                // 验证统计
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Report]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Report]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddReport]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReport]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReport]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseReport]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReport]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReport]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReport]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteReport]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReport]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReport]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }


                        return str
                    }
                },
                // 验证统计分类
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[ReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteReportClassification]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReportClassification]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteReportClassification]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }


                        return str
                    }
                },
                // 共享账号
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Share]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Share]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Share]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Share]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddShare]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddShare]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddShare]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddShare]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseShare]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseShare]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseShare]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseShare]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteShare]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteShare]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteShare]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteShare]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineImg]')\"><i class='fas fa-times-circle text-secondary'></i> 设备图</div>";
                        } else if (full.ifield.indexOf("[MachineImg]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineImg]')\"><i class='fas fa-check-circle text-success'></i> 设备图</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[MachineImg]')\"><i class='fas fa-times-circle text-secondary'></i> 设备图</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备列表</div>";
                        } else if (full.ifield.indexOf("[ShareMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ShareMachine]')\"><i class='fas fa-check-circle text-success'></i> 设备列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备权限操作</div>";
                        } else if (full.ifield.indexOf("[CheckShareMachine]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckShareMachine]')\"><i class='fas fa-check-circle text-success'></i> 设备权限操作</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckShareMachine]')\"><i class='fas fa-times-circle text-secondary'></i> 设备权限操作</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 文件列表</div>";
                        } else if (full.ifield.indexOf("[ShareFile]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ShareFile]')\"><i class='fas fa-check-circle text-success'></i> 文件列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 文件列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 添加文件</div>";
                        } else if (full.ifield.indexOf("[AddShareFile]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddShareFile]')\"><i class='fas fa-check-circle text-success'></i> 添加文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 添加文件</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 删除文件</div>";
                        } else if (full.ifield.indexOf("[DeleteShareFile]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteShareFile]')\"><i class='fas fa-check-circle text-success'></i> 删除文件</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteShareFile]')\"><i class='fas fa-times-circle text-secondary'></i> 删除文件</div>";
                        }


                        return str
                    }
                },
                // 管理员
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        var str = '';

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Admin]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        } else if (full.ifield.indexOf("[Admin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Admin]')\"><i class='fas fa-check-circle text-success'></i> 列表</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[Admin]')\"><i class='fas fa-times-circle text-secondary'></i> 列表</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        } else if (full.ifield.indexOf("[AddAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddAdmin]')\"><i class='fas fa-check-circle text-success'></i> 添加</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[AddAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 添加</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        } else if (full.ifield.indexOf("[ReviseAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseAdmin]')\"><i class='fas fa-check-circle text-success'></i> 修改</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[ReviseAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 修改</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        } else if (full.ifield.indexOf("[DeleteAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteAdmin]')\"><i class='fas fa-check-circle text-success'></i> 删除</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[DeleteAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 删除</div>";
                        }

                        if (full.ifield == "NULL") {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 权限</div>";
                        } else if (full.ifield.indexOf("[CheckAdmin]") != -1) {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckAdmin]')\"><i class='fas fa-check-circle text-success'></i> 权限</div>";
                        } else {
                            str += "<div class='text-left f14 text-nowrap' onclick=\"window.customer_checkifieldfunc(this,'" + data + "','[CheckAdmin]')\"><i class='fas fa-times-circle text-secondary'></i> 权限</div>";
                        }


                        return str
                    }
                },

                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                    }
                },
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}

window.loadingmachinelist = (customerkey, classificationkey) => {
    window.operate_data = {};
    window.machine_k = classificationkey;
    window.machine_c = customerkey;

    $("#range_16").ionRangeSlider({
        //文档说明：https://blog.csdn.net/MisterSugarA/article/details/53825200
        grid: false,
        min: 1,
        max: 12,
        from: 1,
        postfix: " 月",
        onChange: function (data) {
            window.gsm_page.state.xfyf = parseInt(data.from);
            window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2);
        },
    });
    $('#zero_config').DataTable({
        destroy: true,
        language: {
            "sProcessing": "处理中...",
            "sLengthMenu": "显示 _MENU_ 项结果",
            "sZeroRecords": "没有匹配结果",
            "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
            "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
            "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
            "sInfoPostFix": "",
            "sSearch": "搜索:",
            "sUrl": "",
            "sEmptyTable": "表中数据为空",
            "sLoadingRecords": "载入中...",
            "sInfoThousands": ",",
            "oPaginate": {
                "sFirst": "首页",
                "sPrevious": "上页",
                "sNext": "下页",
                "sLast": "末页"
            },
            "oAria": {
                "sSortAscending": ": 以升序排列此列",
                "sSortDescending": ": 以降序排列此列"
            }
        },
        ajax: {
            type: "POST",
            url: "/customer/machine?i=" + customerkey + "&c=" + classificationkey,
            dataSrc: ''
        },
        "columns": [
            {"data": "machine_id"},
            {"data": "name"},
            {
                "data": "specification", render: function (data, type, full, meta) {
                    return data == "NULL" ? "" : data
                }
            },
            {
                "data": "status",
                render: function (data, type, full, meta) {
                    switch (data) {
                        case "在线":
                            return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>";
                            break;
                        case "离线":
                            return "<span class=\"btn btn-xs btn-secondary font-weight-100\">" + data + "</span>";
                            break;
                        default:
                            return "<span class=\"btn btn-xs btn-danger font-weight-100\">" + data + "</span>"
                    }
                }
            },
            {
                "data": "repair",
                render: function (data, type, full, meta) {
                    switch (data) {
                        case "正常":
                            return "<span class=\"btn btn-xs btn-success font-weight-100\">正常</span>";
                            break;
                        case "报修":
                            return "<span class=\"btn btn-xs btn-danger font-weight-100\">报修</span>";
                            break;
                        default:
                            return "<span class=\"btn btn-xs btn-success font-weight-100\">" + data + "</span>"
                    }
                }
            },
            {"data": "money"},
            {
                "data": "end_at",
                render: function (data, type, full, meta) {
                    if (data == "NULL")
                        return ""
                    else
                        return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                }
            },
            {
                "data": "data_at",
                render: function (data, type, full, meta) {
                    if (data == "NULL")
                        return ""
                    else
                        return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD")
                }
            },
            {
                "data": "id",
                render: function (data, type, full, meta) {
                    window.operate_data["t" + full["id"]] = full;
                    return ((window.user_permission.indexOf("[MachineDetail]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-success p-r-10\" data-toggle=\"tooltip\" title=\"\" data-original-title=\"查看\" onclick=\"window.gsm_history_save('window.gsm_to_machine_detail(" + full["id"] + ")',false)\" ><i class=\"fas fa-eye\"></i></a> " : "") +
                        (full["repair"] == '报修' ? "<a href=\"javascript:void(0)\"  data-original-title=\"报修\" data-toggle=\"modal\" data-target=\".chakanbeizhu\" class=\"text-danger p-r-10\" onclick='$(\".chakanbeizhu textarea.form-control\").val(\"" + full["remind_operate"] + "\\n\\n" + full["remind_name"] + "\\n" + full["remind_at"] + "\");'><i class=\"fas fa-wrench\"></i></a> " : ((window.user_permission.indexOf("[MachineOperate]") != -1) ? "<a href=\"javascript:void(0)\" data-toggle=\"modal\" data-target=\".beizhu\" data-original-title=\"报修\"  class=\"text-danger p-r-10\" onclick='window.operate_id = " + full["id"] + "'><i class=\"fas fa-wrench\"></i></a> " : "")) +
                        "<a  onMouseOver=\"$(this).tooltip('show')\"  href=\"javascript:void(0)\" class=\"text-warning p-r-10\" data-toggle=\"modal\" data-target=\".xfmoneydevice\" data-original-title=\"续费\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";window.gsm_page.state.mid = \"" + full["machine_id"] + "\";window.gsm_page.xfdj_input.value = \"" + full["money"] + "\";window.gsm_page.xfhj_input.value = parseFloat(window.gsm_page.state.xfyf * parseFloat(window.gsm_page.xfdj_input.value)).toFixed(2)'><i class=\"fas fa-yen-sign\"></i></a>  ";
                }
            }
        ]
    });
}

window.gsm_to_customer_add = (new0, id0, parent0) => {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmCustomerAdd/>
    })
    setTimeout(function () {
        window.gsm_page.setState({
            new: new0,
            id: id0,
            parent: parent0,
        });
        // var updateOutput = function (e) {
        //     var list = e.length ? e : $(e.target),
        //         output = list.data('output');
        //     if (window.JSON) {
        //         output.val(window.JSON.stringify(list.nestable('serialize'))); //, null, 2));
        //     } else {
        //         output.val('JSON browser support required for this demo.');
        //     }
        // };
        // $('#nestable').nestable({
        //     group: 1
        // }).on('change', updateOutput);
        // updateOutput($('#nestable').data('output', $('#nestable-output')));
        // 百度地图API功能
        if (!new0) {
            window.ajax({
                cache: true,
                type: "POST",
                url: "/customer/classification/one?i=" + id0,
                error: function () {
                },
                fail: function () {
                },
                success: function (data) {
                    const json_data = JSON.parse(data);
                    window.gsm_page.mc_input.value = json_data[0].name;
                    window.gsm_page.zt_input.value = json_data[0].status;
                    window.gsm_page.lxr_input.value = json_data[0].person;
                    window.gsm_page.lxdh_input.value = json_data[0].phone;
                    window.gsm_page.lxdz_input.value = json_data[0].address;
                    window.gsm_page.zh_input.value = json_data[0].account;
                    window.gsm_page.mm_input.value = json_data[0].password;
                    window.gsm_page.sheng_input.value = json_data[0].sheng;
                    window.gsm_page.shi_input.value = json_data[0].shi;
                    window.gsm_page.qu_input.value = json_data[0].qu;
                    window.gsm_page.cs_input.value = json_data[0]["count"] == "NULL" ? 0 : json_data[0]["count"];
                    window.gsm_page.wz_jd_input.value = json_data[0]["longitude"] == "NULL" ? 0.00 : json_data[0]["longitude"]
                    window.gsm_page.wz_wd_input.value = json_data["latitude"] == "NULL" ? 0.00 : json_data[0]["latitude"]
                }
            })
        } else {
            window.gsm_page.cs_input.value = 0;
            window.gsm_page.wz_jd_input.value = 0.00
            window.gsm_page.wz_wd_input.value = 0.00
        }
        // eslint-disable-next-line no-undef
        var map = new BMap.Map("allmap");    // 创建Map实例
        map.centerAndZoom("成都", 10);  // 初始化地图,设置中心点坐标和地图级别

        function showInfo(e) {
            map.clearOverlays()
            // eslint-disable-next-line no-undef
            map.addOverlay(new BMap.Marker(new BMap.Point(e.point.lng, e.point.lat)));  // 创建标注
            window.gsm_page.wz_jd_input.value = e.point.lng;
            window.gsm_page.wz_wd_input.value = e.point.lat;
        }

        map.addEventListener("click", showInfo);
        //添加地图类型控件
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }, 1)
}
