import React from "react";
import $ from "jquery";
import {GsmReport,GsmReportClassification,GsmReport2List,GsmReportClassification2,GsmReport2} from "./report"
import dayjs from "dayjs";
import "../boli/js/fullcalendar.js";
import "../boli/js/fullcalendar-zh-cn.js";
import "./lib/jquery.magnific-popup"

window.gsm_to_reportclassification=()=> {
    window.gsm_app.setState({
        page: <GsmReportClassification/>
    })

    function add_tree_node(id, name, parent, is_default) {

        let add_string = "<div class=\"col-6\" gsm-id=\"tree" + id + "\"><div class=\"card\"><div class='card-body'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-bodyx\"><span onclick=\"window.gsm_history_save('window.gsm_to_report(\\'" + id + "\\')')\">" + name + "</span>" +
            "<div class=\"m-l-5 btn-group btn-group-sm h-link\" role=\"group\">\n" +

            ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL") ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +

        //     ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL") ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const id0 = id;
        const parent0 = parent;

        let count = 2;
        if ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL")) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {

                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    function add_tree_node1(id, name, parent, is_default) {
        let add_string = "<div class=\"col-6\" gsm-id=\"tree" + id + "\"><div class='card' style=\"height:calc((100vh - 194px) / 2); border-radius:10px;\"><div class='card-body sc2 p0' style=\"overflow-y:scroll; overflow-x:hidden;\">\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-header text-center\">" + name +
            "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +

            ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL") ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +

        //     ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL") ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const id0 = id;
        const parent0 = parent;

        let count = 2;
        if ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL")) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {

                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/report/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                if (json_data[i].parent == "NULL") {
                    add_tree_node1(json_data[i].id, json_data[i].name, json_data[i].parent, json_data[i].is_default);
                } else {
                    add_tree_node(json_data[i].id, json_data[i].name, json_data[i].parent, json_data[i].is_default);
                }
            }

        }
    })
}

window.gsm_to_reportclassification2=()=> {
    window.gsm_app.setState({
        page: <GsmReportClassification2/>
    })

    function add_tree_node(id, name, parent, is_default) {

        let add_string = "<div class=\"col-6\" gsm-id=\"tree" + id + "\"><div class=\"card\"><div class='card-body'>\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-bodyx\"><span >" + name + "</span>" +
            "<div class=\"m-l-5 btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[ReviseReportClassification2]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteReportClassification2]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +

        //     ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL") ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const id0 = id;
        const parent0 = parent;

        let count = 2;
        // if ((window.user_permission.indexOf("[AddReportClassification2]") != -1 && parent != "NULL")) {
        //     count--
        //     add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {
        //
        //         window.gsm_page.setState({
        //             new: true,
        //             id: id0,
        //             parent: parent0,
        //         })
        //         window.gsm_page.mc_input.value = "";
        //     }, false);
        // }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseReportClassification2]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteReportClassification2]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").firstElementChild.firstElementChild.lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    function add_tree_node1(id, name, parent, is_default) {
        let add_string = "<div class=\"col-6\" gsm-id=\"tree" + id + "\"><div class='card' style=\"height:calc((100vh - 194px) / 2); border-radius:10px;\"><div class='card-body sc2 p0' style=\"overflow-y:scroll; overflow-x:hidden;\">\n" +
            "<div class=\"dd-handle dd3-handle d-none\"></div>\n" +
            "<div class=\"card-header text-center\">" + name +
            "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +
            ((window.user_permission.indexOf("[AddReportClassification2]") != -1) ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
            ((window.user_permission.indexOf("[ReviseReportClassification2]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
            ((window.user_permission.indexOf("[DeleteReportClassification2]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
            "</div>\n" +
            "</div>\n" +
            "<div class='row'></div>\n" +
            "</div></div></div>";

        // let add_string = "<li class=\"dd-item dd3-item\" gsm-id=\"tree" + id + "\">\n" +
        //     "<div class=\"dd-handle dd3-handle\"></div>\n" +
        //     "<div class=\"dd3-content\">" + name +
        //     "<div class=\"btn-group btn-group-sm h-link\" role=\"group\">\n" +

        //     ((window.user_permission.indexOf("[AddReportClassification]") != -1 && parent != "NULL") ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">新增</a>\n" : "") +
        //     ((window.user_permission.indexOf("[ReviseReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".adddevice\">修改</a>\n" : "") : "") +
        //     ((window.user_permission.indexOf("[DeleteReportClassification]") != -1 && is_default != 1) ? (parent != "NULL" ? "<a href=\"#\" class=\"btn btn-primary\" data-toggle=\"modal\" data-target=\".deletedevice\">删除</a>\n" : "") : "") +
        //     "</div>\n" +
        //     "</div>\n" +
        //     "<ol></ol>\n" +
        //     "</li>";

        let add_div = document.createElement("div");
        add_div.innerHTML = add_string;

        const name0 = name;
        const id0 = id;
        const parent0 = parent;

        let count = 2;
        if ((window.user_permission.indexOf("[AddReportClassification2]") != -1)) {
            count--
            add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[0].addEventListener("click", function () {

                window.gsm_page.setState({
                    new: true,
                    id: id0,
                    parent: parent0,
                })
                window.gsm_page.mc_input.value = "";
            }, false);
        }
        if (parent != "NULL") {
            if ((window.user_permission.indexOf("[ReviseReportClassification2]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })

                    window.gsm_page.mc_input.value = name0;
                }, false);
                count--
            }

            if ((window.user_permission.indexOf("[DeleteReportClassification2]") != -1 && is_default != 1)) {
                add_div.firstElementChild.firstElementChild.firstElementChild.children[1].lastElementChild.children[2 - count].addEventListener("click", function () {
                    window.gsm_page.setState({
                        new: false,
                        id: id0,
                        parent: parent0,
                    })
                }, false);
            }
        }
        if (parent == "NULL") {
            document.querySelector("[gsm-id=root]").lastElementChild.appendChild(add_div.firstElementChild);
        } else {
            document.querySelector("[gsm-id=tree" + parent + "]").lastElementChild.appendChild(add_div.firstElementChild);
        }

    }

    window.ajax({
        cache: true,
        type: "POST",
        url: "/report2/classification",
        error: function () {
        },
        fail: function () {
        },
        success: function (data) {
            const json_data = JSON.parse(data);

            document.querySelector("[gsm-id=root]").lastElementChild.innerHTML = "";
            for (let i = 0; i < json_data.length; i++) {
                if (json_data[i].parent == "NULL") {
                    add_tree_node1(json_data[i].id, json_data[i].name, json_data[i].parent, json_data[i].is_default);
                } else {
                    add_tree_node(json_data[i].id, json_data[i].name, json_data[i].parent, json_data[i].is_default);
                }
            }

        }
    })
}

window.gsm_to_report=(props_flag)=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmReport MMSFlag={props_flag}/>
    })
    if (window.user_permission.indexOf("[ReportClassification]") != -1) {
        window.ajax({
            cache: true,
            type: "POST",
            url: "/report/classification",
            error: function () {
            },
            fail: function () {
            },
            success: function (data) {
                const json_data = JSON.parse(data);
                window.gsm_page.state.select = [];
                for (let i = 0; i < json_data.length; i++) {
                    window.gsm_page.state.select.push(<option key={i} value={json_data[i].id}>{json_data[i].name}</option>)
                }
                window.gsm_page.setState({
                    select: window.gsm_page.state.select
                })
            }
        })
    }

    setTimeout(function () {
        $('.daterange').daterangepicker();
        $('#ts-error').on('click', function () {
            window.toastr.error('注意', '您的V001Z602名为V001Z602的设备于09-08 16:03后120分钟无数据上传，请关注信号和电量', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 4000,
                positionClass: 'toast-top-right'
            });
        });

        $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="far fa-file-excel"></i> EXCEL',
                exportOptions: {columns: ':visible'}
            },
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}],
            ajax: {
                type: "POST",
                url: "/report?key=" + (props_flag === undefined ? "" : props_flag),
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {"data": "classification_name"},
                {"data": "customer_name"},
                {
                    "data": "end_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL" || data == "") {
                            return ""
                        } else {
                            return data.replace(/ 00:00:00/g, "");
                        }
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file" + full.path + "','" + full.name + (full.path.split(".").length > 1 ? ("." + full.path.split(".")[(full.path.split(".").length - 1)]) : "") + "')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"下载\"><i class=\"fas fa-download\"></i></a>" +
                            ((window.user_permission.indexOf("[ReviseReport]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\"  data-toggle=\"modal\" data-target=\".adddevice\" title=\"\" data-original-title=\"编辑\" onclick='window.gsm_page.state.new = false;window.gsm_page.state.id = \"" + full["id"] + "\";" +
                                "                window.gsm_page.setState({file: \"" + full["name"] + "\"});" +
                                "                window.gsm_page.mc_input.value = \"" + full["name"] + "\";" +
                                "                window.gsm_page.dqsj_input.value = \"" + full["end_at"] + "\";" +
                                "                window.gsm_page.lx_input.value = \"" + full["classification"] + "\";" +
                                "                window.gsm_page.bz_input.value = \"" + full["remark"] + "\";" +
                                "'><i class=\"fas fa-pencil-alt\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[DeleteReport]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "")
                            + ((full.classification == '验证计划-年度验证计划') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/年度验证计划.docx','年度验证计划.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" :
                                (full.classification == '标准操作规程-冷藏库/冷冻库SOP') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/冷库管理规程操作规程.docx','冷库管理规程操作规程.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" :
                                    (full.classification == '标准操作规程-冷藏车SOP') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/冷藏车规程操作规程.docx','冷藏车规程操作规程.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" :
                                        (full.classification == '标准操作规程-保温箱SOP') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/保温箱规程操作规程.docx','保温箱规程操作规程.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" : "");
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        // if (props_flag === undefined) {
        //     $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        //     $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');
        // }

        // searching text
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);

    }, 1)
}

window.gsm_to_report_refresh=(key_word)=> {
    setTimeout(function () {
        $('.daterange').daterangepicker();
        $('#ts-error').on('click', function () {
            window.toastr.error('注意', '您的V001Z602名为V001Z602的设备于09-08 16:03后120分钟无数据上传，请关注信号和电量', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 4000,
                positionClass: 'toast-top-right'
            });
        });

        $('#zero_config').DataTable({
            destroy: true,
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            // dom: 'Bfrtip',
            buttons: [{
                extend: 'excel',
                text: '<i class="far fa-file-excel"></i> EXCEL',
                exportOptions: {columns: ':visible'}
            },
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}],
            ajax: {
                type: "POST",
                url: "/report?key=" + key_word,
                dataSrc: ''
            },
            "columns": [
                {"data": "id"},
                {"data": "name"},
                {"data": "classification_name"},
                {"data": "customer_name"},
                {
                    "data": "end_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL" || data == "") {
                            return ""
                        } else {
                            return data.replace(/ 00:00:00/g, "");
                        }
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file" + full.path + "','" + full.name + (full.path.split(".").length > 1 ? ("." + full.path.split(".")[(full.path.split(".").length - 1)]) : "") + "')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"下载\"><i class=\"fas fa-download\"></i></a>" +
                            ((window.user_permission.indexOf("[ReviseReport]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-inverse p-r-10\"  data-toggle=\"modal\" data-target=\".adddevice\" title=\"\" data-original-title=\"编辑\" onclick='window.gsm_page.state.new = false;window.gsm_page.state.id = \"" + full["id"] + "\";" +
                                "                window.gsm_page.setState({file: \"" + full["name"] + "\"});" +
                                "                window.gsm_page.mc_input.value = \"" + full["name"] + "\";" +
                                "                window.gsm_page.dqsj_input.value = \"" + full["end_at"] + "\";" +
                                "                window.gsm_page.lx_input.value = \"" + full["classification"] + "\";" +
                                "                window.gsm_page.bz_input.value = \"" + full["remark"] + "\";" +
                                "'><i class=\"fas fa-pencil-alt\"></i></a> " : "") +
                            ((window.user_permission.indexOf("[DeleteReport]") != -1) ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0)\" class=\"text-danger\" title=\"\"  data-toggle=\"modal\" data-target=\".deletedevice\" data-original-title=\"删除\" onclick='window.gsm_page.state.id = \"" + full["id"] + "\";'><i class=\"fas fa-trash\"></i></a>" : "")
                            + ((full.classification == '验证计划-年度验证计划') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/年度验证计划.docx','年度验证计划.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" :
                                (full.classification == '标准操作规程-冷藏库/冷冻库SOP') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/冷库管理规程操作规程.docx','冷库管理规程操作规程.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" :
                                    (full.classification == '标准操作规程-冷藏车SOP') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/冷藏车规程操作规程.docx','冷藏车规程操作规程.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" :
                                        (full.classification == '标准操作规程-保温箱SOP') ? "<a onMouseOver=\"$(this).tooltip('show')\" href=\"javascript:void(0);\" onclick=\"window.gsm_file_download('/file/保温箱规程操作规程.docx','保温箱规程操作规程.docx')\" class=\"text-success p-r-10\" title=\"\" data-toggle=\"tooltip\" data-original-title=\"SOP下载\"><i class=\"fas fa-download\"></i></a>" : "");
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // searching text
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1)
}

window.gsm_to_report2list=()=> {
    window.gsm_app.setState({
        page: null
    })
    window.gsm_app.setState({
        page: <GsmReport2List/>
    })


    setTimeout(function () {
        $('#ts-error').on('click', function () {
            window.toastr.error('标题', '内容', {
                "showMethod": "slideDown",
                "hideMethod": "slideUp",
                timeOut: 2000,
                positionClass: 'toast-top-center'
            });
        });
        $('#zero_config').DataTable({
            columnDefs: [
                {orderable: false, targets: 0},
                {orderable: false, targets: -1}
            ],
            colReorder: {
                fixedColumnsLeft: 1
            },
            bServerSide: true,
            language: {
                "sProcessing": "处理中...",
                "sLengthMenu": "显示 _MENU_ 项结果",
                "sZeroRecords": "没有匹配结果",
                "sInfo": "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                "sInfoEmpty": "显示第 0 至 0 项结果，共 0 项",
                "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                "sInfoPostFix": "",
                "sSearch": "搜索:",
                "sUrl": "",
                "sEmptyTable": "表中数据为空",
                "sLoadingRecords": "载入中...",
                "sInfoThousands": ",",
                "oPaginate": {
                    "sFirst": "首页",
                    "sPrevious": "上页",
                    "sNext": "下页",
                    "sLast": "末页"
                },
                "oAria": {
                    "sSortAscending": ": 以升序排列此列",
                    "sSortDescending": ": 以降序排列此列"
                }
            },
            searching: true,
            lengthChange: false,
            //serverSide: false,
            processing: true,
            //dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'excel',
                    text: '<i class="far fa-file-excel"></i> EXCEL',
                    exportOptions: {columns: ':visible'}
                },
                {extend: 'pdf', text: '<i class="far fa-file-pdf"></i> PDF', exportOptions: {columns: ':visible'}},
                {extend: 'print', text: '<i class="fas fa-print"></i> 打印', exportOptions: {columns: ':visible'}},
                {extend: 'colvis', text: '<i class="mdi mdi-filter"></i> 选择列', collectionLayout: 'selectlist'}
            ],
            ajax: {
                type: "POST",
                url: "/report2/list"
            },
            "order": [[1, "asc"]],
            "columns": [
                {
                    "data": "customer",
                    render: (data, type, full, mate) => {
                        return full["customer_name"]
                    }
                },
                {
                    "data": "status",
                    render: (data, type, full, mate) => {
                        switch (data) {
                            case "1":
                                return "验证计划";
                            case "2":
                                return "验证方案";
                            case "3":
                                return "验证实施";
                            case "4":
                                return "验证报告";
                            case "5":
                                return "验证培训";
                            case "6":
                                return "验证完毕";
                            default:
                                return "验证结束"
                        }
                    }
                },
                {
                    "data": "mingcheng",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "dianhua",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "dizhi",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "danhao",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return data
                    }
                },
                {
                    "data": "create_at",
                    render: function (data, type, full, meta) {
                        if (data == "NULL")
                            return ""
                        else
                            return dayjs(data.replace("T","").replace("Z","")).format("YYYY-MM-DD HH:mm:ss")
                    }
                },
                {
                    "data": "id",
                    render: function (data, type, full, meta) {
                        return "<a onMouseOver=\"$(this).tooltip('show')\" onclick=\"window.gsm_history_save('window.gsm_to_report22(" + full["id"] + "," + full["customer"] + ")')\" href=\"javascript:void(0)\" class=\"text-success p-r-10\" data-toggle=\"modal\" data-target=\".chakanbeizhu\" data-original-title=\"查看\"><i class=\"fas fa-eye\"></i></a>"
                    }
                }
            ]
        });

        //导出
        // $('#zero_config').DataTable().buttons(0, null).container().prependTo($('#zero_config').DataTable().table().container());
        $('#zero_config').DataTable().buttons().container().appendTo('#dcbtn');
        $('.buttons-print, .buttons-pdf, .buttons-excel, .buttons-colvis').addClass('btn btn-primary mr-1 m-b-10');

        // table filter
        $('.text-inputs-searching tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="form-control iconfont" placeholder="&#xe671;" style="padding: .175rem .85rem!important;border:0px!important;" />');
        });
        var tableSearching = $('.text-inputs-searching').DataTable();
        tableSearching.columns().every(function () {
            var that = this;
            $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== this.value) {
                    that
                        .search(this.value)
                        .draw();
                }
            });
        });
        var r = $('#zero_config tfoot tr');
        r.find('th').each(function () {
            $(this).css('padding', 1);
        });
        $('#zero_config thead').append(r);
    }, 1);
}

window.gsm_to_report22=(key, customer)=> {
    window.gsm_app.setState({
        page: null
    });
    window.gsm_app.setState({
        page: <GsmReport2 GsmCustomer={customer} GsmKey={key}/>
    });
    // if (window.user_permission.indexOf("[ReportClassification]") != -1) {
    //     window.ajax({
    //         cache: true,
    //         type: "POST",
    //         url: "/report/classification",
    //         error: function () {
    //         },
    //         fail: function () {
    //         },
    //         success: function (data) {
    //             const json_data = JSON.parse(data);
    //             window.gsm_page.state.select = [];
    //             for (let i = 0; i < json_data.length; i++) {
    //                 window.gsm_page.state.select.push(<option key={i} value={json_data[i].id}>{json_data[i].name}</option>)
    //             }
    //             window.gsm_page.setState({
    //                 select: window.gsm_page.state.select
    //             })
    //         }
    //     })
    // }

    setTimeout(function () {
        window.ajax({
            cache: true,
            type: "POST",
            url: "/report22",
            data: {
                c: key
            },
            error: function () {
            },
            fail: function () {
            },
            success: function (data2) {
                let json_data = JSON.parse(data2);

                window.gsm_page.setState({
                    status: parseInt(json_data.value.status),
                    choose_status: parseInt(json_data.value.status) >= 7 ? 1 : parseInt(json_data.value.status),
                    value: json_data.value,
                    image: json_data.image,
                    image2: json_data.image2,
                    file: json_data.file,
                    classification: json_data.classification,
                    info: json_data.info,
                }, () => {
                    window.gsm_to_report2_init(json_data.value, json_data.info)
                });
            }
        })


    }, 1)
}

window.gsm_to_report2=()=> {
    window.gsm_app.setState({
        page: null
    });
    window.gsm_app.setState({
        page: <GsmReport2 GsmCustomer=""/>
    });
    // if (window.user_permission.indexOf("[ReportClassification]") != -1) {
    //     window.ajax({
    //         cache: true,
    //         type: "POST",
    //         url: "/report/classification",
    //         error: function () {
    //         },
    //         fail: function () {
    //         },
    //         success: function (data) {
    //             const json_data = JSON.parse(data);
    //             window.gsm_page.state.select = [];
    //             for (let i = 0; i < json_data.length; i++) {
    //                 window.gsm_page.state.select.push(<option key={i} value={json_data[i].id}>{json_data[i].name}</option>)
    //             }
    //             window.gsm_page.setState({
    //                 select: window.gsm_page.state.select
    //             })
    //         }
    //     })
    // }

    setTimeout(function () {
        window.ajax({
            cache: true,
            type: "POST",
            url: "/report2",
            error: function () {
            },
            fail: function () {
            },
            success: function (data2) {
                let json_data = JSON.parse(data2);

                window.gsm_page.setState({
                    status: parseInt(json_data.value.status),
                    choose_status: parseInt(json_data.value.status),
                    value: json_data.value,
                    image: json_data.image,
                    image2: json_data.image2,
                    file: json_data.file,
                    classification: json_data.classification,
                    info: json_data.info,
                }, () => {
                    window.gsm_to_report2_init(json_data.value, json_data.info)
                });

                //步骤1-6
                // $("#example-basic").steps({
                //     headerTag: "h3",
                //     bodyTag: "section",
                //     transitionEffect: "slideLeft",
                //     autoFocus: true,
                //     /* Labels */
                //     labels: {
                //         cancel: "取消",
                //         current: "current step:",
                //         pagination: "Pagination",
                //         finish: "完成",
                //         next: "下一步",
                //         previous: "上一步",
                //         loading: "Loading ..."
                //     }
                // });
            }
        })


    }, 1)
}

window.gsm_to_report2_init=(json_data, json_info)=> {
    if (json_data.status == 2 || json_data.status == 5) {
        // baguetteBox.run('.tz-gallery');
        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true
            }
        });

        //禁止自动滚动 轮播图
        $('#galley').carousel({
            interval: false
        });
        var CalendarApp = function () {
            this.$body = $("body")
            // eslint-disable-next-line no-unused-expressions
            this.$calendar = $('.calendar_yz'),
                // this.$event = ('#calendar-events div.calendar-events'),
                // this.$categoryForm = $('#add-new-event form'),
                // this.$extEvents = $('#calendar-events'),
                // this.$modal = $('#my-event'),
                // this.$saveCategoryBtn = $('.save-category'),
                this.$calendarObj = null
        };
        // eslint-disable-next-line no-unused-expressions
        // this.$calendar = $('.calendar_yz'),
        //             // this.$event = ('#calendar-events div.calendar-events'),
        //             // this.$categoryForm = $('#add-new-event form'),
        //             // this.$extEvents = $('#calendar-events'),
        //             // this.$modal = $('#my-event'),
        //             // this.$saveCategoryBtn = $('.save-category'),
        //             this.$calendarObj = null

    } else {
        //datetime
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime',
            type: 'date'
        });
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime2',
            type: 'date'
        });
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime3',
            type: 'date'
        });
        // eslint-disable-next-line no-undef
        laydate.render({
            elem: '.ptdatetime4',
            type: 'date'
        });

        //日历 start

            var CalendarApp = function () {
                this.$body = $("body")
                // eslint-disable-next-line no-unused-expressions
                this.$calendar = $('.calendar_yz'),
                    // this.$event = ('#calendar-events div.calendar-events'),
                    // this.$categoryForm = $('#add-new-event form'),
                    // this.$extEvents = $('#calendar-events'),
                    // this.$modal = $('#my-event'),
                    // this.$saveCategoryBtn = $('.save-category'),
                    this.$calendarObj = null
            };

            /* on drop */
        // eslint-disable-next-line no-unused-expressions
            CalendarApp.prototype.onDrop = function (eventObj, date) {
                var $this = this;
                // retrieve the dropped element's stored Event Object
                var originalEventObject = eventObj.data('eventObject');
                var $categoryClass = eventObj.attr('data-class');
                // we need to copy it, so that multiple events don't have a reference to the same object
                var copiedEventObject = $.extend({}, originalEventObject);
                // assign it the date that was reported
                copiedEventObject.start = date;
                if ($categoryClass)
                    copiedEventObject['className'] = [$categoryClass];
                // render the event on the calendar
                $this.$calendar.fullCalendar('renderEvent', copiedEventObject, true);
                // is the "remove after drop" checkbox checked?
                if ($('#drop-remove').is(':checked')) {
                    // if so, remove the element from the "Draggable Events" list
                    eventObj.remove();
                }
            },
                /* on click on event */
                CalendarApp.prototype.onEventClick = function (calEvent, jsEvent, view) {
                    var $this = this;
                    var form = $("<form></form>");
                    form.append("<label>Change event name</label>");
                    form.append("<div class='input-group'><input class='form-control' type=text value='" + calEvent.title + "' /><span class='input-group-btn'><button type='submit' class='btn btn-success waves-effect waves-light'><i class='fa fa-check'></i> Save</button></span></div>");
                    $this.$modal.modal({
                        backdrop: 'static'
                    });
                    $this.$modal.find('.delete-event').show().end().find('.save-event').hide().end().find('.modal-body').empty().prepend(form).end().find('.delete-event').unbind('click').click(function () {
                        $this.$calendarObj.fullCalendar('removeEvents', function (ev) {
                            return (ev._id == calEvent._id);
                        });
                        $this.$modal.modal('hide');
                    });
                    $this.$modal.find('form').on('submit', function () {
                        calEvent.title = form.find("input[type=text]").val();
                        $this.$calendarObj.fullCalendar('updateEvent', calEvent);
                        $this.$modal.modal('hide');
                        return false;
                    });
                },
                /* on select */
                CalendarApp.prototype.onSelect = function (start, end, allDay) {
                    var $this = this;
                    $this.$modal.modal({
                        backdrop: 'static'
                    });
                    var form = $("<form></form>");
                    form.append("<div class='row'></div>");
                    form.find(".row")
                        .append("<div class='col-md-6'><div class='form-group'><label class='control-label'>Event Name</label><input class='form-control' placeholder='Insert Event Name' type='text' name='title'/></div></div>")
                        .append("<div class='col-md-6'><div class='form-group'><label class='control-label'>Category</label><select class='form-control' name='category'></select></div></div>")
                        .find("select[name='category']")
                        .append("<option value='bg-danger'>Danger</option>")
                        .append("<option value='bg-success'>Success</option>")
                        .append("<option value='bg-primary'>Primary</option>")
                        .append("<option value='bg-info'>Info</option>")
                        .append("<option value='bg-warning'>Warning</option></div></div>");
                    $this.$modal.find('.delete-event').hide().end().find('.save-event').show().end().find('.modal-body').empty().prepend(form).end().find('.save-event').unbind('click').click(function () {
                        form.submit();
                    });
                    $this.$modal.find('form').on('submit', function () {
                        var title = form.find("input[name='title']").val();
                        var beginning = form.find("input[name='beginning']").val();
                        var ending = form.find("input[name='ending']").val();
                        var categoryClass = form.find("select[name='category'] option:checked").val();
                        if (title !== null && title.length != 0) {
                            $this.$calendarObj.fullCalendar('renderEvent', {
                                title: title,
                                start: start,
                                end: end,
                                allDay: false,
                                className: categoryClass
                            }, true);
                            $this.$modal.modal('hide');
                        } else {
                            alert('You have to give a title to your event');
                        }
                        return false;

                    });
                    $this.$calendarObj.fullCalendar('unselect');
                },
                CalendarApp.prototype.enableDrag = function () {
                    //init events
                    $(this.$event).each(function () {
                        // create an Event Object (http://arshaw.com/fullcalendar/docs/event_data/Event_Object/)
                        // it doesn't need to have a start or end
                        var eventObject = {
                            title: $.trim($(this).text()) // use the element's text as the event title
                        };
                        // store the Event Object in the DOM element so we can get to it later
                        $(this).data('eventObject', eventObject);
                        // make the event draggable using jQuery UI
                        $(this).draggable({
                            zIndex: 999,
                            revert: true, // will cause the event to go back to its
                            revertDuration: 0 //  original position after the drag
                        });
                    });
                }
            /* Initializing */
        // eslint-disable-next-line no-unused-expressions
            CalendarApp.prototype.init = function () {
                this.enableDrag();
                /*  Initialize the calendar  */
                var date = new Date();
                var d = date.getDate();
                var m = date.getMonth();
                var y = date.getFullYear();
                var form = '';
                var today = new Date($.now());

                var defaultEvents = [];
                json_info.map(value => {
                    switch (value.parent) {
                        case "1":
                            defaultEvents.push({
                                title: value["name"],
                                start: new Date(Date.parse(value["op_at"].replace(/-/g, "/"))),
                                end: new Date(Date.parse(value["ed_at"].replace(/-/g, "/"))),
                                className: 'bg-danger'
                            });
                            break;
                        case "2":
                            defaultEvents.push({
                                title: value["name"],
                                start: new Date(Date.parse(value["op_at"].replace(/-/g, "/"))),
                                end: new Date(Date.parse(value["ed_at"].replace(/-/g, "/"))),
                                className: 'bg-info'
                            });
                            break;
                        case "3":
                            defaultEvents.push({
                                title: value["name"],
                                start: new Date(Date.parse(value["op_at"].replace(/-/g, "/"))),
                                end: new Date(Date.parse(value["ed_at"].replace(/-/g, "/"))),
                                className: 'bg-primary'
                            });
                            break;
                        case "4":
                            defaultEvents.push({
                                title: value["name"],
                                start: new Date(Date.parse(value["op_at"].replace(/-/g, "/"))),
                                end: new Date(Date.parse(value["ed_at"].replace(/-/g, "/"))),
                                className: 'bg-warning'
                            });
                            break;
                        case "5":
                            defaultEvents.push({
                                title: value["name"],
                                start: new Date(Date.parse(value["op_at"].replace(/-/g, "/"))),
                                end: new Date(Date.parse(value["ed_at"].replace(/-/g, "/"))),
                                className: 'bg-success'
                            });
                            break;
                    }
                });
                if (json_data["xitong_op"] !== "NULL") {
                    defaultEvents.push({
                        title: '系统验证',
                        start: new Date(Date.parse(json_data["xitong_op"].replace(/-/g, "/"))),
                        end: new Date(Date.parse(json_data["xitong_ed"].replace(/-/g, "/"))),
                        className: 'bg-danger'
                    });
                }
                if (json_data["lengcangku_op"] !== "NULL") {
                    defaultEvents.push({
                        title: '冷藏库验证',
                        start: new Date(Date.parse(json_data["lengcangku_op"].replace(/-/g, "/"))),
                        end: new Date(Date.parse(json_data["lengcangku_ed"].replace(/-/g, "/"))),
                        className: 'bg-info'
                    });
                }
                if (json_data["lengcangche_op"] !== "NULL") {
                    defaultEvents.push({
                        title: '冷藏车验证',
                        start: new Date(Date.parse(json_data["lengcangche_op"].replace(/-/g, "/"))),
                        end: new Date(Date.parse(json_data["lengcangche_ed"].replace(/-/g, "/"))),
                        className: 'bg-primary'
                    });
                }
                if (json_data["baowenxiang_op"] !== "NULL") {
                    defaultEvents.push({
                        title: '保温箱验证',
                        start: new Date(Date.parse(json_data["baowenxiang_op"].replace(/-/g, "/"))),
                        end: new Date(Date.parse(json_data["baowenxiang_ed"].replace(/-/g, "/"))),
                        className: 'bg-warning'
                    });
                }
                if (json_data["bingxiang_op"] !== "NULL") {
                    defaultEvents.push({
                        title: '冰箱/冰柜验证',
                        start: new Date(Date.parse(json_data["bingxiang_op"].replace(/-/g, "/"))),
                        end: new Date(Date.parse(json_data["bingxiang_ed"].replace(/-/g, "/"))),
                        className: 'bg-success'
                    });
                }
                var $this = this;
                $this.$calendarObj = $this.$calendar.fullCalendar({
                    // slotDuration: '00:15:00',
                    // /* If we want to split day time each 15minutes */
                    // minTime: '08:00:00',
                    // maxTime: '19:00:00',
                    defaultView: 'year',
                    handleWindowResize: true,

                    header: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'year,month'
                        // right: 'month,agendaWeek,agendaDay'
                    },
                    events: defaultEvents,
                    editable: false,
                    droppable: false, // this allows things to be dropped onto the calendar !!!
                    eventLimit: false, // allow "more" link when too many events
                    selectable: false,
                    locale: 'zh-cn',
                    contentHeight: true,
                    yearColumns:4,
                    // drop: function(date) { $this.onDrop($(this), date); },
                    // select: function(start, end, allDay) { $this.onSelect(start, end, allDay); },
                    // eventClick: function(calEvent, jsEvent, view) { $this.onEventClick(calEvent, jsEvent, view); }

                });

                //on new event
                // this.$saveCategoryBtn.on('click', function() {
                //     var categoryName = $this.$categoryForm.find("input[name='category-name']").val();
                //     var categoryColor = $this.$categoryForm.find("select[name='category-color']").val();
                //     if (categoryName !== null && categoryName.length != 0) {
                //         $this.$extEvents.append('<div class="calendar-events m-b-20" data-class="bg-' + categoryColor + '" style="position: relative;"><i class="fa fa-circle text-' + categoryColor + ' m-r-10" ></i>' + categoryName + '</div>')
                //         $this.enableDrag();
                //     }
                //
                // });
            },

                //init CalendarApp
                $.CalendarApp = new CalendarApp, $.CalendarApp.Constructor = CalendarApp

            //initializing CalendarApp
            // $(window).on('load', function() {
            $.CalendarApp.init()
        // });
        //日历 end
    }
}

window.report2_imageuploadfunc=(id2, customer)=> {
    var xhr;
    var fileObj = document.getElementById("inputGroupFile02").files[0];
    var form = new FormData();
    form["enctype"] = "multipart/form-data";
    form["Access-Control-Allow-Origin"] = "*";
    form.append("file", fileObj, fileObj.name);

    xhr = new XMLHttpRequest();
    xhr.open("post", window.file_server + "/upload", true);
    xhr.onload = function (evt) {
        // console.log(evt.target.responseText)
        var data = JSON.parse(evt.target.responseText);
        $.ajax({
            type: "POST",
            url: "/report2/image",
            data: {
                i: id2,
                c: customer,
                name: data.name,
                path: data.path,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('添加成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    if (window.gsm_page.state.customer === "") {
                        window.gsm_to_report2();
                    } else {
                        window.gsm_to_report22(window.gsm_page.state.key, window.gsm_page.state.customer)
                    }
                }
            }
        });
    };
    xhr.onerror = function (evt) {
    };
    xhr.send(form);
}

window.report2_image2uploadfunc=(id2, customer)=> {
    var xhr;
    var fileObj = document.getElementById("inputGroupFile03").files[0];
    var form = new FormData();
    form["enctype"] = "multipart/form-data";
    form["Access-Control-Allow-Origin"] = "*";
    form.append("file", fileObj, fileObj.name);

    xhr = new XMLHttpRequest();
    xhr.open("post", window.file_server + "/upload", true);
    xhr.onload = function (evt) {
        // console.log(evt.target.responseText)
        var data = JSON.parse(evt.target.responseText);
        $.ajax({
            type: "POST",
            url: "/report2/image2",
            data: {
                i: id2,
                c: customer,
                name: data.name,
                path: data.path,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('添加成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    if (window.gsm_page.state.customer === "") {
                        window.gsm_to_report2();
                    } else {
                        window.gsm_to_report22(window.gsm_page.state.key, window.gsm_page.state.customer)
                    }
                }
            }
        });
    };
    xhr.onerror = function (evt) {
        alert("errr")
    };
    xhr.send(form);
}

window.report2_fileuploadfunc=(id2, customer)=> {
    var xhr;
    var fileObj = document.getElementById("inputGroupFile01").files[0];
    var form = new FormData();
    form["enctype"] = "multipart/form-data";
    form["Access-Control-Allow-Origin"] = "*";
    form.append("file", fileObj, fileObj.name);

    xhr = new XMLHttpRequest();
    xhr.open("post", window.file_server + "/upload", true);
    xhr.onload = function (evt) {
        // console.log(evt.target.responseText)
        var data = JSON.parse(evt.target.responseText);
        $.ajax({
            type: "POST",
            url: "/report2/file",
            data: {
                i: id2,
                c: customer,
                name: data.name,
                path: data.path,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('添加成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    if (window.gsm_page.state.customer === "") {
                        window.gsm_to_report2();
                    } else {
                        window.gsm_to_report22(window.gsm_page.state.key, window.gsm_page.state.customer)
                    }
                }
            }
        });
    };
    xhr.onerror = function (evt) {
    };
    xhr.send(form);
}

window.report2_fileuploadfunc_re=(id0, id2, customer)=> {
    var xhr;
    var fileObj = document.getElementById("inputGroupFile03").files[0];
    var form = new FormData();
    form["enctype"] = "multipart/form-data";
    form["Access-Control-Allow-Origin"] = "*";
    form.append("file", fileObj, fileObj.name);

    xhr = new XMLHttpRequest();
    xhr.open("post", window.file_server + "/upload", true);
    xhr.onload = function (evt) {
        // console.log(evt.target.responseText)
        var data = JSON.parse(evt.target.responseText);
        $.ajax({
            type: "POST",
            url: "/report2/file/revise",
            data: {
                i0: id0,
                i: id2,
                c: customer,
                name: data.name,
                path: data.path,
            },
            dataType: "json",
            success: function (data) {
                if (data == "success") {
                    window.toastr.success('添加成功', '消息', {
                        "showMethod": "slideDown",
                        "hideMethod": "slideUp",
                        timeOut: 2000,
                        positionClass: 'toast-top-center'
                    });
                    if (window.gsm_page.state.customer === "") {
                        window.gsm_to_report2();
                    } else {
                        window.gsm_to_report22(window.gsm_page.state.key, window.gsm_page.state.customer)
                    }
                }
            }
        });
    };
    xhr.onerror = function (evt) {
    };
    xhr.send(form);
}
